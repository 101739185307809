import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PurchaseItemFile } from './purchase-item-file.model';

@Injectable()
export class PurchaseItemFileService {
  private readonly endpoint = `${environment.baseApiUrl}purchase-item-files`;

  constructor(private readonly http: HttpClient) {}

  public get(id: number): Observable<Blob> {
    return this.http.get(`${this.endpoint}/${id}`, {
      responseType: 'blob' as 'blob'
    });
  }

  public getMetadata(id: number): Observable<PurchaseItemFile> {
    return this.http.get<PurchaseItemFile>(`${this.endpoint}/${id}/metadata`);
  }

  public updateMetadata(
    id: number,
    purchaseItemFile: PurchaseItemFile
  ): Observable<PurchaseItemFile> {
    return this.http.put<PurchaseItemFile>(`${this.endpoint}/${id}/metadata`, purchaseItemFile);
  }
}
