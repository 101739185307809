<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body container-fluid">
  <form role="form" class="form-horizontal" autocomplete="off" novalidate>
    <label>{{ message }}</label>
    <select class="form-control input-sm" [(ngModel)]="selectedSupplierId" name="supplier">
      <option value="0" selected>Seçiniz</option>
      <option *ngFor="let supplier of asyncSuppliers | async" value="{{ supplier.id }}">{{ supplier.title }}</option>
    </select>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="activeModal.dismiss()">İptal</button>
  <button type="button" class="btn btn-primary" (click)="continue()" *ngIf="selectedSupplierId > 0">Devam</button>
</div>
