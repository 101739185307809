<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
    <small>{{ selectedProductGroupName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li><a routerLink="/stock">Stok</a></li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">Stok Listesi</h3>

      <div class="box-tools"></div>
    </div>
    <!-- /.box-header -->
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th *ngFor="let productGroupProperty of selectedProductGroup.productGroupProperties">
              {{ productGroupProperty.name }}
            </th>
            <th>
              Depodaki Miktar ({{
                selectedProductGroup.unit | measurementUnitSymbol: selectedProductGroup.unitGroup
              }})
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let stockItem of asyncStockItems
                | async
                | paginate
                  : { itemsPerPage: perPage, currentPage: pageNumber, totalItems: totalCount }
            "
          >
            <td *ngFor="let propertyValue of stockItem.product.propertyValues">
              {{ propertyValue.value }}
            </td>
            <td>{{ stockItem.stockCount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">
        Seçilmiş kriterlere göre {{ selectedProductGroupName }} grubunun stoğunda hiç ürün
        bulunamadı.
      </p>
    </div>
    <div class="box-footer clearfix">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>
</section>
