import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activePassive'
})
export class ActivePassivePipe implements PipeTransform {

  transform(value: boolean): string {
    if (value) {
      return '<i class="fa fa-check" aria-hidden="true"></i>';
    } else {
      return '<i class="fa fa-minus" aria-hidden="true"></i>';
    }
  }

}
