import { SaleListItem } from './sale-list-item.model';

export class SaleListResult {
  totalCount: number;
  offset: number;
  limit: number;
  query: string;
  sales: SaleListItem[];

  constructor(totalCount: number, offset: number, limit: number, query: string, sales: SaleListItem[]) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.query = query;
    this.sales = sales;
  }
}
