<div class="login-box forgot-password-box">
  <div class="login-logo">
    <a routerLink="/"><b>Dipendo</b></a>
  </div>
  <div class="login-box-body">
    <h1>Şifremi Unuttum</h1>
    <div class="alert alert-success" *ngIf="submitSuccess">
      Eğer yazdığınız e-posta adresi kayıtlarımızda mevcutsa size bir doğrulama e-postası gönderdik. Size gelen e-postadaki bağlantıya tıklayarak yeni şifrenizi belirleyebilirsiniz.
    </div>
    <p class="login-box-msg" *ngIf="!submitSuccess">Lütfen Dipendo hesabınıza giriş için kullandığınız e-posta adresinizi girin. Size şifrenizi sıfırlamanız için bir e-posta göndereceğiz.</p>
    <form role="form" #forgotPasswordForm="ngForm" (ngSubmit)="sendEmail()" autocomplete="off" novalidate *ngIf="!submitSuccess">
      <ng-container *ngIf="errors">
        <div class="form-group" *ngFor="let error of errors">
          <div class="alert alert-danger">
            {{error}}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="alertService.getAlert()">
        <div class="form-group">
          <div class="alert alert-danger">
            {{ alertService.getAlert().message }}
          </div>
        </div>
      </ng-container>
      <div class="form-group has-feedback">
        <input id="email" name="email" type="email" class="form-control" placeholder="E-posta" [(ngModel)]="model.email">
        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
      </div>
      <div class="form-group">
        <re-captcha site_key="6Ld7_wkUAAAAANiVL-Cq9Ip6mckpQZN42mLUSYzB" [(ngModel)]="model.captcha" name="captcha" language="tr" (captchaExpired)="resetCaptcha()"></re-captcha>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block btn-flat" id="login-form-submit-button" [disabled]="submitting">Doğrulama E-postası Gönder</button>
      </div>
    </form>

    <a routerLink="/login"><i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> Giriş Sayfasına Dön</a><br>
  </div>
</div>
