import { Component, OnInit } from '@angular/core';

import { AppComponent } from '../../core/app.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  pageName = 'Hakkında';

  constructor(private readonly appComponent: AppComponent) { }

  ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
  }

}
