import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertService } from '../core/alert.service';
import { AppComponent } from '../core/app.component';
import { AuthService } from '../core/auth/auth.service';
import { ChangePasswordBinding } from '../core/auth/change-password-binding.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnDestroy, OnInit {
  public pageName = 'Şifremi Sıfırla';
  public model: ChangePasswordBinding = new ChangePasswordBinding('', '', '', '', '');
  public submitSuccess = false;
  public submitting = false;
  public errors: string[] = [];

  @ViewChild(ReCaptchaComponent)
  private readonly captcha: ReCaptchaComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public readonly alertService: AlertService,
    private readonly appComponent: AppComponent,
    private readonly authService: AuthService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.model.email = params['email'];
      this.model.token = params['token'];
    });
  }

  public changePassword(): void {
    this.errors = [];
    this.submitting = false;

    if (this.model.email === '') {
      this.errors.push('Lütfen geçerli bir e-posta adresi girin.');
    }

    if (this.model.captcha === '') {
      this.errors.push('Lütfen robot olmadığınızı doğrulayın.');
    }

    if (this.errors.length === 0) {
      this.authService
        .changePassword(this.model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.submitSuccess = true;
          this.submitting = false;
        });
    } else {
      this.submitting = false;
    }
  }

  public resetCaptcha(): void {
    this.model.captcha = '';
    this.captcha.reset();
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
