import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { AppComponent } from '../../core/app.component';
import { CustomerListItem } from '../../customers/shared/customer-list-item.model';
import { CustomerService } from '../../customers/shared/customer.service';
import { ProductListItem } from '../../products/shared/product-list-item.model';
import { ProductService } from '../../products/shared/product.service';
import { ProductGroupListItem } from '../../settings/product-groups/shared/product-group-list-item.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { SupplierListItem } from '../../suppliers/shared/supplier-list-item.model';
import { SupplierService } from '../../suppliers/shared/supplier.service';
import { User } from '../../users/shared/user.model';
import { UserService } from '../../users/shared/user.service';
import { ReportListItem } from '../report/report-list-item.model';
import { ProfitabilityReportService } from './profitability-report.service';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-profitability-report',
  templateUrl: './profitability-report.component.html',
  styleUrls: ['./profitability-report.component.scss']
})
export class ProfitabilityReportComponent implements AfterViewInit, OnChanges, OnInit {
  public pageName = 'Kârlılık Raporları';
  public selectedSaleBeginDate: string = '';
  public selectedSaleEndDate: string = '';

  public suppliers: SupplierListItem[] = [];
  public asyncSuppliers: Observable<SupplierListItem[]>;
  public selectedSupplierId = 0;

  public customers: CustomerListItem[] = [];
  public asyncCustomers: Observable<CustomerListItem[]>;
  public selectedCustomerId = 0;

  public productGroups: ProductGroupListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupListItem[]>;
  public selectedProductGroups: number[] = [];

  public products: ProductListItem[] = [];
  public asyncProducts: Observable<ProductListItem[]>;
  public selectedProductValues: number[] = [];

  public users: User[] = [];
  public asyncUsers: Observable<User[]>;
  public selectedUserId = 0;

  public reportItems: ReportListItem;
  public asyncReportItems: Observable<ReportListItem>;
  public totalCount: number;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly supplierService: SupplierService,
    private readonly customerService: CustomerService,
    private readonly productGroupService: ProductGroupService,
    private readonly productService: ProductService,
    private readonly userService: UserService,
    private readonly reportService: ProfitabilityReportService,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe(() => {
      this.getSuppliers();
      this.getCustomers();
      this.getProductGroups();
      this.getUsers();
    });
  }

  public ngAfterViewInit() {
    $('.datepicker').datepicker({
      autoclose: true,
      format: 'dd.mm.yyyy',
      language: 'tr'
    });
  }

  public ngOnChanges() {
    this.cdRef.detectChanges();
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService.getAll(0, 1000000).pipe(
      tap(
        (res) => {
          this.productGroups = res.productGroups;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.productGroups),
      share()
    );
  }

  public getProducts() {
    this.asyncProducts = this.productService.getAll(0, 0, 1000000, this.selectedProductGroups).pipe(
      tap(
        (res) => {
          this.products = res.products;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.products),
      share()
    );
  }

  public getSuppliers() {
    this.asyncSuppliers = this.supplierService.getAll(0, 1000000).pipe(
      tap(
        (res) => {
          this.suppliers = res.suppliers;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.suppliers),
      share()
    );
  }

  public getCustomers() {
    this.asyncCustomers = this.customerService.getAll(0, 1000000).pipe(
      tap(
        (res) => {
          this.customers = res.customers;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.customers),
      share()
    );
  }

  public getUsers() {
    this.asyncUsers = this.userService.getAll(0, 1000000).pipe(
      tap(
        (res) => {
          this.users = res.users;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.users),
      share()
    );
  }

  public getReport() {
    this.selectedSaleBeginDate = moment($('#saleBeginDate').val(), 'DD.MM.YYYY')
      .startOf('day')
      .toISOString();
    this.selectedSaleEndDate = moment($('#saleEndDate').val(), 'DD.MM.YYYY')
      .endOf('day')
      .toISOString();

    this.selectedSaleBeginDate =
      this.selectedSaleBeginDate == null ? '' : this.selectedSaleBeginDate;
    this.selectedSaleEndDate = this.selectedSaleEndDate == null ? '' : this.selectedSaleEndDate;

    this.asyncReportItems = this.reportService
      .get(
        this.selectedSaleBeginDate,
        this.selectedSaleEndDate,
        this.selectedSupplierId,
        this.selectedCustomerId,
        this.selectedProductValues,
        this.selectedUserId
      )
      .pipe(
        tap(
          (res) => {
            this.totalCount = 1;
            this.reportItems = res.reports;
          },
          () => {
            this.totalCount = 0;
          }
        ),
        map((res) => res.reports),
        share()
      );
  }
}
