import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { share, takeUntil, tap } from 'rxjs/operators';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { DipendoFormComponent } from '../../shared/forms/dipendo-form/dipendo-form.component';
import { InputBase } from '../../shared/forms/input-base';
import { InputDatepicker } from '../../shared/forms/input-datepicker';
import { InputTextarea } from '../../shared/forms/input-textarea';
import { InputTextbox } from '../../shared/forms/input-textbox';
import { Supplier } from '../../suppliers/shared/supplier.model';
import { SupplierService } from '../../suppliers/shared/supplier.service';
import { PurchaseActiveConfirmComponent } from '../purchase-active-confirm/purchase-active-confirm.component';
import { PurchaseProductListComponent } from '../purchase-product-list/purchase-product-list.component';
import { PurchaseItem } from '../shared/purchase-item.model';
import { Purchase } from '../shared/purchase.model';
import { PurchaseService } from '../shared/purchase.service';

@Component({
  providers: [DecimalPipe],
  selector: 'app-purchase-edit',
  templateUrl: './purchase-edit.component.html',
  styleUrls: ['./purchase-edit.component.scss']
})
export class PurchaseEditComponent implements OnDestroy, OnInit {
  public pageName = 'Satın Alma Sepeti';
  public selectedSupplierId = 0;
  public selectedSupplierTitle = '';
  public supplier: Supplier;
  public asyncSupplier: Observable<Supplier>;
  public productGroupIds: number[] = [];
  public productGroups = new Map<number, ProductGroup>();
  public isLoaded: Observable<boolean>;
  public inputs: any[] = [];
  public totalPurchaseCount = 0;
  public purchase: Purchase = new Purchase(
    0,
    0,
    null,
    0,
    null,
    null,
    null,
    0,
    0,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    []
  );
  public headerSelectionList: any[] = [];
  public selectedCount = 0;
  public totalCount = 0;
  public isSaving = false;
  public totalMass = new Map<number, number>();
  public grandTotalMass: number = 0;
  public totalPriceList = new Map<number, string[]>();
  public grandTotalPriceList: string[] = [];
  private selectedId: number;
  private selectedPurchase: Purchase;

  @ViewChild(DipendoFormComponent, { static: true })
  private readonly formComponent: DipendoFormComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly decimalPipe: DecimalPipe,
    private readonly supplierService: SupplierService,
    private readonly productGroupService: ProductGroupService,
    private readonly purchaseService: PurchaseService,
    private readonly modalService: NgbModal,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    this.inputs = this.getInputs();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.selectedSupplierId = parseInt(params['supplierId'], 10) || 0;

      if (this.selectedSupplierId > 0) {
        this.getSupplier(this.selectedSupplierId);
        this.purchase = new Purchase(
          0,
          this.selectedSupplierId,
          null,
          0,
          null,
          null,
          null,
          0,
          0,
          '',
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          '',
          []
        );
      }
    });

    if (this.selectedSupplierId <= 0) {
      this.activatedRoute.url.subscribe((url) => {
        if (url[url.length - 1].path === 'edit') {
          this.activatedRoute.params.subscribe((params) => {
            this.selectedId = parseInt(params.id, 10) || -1;

            if (this.selectedId > 0) {
              this.getPurchase(this.selectedId);
            }
          });
        }
      });
    }
  }

  public getSupplier(id: number) {
    this.supplierService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.supplier = res;
        this.selectedSupplierTitle = res.title;
      });
  }

  public openProductsDialog(): void {
    const productsModal = this.modalService.open(PurchaseProductListComponent, {
      windowClass: 'large-modal'
    });

    productsModal.result.then(
      (res) => {
        this.purchase.purchaseItems = this.purchase.purchaseItems.concat(res);
        this.purchase.purchaseItems.filter((x) => !x.count).forEach((x) => (x.count = 1));
        this.getProductGroups();
      },
      (err) => {}
    );
  }

  public onChangeHeaderCheckbox(index: number) {
    this.purchase.purchaseItems.forEach((item) => {
      if (item.product.productGroupId === this.headerSelectionList[index].groupId) {
        item.selected = this.headerSelectionList[index].selected;
      }
    });

    this.selectedCount = this.purchase.purchaseItems.filter((x) => x.selected).length;
  }

  public onChangeItemCheckbox(item: PurchaseItem, index: number): void {
    let isAllSelectedForGroup = true;

    for (const purchaseItem of this.purchase.purchaseItems) {
      if (
        purchaseItem.product.productGroupId === item.product.productGroupId &&
        !purchaseItem.selected
      ) {
        isAllSelectedForGroup = false;
      }
    }

    this.headerSelectionList[index].selected = isAllSelectedForGroup;

    this.selectedCount = this.purchase.purchaseItems.filter((x) => x.selected).length;
  }

  public deleteSelectedItems(): void {
    this.purchase.purchaseItems.forEach((item) => {
      if (item.selected) {
        this.purchase.purchaseItems.splice(this.purchase.purchaseItems.indexOf(item), 1);
      }
    });

    this.selectedCount = this.purchase.purchaseItems.filter((x) => x.selected).length;

    this.getProductGroups();
  }

  public save(status: number): void {
    this.isSaving = true;
    if (status === 2) {
      const purchaseInsert: Purchase = { ...this.purchase };
      purchaseInsert.status = status;
      purchaseInsert.recordTime = moment()
        .utc()
        .toDate();
      purchaseInsert.orderTime = moment()
        .utc()
        .toDate();
      purchaseInsert.letterOfCreditTime = moment(
        this.formComponent.form.value.letterOfCreditTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.estimatedShippingTime = moment(
        this.formComponent.form.value.estimatedShippingTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.estimatedArrivalTime = moment(
        this.formComponent.form.value.estimatedArrivalTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.arrivalTime = moment(
        this.formComponent.form.value.arrivalTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.invoiceTime = moment(
        this.formComponent.form.value.invoiceTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.invoiceNumber = this.formComponent.form.value.invoiceNumber
        ? this.formComponent.form.value.invoiceNumber
        : null;
      purchaseInsert.customsDeclarationTime = moment(
        this.formComponent.form.value.customsDeclarationTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.customsDeclarationNumber = this.formComponent.form.value
        .customsDeclarationNumber
        ? this.formComponent.form.value.customsDeclarationNumber
        : null;
      purchaseInsert.explanation = this.formComponent.form.value.explanation
        ? this.formComponent.form.value.explanation
        : 'Satın alma yapıldı.';

      if (this.purchase.purchaseItems && this.purchase.purchaseItems.length > 0) {
        purchaseInsert.purchaseItems = [];

        for (const item of this.purchase.purchaseItems) {
          for (let i = 0; i < item.count; ++i) {
            purchaseInsert.purchaseItems.push(item);
          }
        }
      }

      if (purchaseInsert.purchaseId > 0) {
        purchaseInsert.purchaseItems.forEach((p) => (p.purchaseItemId = 0));
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.letterOfCreditTime = purchaseInsert.letterOfCreditTime)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.estimatedShippingTime = purchaseInsert.estimatedShippingTime)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.estimatedArrivalTime = purchaseInsert.estimatedArrivalTime)
        );
        purchaseInsert.purchaseItems.forEach((p) => (p.arrivalTime = purchaseInsert.arrivalTime));
        purchaseInsert.purchaseItems.forEach((p) => (p.invoiceTime = purchaseInsert.invoiceTime));
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.invoiceNumber = purchaseInsert.invoiceNumber)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.customsDeclarationTime = purchaseInsert.customsDeclarationTime)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.customsDeclarationNumber = purchaseInsert.customsDeclarationNumber)
        );

        this.purchaseService
          .update(purchaseInsert.purchaseId, purchaseInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              this.alertService.setNextAlert(
                AlertType.success,
                'Satın alma sepeti teklif olarak kayıt edildi.'
              );
              this.router.navigate(['/purchases']);
            },
            () => {
              this.isSaving = false;
            }
          );
      } else {
        this.purchaseService
          .insert(purchaseInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              this.alertService.setNextAlert(
                AlertType.success,
                'Satın alma sepeti teklif olarak kayıt edildi.'
              );
              this.router.navigate(['/purchases']);
            },
            () => {
              this.isSaving = false;
            }
          );
      }
    } else if (status === 3) {
      this.formComponent.validate();

      if (!this.formComponent.form.valid) {
        this.isSaving = false;

        return;
      }

      if (!this.checkFormInputs()) {
        this.alertService.setAlert(
          AlertType.danger,
          'Sepetteki ürünler için Satın Alma Fiyatı ve Miktar alanları doldurulmalıdır.'
        );
        this.isSaving = false;

        return;
      }

      const purchaseInsert: Purchase = { ...this.purchase };
      purchaseInsert.status = status;
      purchaseInsert.recordTime = moment()
        .utc()
        .toDate();
      purchaseInsert.orderTime = moment()
        .utc()
        .toDate();
      purchaseInsert.letterOfCreditTime = moment(
        this.formComponent.form.value.letterOfCreditTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.estimatedShippingTime = moment(
        this.formComponent.form.value.estimatedShippingTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.estimatedArrivalTime = moment(
        this.formComponent.form.value.estimatedArrivalTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.arrivalTime = moment(
        this.formComponent.form.value.arrivalTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.invoiceTime = moment(
        this.formComponent.form.value.invoiceTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.invoiceNumber = this.formComponent.form.value.invoiceNumber
        ? this.formComponent.form.value.invoiceNumber
        : null;
      purchaseInsert.customsDeclarationTime = moment(
        this.formComponent.form.value.customsDeclarationTime,
        'DD.MM.YYYY'
      ).toDate();
      purchaseInsert.customsDeclarationNumber = this.formComponent.form.value
        .customsDeclarationNumber
        ? this.formComponent.form.value.customsDeclarationNumber
        : null;
      purchaseInsert.explanation = this.formComponent.form.value.explanation
        ? this.formComponent.form.value.explanation
        : 'Satın alma yapıldı.';

      if (this.purchase.purchaseItems && this.purchase.purchaseItems.length > 0) {
        purchaseInsert.purchaseItems = [];

        for (const item of this.purchase.purchaseItems) {
          for (let i = 0; i < item.count; ++i) {
            this.totalPurchaseCount++;
            purchaseInsert.purchaseItems.push(item);
          }
        }

        for (let i = 0; i < this.totalPurchaseCount; i++) {
          purchaseInsert.purchaseItems[i].reservableCount =
            purchaseInsert.purchaseItems[i].purchaseCount;
        }
      }

      if (purchaseInsert.purchaseId > 0) {
        purchaseInsert.purchaseItems.forEach((p) => (p.purchaseItemId = 0));
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.letterOfCreditTime = purchaseInsert.letterOfCreditTime)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.estimatedShippingTime = purchaseInsert.estimatedShippingTime)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.estimatedArrivalTime = purchaseInsert.estimatedArrivalTime)
        );
        purchaseInsert.purchaseItems.forEach((p) => (p.arrivalTime = purchaseInsert.arrivalTime));
        purchaseInsert.purchaseItems.forEach((p) => (p.invoiceTime = purchaseInsert.invoiceTime));
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.invoiceNumber = purchaseInsert.invoiceNumber)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.customsDeclarationTime = purchaseInsert.customsDeclarationTime)
        );
        purchaseInsert.purchaseItems.forEach(
          (p) => (p.customsDeclarationNumber = purchaseInsert.customsDeclarationNumber)
        );

        if (this.wasPurchaseDraft()) {
          purchaseInsert.purchaseItems.forEach((p) => (p.status = 1));
        }

        this.purchaseService
          .update(purchaseInsert.purchaseId, purchaseInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              this.alertService.setNextAlert(
                AlertType.success,
                'Satın alma sepetiniz sisteme başarıyla girildi.'
              );
              this.router.navigate(['/purchases']);
            },
            () => {
              this.isSaving = false;
            }
          );
      } else {
        purchaseInsert.recordTime = new Date();

        this.purchaseService
          .insert(purchaseInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              this.alertService.setNextAlert(
                AlertType.success,
                'Satın alma sepetiniz sisteme başarıyla girildi.'
              );
              this.router.navigate(['/purchases']);
            },
            () => {
              this.isSaving = false;
            }
          );
      }
    }
  }

  public deletePurchase(): void {
    const confirmModal = this.modalService.open(PurchaseActiveConfirmComponent);
    confirmModal.componentInstance.purchaseId = this.purchase.purchaseId;
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message =
      'Satın Alma Sepetiniz tekliflerden silinecektir. Onaylıyor musunuz?';

    confirmModal.result.then(
      (res) => {
        this.purchaseService
          .delete(this.purchase.purchaseId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((purchaseResult) => {
            this.alertService.setNextAlert(
              AlertType.success,
              'Seçtiğiniz teklif başarıyla silindi.'
            );
            this.router.navigate(['/purchases']);
          });
      },
      (err) => {}
    );
  }

  public checkFormInputs(): boolean {
    for (const item of this.purchase.purchaseItems) {
      if (item.count <= 0 || item.cost <= 0 || item.purchaseCount <= 0) {
        return false;
      }
    }

    return true;
  }

  public onInputBlur(item: PurchaseItem): void {
    item.totalCount = item.count * item.purchaseCount;
    item.totalMass = item.count * item.purchaseCount * item.product.unitMass;
    item.totalPrice = item.count * item.purchaseCount * item.cost;

    this.totalMass.clear();
    this.grandTotalMass = 0;

    this.purchase.purchaseItems.forEach((p) => {
      if (!this.totalMass.get(p.product.productGroupId)) {
        this.totalMass.set(p.product.productGroupId, 0);
      }

      if (p.product.unitOfMass === 'mg') {
        this.totalMass.set(
          p.product.productGroupId,
          this.totalMass.get(p.product.productGroupId) + p.totalMass / 1000000
        );
      } else if (p.product.unitOfMass === 'g') {
        this.totalMass.set(
          p.product.productGroupId,
          this.totalMass.get(p.product.productGroupId) + p.totalMass / 1000
        );
      } else {
        this.totalMass.set(
          p.product.productGroupId,
          this.totalMass.get(p.product.productGroupId) + p.totalMass
        );
      }
    });

    this.totalMass.forEach((mass: number) => {
      this.grandTotalMass += mass;
    });

    this.calculateTotalPrice(this.purchase.purchaseItems);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getPurchase(id: number) {
    this.purchaseService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        if (res && res.purchaseItems && res.purchaseItems.length > 0) {
          const purchaseDetail: Purchase = { ...res };
          purchaseDetail.purchaseItems = [];

          for (const item of res.purchaseItems) {
            const matched = purchaseDetail.purchaseItems.filter(
              (p) => p.productId === item.productId && p.purchaseCount === item.purchaseCount
            );
            if (matched && matched.length) {
              purchaseDetail.purchaseItems
                .filter(
                  (p) => p.productId === item.productId && p.purchaseCount === item.purchaseCount
                )
                .forEach((p) => (p.count += 1));
            } else {
              item.count = 1;
              purchaseDetail.purchaseItems.push(item);
            }
          }

          for (const item of purchaseDetail.purchaseItems) {
            this.onInputBlur(item);
          }

          this.selectedPurchase = purchaseDetail;
          this.purchase = purchaseDetail;
        } else {
          this.selectedPurchase = res;
          this.purchase = res;
        }

        this.fillForm();
        this.getSupplier(res.supplierId);
        this.getProductGroups();
      });
  }

  private fillForm() {
    if (this.formComponent.form) {
      this.formComponent.setValues(this.purchase);
    }
  }

  private getProductGroup(id: number) {
    this.productGroupService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        if (!this.productGroups.has(res.productGroupId)) {
          this.productGroups.set(res.productGroupId, res);
        }
      });
  }

  private getProductGroups() {
    if (this.purchase && this.purchase.purchaseItems) {
      this.totalCount = this.purchase.purchaseItems.length;
    }

    this.productGroupIds = [];
    this.headerSelectionList = [];

    if (this.purchase.purchaseItems !== null && this.purchase.purchaseItems.length > 0) {
      for (const item of this.purchase.purchaseItems) {
        if (this.productGroupIds.indexOf(item.product.productGroupId) < 0) {
          this.productGroupIds.push(item.product.productGroupId);
          this.headerSelectionList.push({ groupId: item.product.productGroupId, selected: false });
        }
      }

      for (const id of this.productGroupIds) {
        if (!this.productGroups.has(id)) {
          this.getProductGroup(id);
        }
      }

      for (const purchaseItem of this.purchase.purchaseItems) {
        this.onInputBlur(purchaseItem);
      }
    }
  }

  private calculateTotalPrice(itemList: PurchaseItem[]): void {
    const priceList = new Map<number, Map<string, number>>();

    for (const item of this.purchase.purchaseItems) {
      if (item.totalPrice > 0) {
        if (!priceList.get(item.product.productGroupId)) {
          priceList.set(item.product.productGroupId, new Map<string, number>());
        }

        if (priceList.get(item.product.productGroupId).get(item.product.currencyCode)) {
          const totalAmount =
            priceList.get(item.product.productGroupId).get(item.product.currencyCode) +
            item.totalPrice;
          priceList.get(item.product.productGroupId).set(item.product.currencyCode, totalAmount);
        } else {
          priceList
            .get(item.product.productGroupId)
            .set(item.product.currencyCode, item.totalPrice);
        }
      }
    }

    this.totalPriceList.clear();
    this.grandTotalPriceList = [];
    const grandPriceList = new Map<string, number>();

    priceList.forEach((value: Map<string, number>, key: number) => {
      if (!this.totalPriceList.get(key)) {
        this.totalPriceList.set(key, []);
      }

      value.forEach((totalPrice: number, currencyCode: string) => {
        this.totalPriceList
          .get(key)
          .push(`${this.decimalPipe.transform(totalPrice, '1.2-4')} ${currencyCode}`);

        if (!grandPriceList.get(currencyCode)) {
          grandPriceList.set(currencyCode, 0);
        }

        grandPriceList.set(currencyCode, grandPriceList.get(currencyCode) + totalPrice);
      });
    });

    grandPriceList.forEach((totalPrice: number, currencyCode: string) => {
      this.grandTotalPriceList.push(
        `${this.decimalPipe.transform(totalPrice, '1.2-4')} ${currencyCode}`
      );
    });
  }

  private wasPurchaseDraft() {
    return this.purchase.status === 2;
  }

  private getInputs(): InputBase<any>[] {
    const inputs: InputBase<any>[] = [
      new InputDatepicker({
        key: 'letterOfCreditTime',
        label: 'Akreditif Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        maxDates: 'estimatedShippingTime,estimatedArrivalTime,arrivalTime',
        required: false,
        order: 1
      }),
      new InputDatepicker({
        key: 'estimatedShippingTime',
        label: 'Tahmini Gönderim Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        minDates: 'letterOfCreditTime',
        maxDates: 'estimatedArrivalTime,arrivalTime',
        required: false,
        order: 2
      }),
      new InputDatepicker({
        key: 'estimatedArrivalTime',
        label: 'Tahmini Varış Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        minDates: 'estimatedShippingTime,letterOfCreditTime',
        maxDates: 'arrivalTime',
        required: false,
        order: 3,
        orientation: 'bottom'
      }),
      new InputDatepicker({
        key: 'arrivalTime',
        label: 'Kesin Varış Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        minDates: 'estimatedArrivalTime,estimatedShippingTime,letterOfCreditTime',
        required: false,
        order: 4
      }),
      new InputDatepicker({
        key: 'invoiceTime',
        label: 'Fatura Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        minDates: '',
        required: false,
        order: 5
      }),
      new InputTextbox({
        key: 'invoiceNumber',
        label: 'Fatura No',
        type: 'text',
        value: '',
        maxLength: 50,
        required: false,
        order: 6
      }),
      new InputDatepicker({
        key: 'customsDeclarationTime',
        label: 'Gümrük Beyannamesi Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        minDates: '',
        required: false,
        order: 7
      }),
      new InputTextbox({
        key: 'customsDeclarationNumber',
        label: 'Gümrük Beyannamesi No',
        type: 'text',
        value: '',
        maxLength: 50,
        required: false,
        order: 8
      })
    ];

    if (this.purchase) {
      inputs.push(
        new InputTextarea({
          key: 'explanation',
          label: 'Açıklama',
          rowCount: 5,
          value: '',
          required: false,
          order: 9
        })
      );
    }

    return inputs.sort((a, b) => a.order - b.order);
  }
}
