import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatPipe, FromUtcPipe, LocalePipe, LocalTimePipe } from 'ngx-moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import zipcelx from 'zipcelx-es5';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { IgnoreInvalidDatePipe } from '../../shared/pipes/ignore-invalid-date.pipe';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { User } from '../../users/shared/user.model';
import { PurchaseItemListItem } from '../../warehouse/shared/purchase-item-list-item.model';
import { PurchaseItemService } from '../../warehouse/shared/purchase-item.service';
import { PurchaseActiveConfirmComponent } from '../purchase-active-confirm/purchase-active-confirm.component';
import { PurchaseItemEditFormComponent } from '../purchase-item-edit-form/purchase-item-edit-form.component';
import { Purchase } from '../shared/purchase.model';

@Component({
  selector: 'app-ordered',
  templateUrl: './ordered.component.html',
  styleUrls: ['./ordered.component.scss']
})
export class OrderedComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Siparişler';
  public asyncPurchaseItems = new BehaviorSubject<PurchaseItemListItem[]>([]);
  public purchaseItemsSubscription: Subscription;
  public selectedCount = 0;
  public selectedMass = 0;
  public selectedCosts: string[] = [];
  public isHeaderSelected = false;
  public pageNumber = 1;
  public totalCount: number;
  public itemCountInPage: number = 0;
  public perPage: number = 10000;
  public searchValue = '';
  public selectedProductId = 0;
  public searchQuery = new Subject<PagedSearchQuery>();
  public isSearchValueChanged = false;
  public isAllDataRowsLoaded = false;
  public dateFormatPipe = new DateFormatPipe();
  public fromUtcPipe = new FromUtcPipe();
  public ignoreInvalidDatePipe = new IgnoreInvalidDatePipe();
  public localePipe = new LocalePipe();
  public localTimePipe = new LocalTimePipe();
  public measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  public user: User;
  public purchase: Purchase = new Purchase(
    0,
    0,
    null,
    0,
    null,
    null,
    null,
    0,
    0,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    []
  );

  @ViewChild('mainTable', { static: true }) public mainTableRef: ElementRef;

  private unsubscribe: Subject<void> = new Subject();
  private isPageLoaded = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly appComponent: AppComponent,
    private readonly modalService: NgbModal,
    private readonly purchaseItemService: PurchaseItemService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductId = parseInt(params['productId'], 10) || 0;

      this.getPage(this.pageNumber);
    });
  }

  public ngAfterViewInit(): void {
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public onScrollDown(): void {
    if (this.isPageLoaded) {
      this.getPage(this.pageNumber + 1);
    }
  }

  public onKeyup(event: any): void {
    this.searchValue = event.target.value;
    this.pageNumber = 1;
    this.isSearchValueChanged = true;
    this.isAllDataRowsLoaded = false;
    this.itemCountInPage = 0;
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public getPage(page: number) {
    if (this.isAllDataRowsLoaded) {
      return;
    }

    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (page - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged
      )
    );

    if (!this.purchaseItemsSubscription) {
      this.purchaseItemsSubscription = this.purchaseItemService
        .search(1, 0, 0, this.searchQuery, null, this.selectedProductId)
        .pipe(
          tap((res) => {
            if (res.query !== this.searchValue) {
              return;
            }

            if (this.isSearchValueChanged) {
              this.asyncPurchaseItems.next([]);
              this.isSearchValueChanged = false;
              this.itemCountInPage = 0;
            }

            this.totalCount = res.totalCount;
            this.pageNumber = Math.floor(res.offset / res.limit) + 1;

            const currentData = this.asyncPurchaseItems.getValue();

            for (let i = res.purchaseItems.length - 1; i >= 0; --i) {
              res.purchaseItems[i].identicalItems = [];
              const item = res.purchaseItems[i];
              const index = currentData.findIndex(this.isCorrespondingGroupItem(item));

              item.isGroupingItem = false;

              if (index > -1) {
                currentData[index].identicalItems.push(item);
              } else {
                const groupingItem = PurchaseItemListItem.clone(item);
                groupingItem.purchaseItemId = -1;
                groupingItem.isGroupingItem = true;
                groupingItem.isGroupOpen = false;
                groupingItem.identicalItems.push(item);
                currentData.push(groupingItem);
              }
            }

            this.asyncPurchaseItems.next(currentData);

            this.itemCountInPage += res.purchaseItems.length;
            this.isAllDataRowsLoaded = res.totalCount === this.itemCountInPage;

            if (!this.isPageLoaded) {
              this.isPageLoaded = true;
            }

            this.getPage(this.pageNumber + 1);
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe();

      this.searchQuery.next(
        new PagedSearchQuery(
          this.searchValue,
          (page - 1) * this.perPage,
          this.perPage,
          !this.isSearchValueChanged
        )
      );
    }
  }

  public onClickGroupCaret(item: PurchaseItemListItem) {
    const currentData = this.asyncPurchaseItems.getValue();
    const selectedItemIndex = currentData.findIndex(this.isCorrespondingGroupItem(item));

    item.identicalItems.forEach((i) => {
      if (!item.isGroupOpen) {
        i.selected = item.selected;
        currentData.splice(selectedItemIndex + 1, 0, i);
      } else {
        const currentIndex = currentData.findIndex(
          (data) => data.purchaseItemId === i.purchaseItemId
        );
        currentData.splice(currentIndex, 1);
      }
    });

    currentData[selectedItemIndex].isGroupOpen = !currentData[selectedItemIndex].isGroupOpen;

    this.asyncPurchaseItems.next(currentData);

    this.calculateSelectedItem();
  }

  public onChangeHeaderCheckbox(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();

    purchaseItems.forEach((item) => {
      item.selected = this.isHeaderSelected;
    });

    this.calculateSelectedItem();
  }

  public onChangeItemCheckbox(item: PurchaseItemListItem): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    let isAllSelected = true;

    for (const purchaseItem of purchaseItems) {
      if (!purchaseItem.selected) {
        isAllSelected = false;
      }
    }

    this.isHeaderSelected = isAllSelected;

    if (item.isGroupingItem && item.isGroupOpen) {
      item.identicalItems.forEach((i) => {
        purchaseItems[
          purchaseItems.findIndex((k) => k.purchaseItemId === i.purchaseItemId)
        ].selected = item.selected;
      });
    }

    if (!item.isGroupingItem) {
      let isAllGroupItemsSelected = true;
      let itemGroupIndex = -1;

      for (let i = 0; i < purchaseItems.length; ++i) {
        if (!purchaseItems[i].isGroupingItem || !purchaseItems[i].isGroupOpen) {
          continue;
        }

        if (
          purchaseItems[i].identicalItems.findIndex(
            (k) => k.purchaseItemId === item.purchaseItemId
          ) > -1
        ) {
          itemGroupIndex = i;

          purchaseItems[i].identicalItems.forEach((k) => {
            if (!k.selected) {
              isAllGroupItemsSelected = false;
            }
          });
        }
      }

      purchaseItems[itemGroupIndex].selected = isAllGroupItemsSelected;
    }

    this.calculateSelectedItem();
  }

  public calculateSelectedItem(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    const selectedItems = purchaseItems.filter(
      (x) => x.selected && (!x.isGroupingItem || !x.isGroupOpen)
    );
    this.selectedCount = 0;

    let totalMass = 0;

    selectedItems.forEach((p) => {
      let massModifier = 1;

      if (p.product.unitOfMass === 'mg') {
        massModifier = 1e-6;
      } else if (p.product.unitOfMass === 'g') {
        massModifier = 1e-3;
      } else {
        // Do nothing. Mass Modifier is already 1.
      }

      const itemMass = p.purchaseCount * p.product.unitMass * massModifier;

      if (p.isGroupingItem && !p.isGroupOpen) {
        this.selectedCount += p.identicalItems.length;
        totalMass += itemMass * p.identicalItems.length;
      } else if (p.isGroupingItem && p.isGroupOpen) {
        // Do nothing.
      } else {
        ++this.selectedCount;
        totalMass += itemMass;
      }
    });

    this.selectedMass = totalMass;

    this.calculateTotalPrice(selectedItems);
  }

  public cancelItem(): void {
    const confirmModal = this.modalService.open(PurchaseActiveConfirmComponent);
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message =
      'Seçtiğiniz ürünler iptal edilecektir. Onaylıyor musunuz?';

    confirmModal.result.then(
      () => {
        const purchaseItems = this.asyncPurchaseItems.getValue();
        const selectedItems = purchaseItems.filter((x) => x.selected);

        this.purchaseItemService
          .patch('', this.createPatchObject(selectedItems, null, 'delete'))
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              'Seçtiğiniz ürünler başarıyla iptal edildi.'
            );

            for (let i = purchaseItems.length - 1; i >= 0; --i) {
              if (!purchaseItems[i].selected) {
                continue;
              }

              if (!purchaseItems[i].isGroupingItem) {
                const correspondingGroupIndex = purchaseItems.findIndex(
                  this.isCorrespondingGroupItem(purchaseItems[i])
                );

                if (correspondingGroupIndex > -1) {
                  const identicalItemIndex = purchaseItems[
                    correspondingGroupIndex
                  ].identicalItems.findIndex(
                    (j) => j.purchaseItemId === purchaseItems[i].purchaseItemId
                  );
                  purchaseItems[correspondingGroupIndex].identicalItems.splice(
                    identicalItemIndex,
                    1
                  );
                }
              }

              purchaseItems.splice(i, 1);
            }

            this.asyncPurchaseItems.next(purchaseItems);

            this.selectedCount = 0;
            this.isHeaderSelected = false;
            this.totalCount = purchaseItems.length;
          });
      },
      () => {}
    );
  }

  public proceedItemToNextStep(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    const selectedItems = purchaseItems.filter((x) => x.selected);
    const confirmModal = this.modalService.open(PurchaseItemEditFormComponent, {
      windowClass: 'large-modal'
    });
    confirmModal.componentInstance.purchaseItem = selectedItems[0];
    confirmModal.componentInstance.itemCount = selectedItems.length;
    confirmModal.componentInstance.title = 'Ürünleri İlerlet';
    confirmModal.componentInstance.primaryButtonLabel = 'İlerlet';
    confirmModal.componentInstance.message = `<p>Seçtiğiniz ürünler '${Globals.purchaseItemStatus[2]}' adımına ilerletilecektir. Lütfen ilgili tarihleri girin.</p><p><strong>Hatırlatma:</strong> Burada girilen tarihler, ürünler üzerindeki mevcut tarihlerin üzerine yazılacaktır.</p>`;

    confirmModal.result.then(
      (res) => {
        const editedPurchaseItem = res as PurchaseItemListItem;
        editedPurchaseItem.status = 2;

        purchaseItems.forEach((item) => {
          if (item.selected) {
            item.letterOfCreditTime = editedPurchaseItem.letterOfCreditTime;
            item.estimatedShippingTime = editedPurchaseItem.estimatedShippingTime;
            item.estimatedArrivalTime = editedPurchaseItem.estimatedArrivalTime;
            item.arrivalTime = editedPurchaseItem.arrivalTime;
            item.status = 2;
          }
        });

        this.purchaseItemService
          .patch(
            'arrivalTime,estimatedArrivalTime,estimatedShippingTime,letterOfCreditTime,status',
            this.createPatchObject(selectedItems, editedPurchaseItem, 'update')
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              `Seçtiğiniz ürünler '${Globals.purchaseItemStatus[2]}' adımına başarıyla ilerletildi.`
            );

            for (let i = purchaseItems.length - 1; i >= 0; --i) {
              if (!purchaseItems[i].selected) {
                continue;
              }

              if (!purchaseItems[i].isGroupingItem) {
                const correspondingGroupIndex = purchaseItems.findIndex(
                  this.isCorrespondingGroupItem(purchaseItems[i])
                );

                if (correspondingGroupIndex > -1) {
                  const identicalItemIndex = purchaseItems[
                    correspondingGroupIndex
                  ].identicalItems.findIndex(
                    (j) => j.purchaseItemId === purchaseItems[i].purchaseItemId
                  );
                  purchaseItems[correspondingGroupIndex].identicalItems.splice(
                    identicalItemIndex,
                    1
                  );
                }
              }

              purchaseItems.splice(i, 1);
            }

            this.asyncPurchaseItems.next(purchaseItems);

            this.selectedCount = 0;
            this.isHeaderSelected = false;
            this.totalCount = purchaseItems.length;
          });
      },
      () => {}
    );
  }

  public editItem(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    const selectedItems = purchaseItems.filter((x) => x.selected);
    const updateModal = this.modalService.open(PurchaseItemEditFormComponent, {
      windowClass: 'large-modal'
    });
    updateModal.componentInstance.purchaseItem = selectedItems[0];
    updateModal.componentInstance.itemCount = selectedItems.length;
    updateModal.componentInstance.title = 'Ürün Detayları';
    updateModal.componentInstance.inputLabels = new Map<string, string>([
      ['letterOfCreditTime', 'Hazırlanma Tarihi']
    ]);

    updateModal.result.then(
      (res) => {
        const editedPurchaseItem = res as PurchaseItemListItem;
        purchaseItems.forEach((item) => {
          if (item.selected) {
            this.copyDates(editedPurchaseItem, item);

            if (item.isGroupingItem && !item.isGroupOpen) {
              item.identicalItems.forEach((i) => {
                this.copyDates(editedPurchaseItem, i);
              });
            }
          }
        });

        this.purchaseItemService
          .patch(
            'arrivalTime,estimatedArrivalTime,estimatedShippingTime,letterOfCreditTime',
            this.createPatchObject(selectedItems, editedPurchaseItem, 'update')
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              'Seçtiğiniz ürünlerin bilgileri başarıyla güncellendi.'
            );

            for (
              let selectedItemIndex = purchaseItems.length - 1;
              selectedItemIndex >= 0;
              --selectedItemIndex
            ) {
              const selectedItem = purchaseItems[selectedItemIndex];

              if (!purchaseItems[selectedItemIndex].selected) {
                continue;
              }

              if (selectedItem.isGroupingItem) {
                const identicalItem = selectedItem.identicalItems[0];
                const correspondingGroupIndex = purchaseItems.findIndex(
                  this.isCorrespondingGroupItem(identicalItem)
                );
                const isCorrespondingGroupCurrentGroup =
                  correspondingGroupIndex === selectedItemIndex;

                if (!isCorrespondingGroupCurrentGroup) {
                  const correspondingGroupLength =
                    purchaseItems[correspondingGroupIndex].identicalItems.length;
                  for (let i = selectedItem.identicalItems.length - 1; i >= 0; --i) {
                    purchaseItems[correspondingGroupIndex].identicalItems.splice(
                      correspondingGroupLength,
                      0,
                      selectedItem.identicalItems[i]
                    );
                    purchaseItems[selectedItemIndex].identicalItems.splice(i, 1);
                  }
                }
              } else {
                const correspondingGroupIndex = purchaseItems.findIndex(
                  this.isCorrespondingGroupItem(selectedItem)
                );
                const isCorrespondingGroupCurrentGroup =
                  correspondingGroupIndex > -1 &&
                  purchaseItems[correspondingGroupIndex].identicalItems.findIndex(
                    (i) => i.purchaseItemId === selectedItem.purchaseItemId
                  ) > -1;

                if (correspondingGroupIndex > -1 && !isCorrespondingGroupCurrentGroup) {
                  purchaseItems[correspondingGroupIndex].identicalItems.splice(
                    purchaseItems[correspondingGroupIndex].identicalItems.length,
                    0,
                    selectedItem
                  );
                  let identicalItemGroupIndex = -1;
                  let identicalItemIndex = -1;
                  for (let i = 0; i < purchaseItems.length; ++i) {
                    if (purchaseItems[i].isGroupingItem) {
                      identicalItemIndex = purchaseItems[i].identicalItems.findIndex(
                        (j) => j.purchaseItemId === selectedItem.purchaseItemId
                      );

                      if (identicalItemIndex > -1) {
                        identicalItemGroupIndex = i;

                        break;
                      }
                    }
                  }

                  purchaseItems[identicalItemGroupIndex].identicalItems.splice(
                    identicalItemIndex,
                    1
                  );
                  purchaseItems.splice(selectedItemIndex, 1);
                } else if (correspondingGroupIndex > -1 && isCorrespondingGroupCurrentGroup) {
                  // Do nothing
                } else {
                  let identicalItemGroupIndex = -1;
                  let identicalItemIndex = -1;

                  for (let i = 0; i < purchaseItems.length; ++i) {
                    if (purchaseItems[i].isGroupingItem) {
                      identicalItemIndex = purchaseItems[i].identicalItems.findIndex(
                        (j) => j.purchaseItemId === selectedItem.purchaseItemId
                      );

                      if (identicalItemIndex > -1) {
                        identicalItemGroupIndex = i;

                        break;
                      }
                    }
                  }

                  purchaseItems[identicalItemGroupIndex].identicalItems.splice(
                    identicalItemIndex,
                    1
                  );
                  purchaseItems.splice(selectedItemIndex, 1);

                  const groupingItem = PurchaseItemListItem.clone(selectedItem);
                  groupingItem.purchaseItemId = -1;
                  groupingItem.isGroupingItem = true;
                  groupingItem.isGroupOpen = false;
                  groupingItem.identicalItems.push(selectedItem);
                  purchaseItems.push(groupingItem);
                }
              }
            }

            for (let i = purchaseItems.length - 1; i >= 0; --i) {
              if (!purchaseItems[i].isGroupingItem) {
                continue;
              }

              if (purchaseItems[i].identicalItems.length === 0) {
                purchaseItems.splice(i, 1);
                continue;
              }

              let isAllIdenticalItemsInGroupSelected = true;
              let isAnyIdenticalItemInGroupSelected = false;

              purchaseItems[i].identicalItems.forEach((identicalItem) => {
                if (!identicalItem.selected) {
                  isAllIdenticalItemsInGroupSelected = false;
                } else {
                  isAnyIdenticalItemInGroupSelected = true;
                }
              });

              if (isAllIdenticalItemsInGroupSelected) {
                purchaseItems[i].selected = true;
              }

              if (
                !purchaseItems[i].isGroupOpen &&
                isAnyIdenticalItemInGroupSelected &&
                !isAllIdenticalItemsInGroupSelected
              ) {
                purchaseItems[i].isGroupOpen = true;
              }
            }

            this.asyncPurchaseItems.next(purchaseItems);
          });
      },
      () => {}
    );
  }

  public saveExcel(): void {
    const itemData = [];

    itemData.push([
      {
        value: 'Ürün Adı',
        type: 'string'
      },
      {
        value: 'Tedarikçi',
        type: 'string'
      },
      {
        value: 'Kütle',
        type: 'string'
      },
      {
        value: 'Kütle Birimi',
        type: 'string'
      },
      {
        value: 'Akreditif Tarihi',
        type: 'string'
      },
      {
        value: 'Tahmini Yükleme Tarihi',
        type: 'string'
      },
      {
        value: 'Tahmini Varış Tarihi',
        type: 'string'
      },
      {
        value: 'Kesin Varış Tarihi',
        type: 'string'
      },
      {
        value: 'Fiyat',
        type: 'string'
      },
      {
        value: 'Para Birimi',
        type: 'string'
      },
      {
        value: 'Miktar',
        type: 'string'
      },
      {
        value: 'Ölçü Birimi',
        type: 'string'
      }
    ]);

    let isAnyItemSelected = false;

    const purchaseItems = this.asyncPurchaseItems.getValue();

    for (const purchaseItem of purchaseItems) {
      if (purchaseItem.status === 1 && purchaseItem.selected) {
        isAnyItemSelected = true;
        break;
      }
    }

    for (const purchaseItem of purchaseItems) {
      if (isAnyItemSelected && !purchaseItem.selected) {
        continue;
      }

      if (purchaseItem.isGroupingItem && !purchaseItem.isGroupOpen) {
        purchaseItem.identicalItems.forEach((i) => {
          itemData.push(this.createExcelRow(i));
        });
      } else if (purchaseItem.isGroupingItem && purchaseItem.isGroupOpen) {
        // Do nothing.
      } else {
        itemData.push(this.createExcelRow(purchaseItem));
      }
    }

    zipcelx({
      filename: 'siparis',
      sheet: {
        data: itemData
      }
    });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private createPatchObject(
    selectedItems: PurchaseItemListItem[],
    editedPurchaseItem: PurchaseItemListItem,
    operation: string
  ): any[] {
    const patchObject = [];

    selectedItems.forEach((i) => {
      if (i.isGroupingItem && !i.isGroupOpen) {
        i.identicalItems.forEach((k) => {
          if (operation === 'update') {
            patchObject.push({
              id: k.purchaseItemId,
              op: operation,
              arrivalTime: editedPurchaseItem.arrivalTime,
              estimatedArrivalTime: editedPurchaseItem.estimatedArrivalTime,
              estimatedShippingTime: editedPurchaseItem.estimatedShippingTime,
              letterOfCreditTime: editedPurchaseItem.letterOfCreditTime,
              status: editedPurchaseItem.status
            });
          } else if (operation === 'delete') {
            patchObject.push({
              id: k.purchaseItemId,
              op: operation
            });
          } else {
            throw new RangeError('Invalid operation.');
          }
        });
      } else if (i.isGroupingItem && i.isGroupOpen) {
        // Do nothing
      } else {
        if (operation === 'update') {
          patchObject.push({
            id: i.purchaseItemId,
            op: operation,
            arrivalTime: editedPurchaseItem.arrivalTime,
            estimatedArrivalTime: editedPurchaseItem.estimatedArrivalTime,
            estimatedShippingTime: editedPurchaseItem.estimatedShippingTime,
            letterOfCreditTime: editedPurchaseItem.letterOfCreditTime,
            status: editedPurchaseItem.status
          });
        } else if (operation === 'delete') {
          patchObject.push({
            id: i.purchaseItemId,
            op: operation
          });
        } else {
          throw new RangeError('Invalid operation.');
        }
      }
    });

    return patchObject;
  }

  private copyDates(source: PurchaseItemListItem, dest: PurchaseItemListItem) {
    dest.letterOfCreditTime = source.letterOfCreditTime;
    dest.estimatedShippingTime = source.estimatedShippingTime;
    dest.estimatedArrivalTime = source.estimatedArrivalTime;
    dest.arrivalTime = source.arrivalTime;
  }

  private isCorrespondingGroupItem(
    item: PurchaseItemListItem
  ): (value: PurchaseItemListItem, index: number, obj: PurchaseItemListItem[]) => boolean {
    return (data) =>
      data.product.id === item.product.id &&
      data.purchaseCount === item.purchaseCount &&
      data.cost === item.cost &&
      data.currencyCode === item.currencyCode &&
      data.letterOfCreditTime === item.letterOfCreditTime &&
      data.estimatedShippingTime === item.estimatedShippingTime &&
      data.estimatedArrivalTime === item.estimatedArrivalTime &&
      data.arrivalTime === item.arrivalTime &&
      data.containerId === item.containerId &&
      data.supplierId === item.supplierId &&
      data.isGroupingItem;
  }

  private calculateTotalPrice(itemList: PurchaseItemListItem[]): void {
    const priceList = new Map<string, number>();

    for (const item of itemList) {
      let itemCost = 0;

      if (item.isGroupingItem && !item.isGroupOpen) {
        itemCost = item.cost * item.identicalItems.length;
      } else if (item.isGroupingItem && item.isGroupOpen) {
        // Do nothing.
      } else {
        itemCost = item.cost;
      }

      if (priceList.get(item.product.currencyCode)) {
        const totalAmount =
          priceList.get(item.product.currencyCode) + itemCost * item.purchaseCount;
        priceList.set(item.product.currencyCode, totalAmount);
      } else {
        priceList.set(item.product.currencyCode, itemCost * item.purchaseCount);
      }
    }

    this.selectedCosts = [];

    priceList.forEach((value: number, key: string) => {
      this.selectedCosts.push(`${value.toFixed(4)} ${key}`);
    });
  }

  private createExcelRow(item: PurchaseItemListItem): any {
    return [
      {
        value: item.product.name,
        type: 'string'
      },
      {
        value: item.supplierTitle,
        type: 'string'
      },
      {
        value: (item.product.unitMass * item.purchaseCount).toFixed(3),
        type: 'number'
      },
      {
        value: item.product.unitOfMass,
        type: 'string'
      },
      {
        value: this.formatDate(item.letterOfCreditTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.estimatedShippingTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.estimatedArrivalTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.arrivalTime),
        type: 'string'
      },
      {
        value: item.cost.toFixed(4),
        type: 'number'
      },
      {
        value: item.product.currencyCode,
        type: 'string'
      },
      {
        value: item.purchaseCount,
        type: 'number'
      },
      {
        value: this.measurementUnitSymbolPipe.transform(
          item.product.groupUnit,
          item.product.groupUnitGroup
        ),
        type: 'string'
      }
    ];
  }

  private formatDate(value: Date): string {
    return this.ignoreInvalidDatePipe.transform(
      this.dateFormatPipe.transform(
        this.localePipe.transform(
          this.localTimePipe.transform(this.fromUtcPipe.transform(value)).toString(),
          'tr'
        ),
        'L'
      )
    );
  }
}
