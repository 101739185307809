import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { AppComponent } from '../../core/app.component';
import { ProductListItem } from '../../products/shared/product-list-item.model';
import { ProductService } from '../../products/shared/product.service';
import { ProductGroupListItem } from '../../settings/product-groups/shared/product-group-list-item.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { SupplierListItem } from '../../suppliers/shared/supplier-list-item.model';
import { SupplierPurchaseItemsSummary } from '../../suppliers/shared/supplier-purchase-items-summary.model';
import { SupplierService } from '../../suppliers/shared/supplier.service';
import { ReportListItem } from '../report/report-list-item.model';
import { ReportService } from '../report/report.service';
import { PurchaseReportService } from './purchase-report.service';

declare var $: any;

@Component({
  providers: [DecimalPipe],
  selector: 'app-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.scss']
})
export class PurchaseReportComponent implements AfterViewInit, OnChanges, OnInit {
  public pageName = 'Satın Alma Raporları';
  public selectedPurchaseBeginDate: string = '';
  public selectedPurchaseEndDate: string = '';

  public suppliers: SupplierListItem[] = [];
  public asyncSuppliers: Observable<SupplierListItem[]>;
  public selectedSupplierId = 0;

  public productGroups: ProductGroupListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupListItem[]>;
  public selectedProductGroups: number[] = [];

  public products: ProductListItem[] = [];
  public asyncProducts: Observable<ProductListItem[]>;
  public selectedProductValues: number[] = [];

  public reportItems: ReportListItem[] = [];
  public asyncReportItems: Observable<ReportListItem[]>;
  public totalCount: number;
  public asyncPurchaseItemsSummary: Observable<SupplierPurchaseItemsSummary[]>;
  public perPage: number = 10;
  public pageNumber = 1;
  public selectedCosts: string[] = [];

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly supplierService: SupplierService,
    private readonly productGroupService: ProductGroupService,
    private readonly productService: ProductService,
    private readonly reportService: ReportService,
    private readonly purchaseReportService: PurchaseReportService,
    private readonly router: Router,
    private readonly decimalPipe: DecimalPipe,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
    this.totalCount = 0;

    this.activatedRoute.queryParams.subscribe(() => {
      this.getSuppliers();
      this.getProductGroups();
    });

    this.activatedRoute.url.subscribe(() => {
      this.activatedRoute.queryParams.subscribe((params) => {
        const page = parseInt(params['page'], 10) || 1;

        if (page !== this.pageNumber) {
          this.pageNumber = page;
          this.getPurchaseItems(this.pageNumber);
        }
      });
    });
  }

  public ngAfterViewInit() {
    $('.datepicker').datepicker({
      autoclose: true,
      format: 'dd.mm.yyyy',
      language: 'tr'
    });
  }

  public ngOnChanges() {
    this.cdRef.detectChanges();
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService.getAll(0, 1000000).pipe(
      tap(
        (res) => {
          this.productGroups = res.productGroups;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.productGroups),
      share()
    );
  }

  public getProducts() {
    this.asyncProducts = this.productService.getAll(0, 0, 1000000, this.selectedProductGroups).pipe(
      tap(
        (res) => {
          this.products = res.products;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.products),
      share()
    );
  }

  public getSuppliers() {
    this.asyncSuppliers = this.supplierService.getAll(0, 1000000).pipe(
      tap(
        (res) => {
          this.suppliers = res.suppliers;
        },
        () => {
          this.totalCount = 0;
        }
      ),
      map((res) => res.suppliers),
      share()
    );
  }

  public getReport() {
    this.asyncReportItems = this.reportService
      .get(
        this.selectedPurchaseBeginDate,
        this.selectedPurchaseEndDate,
        '',
        '',
        this.selectedSupplierId,
        0,
        this.selectedProductValues,
        0
      )
      .pipe(
        tap(
          (res) => {
            this.reportItems = res.reports;
            this.calculateTotalPrice(this.reportItems);
          },
          () => {
            this.totalCount = 0;
          }
        ),
        map((res) => res.reports),
        share()
      );
  }

  public changePage(page: number) {
    this.router.navigate(
      ['.'],
      page > 1
        ? { queryParams: { page }, relativeTo: this.activatedRoute }
        : { queryParams: {}, relativeTo: this.activatedRoute }
    );
  }

  public getPurchaseItems(page: number) {
    this.selectedPurchaseBeginDate = moment($('#purchaseBeginDate').val(), 'DD.MM.YYYY')
      .startOf('day')
      .toISOString();
    this.selectedPurchaseEndDate = moment($('#purchaseEndDate').val(), 'DD.MM.YYYY')
      .endOf('day')
      .toISOString();

    this.selectedPurchaseBeginDate =
      this.selectedPurchaseBeginDate == null ? '' : this.selectedPurchaseBeginDate;
    this.selectedPurchaseEndDate =
      this.selectedPurchaseEndDate == null ? '' : this.selectedPurchaseEndDate;

    this.asyncPurchaseItemsSummary = this.purchaseReportService
      .getPurchaseItemsSummary(
        this.selectedSupplierId,
        (page - 1) * this.perPage,
        this.perPage,
        this.selectedPurchaseBeginDate,
        this.selectedPurchaseEndDate,
        this.selectedProductValues
      )
      .pipe(
        tap((res) => {
          this.totalCount = res.totalCount;
          this.pageNumber = page;
          this.getReport();
        }),
        map((res) => {
          return res.supplierPurchaseItemsSummaries;
        }),
        takeUntil(this.unsubscribe)
      );
  }

  private calculateTotalPrice(itemList: ReportListItem[]): void {
    const priceList = new Map<string, number>();

    for (const item of itemList) {
      for (const totalPurchase of item.totalPurchases) {
        priceList.set(totalPurchase.currency, totalPurchase.amount);
      }
    }
    this.selectedCosts = [];

    priceList.forEach((value: number, key: string) => {
      this.selectedCosts.push(`${this.decimalPipe.transform(value, '1.2-4')} ${key}`);
    });
  }
}
