import { SaleItemListItem } from './sale-item-list-item.model';

export class SaleItemListResult {
  totalCount: number;
  saleItems: SaleItemListItem[];

  constructor(totalCount: number, saleItems: SaleItemListItem[]) {
    this.totalCount = totalCount;
    this.saleItems = saleItems;
  }
}
