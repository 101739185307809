<section class="content-header">
  <h1>
    {{ pageName }}
    <small></small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink="/stock"><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <div class="box box-primary" #mainTable>
    <div class="box-header with-border">
      <h3 class="box-title">Rezerveler</h3>

      <div class="box-tools">
        <form class="form-horizontal form-inline">
          <button
            *appIfAuthorizedTo="'EditSales'"
            type="button"
            class="btn btn-sm btn-success"
            (click)="openBasketDialog()"
          >
            Yeni Rezerve
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success"
            style="margin-left: 4pt;"
            (click)="saveExcel()"
          >
            Excel'e Aktar
          </button>
          <div class="input-group" style="margin-left: 4pt;">
            <input
              name="timeRange"
              class="form-control input-sm"
              id="timeRange"
              type="text"
              autocomplete="off"
            />
          </div>
          <input
            type="search"
            [(ngModel)]="searchValue"
            name="searchValue"
            class="form-control input-sm"
            placeholder="Rezerve ara"
            (keyup)="onKeyup($event)"
          />
        </form>
      </div>
    </div>

    <div
      class="box-body table-responsive"
      infinite-scroll
      (scrolled)="onScrollDown()"
      [immediateCheck]="true"
    >
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th style="width: 30%;">Müşteri</th>
            <th>Tarih</th>
            <th style="width: 20%;">Tutar</th>
            <th style="width: 20%;">Durum</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sale of asyncSales | async">
            <td *ngIf="sale.status == 4">
              <a [routerLink]="['/customers', sale.customer.id, 'detail']">{{
                sale.customer.title
              }}</a>
            </td>
            <td *ngIf="sale.status == 4">
              {{
                sale.saleTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </td>
            <td *ngIf="sale.status == 4">
              <ul class="amount-list">
                <li *ngFor="let price of sale.totalPrice">
                  {{ price.amount | number: '1.2-4' }} {{ price.currency }}
                </li>
              </ul>
            </td>
            <td *ngIf="sale.status == 4">
              <a [routerLink]="['/reserved', sale.id, sale.status === 4 ? 'detail' : 'edit']"
                ><strong>{{ saleStatus[sale.status] }}</strong></a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">
        Seçtiğiniz tarih aralığında herhangi bir satış rezervesi bulunmamaktadır.
      </p>
    </div>
    <div class="box-footer clearfix"></div>
  </div>
</section>
