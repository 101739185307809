<pagination-template #p="paginationApi"
                     [id]="id"
                     [maxSize]="maxSize"
                     (pageChange)="pageChange.emit($event)">
  <ul class="pagination pagination-sm no-margin pull-right"
      role="navigation"
      [attr.aria-label]="screenReaderPaginationLabel"
      *ngIf="!(autoHide && p.pages.length <= 1)">
    <li class="pagination-previous" [class.disabled]="p.isFirstPage()" *ngIf="directionLinks">
      <a *ngIf="1 < p.getCurrent()" (click)="p.previous()">
        ‹
      </a>
      <span *ngIf="p.isFirstPage()">
        ‹
      </span>
    </li>
    <li [class.active]="p.getCurrent() === page.value" *ngFor="let page of p.pages">
      <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
        {{ page.label }}
      </a>
      <ng-container *ngIf="p.getCurrent() === page.value">
        <span>{{ page.label }}</span>
      </ng-container>
    </li>
    <li class="pagination-next" [class.disabled]="p.isLastPage()" *ngIf="directionLinks">
      <a *ngIf="!p.isLastPage()" (click)="p.next()">
        ›
      </a>
      <span *ngIf="p.isLastPage()">
        ›
      </span>
    </li>
  </ul>
</pagination-template>
