import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DateFormatPipe, FromUtcPipe, LocalePipe, LocalTimePipe } from 'ngx-moment';
import { Observable, Subject } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';
import zipcelx from 'zipcelx-es5';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { Sale } from '../../sales/shared/sale.model';
import { ProductGroupSummaryListItem } from '../../settings/product-groups/shared/product-group-summary-list-item.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { IgnoreInvalidDatePipe } from '../../shared/pipes/ignore-invalid-date.pipe';
import { User } from '../../users/shared/user.model';
import { SaleItemListItem } from '../shared/sale-item-list-item.model';
import { SaleItemService } from '../shared/sale-item.service';

declare var $: any;

@Component({
  selector: 'app-waiting-for-shipment',
  templateUrl: './waiting-for-shipment.component.html',
  styleUrls: ['./waiting-for-shipment.component.scss']
})
export class WaitingForShipmentComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Depo Çıkışı Bekleyenler';
  public saleItems: SaleItemListItem[] = [];
  public asyncSaleItems: Observable<SaleItemListItem[]>;
  public productGroups: ProductGroupSummaryListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupSummaryListItem[]>;
  public selectedProductGroup: ProductGroupSummaryListItem;
  public selectedProductGroupId = 0;
  public selectedProductId = 0;
  public selectedProductGroupName = '';
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public ignoreInvalidDatePipe = new IgnoreInvalidDatePipe();
  public dateFormatPipe = new DateFormatPipe();
  public localePipe = new LocalePipe();
  public localTimePipe = new LocalTimePipe();
  public fromUtcPipe = new FromUtcPipe();
  public sale: Sale;
  public user: User;

  private startTime: Date;
  private endTime: Date;
  private isPageLoaded = false;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly appComponent: AppComponent,
    private readonly modalService: NgbModal,
    private readonly productGroupService: ProductGroupService,
    private readonly router: Router,
    private readonly saleItemService: SaleItemService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;
      this.selectedProductId = parseInt(params['productId'], 10) || 0;

      this.getProductGroups();
    });
  }

  public ngAfterViewInit() {
    const startTime = moment().add(-2, 'week');
    const endTime = moment().add(2, 'week');
    this.startTime = startTime.toDate();
    this.endTime = endTime.toDate();
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService
      .getSummary('WaitingForShipment', 0, 10000)
      .pipe(
        tap(() => {}),
        map((res) => {
          res.productGroups.sort((a, b) => a.order - b.order);

          return res.productGroups;
        }),
        tap((productGroups) => {
          this.productGroups = productGroups;

          if (productGroups.length > 0 && this.selectedProductGroupId === 0) {
            this.selectedProductGroupId = productGroups[0].id;
          }

          if (this.selectedProductGroupId > 0) {
            this.selectedProductGroup = productGroups.find(
              (g) => g.id === this.selectedProductGroupId
            );
            this.selectedProductGroupName = this.selectedProductGroup.name;
          } else {
            this.selectedProductGroup = productGroups.find(
              (g) => g.id === this.selectedProductGroupId
            );
          }

          this.getPage(this.pageNumber);
        }),
        share()
      );
  }

  public changePage(page: number) {
    this.router.navigate(
      ['/warehouse/waiting-for-shipment'],
      page > 1
        ? {
            queryParams: {
              productId: this.selectedProductId < 0 ? 0 : this.selectedProductId,
              groupId: this.selectedProductGroupId < 0 ? 0 : this.selectedProductGroupId,
              page
            }
          }
        : {
            queryParams: { productId: this.selectedProductId, groupId: this.selectedProductGroupId }
          }
    );
  }

  public getPage(page: number) {
    this.asyncSaleItems = this.saleItemService
      .getAll(
        this.selectedProductGroupId < 0 ? 0 : this.selectedProductGroupId,
        1,
        0,
        (page - 1) * this.perPage,
        this.perPage,
        this.startTime,
        this.endTime,
        'ReadyForShipment',
        this.selectedProductId < 0 ? 0 : this.selectedProductId
      )
      .pipe(
        tap((res) => {
          this.totalCount = res.totalCount;
          this.pageNumber = page;
          this.saleItems = res.saleItems;

          if (!this.isPageLoaded) {
            this.configureDateRangePicker();
            this.isPageLoaded = true;
          }
        }),
        map((res) => {
          res.saleItems.forEach((p) => {
            p.purchaseItem.product.propertyValues.sort((a, b) => a.propertyOrder - b.propertyOrder);
          });

          return res.saleItems;
        })
      );
  }

  public doCheckout(id: number) {
    this.saleItemService
      .updateStatus(id, 2)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.alertService.setAlert(AlertType.success, 'Ürün sevke hazır hale getirildi.');

          setTimeout(() => {
            this.getPage(this.pageNumber);
          }, 1000);
        },
        () => {
          this.alertService.setAlert(
            AlertType.danger,
            'Ürünün sevke hazır hale getirilmesi esnasında bir hata oluştu.'
          );
        }
      );
  }

  public onProductGroupChange() {
    this.router.navigate(['/warehouse/waiting-for-shipment'], {
      queryParams: { productId: this.selectedProductId, groupId: this.selectedProductGroupId }
    });
  }

  public saveExcel(): void {
    const itemData = [];

    itemData.push([
      {
        value: 'Ürün Adı',
        type: 'string'
      },
      {
        value: 'Miktar',
        type: 'string'
      },
      {
        value: 'Birim Ağırlık',
        type: 'string'
      },
      {
        value: 'Toplam Ağırlık',
        type: 'string'
      },
      {
        value: 'Teslimat Zamanı',
        type: 'string'
      },
      {
        value: 'Müşteri',
        type: 'string'
      }
    ]);

    this.saleItems.forEach((saleItem) => {
      itemData.push(this.createExcelRow(saleItem));
    });

    zipcelx({
      filename: 'depo-cikisi-bekleyenler',
      sheet: {
        data: itemData
      }
    });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private configureDateRangePicker() {
    const startTime = moment().add(-2, 'week');
    const endTime = moment().add(2, 'week');
    const $this = this;

    $('#timeRange').daterangepicker(
      {
        locale: {
          separator: ' - ',
          applyLabel: 'Uygula',
          cancelLabel: 'İptal'
        },
        opens: 'left',
        startDate: startTime,
        endDate: endTime
      },
      (start: moment.Moment, end: moment.Moment) => {
        $this.startTime = start.toDate();
        $this.endTime = end.toDate();
        $this.getPage($this.pageNumber);
      }
    );
  }

  private createExcelRow(item: SaleItemListItem): any {
    return [
      {
        value: item.purchaseItem.product.name,
        type: 'string'
      },
      {
        value: item.saleCount,
        type: 'string'
      },
      {
        value: item.purchaseItem.product.unitMass,
        type: 'string'
      },
      {
        value: item.purchaseItem.product.unitMass * item.saleCount,
        type: 'number'
      },
      {
        value: this.formatDate(item.deliveryTime),
        type: 'string'
      },
      {
        value: item.customer.title,
        type: 'string'
      }
    ];
  }

  private formatDate(value: Date): string {
    return this.ignoreInvalidDatePipe.transform(
      this.dateFormatPipe.transform(
        this.localePipe.transform(
          this.localTimePipe.transform(this.fromUtcPipe.transform(value)).toString(),
          'tr'
        ),
        'L'
      )
    );
  }
}
