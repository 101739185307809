export const Globals = Object.freeze({
  appName: 'Dipendo',
  client: {
    id: 'DipendoWeb',
    version: '1402'
  },
  pagination: {
    itemsPerPage: 30
  },
  addressTypes: {
    1: 'Fatura Adresi',
    2: 'Sevk Adresi',
    3: 'Diğer Adres'
  },
  phoneTypes: {
    1: 'Telefon',
    2: 'Faks'
  },
  purchaseStatus: {
    1: 'Aktif',
    2: 'Teklif',
    3: 'Sipariş Verildi'
  },
  purchaseItemStatus: {
    1: 'Sipariş',
    2: 'Transfer',
    3: 'Gümrük',
    4: 'Depo',
    5: 'Rezerve'
  },
  saleStatus: {
    1: 'Aktif',
    2: 'Teklif',
    3: 'Satıldı',
    4: 'Rezerve'
  },
  saleItemStatus: {
    1: 'Satıldı',
    2: 'Sevke Hazır',
    3: 'Gönderildi'
  },
  productGroupPropertyTypes: {
    1: 'Serbest Değer',
    2: 'Çoktan Seçmeli'
  },
  measurementUnits: [
    {
      id: 'length',
      name: 'Uzunluk',
      units: [
        {
          id: 'meter',
          name: 'Metre',
          symbol: 'm'
        },
        {
          id: 'centimeter',
          name: 'Santimetre',
          symbol: 'cm'
        },
        {
          id: 'kilometer',
          name: 'Kilometre',
          symbol: 'km'
        }
      ]
    },
    {
      id: 'number',
      name: 'Sayı',
      units: [
        {
          id: 'piece',
          name: 'Adet',
          symbol: 'adet'
        }
      ]
    },
    {
      id: 'weight',
      name: 'Ağırlık',
      units: [
        {
          id: 'kilogram',
          name: 'Kilogram',
          symbol: 'kg'
        }
      ]
    },
    {
      id: 'volume',
      name: 'Hacim',
      units: [
        {
          id: 'cubicmeter',
          name: 'Metreküp',
          symbol: 'm^3'
        },
        {
          id: 'liter',
          name: 'Litre',
          symbol: 'L'
        }
      ]
    }
  ],
  months: [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık'
  ]
});
