import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { share, takeUntil, tap } from 'rxjs/operators';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { IDeactivateGuardBase } from '../../core/confirm-deactivate-guard';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { DipendoFormComponent } from '../../shared/forms/dipendo-form/dipendo-form.component';
import { InputBase } from '../../shared/forms/input-base';
import { InputCheckbox } from '../../shared/forms/input-checkbox';
import { InputDropdown } from '../../shared/forms/input-dropdown';
import { InputTextbox } from '../../shared/forms/input-textbox';
import { ProductProperty } from '../shared/product-property.model';
import { Product } from '../shared/product.model';
import { ProductService } from '../shared/product.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnDestroy, OnInit, IDeactivateGuardBase {
  public selectedProductGroupId = 0;
  public productGroup: ProductGroup;
  public asyncProductGroup: Observable<ProductGroup>;
  public product: Product = new Product(0, '', 0, false, 0, '', 0, '', []);
  public inputs: any[] = [];

  private selectedId: number;
  private selectedProduct: Product;

  @ViewChild(DipendoFormComponent)
  private readonly formComponent: DipendoFormComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly productGroupService: ProductGroupService,
    private readonly productService: ProductService,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;

      this.getProductGroup(this.selectedProductGroupId);
    });
  }

  @HostListener('window:beforeunload')
  public canDeactivate(): Observable<boolean> | boolean {
    return this.formComponent.form.dirty && !this.formComponent.isSuccess;
  }

  public getProductGroup(id: number) {
    this.asyncProductGroup = this.productGroupService.get(id).pipe(
      tap(
        (res) => {
          this.productGroup = res;

          this.inputs = this.getInputs();

          this.activatedRoute.url.subscribe((url) => {
            if (url[url.length - 1].path === 'edit') {
              this.activatedRoute.params.subscribe((params) => {
                this.selectedId = parseInt(params.id, 10) || -1;

                if (this.selectedId > 0) {
                  this.getProduct(this.selectedId);
                } else {
                  this.router.navigate(['/products'], {
                    queryParams: { groupId: this.productGroup.productGroupId }
                  });
                }
              });
            }
          });
        },
        (err) => {
          this.router.navigate(['/products']);
        }
      )
    );
  }

  public onSubmit(formComponent: DipendoFormComponent) {
    if (this.selectedId > 0) {
      this.productService
        .update(this.selectedId, this.prepareBindingObject(formComponent))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res) => {
            this.alertService.setNextAlert(AlertType.success, 'Ürün başarıyla güncellendi.');
            this.formComponent.isSuccess = true;
            this.router.navigate(['/products'], {
              queryParams: { groupId: this.productGroup.productGroupId }
            });
          },
          (err) => {
            formComponent.isError = true;
            this.formComponent.isSuccess = false;
            formComponent.errorMessage = err.error.message;
          }
        );
    } else {
      this.productService
        .insert(this.prepareBindingObject(formComponent))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res) => {
            this.alertService.setNextAlert(AlertType.success, 'Ürün başarıyla eklendi.');
            this.formComponent.isSuccess = true;
            this.router.navigate(['/products'], {
              queryParams: { groupId: this.productGroup.productGroupId }
            });
          },
          (err) => {
            formComponent.isError = true;
            this.formComponent.isSuccess = false;
            formComponent.errorMessage = err.error.message;
          }
        );
    }
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getProduct(id: number) {
    this.productService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.selectedProduct = res;
        this.product = res;

        this.formComponent.setValues(this.selectedProduct);

        for (const propertyValue of this.selectedProduct.productPropertyValues) {
          this.formComponent.form.controls[propertyValue.productGroupPropertyId].patchValue(
            propertyValue.propertyValue
          );
        }
      });
  }

  private getInputs(): Array<InputBase<any>> {
    const inputs: Array<InputBase<any>> = [];

    inputs.push(
      new InputTextbox({
        key: 'name',
        label: 'Ürün Adı',
        type: 'text',
        value: '',
        required: true,
        order: 1
      })
    );

    for (const productGroupProperty of this.productGroup.productGroupProperties) {
      if (productGroupProperty.propertyType === 1) {
        inputs.push(
          new InputTextbox({
            key: productGroupProperty.productGroupPropertyId,
            label: productGroupProperty.name,
            type: 'text',
            value: '',
            required: productGroupProperty.isRequired,
            order: productGroupProperty.order + 1
          })
        );
      } else if (productGroupProperty.propertyType === 2) {
        const propertyValues = productGroupProperty.propertyValues
          .split(';')
          .map((p) => p.trim())
          .filter((p) => p !== '');
        const dropdownOptions = [];

        for (const dropdownValue of propertyValues) {
          dropdownOptions.push({
            key: dropdownValue,
            value: dropdownValue
          });
        }

        inputs.push(
          new InputDropdown({
            key: productGroupProperty.productGroupPropertyId,
            label: productGroupProperty.name,
            options: dropdownOptions,
            value: '',
            required: productGroupProperty.isRequired,
            showSelect: true,
            order: productGroupProperty.order + 1
          })
        );
      }
    }

    inputs.push(
      new InputTextbox({
        key: 'listPrice',
        label: 'Liste Fiyatı',
        type: 'number',
        value: '',
        required: true,
        order: this.productGroup.productGroupProperties.length + 100
      }),
      new InputDropdown({
        key: 'currencyCode',
        label: 'Fiyat Para Birimi',
        options: [
          { key: 'TRY', value: 'TRY' },
          { key: 'USD', value: 'USD' },
          { key: 'EUR', value: 'EUR' }
        ],
        value: 'TRY',
        required: true,
        order: this.productGroup.productGroupProperties.length + 101
      }),
      new InputTextbox({
        key: 'unitMass',
        label: 'Birim Kütle',
        type: 'number',
        value: '',
        required: true,
        order: this.productGroup.productGroupProperties.length + 102
      }),
      new InputDropdown({
        key: 'unitOfMass',
        label: 'Kütle Ölçü Birimi',
        options: [
          { key: 'mg', value: 'Miligram' },
          { key: 'g', value: 'Gram' },
          { key: 'kg', value: 'Kilogram' }
        ],
        showSelect: true,
        required: true,
        order: this.productGroup.productGroupProperties.length + 103
      }),
      new InputCheckbox({
        key: 'isActive',
        label: 'Aktif',
        value: true,
        checked: false,
        order: this.productGroup.productGroupProperties.length + 104
      })
    );

    return inputs.sort((a, b) => a.order - b.order);
  }

  private prepareBindingObject(formComponent: DipendoFormComponent): Product {
    const propertyValues: ProductProperty[] = [];

    for (const productGroupProperty of this.productGroup.productGroupProperties) {
      propertyValues.push(
        new ProductProperty(
          productGroupProperty.productGroupPropertyId,
          formComponent.form.value[productGroupProperty.productGroupPropertyId]
        )
      );
    }

    return {
      productId: this.selectedId > 0 ? this.selectedId : 0,
      name: formComponent.form.value.name,
      productGroupId: this.productGroup.productGroupId,
      listPrice: formComponent.form.value.listPrice,
      currencyCode: formComponent.form.value.currencyCode,
      unitMass: formComponent.form.value.unitMass,
      unitOfMass: formComponent.form.value.unitOfMass,
      isActive:
        formComponent.form.value.isActive === true || formComponent.form.value.isActive === 'true',
      productPropertyValues: propertyValues
    };
  }
}
