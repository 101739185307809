import { InputBase } from './input-base';

export class InputTwoSidedMultiSelect extends InputBase<string> {
  controlType = 'twoSidedMultiSelect';
  keyRight = '';
  labelRight = '';
  valueRight = [];
  buttonLabelLeft = '';
  buttonLabelRight = '';
  size = 6;
  sizeRight = 6;
  options: { key: string, value: string }[] = [];
  optionsRight: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.keyRight = options['keyRight'] || '';
    this.labelRight = options['labelRight'] || '';
    this.buttonLabelLeft = options['buttonLabelLeft'] || '';
    this.buttonLabelRight = options['buttonLabelRight'] || '';
    this.size = options['size'] || 6;
    this.sizeRight = options['sizeRight'] || 6;
    this.options = options['options'] || [];
    this.optionsRight = options['optionsRight'] || [];

    this.options.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    this.optionsRight.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });
  }
}
