import { Pipe, PipeTransform } from '@angular/core';
import { SaleItem } from '../../sales/shared/sale-item.model';

@Pipe({
  name: 'matchSaleItemStatus'
})
export class MatchSaleItemStatusPipe implements PipeTransform {
  transform(items: Array<SaleItem>, saleItemStatus: number): Array<SaleItem> {
    return items.filter(item => item.status === saleItemStatus);
  }
}
