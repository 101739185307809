import { InputBase } from './input-base';

export class InputDropdown extends InputBase<string> {
  controlType = 'dropdown';
  options: { key: string, value: string }[] = [];
  showSelect = false;

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.showSelect = options['showSelect'] || false;
  }
}
