import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { SaleListItem } from './sale-list-item.model';
import { SaleListResult } from './sale-list-result.model';
import { Sale } from './sale.model';

@Injectable()
export class SaleService {
  private readonly endpoint = `${environment.baseApiUrl}sales`;

  constructor(private readonly http: HttpClient) {}

  public getAll(offset = 0, limit = 10): Observable<SaleListResult> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    return this.http
      .get<SaleListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new SaleListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            null,
            res.body
          );
        })
      );
  }

  public search(pagedSearchQueries: Observable<PagedSearchQuery>): Observable<SaleListResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) => this.searchItems(pagedSearchQuery))
    );
  }

  public getActiveSale(): Observable<SaleListResult> {
    const params = new HttpParams()
      .set('status', '1')
      .set('byUser', 'true')
      .set('offset', '0')
      .set('limit', '10');

    return this.http
      .get<SaleListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new SaleListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            0,
            10,
            null,
            res.body
          );
        })
      );
  }

  public get(id: number): Observable<Sale> {
    return this.http.get<Sale>(`${this.endpoint}/${id}`);
  }

  public insert(sale: Sale): Observable<Sale> {
    return this.http.post<Sale>(this.endpoint, sale);
  }

  public delete(id: number): Observable<Sale> {
    return this.http.delete<Sale>(`${this.endpoint}/${id}`);
  }

  public update(id: number, sale: Sale): Observable<Sale> {
    return this.http.put<Sale>(`${this.endpoint}/${id}`, sale);
  }

  private searchItems(pagedSearchQuery: PagedSearchQuery): Observable<SaleListResult> {
    const params = new HttpParams()
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString())
      .set('startTime', pagedSearchQuery.startTime ? pagedSearchQuery.startTime.toISOString() : '')
      .set('endTime', pagedSearchQuery.endTime ? pagedSearchQuery.endTime.toISOString() : '');

    return this.http
      .get<SaleListItem[]>(
        `${this.endpoint}?search=${pagedSearchQuery.query.replace(/\+/g, '%2B')}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new SaleListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            pagedSearchQuery.query,
            res.body
          );
        })
      );
  }
}
