import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchase-active-confirm',
  templateUrl: './purchase-active-confirm.component.html',
  styleUrls: ['./purchase-active-confirm.component.scss']
})
export class PurchaseActiveConfirmComponent implements OnInit {

  public title = '';
  public message = '';
  public purchaseId: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  
  continue() {
    this.activeModal.close();
  }
}
