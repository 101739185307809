import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { User } from '../../users/shared/user.model';
import { UserService } from '../../users/shared/user.service';
import { PurchaseActiveConfirmComponent } from '../purchase-active-confirm/purchase-active-confirm.component';
import { PurchaseSupplierSelectComponent } from '../purchase-supplier-select/purchase-supplier-select.component';
import { PurchaseListItem } from '../shared/purchase-list-item.model';
import { Purchase } from '../shared/purchase.model';
import { PurchaseService } from '../shared/purchase.service';

declare var $: any;

@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaseListComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Satın Alma';
  public purchases: PurchaseListItem[] = [];
  public asyncPurchases = new BehaviorSubject<PurchaseListItem[]>([]);
  public purchasesSubscription: Subscription;
  public purchaseStatus = Globals.purchaseStatus;
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public byUserCount: number;
  public activePurchase: PurchaseListItem;
  public purchase: Purchase = new Purchase(
    0,
    0,
    null,
    0,
    null,
    null,
    null,
    0,
    0,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    []
  );
  public user: User;
  public searchValue = '';
  public searchQuery = new Subject<PagedSearchQuery>();
  public isSearchValueChanged = false;
  public isAllDataRowsLoaded = false;

  @ViewChild('mainTable', { static: true }) public mainTableRef: ElementRef;

  private startTime: Date;
  private endTime: Date;
  private unsubscribe: Subject<void> = new Subject();
  private isPageLoaded = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly purchaseService: PurchaseService,
    private readonly userService: UserService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;

      this.getPage(this.pageNumber);
    });
  }

  public ngAfterViewInit(): void {
    const startTime = moment().startOf('day');
    const endTime = moment().endOf('day');
    this.startTime = startTime.toDate();
    this.endTime = endTime.toDate();

    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (this.pageNumber - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged,
        this.startTime,
        this.endTime
      )
    );
  }

  public onScrollDown(): void {
    if (this.isPageLoaded) {
      this.getPage(this.pageNumber + 1);
    }
  }

  public getPage(page: number) {
    if (this.isAllDataRowsLoaded) {
      return;
    }

    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (page - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged,
        this.startTime,
        this.endTime
      )
    );

    if (!this.purchasesSubscription) {
      this.purchasesSubscription = this.purchaseService
        .search(this.searchQuery)
        .pipe(
          tap((res) => {
            if (res.query !== this.searchValue) {
              return;
            }

            if (this.isSearchValueChanged) {
              this.asyncPurchases.next([]);
              this.isSearchValueChanged = false;
            }

            this.totalCount = res.totalCount;
            this.pageNumber = Math.floor(res.offset / res.limit) + 1;

            const currentData = this.asyncPurchases.getValue();

            this.asyncPurchases.next(currentData.concat(res.purchases));

            this.isAllDataRowsLoaded = res.totalCount === this.asyncPurchases.getValue().length;

            if (!this.isPageLoaded) {
              this.configureDateRangePicker();
              this.isPageLoaded = true;
            }

            if (window.innerHeight > this.mainTableRef.nativeElement.offsetHeight) {
              this.onScrollDown();
            }
          }),
          map((res) => res.purchases),
          takeUntil(this.unsubscribe)
        )
        .subscribe();

      this.searchQuery.next(
        new PagedSearchQuery(
          this.searchValue,
          (page - 1) * this.perPage,
          this.perPage,
          !this.isSearchValueChanged,
          this.startTime,
          this.endTime
        )
      );
    }
  }

  public openBasketDialog(): void {
    this.purchaseService
      .getActivePurchase()
      .pipe(
        tap((res) => {
          this.byUserCount = res.totalCount;

          if (this.byUserCount > 0) {
            this.activePurchase = res.purchases[0];
          }
        }),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.byUserCount = res.totalCount;

        if (this.byUserCount > 0) {
          this.activePurchase = res.purchases[0];
        }

        if (this.byUserCount > 0) {
          const confirmModal = this.modalService.open(PurchaseActiveConfirmComponent);
          confirmModal.componentInstance.purchaseId = this.activePurchase.id;
          confirmModal.componentInstance.title = 'UYARI';
          confirmModal.componentInstance.message =
            'Aktif satın alma sepetiniz bulunuyor. Bu sepete devam etmek istiyor musunuz?';

          confirmModal.result.then(
            () => {
              this.router.navigate(['/purchases', this.activePurchase.id, 'edit']);
            },
            () => {}
          );
        } else {
          const supplierModal = this.modalService.open(PurchaseSupplierSelectComponent);
          supplierModal.componentInstance.title = 'Tedarikçi';
          supplierModal.componentInstance.message = 'Lütfen tedarikçi seçiniz';

          supplierModal.result.then(
            (modalResult) => {
              this.router.navigate(['/purchases/add'], {
                queryParams: { supplierId: modalResult.supplierId }
              });
            },
            () => {}
          );
        }
      });
  }

  public onKeyup(event: any): void {
    this.searchValue = event.target.value;
    this.pageNumber = 1;
    this.isSearchValueChanged = true;
    this.isAllDataRowsLoaded = false;
    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (this.pageNumber - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged,
        this.startTime,
        this.endTime
      )
    );
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private configureDateRangePicker() {
    const startTime = moment().startOf('day');
    const endTime = moment().endOf('day');
    const $this = this;

    $('#timeRange').daterangepicker(
      {
        locale: {
          separator: ' - ',
          applyLabel: 'Uygula',
          cancelLabel: 'İptal'
        },
        opens: 'left',
        startDate: startTime,
        endDate: endTime
      },
      (start: moment.Moment, end: moment.Moment) => {
        $this.startTime = start.toDate();
        $this.endTime = end.toDate();
        $this.pageNumber = 1;
        $this.isSearchValueChanged = true;
        $this.searchQuery.next(
          new PagedSearchQuery(
            $this.searchValue,
            ($this.pageNumber - 1) * $this.perPage,
            $this.perPage,
            !$this.isSearchValueChanged,
            $this.startTime,
            $this.endTime
          )
        );
      }
    );
  }
}
