import { ReportListItem } from './report-list-item.model';

export class ReportListResult {
  totalCount: number;
  reports: ReportListItem[];

  constructor(totalCount: number, reports: ReportListItem[]) {
    this.totalCount = totalCount;
    this.reports = reports;
  }
}
