import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserBinding } from './user-binding.model';
import { UserListResult } from './user-list-result.model';
import { User } from './user.model';

@Injectable()
export class UserService {
  private readonly endpoint = `${environment.baseApiUrl}users`;

  constructor(private readonly http: HttpClient) {}

  public getAll(offset = 0, limit = 10): Observable<UserListResult> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    return this.http
      .get<User[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new UserListResult(parseInt(res.headers.get('X-Total-Count'), 10), res.body);
        })
      );
  }

  public get(id: string): Observable<User> {
    return this.http.get<User>(`${this.endpoint}/${id}`);
  }

  public insert(user: UserBinding): Observable<User> {
    return this.http.post<User>(this.endpoint, user);
  }

  public update(id: string, user: UserBinding): Observable<User> {
    return this.http.put<User>(`${this.endpoint}/${id}`, user);
  }
}
