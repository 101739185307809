<div
  class="uploadfilecontainer form-control"
  (click)="onFileContainerClick(fileInput)"
  appDragDrop
  (uploadFile)="uploadFile($event)"
>
  <p>
    <span class="fa fa-cloud-upload fa-3x"></span><br />Yüklemek istediğiniz dosyayı buraya
    sürükleyin<br />ya da dosyayı seçmek için buraya tıklayın.
  </p>
  <input
    hidden
    type="file"
    #fileInput
    (change)="uploadFile($event.target.files)"
    class="file-upload-display"
    multiple
  />
</div>
<div class="file-div">
  <table class="table table-bordered table-striped">
    <tbody>
      <tr *ngFor="let file of files">
        <td>
          <div class="btn-group pull-right">
            <button type="button" class="btn btn-default" (click)="showFile(file)">
              <i class="fa fa-download" aria-hidden="true" title="İndir"></i>
              <span class="sr-only">İndir</span>
            </button>
            <button type="button" class="btn btn-default" (click)="deleteAttachment(file.index)">
              <i class="fa fa-remove" aria-hidden="true" title="Sil"></i>
              <span class="sr-only">Sil</span>
            </button>
          </div>
          <div>
            <ng-template [ngIf]="file.purchaseItemFileId" [ngIfElse]="fileNameElseBlock">
              <a [routerLink]="['/purchase-item-files', file.purchaseItemFileId, 'detail']">{{
                file.name
              }}</a>
            </ng-template>
            <ng-template #fileNameElseBlock>
              {{ file.name }}
            </ng-template>
            <br /><i>{{ file.description }}</i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="box-footer">
  <button type="button" class="btn btn-sm btn-success pull-right" (click)="saveChanges()">
    Dosya Değişikliklerini Kaydet
  </button>
</div>
