import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeTr from '@angular/common/locales/tr';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import * as Sentry from '@sentry/angular';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxFilesizeModule } from 'ngx-filesize';

import { environment } from '../environments/environment';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AlertService } from './core/alert.service';
import { AppRoutingModule } from './core/app-routing.module';
import { AppComponent } from './core/app.component';
import { AuthGuard } from './core/auth/auth.guard';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { AuthService } from './core/auth/auth.service';
import { ClientInfoInterceptor } from './core/client-info.interceptor';
import { ConfirmDeactivateGuard } from './core/confirm-deactivate-guard';
import { ErrorInterceptor } from './core/error.interceptor';
import { StandardPageComponent } from './core/standard-page/standard-page.component';
import { UpdateService } from './core/update.service';
import { CustomerService } from './customers/shared/customer.service';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { AboutComponent } from './pages/about/about.component';
import { Error404Component } from './pages/error404/error404.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductEditComponent } from './products/product-edit/product-edit.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { ProductService } from './products/shared/product.service';
import { ProfileComponent } from './profile/profile.component';
import { ProfileService } from './profile/profile.service';
import { OrderedComponent } from './purchases/ordered/ordered.component';
import { PurchaseActiveConfirmComponent } from './purchases/purchase-active-confirm/purchase-active-confirm.component';
import { PurchaseCompleteFormComponent } from './purchases/purchase-complete-form/purchase-complete-form.component';
import { PurchaseDetailComponent } from './purchases/purchase-detail/purchase-detail.component';
import { PurchaseEditComponent } from './purchases/purchase-edit/purchase-edit.component';
import { PurchaseItemDetailComponent } from './purchases/purchase-item-detail/purchase-item-detail.component';
import { PurchaseItemEditFormComponent } from './purchases/purchase-item-edit-form/purchase-item-edit-form.component';
import { PurchaseItemFileDetailComponent } from './purchases/purchase-item-file-detail/purchase-item-file-detail.component';
import { PurchaseListComponent } from './purchases/purchase-list/purchase-list.component';
import { PurchaseProductListComponent } from './purchases/purchase-product-list/purchase-product-list.component';
import { PurchaseSummaryEditFormComponent } from './purchases/purchase-summary-edit-form/purchase-summary-edit-form.component';
import { PurchaseSupplierSelectComponent } from './purchases/purchase-supplier-select/purchase-supplier-select.component';
import { PurchaseItemFileService } from './purchases/shared/purchase-item-file.service';
import { PurchaseItemDetailService } from './purchases/shared/purchase-item.service';
import { PurchaseService } from './purchases/shared/purchase.service';
import { ShippedComponent } from './purchases/shipped/shipped.component';
import { ProfitabilityReportComponent } from './reports/profitability-report/profitability-report.component';
import { ProfitabilityReportService } from './reports/profitability-report/profitability-report.service';
import { PurchaseReportComponent } from './reports/purchases-report/purchase-report.component';
import { PurchaseReportService } from './reports/purchases-report/purchase-report.service';
import { ReportComponent } from './reports/report/report.component';
import { ReportService } from './reports/report/report.service';
import { ReservedDetailComponent } from './reserved/reserved-detail/reserved-detail.component';
import { ReservedEditComponent } from './reserved/reserved-edit/reserved-edit.component';
import { ReservedListComponent } from './reserved/reserved-list/reserved-list.component';
import { ReservedProductListComponent } from './reserved/reserved-product-list/reserved-product-list.component';
import { ReservedItemService } from './reserved/shared/reserved-item.service';
import { SaleActiveConfirmComponent } from './sales/sale-active-confirm/sale-active-confirm.component';
import { SaleCompleteFormComponent } from './sales/sale-complete-form/sale-complete-form.component';
import { SaleCustomerSelectComponent } from './sales/sale-customer-select/sale-customer-select.component';
import { SaleDetailComponent } from './sales/sale-detail/sale-detail.component';
import { SaleEditComponent } from './sales/sale-edit/sale-edit.component';
import { SaleListComponent } from './sales/sale-list/sale-list.component';
import { SaleProductListComponent } from './sales/sale-product-list/sale-product-list.component';
import { SaleService } from './sales/shared/sale.service';
import { ProductGroupEditComponent } from './settings/product-groups/product-group-edit/product-group-edit.component';
import { ProductGroupListComponent } from './settings/product-groups/product-group-list/product-group-list.component';
import { ProductGroupPropertyEditComponent } from './settings/product-groups/product-group-property-edit/product-group-property-edit.component';
import { ProductGroupService } from './settings/product-groups/shared/product-group.service';
import { DragDropDirective } from './shared/directives/drag-drop.directive';
import { ModalTextComponent } from './shared/modals/modal-text/modal-text.component';
import { SharedModule } from './shared/shared.module';
import { UploadFileConfirmComponent } from './shared/upload-file/upload-file-confirm/upload-file-confirm';
import { UploadFileComponent } from './shared/upload-file/upload-file.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StockDetailComponent } from './stock/stock-detail/stock-detail.component';
import { StockComponent } from './stock/stock.component';
import { StockService } from './stock/stock.service';
import { SupplierService } from './suppliers/shared/supplier.service';
import { UserService } from './users/shared/user.service';
import { InWarehouseComponent } from './warehouse/in-warehouse/in-warehouse.component';
import { ReadyForDispatchComponent } from './warehouse/ready-for-dispatch/ready-for-dispatch.component';
import { PurchaseItemService } from './warehouse/shared/purchase-item.service';
import { SaleItemService } from './warehouse/shared/sale-item.service';
import { ShippedFromWarehouseComponent } from './warehouse/shipped-from-warehouse/shipped-from-warehouse.component';
import { WaitingForShipmentComponent } from './warehouse/waiting-for-shipment/waiting-for-shipment.component';
import { WaitingForStorageComponent } from './warehouse/waiting-for-storage/waiting-for-storage.component';

registerLocaleData(localeTr, 'tr');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AboutComponent,
    Error404Component,
    ReportComponent,
    ProfitabilityReportComponent,
    PurchaseReportComponent,
    StockComponent,
    LoginComponent,
    StandardPageComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    PurchaseListComponent,
    PurchaseEditComponent,
    PurchaseDetailComponent,
    PurchaseActiveConfirmComponent,
    PurchaseItemEditFormComponent,
    PurchaseSupplierSelectComponent,
    PurchaseProductListComponent,
    PurchaseCompleteFormComponent,
    PurchaseSummaryEditFormComponent,
    OrderedComponent,
    ShippedComponent,
    SaleListComponent,
    SaleEditComponent,
    SaleDetailComponent,
    SaleActiveConfirmComponent,
    SaleCustomerSelectComponent,
    SaleProductListComponent,
    SaleCompleteFormComponent,
    ReservedListComponent,
    ReservedEditComponent,
    ReservedProductListComponent,
    ReservedDetailComponent,
    ProductGroupListComponent,
    ChangePasswordComponent,
    ProductGroupEditComponent,
    ProductGroupPropertyEditComponent,
    ProductListComponent,
    ProductEditComponent,
    ProductDetailComponent,
    PurchaseItemDetailComponent,
    PurchaseItemFileDetailComponent,
    WaitingForStorageComponent,
    InWarehouseComponent,
    WaitingForShipmentComponent,
    ReadyForDispatchComponent,
    ShippedFromWarehouseComponent,
    StockDetailComponent,
    ModalTextComponent,
    DragDropDirective,
    UploadFileComponent,
    UploadFileConfirmComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FileSaverModule,
    HttpClientModule,
    MatSnackBarModule,
    MatTooltipModule,
    NgProgressHttpModule,
    NgProgressModule.withConfig({
      color: '#f0522d',
      debounceTime: 70,
      spinner: false
    }),
    NgxFilesizeModule,
    ReCaptchaModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    SharedModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'tr'
    },
    Title,
    ConfirmDeactivateGuard,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientInfoInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    AlertService,
    AuthService,
    {
      provide: ErrorHandler,
      useValue: environment.production
        ? Sentry.createErrorHandler({
            showDialog: false
          })
        : new ErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    CustomerService, // TODO: Added for report pages. It should be removed after creation of ReportModule.
    UpdateService,
    ProductGroupService,
    ProductService,
    ProfileService,
    PurchaseItemService,
    PurchaseItemFileService,
    SaleItemService,
    ReservedItemService,
    ReportService,
    ProfitabilityReportService,
    PurchaseReportService,
    StockService,
    SupplierService, // TODO: Added for report pages. It should be removed after creation of ReportModule.
    PurchaseService,
    PurchaseItemDetailService,
    SaleService,
    UserService // TODO: Added for report pages. It should be removed after creation of ReportModule.
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
