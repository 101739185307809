import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertService } from '../core/alert.service';
import { AppComponent } from '../core/app.component';
import { AuthService } from '../core/auth/auth.service';
import { ForgotPasswordBinding } from '../core/auth/forgot-password-binding.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnDestroy, OnInit {
  public pageName = 'Şifremi Unuttum';
  public model: ForgotPasswordBinding = new ForgotPasswordBinding('', '');
  public submitSuccess = false;
  public submitting = false;
  public errors: string[] = [];

  @ViewChild(ReCaptchaComponent)
  private readonly captcha: ReCaptchaComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly appComponent: AppComponent,
    public readonly alertService: AlertService,
    private readonly authService: AuthService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
  }

  public sendEmail(): void {
    this.errors = [];
    this.submitting = false;
    this.model.email = this.model.email.trim();

    if (this.model.email === '') {
      this.errors.push('Lütfen geçerli bir e-posta adresi girin.');
    }

    if (this.model.captcha === '') {
      this.errors.push('Lütfen robot olmadığınızı doğrulayın.');
    }

    if (this.errors.length === 0) {
      this.authService
        .forgotPassword(this.model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.submitSuccess = true;
          this.submitting = false;
        });
    } else {
      this.submitting = false;
    }
  }

  public resetCaptcha(): void {
    this.model.captcha = '';
    this.captcha.reset();
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
