import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { saveAs } from 'file-saver';
import { FileSaverService } from 'ngx-filesaver';
import * as QrCode from 'qrcode';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { AppComponent } from '../../core/app.component';
import { PurchaseItemFile } from '../shared/purchase-item-file.model';
import { PurchaseItemFileService } from '../shared/purchase-item-file.service';

@Component({
  selector: 'app-purchase-item-file-detail',
  templateUrl: './purchase-item-file-detail.component.html'
})
export class PurchaseItemFileDetailComponent implements OnDestroy, OnInit {
  public pageName = 'Dosya Detayları';
  public asyncPurchaseItemFile: Observable<PurchaseItemFile>;
  public asyncQrCode = '';
  public publicFileUrl = '';
  public purchaseItemFileId = 0;
  public selectedPurchaseItemFile: PurchaseItemFile;
  public isLoaded = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly appComponent: AppComponent,
    private readonly fileSaverService: FileSaverService,
    private readonly purchaseItemFileService: PurchaseItemFileService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
    this.activatedRoute.queryParams.subscribe((activatedRouteQueryParams: Params) => {
      this.activatedRoute.url.subscribe((url) => {
        this.activatedRoute.params.subscribe((params) => {
          this.purchaseItemFileId = parseInt(params.id, 10) || -1;
          this.getPurchaseItemFile();
        });
      });
    });
  }

  public getPurchaseItemFile() {
    this.asyncPurchaseItemFile = this.purchaseItemFileService
      .getMetadata(this.purchaseItemFileId)
      .pipe(
        tap(
          (res) => {
            this.selectedPurchaseItemFile = res;
            this.isLoaded = true;

            if (res.isPublicSharingActive) {
              this.generateQrCode();
            }
          },
          (err) => {
            this.alertService.setAlert(
              AlertType.danger,
              'Böyle bir dosya bulunmuyor ya da bu dosyayı görüntülemeye yetkiniz yok.'
            );
          }
        )
      );
  }

  public generateQrCode() {
    this.publicFileUrl = `${environment.publicFileViewerUrl}?id=${this.selectedPurchaseItemFile.publicIdentifier}&token=${this.selectedPurchaseItemFile.publicAccessToken}`;
    QrCode.toDataURL(this.publicFileUrl, {
      errorCorrectionLevel: 'Q'
    })
      .then((url) => {
        this.asyncQrCode = url;
      })
      .catch((err) => {
        this.alertService.setAlert(
          AlertType.danger,
          'Karekodun oluşturulması esnasında bir hata oluştu.'
        );

        throw err;
      });
  }

  public saveQrCode() {
    if (this.asyncQrCode) {
      saveAs(this.asyncQrCode, `${this.selectedPurchaseItemFile.fileName}-qrcode.png`, {
        type: 'image/png'
      });
    }
  }

  public downloadFile(): Subscription {
    return this.purchaseItemFileService
      .get(this.purchaseItemFileId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.fileSaverService.save(res, this.selectedPurchaseItemFile.fileName);
        },
        (err) => {
          this.alertService.setAlert(
            AlertType.danger,
            'Dosyanın indirilmesi esnasında bir hata oluştu.'
          );
        }
      );
  }

  public shareFile(): void {
    const purchaseItemFile = { ...this.selectedPurchaseItemFile };
    purchaseItemFile.isPublicSharingActive = true;
    this.purchaseItemFileService
      .updateMetadata(this.purchaseItemFileId, purchaseItemFile)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.alertService.clearAlert();
          this.getPurchaseItemFile();
        },
        (err) => {
          this.alertService.setAlert(
            AlertType.danger,
            'Dosyanın paylaşılması sırasında bir hata oluştu.'
          );
        }
      );
  }

  public unshareFile(): void {
    const purchaseItemFile = { ...this.selectedPurchaseItemFile };
    purchaseItemFile.isPublicSharingActive = false;
    this.purchaseItemFileService
      .updateMetadata(this.purchaseItemFileId, purchaseItemFile)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.alertService.clearAlert();
          this.getPurchaseItemFile();
        },
        (err) => {
          this.alertService.setAlert(
            AlertType.danger,
            'Dosyanın paylaşılmasının durdurulması sırasında bir hata oluştu.'
          );
        }
      );
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
