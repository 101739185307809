import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { CustomerListItem } from '../../customers/shared/customer-list-item.model';
import { CustomerService } from '../../customers/shared/customer.service';

@Component({
  selector: 'app-sale-customer-select',
  templateUrl: './sale-customer-select.component.html',
  styleUrls: ['./sale-customer-select.component.scss']
})
export class SaleCustomerSelectComponent implements OnInit {
  public title = '';
  public message = '';
  public saleId: number;
  asyncCustomers: Observable<CustomerListItem[]>;
  customers: CustomerListItem[] = [];
  selectedCustomerId = 0;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.getCustomers();
  }

  private getCustomers() {
    this.asyncCustomers = this.customerService.getAll(0, 10000).pipe(tap(
      res => {
        this.customers = res.customers;
      },
      err => {
      }
    ),map(res => res.customers),share(),);
  }

  continue() {
    this.activeModal.close({ customerId: this.selectedCustomerId });
  }
}
