import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { SupplierListItem } from '../../suppliers/shared/supplier-list-item.model';
import { SupplierService } from '../../suppliers/shared/supplier.service';

@Component({
  selector: 'app-purchase-supplier-select',
  templateUrl: './purchase-supplier-select.component.html',
  styleUrls: ['./purchase-supplier-select.component.scss']
})
export class PurchaseSupplierSelectComponent implements OnInit {
  public title = '';
  public message = '';
  public purchaseId: number;
  asyncSuppliers: Observable<SupplierListItem[]>;
  suppliers: SupplierListItem[] = [];
  selectedSupplierId = 0;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly supplierService: SupplierService
  ) { }

  ngOnInit(): void {
    this.getSuppliers();
  }

  getSuppliers() {
    this.asyncSuppliers = this.supplierService.getAll(0, 10000).pipe(tap(
      res => {
        this.suppliers = res.suppliers;
      },
      err => {
      }
    ),map(res => res.suppliers),share(),);
  }

  continue() {
    this.activeModal.close({ supplierId: this.selectedSupplierId });
  }
}
