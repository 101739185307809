import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class UpdateService {
  private unsubscribeSubject: Subject<void> = new Subject();

  constructor(private readonly swUpdate: SwUpdate, private readonly snackbar: MatSnackBar) {}

  public subscribe(): void {
    this.swUpdate.available.pipe(takeUntil(this.unsubscribeSubject)).subscribe((evt) => {
      const snack = this.snackbar.open(
        'Dipendo için yeni bir güncelleme mevcut. En yeni özellikleri kullanmak için lütfen sayfayı yenileyin.',
        'Yenile'
      );

      snack
        .onAction()
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(() => {
          window.location.reload();
        });
    });
  }

  public unsubscribe(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
