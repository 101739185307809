import { CustomerSaleItemSummary } from './customer-sale-item-summary.model';

export class CustomerSaleItemsSummaryResult {
  public totalCount: number;
  public offset: number;
  public limit: number;
  public customerSaleItemSummaries: CustomerSaleItemSummary[];

  constructor(
    totalCount: number,
    offset: number,
    limit: number,
    customerSaleItemSummaries: CustomerSaleItemSummary[]
  ) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.customerSaleItemSummaries = customerSaleItemSummaries;
  }
}
