import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';
import zipcelx from 'zipcelx-es5';

import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { SaleActiveConfirmComponent } from '../../sales/sale-active-confirm/sale-active-confirm.component';
import { SaleCustomerSelectComponent } from '../../sales/sale-customer-select/sale-customer-select.component';
import { SaleListItem } from '../../sales/shared/sale-list-item.model';
import { Sale } from '../../sales/shared/sale.model';
import { SaleService } from '../../sales/shared/sale.service';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';

declare var $: any;

@Component({
  selector: 'app-reserved-list',
  templateUrl: './reserved-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservedListComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Satış Rezervesi';
  public sales: SaleListItem[] = [];
  public asyncSales = new BehaviorSubject<SaleListItem[]>([]);
  public salesSubscription: Subscription;
  public saleStatus = Globals.saleStatus;
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public byUserCount: number;
  public activeSale: SaleListItem;
  public searchValue = '';
  public searchQuery = new Subject<PagedSearchQuery>();
  public isSearchValueChanged = false;
  public isAllDataRowsLoaded = false;
  public sale: Sale;

  @ViewChild('mainTable', { static: true }) public mainTableRef: ElementRef;

  private unsubscribe: Subject<void> = new Subject();
  private startTime: Date;
  private endTime: Date;
  private isPageLoaded = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly saleService: SaleService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.startTime =
        typeof params['startTime'] !== 'undefined' && moment(params['startTime']).isValid()
          ? moment(params['startTime']).toDate()
          : moment()
              .startOf('day')
              .toDate();
      this.endTime =
        typeof params['endTime'] !== 'undefined' && moment(params['endTime']).isValid()
          ? moment(params['endTime']).toDate()
          : moment()
              .endOf('day')
              .toDate();
      this.searchValue = params['search'] || '';

      this.pageNumber = 1;
      this.isSearchValueChanged = true;
      this.isAllDataRowsLoaded = false;

      const drp = $('#timeRange').data('daterangepicker');

      if (drp) {
        drp.setStartDate(this.startTime);
        drp.setEndDate(this.endTime);
      }

      this.getPage(this.pageNumber);
    });
  }

  public ngAfterViewInit(): void {
    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (this.pageNumber - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged,
        this.startTime,
        this.endTime
      )
    );
  }

  public saveExcel(): void {
    const itemData = [];

    itemData.push([
      {
        value: 'Müşteri',
        type: 'string'
      },
      {
        value: 'Tarih',
        type: 'string'
      },
      {
        value: 'Tutar',
        type: 'number'
      },
      {
        value: 'Durum',
        type: 'string'
      }
    ]);

    const sales = this.asyncSales.getValue();

    sales.forEach((sale) => {
      itemData.push(this.createExcelRow(sale));
    });

    zipcelx({
      filename: 'rezerve',
      sheet: {
        data: itemData
      }
    });
  }

  public onScrollDown(): void {
    if (this.isPageLoaded) {
      this.getPage(this.pageNumber + 1);
    }
  }

  public getPage(page: number) {
    if (this.isAllDataRowsLoaded) {
      return;
    }

    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (page - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged,
        this.startTime,
        this.endTime
      )
    );

    if (!this.salesSubscription) {
      this.salesSubscription = this.saleService
        .search(this.searchQuery)
        .pipe(
          tap((res) => {
            if (res.query !== this.searchValue) {
              return;
            }

            if (this.isSearchValueChanged) {
              this.asyncSales.next([]);
              this.isSearchValueChanged = false;
            }

            this.totalCount = 0;

            for (const item of res.sales) {
              if (item.status === 4) {
                this.totalCount++;
              }
            }

            this.pageNumber = Math.floor(res.offset / res.limit) + 1;

            const currentData = this.asyncSales.getValue();

            this.asyncSales.next(currentData.concat(res.sales));

            this.isAllDataRowsLoaded = res.totalCount === this.asyncSales.getValue().length;

            if (!this.isPageLoaded) {
              this.configureDateRangePicker();
              this.isPageLoaded = true;
            }

            if (window.innerHeight > this.mainTableRef.nativeElement.offsetHeight) {
              this.onScrollDown();
            }
          }),
          map((res) => res.sales),
          takeUntil(this.unsubscribe)
        )
        .subscribe();

      this.searchQuery.next(
        new PagedSearchQuery(
          this.searchValue,
          (page - 1) * this.perPage,
          this.perPage,
          !this.isSearchValueChanged,
          this.startTime,
          this.endTime
        )
      );
    }
  }

  public openBasketDialog(): void {
    this.saleService
      .getActiveSale()
      .pipe(
        tap((res) => {
          this.byUserCount = res.totalCount;

          if (this.byUserCount > 0) {
            this.activeSale = res.sales[0];
          }
        }),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.byUserCount = res.totalCount;

        if (this.byUserCount > 0) {
          this.activeSale = res.sales[0];
        }

        if (this.byUserCount > 0) {
          const confirmModal = this.modalService.open(SaleActiveConfirmComponent);
          confirmModal.componentInstance.saleId = this.activeSale.id;
          confirmModal.componentInstance.title = 'UYARI';
          confirmModal.componentInstance.message =
            'Aktif satış sepetiniz bulunuyor. Bu sepete devam etmek istiyor musunuz?';

          confirmModal.result.then(
            (modalResult) => {
              this.router.navigate(['/reserved', this.activeSale.id, 'detail']);
            },
            (err) => {}
          );
        } else {
          const customerModal = this.modalService.open(SaleCustomerSelectComponent);
          customerModal.componentInstance.title = 'Müşteri';
          customerModal.componentInstance.message = 'Lütfen müşteri seçiniz';

          customerModal.result.then(
            (modalResult) => {
              this.router.navigate(['/reserved/add'], {
                queryParams: { customerId: modalResult.customerId }
              });
            },
            (err) => {}
          );
        }
      });
  }

  public onKeyup(event: any): void {
    this.searchValue = event.target.value;

    this.router.navigate([], {
      queryParams: {
        startTime: this.startTime.toISOString(),
        endTime: this.endTime.toISOString(),
        search: this.searchValue
      },
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });

    this.pageNumber = 1;
    this.isSearchValueChanged = true;
    this.isAllDataRowsLoaded = false;
    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (this.pageNumber - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged,
        this.startTime,
        this.endTime
      )
    );
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private configureDateRangePicker() {
    const startTime = moment(this.startTime);
    const endTime = moment(this.endTime);
    const $this = this;

    $('#timeRange').daterangepicker(
      {
        locale: {
          separator: ' - ',
          applyLabel: 'Uygula',
          cancelLabel: 'İptal'
        },
        opens: 'left',
        startDate: startTime,
        endDate: endTime
      },
      (start: moment.Moment, end: moment.Moment) => {
        $this.startTime = start.toDate();
        $this.endTime = end.toDate();

        $this.router.navigate([], {
          queryParams: {
            startTime: $this.startTime.toISOString(),
            endTime: $this.endTime.toISOString(),
            search: $this.searchValue
          },
          relativeTo: this.activatedRoute
        });

        $this.pageNumber = 1;
        $this.isSearchValueChanged = true;
        $this.searchQuery.next(
          new PagedSearchQuery(
            $this.searchValue,
            ($this.pageNumber - 1) * $this.perPage,
            $this.perPage,
            !$this.isSearchValueChanged,
            $this.startTime,
            $this.endTime
          )
        );
      }
    );
  }

  private createExcelRow(item: SaleListItem): any {
    return [
      {
        value: item.customer.title,
        type: 'string'
      },
      {
        value: moment.parseZone(item.recordTime).format('DD.MM.YYYY HH:mm'),
        type: 'string'
      },
      {
        value: this.formatPrice(item),
        type: 'string'
      },
      {
        value: this.saleStatus[item.status],
        type: 'string'
      }
    ];
  }

  private formatPrice(item: SaleListItem): string {
    let price = '';

    item.totalPrice.forEach((money) => {
      price += `${money.amount.toFixed(2)} ${money.currency} `;
    });

    return price;
  }
}
