<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>
      <a routerLink="/sales"><i class="fa fa-truck"></i> Satış</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <div class="row">
    <div class="col-md-12">
      <!-- Default box -->
      <div class="box box-primary" *ngIf="sale && sale.saleId > 0">
        <div class="box-header with-border">
          <h3 class="box-title">Özet</h3>
          <div class="box-tools">
            <button
              type="button"
              class="btn btn-sm btn-success"
              style="margin-left: 4pt;"
              (click)="saveWarehouseCheckoutForm()"
            >
              Depo Çıkış Formu
            </button>
          </div>
        </div>
        <!-- /.box-header -->
        <div class="box-body">
          <dl class="dl-horizontal">
            <dt>Müşteri</dt>
            <dd>
              <a [routerLink]="['/customers', sale.customer.customerId, 'detail']">{{
                sale.customer.title
              }}</a>
            </dd>
            <dt>Satış Yapan Kullanıcı</dt>
            <dd>{{ sale.user.firstName + ' ' + sale.user.lastName }}</dd>
            <dt>Satış Tarihi</dt>
            <dd>
              {{
                sale.saleTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </dd>
            <dt *ngIf="sale.deliveryTime">Gönderim Tarihi</dt>
            <dd *ngIf="sale.deliveryTime">
              {{
                sale.deliveryTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </dd>
            <dt>Müşterinin Sipariş Kodu</dt>
            <dd>{{ sale.externalSaleCode }}</dd>
            <dt>Açıklama</dt>
            <dd>{{ sale.explanation }}</dd>
          </dl>
        </div>
        <!-- /.box-body -->
      </div>
      <!-- /.box -->

      <div class="nav-tabs-custom">
        <div *ngIf="!sale" class="overlay loading-overlay">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="nav nav-tabs">
          <li class="active" (click)="clearSelection(1)">
            <a href="#tab_1" data-toggle="tab"><strong>Satıldı</strong></a>
          </li>
          <li (click)="clearSelection(2)">
            <a href="#tab_2" data-toggle="tab"><strong>Sevke Hazır</strong></a>
          </li>
          <li (click)="clearSelection(3)">
            <a href="#tab_3" data-toggle="tab"><strong>Gönderildi</strong></a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane active" id="tab_1">
            <ng-container *ngIf="productGroupIds.get(1)">
              <ng-container *ngFor="let productGroupId of productGroupIds.get(1); let i = index">
                <table *ngIf="sale" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style="width: 10px;">
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="headerSelectionList.get(1)[i].selected"
                          (change)="onChangeHeaderCheckbox(1, i)"
                        />
                      </th>
                      <th>Ürün Adı</th>
                      <th style="width: 100px;">Satış Tarihi</th>
                      <th style="width: 100px;">Gönderim Tarihi</th>
                      <th style="width: 100px;">Satış Fiyatı</th>
                      <th style="width: 80px;">Satılan Miktar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let saleItem of sale.saleItems
                          | matchProductGroup: productGroupId
                          | matchSaleItemStatus: 1
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="saleItem.selected"
                          (change)="onChangeItemCheckbox(saleItem, i)"
                        />
                      </td>
                      <td>
                        <a
                          [routerLink]="[
                            '/purchase-items',
                            saleItem.purchaseItem.purchaseItemId,
                            'detail'
                          ]"
                          >{{ saleItem.purchaseItem.product.name }}</a
                        >
                      </td>
                      <td>
                        {{
                          sale.saleTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          saleItem.deliveryTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>{{ saleItem.price | number: '1.2-4' }} {{ saleItem.currencyCode }}</td>
                      <td>
                        {{ saleItem.saleCount }}
                        {{
                          productGroups.get(productGroupId)?.unit
                            | measurementUnitSymbol: productGroups.get(productGroupId)?.unitGroup
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!productGroupIds.get(1)">
              <p><strong>"Satıldı"</strong> durumunda bir satış bulunmuyor.</p>
            </ng-container>
          </div>

          <div class="tab-pane" id="tab_2">
            <ng-container *ngIf="productGroupIds.get(2)">
              <ng-container *ngFor="let productGroupId of productGroupIds.get(2); let i = index">
                <table *ngIf="sale" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style="width: 10px;">
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="headerSelectionList.get(2)[i].selected"
                          (change)="onChangeHeaderCheckbox(2, i)"
                        />
                      </th>
                      <th>Ürün Adı</th>
                      <th style="width: 100px;">Satış Tarihi</th>
                      <th style="width: 100px;">Gönderim Tarihi</th>
                      <th style="width: 100px;">Satış Fiyatı</th>
                      <th style="width: 80px;">Satılan Miktar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let saleItem of sale.saleItems
                          | matchProductGroup: productGroupId
                          | matchSaleItemStatus: 2
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="saleItem.selected"
                          (change)="onChangeItemCheckbox(saleItem, i)"
                        />
                      </td>
                      <td>
                        <a
                          [routerLink]="[
                            '/purchase-items',
                            saleItem.purchaseItem.purchaseItemId,
                            'detail'
                          ]"
                          >{{ saleItem.purchaseItem.product.name }}</a
                        >
                      </td>
                      <td>
                        {{
                          sale.saleTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          saleItem.deliveryTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>{{ saleItem.price | number: '1.2-4' }} {{ saleItem.currencyCode }}</td>
                      <td>
                        {{ saleItem.saleCount }}
                        {{
                          productGroups.get(productGroupId)?.unit
                            | measurementUnitSymbol: productGroups.get(productGroupId)?.unitGroup
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!productGroupIds.get(2)">
              <p><strong>"Sevke Hazır"</strong> durumunda bir satış bulunmuyor.</p>
            </ng-container>
          </div>

          <div class="tab-pane" id="tab_3">
            <ng-container *ngIf="productGroupIds.get(3)">
              <ng-container *ngFor="let productGroupId of productGroupIds.get(3); let i = index">
                <table *ngIf="sale" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style="width: 10px;">
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="headerSelectionList.get(3)[i].selected"
                          (change)="onChangeHeaderCheckbox(3, i)"
                        />
                      </th>
                      <th>Ürün Adı</th>
                      <th style="width: 100px;">Satış Tarihi</th>
                      <th style="width: 100px;">Gönderim Tarihi</th>
                      <th style="width: 100px;">Satış Fiyatı</th>
                      <th style="width: 80px;">Satılan Miktar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let saleItem of sale.saleItems
                          | matchProductGroup: productGroupId
                          | matchSaleItemStatus: 3
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="saleItem.selected"
                          (change)="onChangeItemCheckbox(saleItem, i)"
                        />
                      </td>
                      <td>
                        <a
                          [routerLink]="[
                            '/purchase-items',
                            saleItem.purchaseItem.purchaseItemId,
                            'detail'
                          ]"
                          >{{ saleItem.purchaseItem.product.name }}</a
                        >
                      </td>
                      <td>
                        {{
                          sale.saleTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          saleItem.deliveryTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>{{ saleItem.price | number: '1.2-4' }} {{ saleItem.currencyCode }}</td>
                      <td>
                        {{ saleItem.saleCount }}
                        {{
                          productGroups.get(productGroupId)?.unit
                            | measurementUnitSymbol: productGroups.get(productGroupId)?.unitGroup
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!productGroupIds.get(3)">
              <p><strong>"Gönderildi"</strong> durumunda bir satış bulunmuyor.</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box-footer" *ngIf="selectedCount > 0">
    <div class="col-md-4"></div>
    <div class="col-md-1">
      <button
        type="submit"
        name="submit"
        id="submit"
        class="btn btn-primary"
        *ngIf="selectedTab > 1"
        (click)="changeItemStatus(-1)"
      >
        Geri Al
      </button>
      <button
        type="submit"
        name="submit"
        id="submit"
        class="btn alert-danger"
        *ngIf="selectedTab == 1"
        (click)="cancelItem()"
      >
        İptal Et
      </button>
    </div>
    <div class="col-md-1">
      <button
        type="submit"
        name="submit"
        id="submit"
        class="btn btn-primary"
        *ngIf="selectedTab != 3"
        (click)="changeItemStatus(1)"
      >
        İlerlet
      </button>
    </div>
    <div class="col-md-1">
      <button
        type="submit"
        name="submit"
        id="submit"
        class="btn btn-success"
        *ngIf="selectedTab != 3"
        (click)="goToSaleEdit()"
      >
        Düzenle
      </button>
    </div>
    <div class="col-md-5"></div>
  </div>
</section>
