<section class="content-header">
  <h1>
    {{ pageName }}
    <small></small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>Depo</li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <!-- Default box -->
  <div class="box box-primary" #mainTable>
    <div class="box-header with-border">
      <h3 class="box-title">Sipariş Verilen Ürünler Listesi</h3>

      <div class="box-tools">
        <form class="form-horizontal form-inline">
          <button type="button" class="btn btn-sm btn-success" (click)="saveExcel()">
            Excel'e Aktar
          </button>
          <input
            type="search"
            [(ngModel)]="searchValue"
            name="searchValue"
            class="form-control input-sm"
            placeholder="Siparişlerde ara"
            (keyup)="onKeyup($event)"
          />
        </form>
      </div>
    </div>
    <!-- /.box-header -->
    <div
      class="box-body table-responsive"
      infinite-scroll
      (scrolled)="onScrollDown()"
      [immediateCheck]="true"
    >
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th class="grouping-cell"></th>
            <th class="selector-cell">
              <input
                type="checkbox"
                autocomplete="off"
                [(ngModel)]="isHeaderSelected"
                (change)="onChangeHeaderCheckbox()"
              />
            </th>
            <th>Ürün Adı</th>
            <th>Tedarikçi</th>
            <th>Ağırlık</th>
            <th style="width: 100px;">Hazırlanma Tarihi</th>
            <th style="width: 100px;">Tahmini Yükleme Tarihi</th>
            <th style="width: 100px;">Tahmini Varış Tarihi</th>
            <th style="width: 100px;">Kesin Varış Tarihi</th>
            <th style="width: 120px;">Satın Alma Fiyatı</th>
            <th style="width: 80px;">Miktar</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let purchaseItem of asyncPurchaseItems | async">
            <td
              [class]="
                'grouping-cell' +
                (purchaseItem.identicalItems.length > 0 ? '' : ' grouping-sub-cell')
              "
            >
              <button
                *ngIf="purchaseItem.identicalItems.length > 0"
                class="btn btn-accordion"
                (click)="onClickGroupCaret(purchaseItem)"
              >
                <i
                  [class]="'fa ' + (purchaseItem.isGroupOpen ? 'fa-caret-down' : 'fa-caret-right')"
                ></i>
                ({{ purchaseItem.identicalItems.length }})
              </button>
            </td>
            <td
              [class]="
                'selector-cell' +
                (purchaseItem.identicalItems.length > 0 ? '' : ' selector-sub-cell')
              "
            >
              <input
                type="checkbox"
                autocomplete="off"
                [(ngModel)]="purchaseItem.selected"
                (change)="onChangeItemCheckbox(purchaseItem)"
              />
            </td>
            <td *ngIf="purchaseItem.purchaseItemId < 0">
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen">{{
                purchaseItem.product.name
              }}</ng-container>
            </td>
            <td *ngIf="purchaseItem.purchaseItemId > 0">
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                ><a [routerLink]="['/purchase-items', purchaseItem.purchaseItemId, 'detail']">{{
                  purchaseItem.product.name
                }}</a></ng-container
              >
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                ><a [routerLink]="['/suppliers', purchaseItem.supplierId, 'detail']">{{
                  purchaseItem.supplierTitle
                }}</a></ng-container
              >
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                >{{ purchaseItem.product.unitMass * purchaseItem.purchaseCount | number: '1.0-3' }}
                {{ purchaseItem.product.unitOfMass }}</ng-container
              >
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen">{{
                purchaseItem.letterOfCreditTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}</ng-container>
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen">{{
                purchaseItem.estimatedShippingTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}</ng-container>
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen">{{
                purchaseItem.estimatedArrivalTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}</ng-container>
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen">{{
                purchaseItem.arrivalTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}</ng-container>
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                >{{ purchaseItem.cost | number: '1.2-4' }}
                {{ purchaseItem.product.currencyCode }}</ng-container
              >
            </td>
            <td>
              <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                >{{ purchaseItem.purchaseCount }}
                {{
                  purchaseItem.product.groupUnit
                    | measurementUnitSymbol: purchaseItem.product.groupUnitGroup
                }}</ng-container
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">Satın alınan ürün bulunmamaktadır.</p>
    </div>
    <div class="box-footer clearfix"></div>
  </div>

  <div class="bottom-calculator" *ngIf="selectedCount > 0">
    {{ selectedCount }} öğe seçildi. Toplam Kütle: {{ selectedMass | number: '1.0-3' }} kg, Toplam
    Tutar: {{ selectedCosts.join(' + ') }}
    <a href="javascript:void(0);" (click)="cancelItem()" class="button"
      ><i class="fa fa-trash"></i
    ></a>
    <a href="javascript:void(0);" (click)="proceedItemToNextStep()" class="button"
      ><i class="fa fa-step-forward"></i
    ></a>
    <a href="javascript:void(0);" (click)="editItem()" class="button"><i class="fa fa-edit"></i></a>
  </div>
</section>
