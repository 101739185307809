import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { ProductGroupListItem } from '../../settings/product-groups/shared/product-group-list-item.model';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { ProductListItem } from '../shared/product-list-item.model';
import { ProductService } from '../shared/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnDestroy, OnInit {
  public errorType = '';
  public pageName = 'Ürünler';
  public productGroups: ProductGroupListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupListItem[]>;
  public selectedProductGroup: ProductGroup;
  public selectedProductGroupId = 0;
  public selectedProductGroupName = '';
  public products: ProductListItem[] = [];
  public asyncProducts: Observable<ProductListItem[]>;
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly productGroupService: ProductGroupService,
    private readonly productService: ProductService,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;

      this.getProductGroups();
    });
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService.getAll(0, 10000).pipe(
      tap((res) => {
        this.productGroups = res.productGroups;

        if (res.totalCount > 0 && this.selectedProductGroupId === 0) {
          this.selectedProductGroupId = res.productGroups[0].id;
        }

        if (this.selectedProductGroupId > 0) {
          this.selectedProductGroupName = res.productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          ).name;

          this.productGroupService
            .get(this.selectedProductGroupId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((productGroupResult) => {
              this.selectedProductGroup = productGroupResult;
              this.selectedProductGroup.productGroupProperties.sort((a, b) => a.order - b.order);

              this.getPage(this.pageNumber);
            });
        } else {
          this.errorType = 'NoProductGroup';
        }
      }),
      map((res) => res.productGroups),
      share()
    );
  }

  public changePage(page: number) {
    this.router.navigate(
      ['/products'],
      page > 1
        ? { queryParams: { groupId: this.selectedProductGroupId, page } }
        : { queryParams: { groupId: this.selectedProductGroupId } }
    );
  }

  public getPage(page: number) {
    this.asyncProducts = this.productService
      .getAll(this.selectedProductGroupId, (page - 1) * this.perPage, this.perPage)
      .pipe(
        tap((res) => {
          this.totalCount = res.totalCount;
          this.pageNumber = page;
        }),
        map((res) => {
          res.products.forEach((p) => {
            p.propertyValues.sort((a, b) => a.propertyOrder - b.propertyOrder);
          });

          return res.products;
        })
      );
  }

  public onChange() {
    this.router.navigate(['/products'], { queryParams: { groupId: this.selectedProductGroupId } });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
