import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import * as Sentry from '@sentry/browser';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private readonly router: Router) { }

  canActivate() {
    const username = localStorage.getItem('currentUser');
    if (username) {
      Sentry.setUser({
        email: username
      });

      return true;
    }

    Sentry.setUser({});

    this.router.navigate(['/login']);
    return false;
  }
}
