import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SupplierPurchaseItemsSummaryResult } from '../../suppliers/shared/supplier-purchase-items-summary-result.model';
import { SupplierPurchaseItemsSummary } from '../../suppliers/shared/supplier-purchase-items-summary.model';

@Injectable()
export class PurchaseReportService {
  private readonly endpoint = `${environment.baseApiUrl}reports`;

  constructor(private readonly http: HttpClient) {}

  public getPurchaseItemsSummary(
    id: number,
    offset = 0,
    limit = 10,
    purchaseBeginDate = '',
    purchaseEndDate = '',
    reportProductIds: number[] = []
  ): Observable<SupplierPurchaseItemsSummaryResult> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('purchaseBeginDate', purchaseBeginDate.toString())
      .set('purchaseEndDate', purchaseEndDate.toString())
      .set('reportProductIds', reportProductIds.join(';').toString());

    return this.http
      .get<SupplierPurchaseItemsSummary[]>(`${this.endpoint}/${id}/purchase-report`, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new SupplierPurchaseItemsSummaryResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            res.body
          );
        })
      );
  }
}
