import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppComponent } from '../core/app.component';
import { AlertService } from '../core/alert.service';
import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, OnInit {
  pageName = 'Giriş';
  model: any = {};
  loading = false;
  error = '';

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public readonly alertService: AlertService,
    private readonly appComponent: AppComponent,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
    this.authService.logout();

    Sentry.setUser({});
  }

  login() {
    this.loading = false;

    this.authService.login(this.model.username, this.model.password).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(result => {
      if (result === true) {
        window.location.href = '/';
      } else {
        this.error = 'Giriş yapılamadı.';
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
