<section class="content-header">
  <h1>
    Profil
    <small>{{ profile?.firstName }} {{ profile?.lastName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">Profil</li>
  </ol>
</section>

<section class="content">
  <div class="box box-primary">
    <dipendo-form
      [boxTitle]="'Profil'"
      [inputs]="inputs"
      (submit)="onSubmit($event)"
    ></dipendo-form>
  </div>
</section>
