<section class="content-header">
  <h1>
    {{ pageName }}
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-dashboard"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <div class="box box-primary"></div>
  <div class="box-body">
    <form #productGroupForm="ngForm" autocomplete="off" novalidate>
      <div class="form-group col-md-12">
        <label for="purchaseBeginDate" class="col-md-1 control-label">Alış Tarihi</label>
        <div class="col-md-4">
          <input
            name="purchaseBeginDate"
            class="form-control pull-right datepicker"
            id="purchaseBeginDate"
            type="text"
            autocomplete="off"
          />
        </div>
        <label
          id="purchaseBeginDateLabel"
          for="purchaseBeginDate"
          class="col-md-1 control-label"
        ></label>
        <div class="col-md-4">
          <input
            name="purchaseEndDate"
            class="form-control pull-right datepicker"
            id="purchaseEndDate"
            type="text"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="" class="col-md-1 control-label">Ürün Grubu</label>
        <div class="col-md-4">
          <select
            multiple
            size="8"
            class="form-control select-multiple-with-button"
            [(ngModel)]="selectedProductGroups"
            (change)="getProducts()"
            name="productGroup"
          >
            <option
              *ngFor="let productGroup of asyncProductGroups | async"
              value="{{ productGroup.id }}"
              >{{ productGroup.name }}</option
            >
          </select>
        </div>
        <label id="productLabel" class="col-md-1 control-label">Ürün</label>
        <div class="col-md-4">
          <select
            multiple
            size="8"
            class="form-control select-multiple-with-button"
            [(ngModel)]="selectedProductValues"
            name="product"
          >
            <option *ngFor="let product of asyncProducts | async" value="{{ product.id }}">{{
              product.name
            }}</option>
          </select>
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="" class="col-md-1 control-label">Tedarikçi</label>
        <div class="col-md-9">
          <select
            class="form-control input-sm col-md-5"
            [(ngModel)]="selectedSupplierId"
            name="supplier"
          >
            <option value="0">Tümü</option>
            <option *ngFor="let supplier of asyncSuppliers | async" value="{{ supplier.id }}">{{
              supplier.title
            }}</option>
          </select>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button type="button" class="btn btn-sm btn-success" (click)="getPurchaseItems(pageNumber)">
          Listele
        </button>
      </div>
    </form>
  </div>
  <div class="box box-primary">
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th>Ürün Grubu</th>
            <th>Ürün Adı</th>
            <th>Tedarikçi</th>
            <th>Satın Alan Kişi</th>
            <th>Satın Alma Tarihi</th>
            <th>Satın Alma Fiyatı</th>
            <th>Miktar</th>
            <th>Tutar</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let purchaseItemSummary of asyncPurchaseItemsSummary
                | async
                | paginate
                  : {
                      itemsPerPage: perPage,
                      currentPage: pageNumber,
                      totalItems: totalCount
                    }
            "
          >
            <td>
              {{ purchaseItemSummary.purchaseItem.product.productGroup.name }}
            </td>
            <td>
              <a
                [routerLink]="[
                  '/products',
                  purchaseItemSummary.purchaseItem.product.productId,
                  'detail'
                ]"
                >{{ purchaseItemSummary.purchaseItem.product.name }}</a
              >
            </td>
            <td>
              {{ purchaseItemSummary.supplier.title }}
            </td>
            <td>
              {{ purchaseItemSummary.user.firstName }}
              {{ purchaseItemSummary.user.lastName }}
            </td>
            <td>
              {{
                purchaseItemSummary.purchaseItem.orderTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </td>
            <td>
              {{ purchaseItemSummary.purchaseItem.cost | number: '1.2-4' }}
              {{ purchaseItemSummary.purchaseItem.currencyCode }}/{{
                purchaseItemSummary.purchaseItem.product.productGroup.unit
                  | measurementUnitSymbol
                    : purchaseItemSummary.purchaseItem.product.productGroup.unitGroup
              }}
            </td>
            <td>
              {{ purchaseItemSummary.purchaseItem.purchaseCount }}
              {{
                purchaseItemSummary.purchaseItem.product.productGroup.unit
                  | measurementUnitSymbol
                    : purchaseItemSummary.purchaseItem.product.productGroup.unitGroup
              }}
            </td>
            <td>
              {{
                purchaseItemSummary.purchaseItem.cost *
                  purchaseItemSummary.purchaseItem.purchaseCount | number: '1.2-4'
              }}
              {{ purchaseItemSummary.purchaseItem.currencyCode }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="totalCount === 0">
      <p class="text-muted">
        &nbsp;&nbsp;Seçtiğiniz tarih aralığında herhangi bir raporlama görülmemektedir.
      </p>
      <br />
    </div>
    <div class="box-footer clearfix" *ngIf="totalCount > 0">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>
  <div *ngFor="let reportItem of asyncReportItems | async">
    <div class="bottom-calculator" *ngFor="let totalPurchase of reportItem.totalPurchases">
      {{ totalCount }} satın alınan ürün listelendi. Toplam Tutar: {{ selectedCosts.join(' + ') }}
    </div>
  </div>
</section>
