import { ProductGroupProperty } from './product-group-property.model';

export class ProductGroup {
  constructor(
    public productGroupId: number,
    public isActive: boolean,
    public name: string,
    public order: number,
    public unit: string,
    public unitGroup: string,
    public areProductsUnique: boolean,
    public productGroupProperties: ProductGroupProperty[]
  ) { }
}
