import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PurchaseItemResult } from './purchase-item-result.model';
import { PurchaseItem } from './purchase-item.model';

@Injectable()
export class PurchaseItemDetailService {
  private readonly endpoint = `${environment.baseApiUrl}purchase-items`;

  constructor(private readonly http: HttpClient) {}

  public getAll(offset = 0, limit = 10, productId = 0, status = 0): Observable<PurchaseItemResult> {
    const params = new HttpParams()
      .set('productId', productId.toString())
      .set('status', status.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.http
      .get<PurchaseItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new PurchaseItemResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            null,
            res.body
          );
        })
      );
  }

  public get(id: number): Observable<PurchaseItem> {
    return this.http.get<PurchaseItem>(`${this.endpoint}/${id}`);
  }

  public insert(purchase: PurchaseItem): Observable<PurchaseItem> {
    return this.http.post<PurchaseItem>(this.endpoint, purchase);
  }

  public update(id: number, purchase: PurchaseItem): Observable<PurchaseItem> {
    return this.http.put<PurchaseItem>(`${this.endpoint}/${id}`, purchase);
  }

  public delete(id: number): Observable<PurchaseItem> {
    return this.http.delete<PurchaseItem>(`${this.endpoint}/${id}`);
  }
}
