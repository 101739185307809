import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { Customer } from '../../customers/shared/customer.model';
import { CustomerService } from '../../customers/shared/customer.service';
import { SaleActiveConfirmComponent } from '../../sales/sale-active-confirm/sale-active-confirm.component';
import { SaleItem } from '../../sales/shared/sale-item.model';
import { Sale } from '../../sales/shared/sale.model';
import { SaleService } from '../../sales/shared/sale.service';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { DipendoFormComponent } from '../../shared/forms/dipendo-form/dipendo-form.component';
import { InputBase } from '../../shared/forms/input-base';
import { InputDatepicker } from '../../shared/forms/input-datepicker';
import { InputTextarea } from '../../shared/forms/input-textarea';
import { InputTextbox } from '../../shared/forms/input-textbox';
import { ReservedProductListComponent } from '../reserved-product-list/reserved-product-list.component';

@Component({
  providers: [DecimalPipe],
  selector: 'app-reserved-detail',
  templateUrl: './reserved-detail.component.html',
  styleUrls: ['./reserved-detail.component.scss']
})
export class ReservedDetailComponent implements OnDestroy, OnInit {
  public isSaving: boolean = false;
  public pageName = 'Rezerve Detayı';
  public selectedCustomerId = 0;
  public selectedCustomerTitle = '';
  public customer: Customer;
  public asyncCustomer: Observable<Customer>;
  public productGroupIds: number[] = [];
  public productGroups = new Map<number, ProductGroup>();
  public inputs: any[] = [];
  public sale: Sale = new Sale(0, 0, null, 0, null, null, null, 0, '', 0, null, '', [], '', '');
  public deletedSaleItems: SaleItem[] = [];
  public headerSelectionList: any[] = [];
  public selectedCount = 0;
  public totalCount = 0;
  public totalPriceList = new Map<number, string[]>();
  public grandTotalPriceList: string[] = [];
  public isCheckSelectedItem: boolean = false;
  private selectedId: number;

  @ViewChild(DipendoFormComponent, { static: true })
  private readonly formComponent: DipendoFormComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly customerService: CustomerService,
    private readonly decimalPipe: DecimalPipe,
    private readonly productGroupService: ProductGroupService,
    private readonly saleService: SaleService,
    private readonly modalService: NgbModal,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    this.inputs = this.getInputs();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.selectedCustomerId = parseInt(params['customerId'], 10) || 0;

      if (this.selectedCustomerId > 0) {
        this.getCustomer(this.selectedCustomerId);
        this.sale = new Sale(
          0,
          this.selectedCustomerId,
          null,
          0,
          null,
          null,
          null,
          0,
          '',
          0,
          null,
          '',
          [],
          '',
          ''
        );
      }
    });

    if (this.selectedCustomerId <= 0) {
      this.activatedRoute.url.subscribe((url) => {
        if (url[url.length - 1].path === 'detail') {
          this.activatedRoute.params.subscribe((params) => {
            this.selectedId = parseInt(params.id, 10) || -1;

            if (this.selectedId > 0) {
              this.getSale(this.selectedId);
            }
          });
        }
      });
    }
  }

  public openProductsDialog(): void {
    const productsModal = this.modalService.open(ReservedProductListComponent, {
      windowClass: 'large-modal'
    });

    productsModal.result.then(
      (res) => {
        this.sale.saleItems = this.sale.saleItems.concat(res);
        this.sale.saleItems.filter((x) => !x.count).forEach((x) => (x.count = 1));
        this.getProductGroups();
      },
      (err) => {}
    );
  }

  public deleteSale(): void {
    const confirmModal = this.modalService.open(SaleActiveConfirmComponent);
    confirmModal.componentInstance.saleId = this.sale.saleId;
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message = 'Rezerve Sepetiniz silinecektir. Onaylıyor musunuz?';

    confirmModal.result.then(
      (modalResult) => {
        this.saleService
          .delete(this.sale.saleId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.alertService.setNextAlert(
              AlertType.success,
              'Seçtiğiniz rezerve sepeti başarıyla silindi.'
            );
            this.router.navigate(['/reserved']);
          });
      },
      (err) => {}
    );
  }

  public onChangeHeaderCheckbox(index: number) {
    this.sale.saleItems.forEach((item) => {
      if (item.purchaseItem.product.productGroupId === this.headerSelectionList[index].groupId) {
        item.selected = this.headerSelectionList[index].selected;
      }
    });

    this.selectedCount = this.sale.saleItems.filter((x) => x.selected).length;
  }

  public onChangeItemCheckbox(item: SaleItem, index: number): void {
    let isAllSelectedForGroup = true;
    this.isCheckSelectedItem = true;

    for (const saleItem of this.sale.saleItems) {
      if (
        saleItem.purchaseItem.product.productGroupId === item.purchaseItem.product.productGroupId &&
        !saleItem.selected
      ) {
        isAllSelectedForGroup = false;
      }
    }

    this.headerSelectionList[index].selected = isAllSelectedForGroup;

    this.selectedCount = this.sale.saleItems.filter((x) => x.selected).length;
  }

  public deleteSelectedItems(): void {
    for (let i = this.sale.saleItems.length - 1; i >= 0; i--) {
      if (this.sale.saleItems[i].selected) {
        this.sale.saleItems.splice(this.sale.saleItems.indexOf(this.sale.saleItems[i]), 1);
      }
    }

    this.selectedCount = this.sale.saleItems.filter((x) => x.selected).length;

    this.getProductGroups();
  }

  public addSelectedItems(): void {
    for (let i = this.sale.saleItems.length - 1; i >= 0; i--) {
      if (!this.sale.saleItems[i].selected && this.isCheckSelectedItem) {
        this.sale.saleItems.splice(this.sale.saleItems.indexOf(this.sale.saleItems[i]), 1);
      }
    }

    this.selectedCount = this.sale.saleItems.filter((x) => x.selected).length;

    this.save(3);
  }

  public save(status: number): void {
    this.isSaving = true;
    if (status === 4) {
      const saleInsert: Sale = { ...this.sale };
      saleInsert.status = status;
      saleInsert.recordTime = moment()
        .utc()
        .toDate();
      saleInsert.saleTime = moment()
        .utc()
        .toDate();

      saleInsert.deliveryTime = moment(
        this.formComponent.form.value.deliveryTime,
        'DD.MM.YYYY'
      ).toDate();
      saleInsert.externalSaleCode = this.formComponent.form.value.externalSaleCode;
      saleInsert.explanation = this.formComponent.form.value.explanation
        ? this.formComponent.form.value.explanation
        : 'Rezerve işlemi yapıldı.';

      if (this.sale.saleItems && this.sale.saleItems.length > 0) {
        saleInsert.saleItems = [];

        for (const item of this.sale.saleItems) {
          for (let i = 0; i < item.count; ++i) {
            saleInsert.saleItems.push(item);
          }
        }
      }

      if (saleInsert.saleId > 0) {
        saleInsert.saleItems.forEach((p) => (p.saleItemId = 0));
        saleInsert.saleItems.forEach((p) => (p.deliveryTime = saleInsert.deliveryTime));

        this.saleService
          .update(saleInsert.saleId, saleInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              this.isSaving = false;
              this.alertService.setNextAlert(
                AlertType.success,
                'Satış rezerve işleminiz başarıyla gerçekleşmiştir.'
              );
              this.router.navigate(['/reserved']);
            },
            () => {
              this.isSaving = false;
            }
          );
      } else {
        this.saleService
          .insert(saleInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              this.isSaving = false;
              this.alertService.setNextAlert(
                AlertType.success,
                'Satış rezerve işleminiz başarıyla gerçekleşmiştir.'
              );
              this.router.navigate(['/reserved']);
            },
            () => {
              this.isSaving = false;
            }
          );
      }
    } else if (status === 3) {
      this.formComponent.validate();

      if (!this.formComponent.form.valid) {
        this.isSaving = false;

        return;
      }

      if (!this.checkFormInputs()) {
        this.alertService.setAlert(
          AlertType.danger,
          'Sepetteki ürünler için Satış Fiyatı ve Miktar alanları doldurulmalıdır.'
        );
        this.isSaving = false;

        return;
      }

      const saleInsert: Sale = { ...this.sale };
      saleInsert.status = status;
      saleInsert.recordTime = moment()
        .utc()
        .toDate();
      saleInsert.saleTime = moment()
        .utc()
        .toDate();
      saleInsert.deliveryTime = moment(
        this.formComponent.form.value.deliveryTime,
        'DD.MM.YYYY'
      ).toDate();
      saleInsert.externalSaleCode = this.formComponent.form.value.externalSaleCode;

      if (this.formComponent.form.value.explanation === 'Rezerve işlemi yapıldı.') {
        saleInsert.explanation = 'Satış işlemi yapıldı.';
      } else {
        saleInsert.explanation = this.formComponent.form.value.explanation
          ? this.formComponent.form.value.explanation
          : 'Satış işlemi yapıldı.';
      }

      if (this.sale.saleItems && this.sale.saleItems.length > 0) {
        saleInsert.saleItems = [];

        for (const item of this.sale.saleItems) {
          for (let i = 0; i < item.count; ++i) {
            saleInsert.saleItems.push(item);
          }
        }
      }

      if (saleInsert.saleId > 0) {
        saleInsert.saleItems.forEach((p) => (p.saleItemId = 0));
        saleInsert.saleItems.forEach((p) => (p.deliveryTime = saleInsert.deliveryTime));

        this.saleService
          .update(saleInsert.saleId, saleInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.isSaving = false;
              this.alertService.setNextAlert(
                AlertType.success,
                'Rezerve sepetiniz başarıyla satış için sisteme girildi.'
              );
              this.router.navigate(['/reserved']);
            },
            (err) => {
              this.isSaving = false;
              this.sale.saleItems = [];
              this.sale.saleItems.push(...this.deletedSaleItems);
            }
          );
      } else {
        this.saleService
          .insert(saleInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.isSaving = false;
              this.alertService.setNextAlert(
                AlertType.success,
                'Rezerve sepetiniz başarıyla satış için sisteme girildi.'
              );
              this.router.navigate(['/reserved']);
            },
            (err) => {
              this.isSaving = false;
              this.sale.saleItems = [];
              this.sale.saleItems.push(...this.deletedSaleItems);
            }
          );
      }
    }
  }

  public checkFormInputs(): boolean {
    for (const item of this.sale.saleItems) {
      if (item.count <= 0 || item.price <= 0 || item.saleCount <= 0) {
        return false;
      }
    }

    return true;
  }

  public onInputBlur(item: SaleItem): void {
    item.totalPrice = item.count * item.saleCount * item.price;

    this.calculateTotalPrice(this.sale.saleItems);

    this.alertService.clearAlert();
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getCustomer(id: number) {
    this.customerService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.customer = res;
        this.selectedCustomerTitle = res.title;
      });
  }

  private getSale(id: number) {
    this.saleService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.sale = res;
        this.fillForm();
        this.getCustomer(res.customerId);
        this.getProductGroups();
        this.deletedSaleItems.push(...res.saleItems);
      });
  }

  private fillForm() {
    if (this.formComponent.form) {
      this.formComponent.setValues(this.sale);
    }
  }

  private getProductGroup(id: number) {
    this.productGroupService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        if (!this.productGroups.has(res.productGroupId)) {
          this.productGroups.set(res.productGroupId, res);
        }
      });
  }

  private getProductGroups() {
    if (this.sale && this.sale.saleItems) {
      this.totalCount = this.sale.saleItems.length;
    }

    this.productGroupIds = [];
    this.headerSelectionList = [];

    if (this.sale.saleItems && this.sale.saleItems.length > 0) {
      for (const item of this.sale.saleItems) {
        if (this.productGroupIds.indexOf(item.purchaseItem.product.productGroupId) < 0) {
          this.productGroupIds.push(item.purchaseItem.product.productGroupId);
          this.headerSelectionList.push({
            groupId: item.purchaseItem.product.productGroupId,
            selected: false
          });
        }

        if (!item.count) {
          item.count = 1;
        }

        this.onInputBlur(item);
      }

      for (const id of this.productGroupIds) {
        if (!this.productGroups.has(id)) {
          this.getProductGroup(id);
        }
      }
    }
  }

  private calculateTotalPrice(itemList: SaleItem[]): void {
    const priceList = new Map<number, Map<string, number>>();

    for (const item of this.sale.saleItems) {
      if (item.totalPrice > 0) {
        if (!priceList.get(item.purchaseItem.product.productGroupId)) {
          priceList.set(item.purchaseItem.product.productGroupId, new Map<string, number>());
        }

        if (priceList.get(item.purchaseItem.product.productGroupId).get(item.currencyCode)) {
          const totalAmount =
            priceList.get(item.purchaseItem.product.productGroupId).get(item.currencyCode) +
            item.totalPrice;
          priceList
            .get(item.purchaseItem.product.productGroupId)
            .set(item.currencyCode, totalAmount);
        } else {
          priceList
            .get(item.purchaseItem.product.productGroupId)
            .set(item.currencyCode, item.totalPrice);
        }
      }
    }

    this.totalPriceList.clear();
    this.grandTotalPriceList = [];
    const grandPriceList = new Map<string, number>();

    priceList.forEach((value: Map<string, number>, key: number) => {
      if (!this.totalPriceList.get(key)) {
        this.totalPriceList.set(key, []);
      }

      value.forEach((totalPrice: number, currencyCode: string) => {
        this.totalPriceList
          .get(key)
          .push(`${this.decimalPipe.transform(totalPrice, '1.2-4')} ${currencyCode}`);

        if (!grandPriceList.get(currencyCode)) {
          grandPriceList.set(currencyCode, 0);
        }

        grandPriceList.set(currencyCode, grandPriceList.get(currencyCode) + totalPrice);
      });
    });

    grandPriceList.forEach((totalPrice: number, currencyCode: string) => {
      this.grandTotalPriceList.push(
        `${this.decimalPipe.transform(totalPrice, '1.2-4')} ${currencyCode}`
      );
    });
  }

  private getInputs(): InputBase<any>[] {
    const inputs: InputBase<any>[] = [
      new InputDatepicker({
        key: 'deliveryTime',
        label: 'Gönderim Tarihi',
        type: 'text',
        value: '',
        required: true,
        order: 1,
        startDate: '-0d'
      })
    ];

    if (this.sale) {
      inputs.push(
        new InputTextbox({
          key: 'externalSaleCode',
          label: 'Müşterinin Sipariş Kodu',
          value: '',
          required: false,
          order: 2
        })
      );
    }

    if (this.sale) {
      inputs.push(
        new InputTextarea({
          key: 'explanation',
          label: 'Açıklama',
          rowCount: 5,
          value: '',
          required: false,
          order: 3
        })
      );
    }

    return inputs.sort((a, b) => a.order - b.order);
  }
}
