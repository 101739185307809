<section class="content-header">
  <h1>
    {{pageName}}
  </h1>
  <ol class="breadcrumb">
    <li><a href="#"><i class="fa fa-dashboard"></i> Ana Sayfa</a></li>
    <li class="active">{{pageName}}</li>
  </ol>
</section>
<section class="content">
  <div class="error-page">
    <h2 class="headline text-yellow"> 404</h2>
    <div class="error-content">
      <h3><i class="fa fa-warning text-yellow"></i> Bir şeyler yanlış gibi.</h3>
      <p>
        Söylemekten utanıyoruz, fakat erişmeye çalıştığınız sayfa bulunamadı.
        <a href="/">Ana sayfaya</a> giderek baştan başlamak iyi bir yöntem gibi görünüyor.
      </p>
    </div>
  </div>
</section>
