<section class="content">
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">Stok Listesi</h3>
      <div class="box-tools">
        <form class="form-inline" #productGroupForm="ngForm" autocomplete="off" novalidate>
          <div class="form-group">
            <select
              class="form-control input-sm"
              [(ngModel)]="selectedProductGroupId"
              (change)="onChange()"
              name="product-group"
            >
              <option
                *ngFor="let productGroup of asyncProductGroups | async"
                value="{{ productGroup.id }}"
                >{{ productGroup.name }}</option
              >
            </select>
          </div>
          <input
            type="search"
            [(ngModel)]="searchValue"
            name="searchValue"
            class="form-control input-sm"
            placeholder="Stokta ara"
            (keyup)="onKeyup($event)"
          />
        </form>
      </div>
    </div>

    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th style="width: 10px;">
              <input
                type="checkbox"
                [(ngModel)]="headerSelection"
                autocomplete="off"
                (change)="onChangeHeaderCheckbox()"
              />
            </th>
            <th *ngIf="selectedProductGroup.areProductsUnique">Nesne Kimliği</th>
            <th>Ürün Adı</th>
            <th>Miktar</th>
            <th>Durum</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let purchaseItem of asyncPurchaseItems
                | async
                | paginate
                  : { itemsPerPage: perPage, currentPage: pageNumber, totalItems: totalCount }
            "
          >
            <th style="width: 10px;">
              <input
                type="checkbox"
                autocomplete="off"
                [(ngModel)]="purchaseItem.selected"
                (change)="onChangeItemCheckbox()"
              />
            </th>
            <td *ngIf="selectedProductGroup.areProductsUnique">
              {{ purchaseItem.purchaseItemId }}
            </td>
            <td>{{ purchaseItem.product.name }}</td>
            <td>
              {{ purchaseItem.reservableCount }}
              {{
                selectedProductGroup.unit | measurementUnitSymbol: selectedProductGroup.unitGroup
              }}
            </td>
            <td>
              {{ purchaseStatus[purchaseItem.status] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">{{ selectedProductGroupName }} grubuna henüz hiç ürün eklenmemiş.</p>
    </div>
    <div class="box-footer clearfix">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary pull-left"
      (click)="add()"
      *ngIf="selectedItemCount > 0"
    >
      Ekle
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.dismiss()">İptal</button>
  </div>
</section>
