import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { StockListItemProperty } from './stock-list-item-property.model';
import { StockListItem } from './stock-list-item.model';
import { StockListResult } from './stock-list-result.model';

@Injectable()
export class StockService {
  private readonly endpoint = `${environment.baseApiUrl}stocks`;

  constructor(private readonly http: HttpClient) {}

  public getAll(groupId = 0, offset = 0, limit = 10): Observable<StockListResult> {
    const params = new HttpParams()
      .set('groupId', groupId.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.http
      .get<StockListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          res.body.forEach(
            (s) => (s.propertiesJson = JSON.stringify(s.properties).replace(/^\\S /g, ''))
          );

          return new StockListResult(parseInt(res.headers.get('X-Total-Count'), 10), res.body);
        })
      );
  }

  public getDetail(
    groupId = 0,
    properties: StockListItemProperty[],
    offset = 0,
    limit = 10
  ): Observable<StockListResult> {
    let params = new HttpParams()
      .set('groupId', groupId.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    properties.forEach((p) => {
      params = params.append('propertyIds', p.id.toString());
      params = params.append('propertyValues', p.value);
    });

    return this.http
      .get<StockListItem[]>(`${this.endpoint}/detail`, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new StockListResult(parseInt(res.headers.get('X-Total-Count'), 10), res.body);
        })
      );
  }
}
