import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

import { InputBase } from '../input-base';

declare var $: any;

export function maxDateValidator(maxDates: string[], inputs: Array<InputBase<any>>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    for (const inputName of maxDates) {
      if (inputName) {
        const date = $('#' + inputName).datepicker('getDate');
        let value = control.value;

        if (typeof value === 'string') {
          if (value.length < 10) {
            break;
          }

          value = moment(value, 'DD.MM.YYYY').toDate();
        }

        if (control.value && date && date < value) {
          return {
            maxDate: {
              value: control.value,
              label: inputs.find((x) => x.key === inputName).label
            }
          };
        }
      }
    }
  };
}
