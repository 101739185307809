<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">
    <!-- sidebar menu: : style can be found in sidebar.less -->
    <ul class="sidebar-menu tree" data-widget="tree">
      <li class="header">MENÜ</li>
      <li
        *appIfAuthorizedTo="'Stock'"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a routerLink="/stock"><i class="fa fa-database"></i> <span>Stok</span></a>
      </li>
      <li
        *appIfAuthorizedTo="'Purchase'"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="treeview"
      >
        <a routerLink="/purchases">
          <i class="fa fa-balance-scale"></i>
          <span>Satın Alma</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/purchase/ordered"><i class="fa fa-circle-o"></i> Siparişler</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/purchase/shipped"><i class="fa fa-circle-o"></i> Transfer Edilenler</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/purchase/waiting-for-storage"
              ><i class="fa fa-circle-o"></i> Depo Giriş Bekleyenler</a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/purchase/in-warehouse"><i class="fa fa-circle-o"></i> Depodakiler</a>
          </li>
        </ul>
      </li>
      <li
        *appIfAuthorizedTo="'Stock'"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="treeview"
      >
        <a href="#">
          <i class="fa fa-qrcode"></i>
          <span>Depo</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/warehouse/waiting-for-storage"
              ><i class="fa fa-circle-o"></i> Giriş Bekleyenler</a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/warehouse/in-warehouse"><i class="fa fa-circle-o"></i> Depodakiler</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/warehouse/waiting-for-shipment"
              ><i class="fa fa-circle-o"></i> Çıkış Bekleyenler</a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/warehouse/ready-for-dispatch"
              ><i class="fa fa-circle-o"></i> Sevke Hazır</a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/warehouse/shipped-from-warehouse"
              ><i class="fa fa-circle-o"></i> Gönderilenler</a
            >
          </li>
        </ul>
      </li>
      <li
        *appIfAuthorizedTo="'Sale'"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="treeview"
      >
        <a routerLink="/sales">
          <i class="fa fa-truck"></i>
          <span>Satış</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/sale/waiting-for-shipment"><i class="fa fa-circle-o"></i> Satılanlar</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/sale/ready-for-dispatch"><i class="fa fa-circle-o"></i> Sevke Hazır</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/sale/shipped-from-warehouse"
              ><i class="fa fa-circle-o"></i> Sevk Edilenler</a
            >
          </li>
        </ul>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/reserved"><i class="fa fa-truck"></i><span>Rezerve</span></a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/products"><i class="fa fa-cubes"></i> <span>Ürünler</span></a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/customers"><i class="fa fa-globe"></i> <span>Müşteriler</span></a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/suppliers"><i class="fa fa-cart-plus"></i> <span>Tedarikçiler</span></a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/users"><i class="fa fa-users"></i> <span>Kullanıcılar</span></a>
      </li>
      <li
        *appIfAuthorizedTo="'ViewReports'"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="treeview"
      >
        <a routerLink="/reports">
          <i class="fa fa-bar-chart"></i>
          <span>Raporlar</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li
            *appIfAuthorizedTo="'ViewReports'"
            ng-class="{active: ('app.reports' | includedByState)}"
          >
            <a routerLink="/purchase-reports"
              ><i class="fa fa-bar-chart"></i> <span>Satın Alma Raporları</span></a
            >
          </li>
          <li
            *appIfAuthorizedTo="'ViewReports'"
            ng-class="{active: ('app.reports' | includedByState)}"
          >
            <a routerLink="/profitability-reports"
              ><i class="fa fa-bar-chart"></i> <span>Kârlılık Raporları</span></a
            >
          </li>
        </ul>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="treeview">
        <a href="#">
          <i class="fa fa-user"></i>
          <span>Bana Özel</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/me/profile"><i class="fa fa-circle-o"></i> Profil</a>
          </li>
        </ul>
      </li>
      <li
        *appIfAuthorizedTo="'EditProducts'"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="treeview"
      >
        <a href="#">
          <i class="fa fa-cogs"></i>
          <span>Ayarlar</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/settings/product-groups"
              ><i class="fa fa-circle-o"></i> Ürün Grupları</a
            >
          </li>
        </ul>
      </li>
      <li ng-class="{active: ('app.help' | includedByState)}" class="treeview">
        <a href="#">
          <i class="fa fa-question"></i>
          <span>Yardım</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li ui-sref-active="active">
            <a ui-sref="app.faq"><i class="fa fa-circle-o"></i> SSS</a>
          </li>
          <li ui-sref-active="active">
            <a ui-sref="app.user-guide"><i class="fa fa-circle-o"></i> Kılavuz</a>
          </li>
          <li ui-sref-active="active">
            <a ui-sref="app.promotional-video"><i class="fa fa-circle-o"></i> Tanıtım Videosu</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/about"><i class="fa fa-circle-o"></i> Hakkında</a>
          </li>
        </ul>
      </li>
    </ul>
  </section>
  <!-- /.sidebar -->
</aside>
