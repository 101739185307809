import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ChangePasswordComponent } from '../change-password/change-password.component';
import { AuthGuard } from '../core/auth/auth.guard';
import { StandardPageComponent } from '../core/standard-page/standard-page.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { LoginComponent } from '../login/login.component';
import { AboutComponent } from '../pages/about/about.component';
import { Error404Component } from '../pages/error404/error404.component';
import { ProductDetailComponent } from '../products/product-detail/product-detail.component';
import { ProductEditComponent } from '../products/product-edit/product-edit.component';
import { ProductListComponent } from '../products/product-list/product-list.component';
import { ProfileComponent } from '../profile/profile.component';
import { OrderedComponent } from '../purchases/ordered/ordered.component';
import { PurchaseDetailComponent } from '../purchases/purchase-detail/purchase-detail.component';
import { PurchaseEditComponent } from '../purchases/purchase-edit/purchase-edit.component';
import { PurchaseItemDetailComponent } from '../purchases/purchase-item-detail/purchase-item-detail.component';
import { PurchaseItemFileDetailComponent } from '../purchases/purchase-item-file-detail/purchase-item-file-detail.component';
import { PurchaseListComponent } from '../purchases/purchase-list/purchase-list.component';
import { PurchaseProductListComponent } from '../purchases/purchase-product-list/purchase-product-list.component';
import { ShippedComponent } from '../purchases/shipped/shipped.component';
import { ProfitabilityReportComponent } from '../reports/profitability-report/profitability-report.component';
import { PurchaseReportComponent } from '../reports/purchases-report/purchase-report.component';
import { ReportComponent } from '../reports/report/report.component';
import { ReservedDetailComponent } from '../reserved/reserved-detail/reserved-detail.component';
import { ReservedEditComponent } from '../reserved/reserved-edit/reserved-edit.component';
import { ReservedListComponent } from '../reserved/reserved-list/reserved-list.component';
import { ReservedProductListComponent } from '../reserved/reserved-product-list/reserved-product-list.component';
import { SaleDetailComponent } from '../sales/sale-detail/sale-detail.component';
import { SaleEditComponent } from '../sales/sale-edit/sale-edit.component';
import { SaleListComponent } from '../sales/sale-list/sale-list.component';
import { SaleProductListComponent } from '../sales/sale-product-list/sale-product-list.component';
import { ProductGroupEditComponent } from '../settings/product-groups/product-group-edit/product-group-edit.component';
import { ProductGroupListComponent } from '../settings/product-groups/product-group-list/product-group-list.component';
import { StockDetailComponent } from '../stock/stock-detail/stock-detail.component';
import { StockComponent } from '../stock/stock.component';
import { InWarehouseComponent } from '../warehouse/in-warehouse/in-warehouse.component';
import { ReadyForDispatchComponent } from '../warehouse/ready-for-dispatch/ready-for-dispatch.component';
import { ShippedFromWarehouseComponent } from '../warehouse/shipped-from-warehouse/shipped-from-warehouse.component';
import { WaitingForShipmentComponent } from '../warehouse/waiting-for-shipment/waiting-for-shipment.component';
import { WaitingForStorageComponent } from '../warehouse/waiting-for-storage/waiting-for-storage.component';
import { ConfirmDeactivateGuard } from './confirm-deactivate-guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          children: [
            { path: '', redirectTo: 'stock', pathMatch: 'full' },
            { path: 'stock', component: StockComponent },
            { path: 'stock/detail', component: StockDetailComponent },
            { path: 'reports', component: ReportComponent },
            { path: 'profitability-reports', component: ProfitabilityReportComponent },
            { path: 'purchase-reports', component: PurchaseReportComponent },
            { path: 'about', component: AboutComponent },
            {
              path: 'customers',
              loadChildren: () =>
                import('app/customers/customers.module').then((m) => m.CustomersModule)
            },
            {
              path: 'me',
              children: [{ path: 'profile', component: ProfileComponent }]
            },
            {
              path: 'settings',
              children: [
                { path: 'product-groups', component: ProductGroupListComponent },
                { path: 'product-groups/add', component: ProductGroupEditComponent },
                { path: 'product-groups/:id/edit', component: ProductGroupEditComponent }
              ]
            },
            { path: 'products', component: ProductListComponent },
            {
              path: 'products/add',
              component: ProductEditComponent,
              canDeactivate: [ConfirmDeactivateGuard]
            },
            {
              path: 'products/:id/edit',
              component: ProductEditComponent,
              canDeactivate: [ConfirmDeactivateGuard]
            },
            { path: 'products/:id/detail', component: ProductDetailComponent },
            { path: 'purchase-items/:id/detail', component: PurchaseItemDetailComponent },
            { path: 'purchase-item-files/:id/detail', component: PurchaseItemFileDetailComponent },
            {
              path: 'suppliers',
              loadChildren: () =>
                import('app/suppliers/suppliers.module').then((m) => m.SuppliersModule)
            },
            { path: 'purchases', component: PurchaseListComponent },
            { path: 'purchases/add', component: PurchaseEditComponent },
            { path: 'purchases/:id/edit', component: PurchaseEditComponent },
            { path: 'purchases/:id/detail', component: PurchaseDetailComponent },
            { path: 'purchase/products', component: PurchaseProductListComponent },
            { path: 'purchase/ordered', component: OrderedComponent },
            { path: 'purchase/shipped', component: ShippedComponent },
            { path: 'purchase/waiting-for-storage', component: WaitingForStorageComponent },
            { path: 'purchase/in-warehouse', component: InWarehouseComponent },
            { path: 'sales', component: SaleListComponent },
            { path: 'sales/add', component: SaleEditComponent },
            { path: 'sales/:id/edit', component: SaleEditComponent },
            { path: 'sales/:id/detail', component: SaleDetailComponent },
            { path: 'sales/products', component: SaleProductListComponent },
            { path: 'sale/waiting-for-shipment', component: WaitingForShipmentComponent },
            { path: 'sale/ready-for-dispatch', component: ReadyForDispatchComponent },
            { path: 'sale/shipped-from-warehouse', component: ShippedFromWarehouseComponent },
            { path: 'reserved', component: ReservedListComponent },
            { path: 'reserved/add', component: ReservedEditComponent },
            { path: 'reserved/:id/edit', component: ReservedEditComponent },
            { path: 'reserved/products', component: ReservedProductListComponent },
            { path: 'reserved/:id/detail', component: ReservedDetailComponent },
            {
              path: 'users',
              loadChildren: () => import('app/users/users.module').then((m) => m.UsersModule)
            },
            { path: 'warehouse/waiting-for-storage', component: WaitingForStorageComponent },
            { path: 'warehouse/in-warehouse', component: InWarehouseComponent },
            { path: 'warehouse/waiting-for-shipment', component: WaitingForShipmentComponent },
            { path: 'warehouse/ready-for-dispatch', component: ReadyForDispatchComponent },
            { path: 'warehouse/shipped-from-warehouse', component: ShippedFromWarehouseComponent }
          ],
          component: StandardPageComponent,
          data: [
            {
              bodyCssClass: 'skin-blue sidebar-mini'
            }
          ],
          canActivate: [AuthGuard]
        },
        { path: 'login', component: LoginComponent, data: [{ bodyCssClass: 'login-page' }] },
        {
          path: 'change-password',
          component: ChangePasswordComponent,
          data: [{ bodyCssClass: 'login-page' }]
        },
        {
          path: 'forgot-password',
          component: ForgotPasswordComponent,
          data: [{ bodyCssClass: 'login-page' }]
        },
        { path: '**', component: Error404Component, data: [{ bodyCssClass: 'login-page' }] }
      ],
      { relativeLinkResolution: 'legacy' }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
