import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppComponent } from '../../../core/app.component';
import { Globals } from '../../../globals';
import { ProductGroupListItem } from '../shared/product-group-list-item.model';
import { ProductGroupService } from '../shared/product-group.service';

@Component({
  selector: 'app-product-group-list',
  templateUrl: './product-group-list.component.html',
  styleUrls: ['./product-group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupListComponent implements AfterViewInit, OnInit {
  public pageName = 'Ürün Grupları';
  public productGroups: ProductGroupListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupListItem[]>;
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public searchValue = '';
  public searchQuery = new Subject<string>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly router: Router,
    private readonly productGroupService: ProductGroupService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;

      this.getPage(this.pageNumber);
    });
  }

  public changePage(page: number) {
    const $this = this;

    this.router
      .navigate(['/settings/product-groups'], page > 1 ? { queryParams: { page } } : {})
      .then((res) => {
        setTimeout(() => {
          $this.searchQuery.next($this.searchValue || '');
        }, 1);
      });
  }

  public getPage(page: number) {
    this.asyncProductGroups = this.productGroupService
      .search(this.searchQuery, (page - 1) * this.perPage, this.perPage)
      .pipe(
        tap((res) => {
          this.totalCount = res.totalCount;
          this.pageNumber = page;
        }),
        map((res) => res.productGroups)
      );
  }

  public ngAfterViewInit(): void {
    this.searchQuery.next(this.searchValue);
  }
}
