import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { Comment } from './comment.model';
import { PurchaseItem } from './purchase-item.model';
import { PurchaseListItem } from './purchase-list-item.model';
import { PurchaseListResult } from './purchase-list-result.model';
import { Purchase } from './purchase.model';

@Injectable()
export class PurchaseService {
  private readonly endpoint = `${environment.baseApiUrl}purchases`;

  constructor(private readonly http: HttpClient) {}

  public getAll(offset = 0, limit = 10): Observable<PurchaseListResult> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    return this.http
      .get<PurchaseListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new PurchaseListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            null,
            res.body
          );
        })
      );
  }

  public search(pagedSearchQueries: Observable<PagedSearchQuery>): Observable<PurchaseListResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) => this.searchItems(pagedSearchQuery))
    );
  }

  public getActivePurchase(): Observable<PurchaseListResult> {
    const params = new HttpParams()
      .set('status', '1')
      .set('byUser', 'true')
      .set('offset', '0')
      .set('limit', '10');

    return this.http
      .get<PurchaseListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new PurchaseListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            0,
            10,
            null,
            res.body
          );
        })
      );
  }

  public get(id: number): Observable<Purchase> {
    return this.http.get<Purchase>(`${this.endpoint}/${id}`);
  }

  public insert(purchase: Purchase): Observable<Purchase> {
    return this.http.post<Purchase>(this.endpoint, purchase);
  }

  public update(id: number, purchase: Purchase): Observable<Purchase> {
    return this.http.put<Purchase>(`${this.endpoint}/${id}`, purchase);
  }

  public delete(id: number): Observable<Purchase> {
    return this.http.delete<Purchase>(`${this.endpoint}/${id}`);
  }

  public addItemComment(purchase: PurchaseItem, comment: any): Observable<Comment> {
    return this.http.post<Comment>(
      `${this.endpoint}/${purchase.purchaseId}/purchase-items/${purchase.purchaseItemId}/comments`,
      comment
    );
  }

  public insertFile(purchase: PurchaseItem, formdata) {
    return this.http.post<any>(
      `${this.endpoint}/${purchase.purchaseId}/purchase-items/${purchase.purchaseItemId}/files`,
      formdata
    );
  }

  public deleteFile(purchase: PurchaseItem, id: number) {
    return this.http.delete<any>(
      `${this.endpoint}/${purchase.purchaseId}/purchase-items/${purchase.purchaseItemId}/files/${id}`
    );
  }

  public getFile(purchase: PurchaseItem, id: number) {
    return this.http.get<any>(
      `${this.endpoint}/${purchase.purchaseId}/purchase-items/${purchase.purchaseItemId}/files/${id}`,
      { responseType: 'blob' as 'json' }
    );
  }

  private searchItems(pagedSearchQuery: PagedSearchQuery): Observable<PurchaseListResult> {
    const params = new HttpParams()
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString())
      .set('startTime', pagedSearchQuery.startTime ? pagedSearchQuery.startTime.toISOString() : '')
      .set('endTime', pagedSearchQuery.endTime ? pagedSearchQuery.endTime.toISOString() : '');

    return this.http
      .get<PurchaseListItem[]>(
        `${this.endpoint}?search=${pagedSearchQuery.query.replace(/\+/g, '%2B')}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new PurchaseListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            pagedSearchQuery.query,
            res.body
          );
        })
      );
  }
}
