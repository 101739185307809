import { InputBase } from './input-base';

export class InputCheckbox extends InputBase<string> {
  controlType = 'checkbox';
  checked: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.checked = options['checked'] || false;
  }
}
