import { Component, OnInit } from '@angular/core';

import { AppComponent } from '../../core/app.component';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  pageName = 'Sayfa Bulunamadı';

  constructor(private readonly appComponent: AppComponent) { }

  ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
  }

}
