import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Profile } from './profile.model';
import { ProfileBinding } from './profile-binding.model';

@Injectable()
export class ProfileService {
  private readonly endpoint = `${environment.baseApiUrl}profile`;
  
  constructor(
    private readonly http: HttpClient
  ) { }

  get(): Observable<Profile> {
    return this.http
      .get<Profile>(this.endpoint);
  }

  update(profile: ProfileBinding): Observable<number> {
    return this.http
      .put(this.endpoint, profile, {
        observe: 'response'
      }).pipe(
      map(success => success.status));
  }
}
