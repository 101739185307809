<section class="content-header">
  <h1>
    {{ pageName }}
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-dashboard"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <div class="box box-primary">
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">Rapor bulunmamaktadır.</p>
    </div>
  </div>
  <div class="box-body">
    <form #productGroupForm="ngForm" autocomplete="off" novalidate>
      <div class="form-group col-md-12">
        <label for="purchaseBeginDate" class="col-md-1 control-label">Alış Tarihi</label>
        <div class="col-md-2">
          <input
            name="purchaseBeginDate"
            class="form-control pull-right datepicker"
            id="purchaseBeginDate"
            type="text"
            autocomplete="off"
          />
        </div>
        <div class="col-md-2">
          <input
            name="purchaseEndDate"
            class="form-control pull-right datepicker"
            id="purchaseEndDate"
            type="text"
            autocomplete="off"
          />
        </div>
        <label for="saleBeginDate" class="col-md-1 control-label">Satış Tarihi</label>
        <div class="col-md-2">
          <input
            name="saleBeginDate"
            class="form-control pull-right datepicker"
            id="saleBeginDate"
            type="text"
            autocomplete="off"
          />
        </div>
        <div class="col-md-2">
          <input
            name="saleEndDate"
            class="form-control pull-right datepicker"
            id="saleEndDate"
            type="text"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="" class="col-md-1 control-label">Ürün Grubu</label>
        <div class="col-md-4">
          <select
            multiple
            size="8"
            class="form-control select-multiple-with-button"
            [(ngModel)]="selectedProductGroups"
            (change)="getProducts()"
            name="productGroup"
          >
            <option
              *ngFor="let productGroup of asyncProductGroups | async"
              value="{{ productGroup.id }}"
              >{{ productGroup.name }}</option
            >
          </select>
        </div>
        <label for="" class="col-md-1 control-label">Ürün</label>
        <div class="col-md-4">
          <select
            multiple
            size="8"
            class="form-control select-multiple-with-button"
            [(ngModel)]="selectedProductValues"
            name="product"
          >
            <option *ngFor="let product of asyncProducts | async" value="{{ product.id }}">{{
              product.name
            }}</option>
          </select>
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="" class="col-md-1 control-label">Tedarikçi</label>
        <div class="col-md-9">
          <select
            class="form-control input-sm col-md-5"
            [(ngModel)]="selectedSupplierId"
            name="supplier"
          >
            <option value="0">Tümü</option>
            <option *ngFor="let supplier of asyncSuppliers | async" value="{{ supplier.id }}">{{
              supplier.title
            }}</option>
          </select>
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="" class="col-md-1 control-label">Müşteri</label>
        <div class="col-md-9">
          <select class="form-control input-sm" [(ngModel)]="selectedCustomerId" name="customer">
            <option value="0">Tümü</option>
            <option *ngFor="let customer of asyncCustomers | async" value="{{ customer.id }}">{{
              customer.title
            }}</option>
          </select>
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="" class="col-md-1 control-label">Satışçı</label>
        <div class="col-md-9">
          <select class="form-control input-sm" [(ngModel)]="selectedUserId" name="user">
            <option value="0">Tümü</option>
            <option *ngFor="let user of asyncUsers | async" value="{{ user.id }}"
              >{{ user.firstName }} {{ user.lastName }}</option
            >
          </select>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button type="button" class="btn btn-sm btn-success" (click)="getReport()">Listele</button>
      </div>
    </form>
  </div>
  <div class="box box-primary">
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Toplam Satış</th>
            <th>Toplam Alış</th>
            <th>Toplam Kar</th>
            <th>Kar Oranı</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let reportItem of asyncReportItems | async">
            <td>
              <span *ngFor="let totalSale of reportItem.totalSales">
                {{ totalSale.amount | number }} {{ totalSale.currency }}<br />
              </span>
            </td>

            <td>
              <span *ngFor="let totalPurchase of reportItem.totalPurchases">
                {{ totalPurchase.amount | number }} {{ totalPurchase.currency }}<br />
              </span>
            </td>

            <td>
              <span *ngFor="let totalProfit of reportItem.totalProfits">
                {{ totalProfit.amount | number }} {{ totalProfit.currency }}<br />
              </span>
            </td>

            <td>
              <span *ngFor="let totalProfitPercentage of reportItem.totalProfitPercentages">
                % {{ totalProfitPercentage.amount }}<br />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
