import { Component } from '@angular/core';

import { AlertService } from '../alert.service';

@Component({
  selector: 'app-standard-page',
  templateUrl: './standard-page.component.html',
  styleUrls: ['./standard-page.component.scss']
})
export class StandardPageComponent {

  constructor(public alertService: AlertService) { }

  closeAlert() {
    this.alertService.clearAlert();
  }

}
