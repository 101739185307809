import { Injectable, OnChanges, SimpleChanges, Type } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { ProductEditComponent } from '../products/product-edit/product-edit.component';
import { UploadFileConfirmComponent } from '../shared/upload-file/upload-file-confirm/upload-file-confirm';

export interface IDeactivateGuardBase {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<IDeactivateGuardBase> {
  constructor(private readonly modalService: NgbModal) {}

  public canDeactivate(target: IDeactivateGuardBase) {
    if (target.canDeactivate && target.canDeactivate()) {
      const confirmModal = this.modalService.open(UploadFileConfirmComponent);
      confirmModal.componentInstance.title = 'ONAY';
      confirmModal.componentInstance.message =
        'Yaptığınız değişiklikler kaydedilmedi. Sayfayı değiştirmek istediğinizden emin misiniz?';

      return confirmModal.result.then(
        (modalResult) => {
          return true;
        },
        (err) => {
          return false;
        }
      );
    }
    return true;
  }
}
