import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SaleItemListItem } from './sale-item-list-item.model';
import { SaleItemListResult } from './sale-item-list-result.model';

@Injectable()
export class SaleItemService {
  private readonly endpoint = `${environment.baseApiUrl}sale-items`;

  constructor(private readonly http: HttpClient) {}

  public getAll(
    groupId = 0,
    status = 0,
    saleId = 0,
    offset = 0,
    limit = 10,
    startTime: Date = null,
    endTime: Date = null,
    view: string = null,
    productId = 0
  ): Observable<SaleItemListResult> {
    const params = new HttpParams()
      .set('productId', productId.toString())
      .set('groupId', groupId.toString())
      .set('status', status.toString())
      .set('saleId', saleId.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('startTime', startTime ? startTime.toISOString() : '')
      .set('endTime', endTime ? endTime.toISOString() : '')
      .set('view', view);

    return this.http
      .get<SaleItemListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new SaleItemListResult(parseInt(res.headers.get('X-Total-Count'), 10), res.body);
        })
      );
  }

  public updateStatus(id: number, status: number): Observable<void> {
    return this.http.patch<void>(`${this.endpoint}/${id}`, {
      status
    });
  }
}
