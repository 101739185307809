<div class="login-box forgot-password-box">
  <div class="login-logo">
    <a routerLink="/"><b>Dipendo</b></a>
  </div>
  <div class="login-box-body">
    <h1>Şifremi Sıfırla</h1>
    <div class="alert alert-success" *ngIf="submitSuccess">
      Şifrenizi başarıyla belirlediniz. Giriş sayfasına geri dönerek yeni şifrenizle hemen giriş yapabilirsiniz.
    </div>
    <p class="login-box-msg" *ngIf="!submitSuccess">Lütfen yeni şifrenizi girin.</p>
    <form role="form" #changePasswordForm="ngForm" (ngSubmit)="changePassword()" autocomplete="off" novalidate *ngIf="!submitSuccess">
      <ng-container *ngIf="errors">
        <div class="form-group" *ngFor="let error of errors">
          <div class="alert alert-danger">
            {{error}}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="alertService.getAlert()">
        <div class="form-group">
          <div class="alert alert-danger">
            {{ alertService.getAlert().message }}
          </div>
        </div>
      </ng-container>
      <div class="form-group has-feedback">
        <input id="email" name="email" type="email" class="form-control" placeholder="E-posta" [(ngModel)]="model.email" disabled>
        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
      </div>
      <div class="form-group has-feedback">
        <input id="password" name="password" type="password" class="form-control" placeholder="Yeni Şifre" [(ngModel)]="model.newPassword">
        <span class="glyphicon glyphicon-lock form-control-feedback"></span>
      </div>
      <div class="form-group has-feedback">
        <input id="password-verification" name="password-verification" type="password" class="form-control" placeholder="Yeni Şifre (Tekrar)" [(ngModel)]="model.confirmPassword">
        <span class="glyphicon glyphicon-log-in form-control-feedback"></span>
      </div>
      <div class="form-group">
        <re-captcha site_key="6Ld7_wkUAAAAANiVL-Cq9Ip6mckpQZN42mLUSYzB" [(ngModel)]="model.captcha" name="captcha" language="tr" (captchaExpired)="resetCaptcha()"></re-captcha>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block btn-flat" id="login-form-submit-button" [disabled]="submitting">Gönder</button>
      </div>
    </form>

    <a routerLink="/login"><i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> Giriş Sayfasına Dön</a><br>
  </div>
  <!-- /.login-box-body -->
</div>
