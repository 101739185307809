<section class="content-header">
  <h1>
    {{ pageName }}
    <small>{{ selectedProductGroupName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-dashboard"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <!-- Default box -->
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">Stok Durumu</h3>

      <div class="box-tools">
        <form class="form-inline" #productGroupForm="ngForm" autocomplete="off" novalidate>
          <div class="form-group">
            <select
              class="form-control input-sm"
              [(ngModel)]="selectedProductGroupId"
              (change)="onChange()"
              name="product-group"
            >
              <option
                *ngFor="let productGroup of asyncProductGroups | async"
                value="{{ productGroup.id }}"
                >{{ productGroup.name }}</option
              >
            </select>
          </div>
        </form>
      </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th>
              <span *ngFor="let property of selectedStockGroupingProperties; let i = index"
                >{{ property.name }}
                <ng-container *ngIf="i < selectedStockGroupingProperties.length - 1">
                  &mdash;
                </ng-container>
              </span>
            </th>
            <th>
              Depodaki Miktar ({{
                selectedProductGroup.unit | measurementUnitSymbol: selectedProductGroup.unitGroup
              }})
            </th>
            <!-- <th>Ağırlık</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let stockItem of asyncStockItems
                | async
                | paginate
                  : { itemsPerPage: perPage, currentPage: pageNumber, totalItems: totalCount }
            "
          >
            <td>
              <a
                [routerLink]="['/stock/detail']"
                [queryParams]="{
                  groupId: selectedProductGroupId,
                  properties: stockItem.propertiesJson
                }"
              ></a
              >{{ stockItem.name }}
            </td>
            <td>{{ stockItem.count }}</td>
            <!-- <td>{{ stockItem.totalMass | number:'1.0-3' }} {{ stockItem.unitOfMass }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="errorType === 'NoProductGroup'">
      <p class="alert alert-info">
        Henüz hiç ürün grubu eklenmemiş. Yeni ürün grubu eklemek için
        <a [routerLink]="['/settings/product-groups']">tıklayın</a>.
      </p>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">Stokta ürün bulunmamaktadır.</p>
    </div>
    <div class="box-footer clearfix">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>
</section>
