import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { CustomerListItem } from './customer-list-item.model';
import { CustomerListResult } from './customer-list-result.model';
import { CustomerPersonalNoteBinding } from './customer-personal-note-binding.model';
import { CustomerSaleItemSummary } from './customer-sale-item-summary.model';
import { CustomerSaleItemsSummaryResult } from './customer-sale-items-summary-result.model';
import { CustomerSalesChartSummaryResult } from './customer-sales-chart-summary-result.model';
import { CustomerSalesChartSummary } from './customer-sales-chart-summary.model';
import { Customer } from './customer.model';

@Injectable()
export class CustomerService {
  private readonly endpoint = `${environment.baseApiUrl}customers`;

  constructor(private readonly http: HttpClient) {}

  public getAll(offset = 0, limit = 10): Observable<CustomerListResult> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    return this.http
      .get<CustomerListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new CustomerListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            null,
            res.body
          );
        })
      );
  }

  public get(id: number): Observable<Customer> {
    return this.http.get<Customer>(`${this.endpoint}/${id}`);
  }

  public getSalesChartSummary(
    id: number,
    startTime: Date = null
  ): Observable<CustomerSalesChartSummaryResult> {
    const params = new HttpParams().set('startTime', startTime ? startTime.toISOString() : '');

    return this.http
      .get<CustomerSalesChartSummary[]>(`${this.endpoint}/${id}/sales-chart-summary`, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new CustomerSalesChartSummaryResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            res.body
          );
        })
      );
  }

  public searchSaleItems(
    id: number,
    pagedSearchQueries: Observable<PagedSearchQuery>
  ): Observable<CustomerSaleItemsSummaryResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) => this.getSaleItemsSummary(id, pagedSearchQuery))
    );
  }

  public getSaleItemsSummary(
    id: number,
    pagedSearchQuery: PagedSearchQuery
  ): Observable<CustomerSaleItemsSummaryResult> {
    const params = new HttpParams()
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString())
      .set('startTime', pagedSearchQuery.startTime ? pagedSearchQuery.startTime.toISOString() : '')
      .set('endTime', pagedSearchQuery.endTime ? pagedSearchQuery.endTime.toISOString() : '');

    return this.http
      .get<CustomerSaleItemSummary[]>(
        `${this.endpoint}/${id}/sale-items-summary?search=${pagedSearchQuery.query.replace(
          /\+/g,
          '%2B'
        )}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new CustomerSaleItemsSummaryResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            res.body
          );
        })
      );
  }

  public insert(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(this.endpoint, customer);
  }

  public update(id: number, customer: Customer): Observable<Customer> {
    return this.http.put<Customer>(`${this.endpoint}/${id}`, customer);
  }

  public search(pagedSearchQueries: Observable<PagedSearchQuery>): Observable<CustomerListResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) => this.searchItems(pagedSearchQuery))
    );
  }

  public savePersonalNote(id: number, personalNote: CustomerPersonalNoteBinding): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/${id}/personal-note`, personalNote);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/${id}`);
  }

  private searchItems(pagedSearchQuery: PagedSearchQuery): Observable<CustomerListResult> {
    const params = new HttpParams()
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString());

    return this.http
      .get<CustomerListItem[]>(
        `${this.endpoint}?search=${pagedSearchQuery.query.replace(/\+/g, '%2B')}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new CustomerListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            pagedSearchQuery.query,
            res.body
          );
        })
      );
  }
}
