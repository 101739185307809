import { Injectable } from '@angular/core';

import { Alert } from './alert.model';
import { AlertType } from './alert-type.model';

@Injectable()
export class AlertService {
  private readonly queue: Alert[] = [];
  private currentAlert: Alert = null;

  constructor() { }

  public getAlert(): Alert {
    return this.currentAlert;
  }

  public shiftAlert(): void {
    this.currentAlert = this.queue.shift() || null;
  }

  public clearAlert(): void {
    this.currentAlert = null;
  }

  public setAlert(type: AlertType, message: string): void {
    this.currentAlert = {
      type,
      message
    };
  }

  public setNextAlert(type: AlertType, message: string): void {
    this.queue.push({
      type,
      message
    });
  }
}
