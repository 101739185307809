import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ignoreInvalidDate'
})
export class IgnoreInvalidDatePipe implements PipeTransform {

  transform(value: string): string {
    if (value.localeCompare('Invalid date', 'en', {sensitivity: 'base'}) == 0) {
      return null;
    } else {
      return value;
    }
  }

}
