import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { InputBase } from './input-base';
import { InputDatepicker } from './input-datepicker';
import { InputTextbox } from './input-textbox';
import { InputTwoSidedMultiSelect } from './input-two-sided-multi-select';
import { minDateValidator } from './validators/min-date.directive';
import { maxDateValidator } from './validators/max-date.directive';

@Injectable()
export class InputControlService {

  toFromGroup(inputs: InputBase<any>[]): FormGroup {
    const group: any = {};

    inputs.forEach(input => {
      const validators = [];

      if (input.required) {
        validators.push(Validators.required);
      }

      if (input instanceof InputTextbox && input.type === 'email') {
        validators.push(Validators.email);
      }

      if (input instanceof InputDatepicker && (input as InputDatepicker).minDates && (input as InputDatepicker).minDates.length > 0) {
        validators.push(minDateValidator(input.minDates, inputs));
      }

      if (input instanceof InputDatepicker && (input as InputDatepicker).maxDates && (input as InputDatepicker).maxDates.length > 0) {
        validators.push(maxDateValidator(input.maxDates, inputs));
      }

      if (input instanceof InputTwoSidedMultiSelect) {
        group[input.key] = new FormControl(input.value || [], validators);
        group[input.keyRight] = new FormControl(input.valueRight || [], validators);
      } else {
        group[input.key] = new FormControl(input.value || '', validators);
      }

      if (input.disabled) {
        (group[input.key] as FormControl).disable();
      }
    });

    return new FormGroup(group);
  }

}
