import { PurchaseItemListItem } from './purchase-item-list-item.model';

export class PurchaseItemListResult {
  totalCount: number;
  offset: number;
  limit: number;
  query: string;
  purchaseItems: PurchaseItemListItem[];

  constructor(totalCount: number, offset: number, limit: number, query: string, purchaseItems: PurchaseItemListItem[]) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.query = query;
    this.purchaseItems = purchaseItems;
  }
}
