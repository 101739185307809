<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    Ürün
    <small>{{ productGroup?.name }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink="/"><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>
      <a routerLink="/products" [queryParams]="{ groupId: productGroup?.productGroupId }"
        >Ürünler</a
      >
    </li>
    <li class="active">Ürün Düzenle</li>
  </ol>
</section>

<section class="content">
  <div class="box box-primary">
    <dipendo-form
      *ngIf="asyncProductGroup | async"
      [boxTitle]="'Ürün Bilgileri'"
      [inputs]="inputs"
      (submit)="onSubmit($event)"
    ></dipendo-form>
  </div>
</section>
