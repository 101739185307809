import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';
import zipcelx from 'zipcelx-es5';

import { AppComponent } from '../../core/app.component';
import { Purchase } from '../../purchases/shared/purchase.model';
import { ProductGroupSummaryListItem } from '../../settings/product-groups/shared/product-group-summary-list-item.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { User } from '../../users/shared/user.model';
import { PurchaseItemListItem } from '../shared/purchase-item-list-item.model';
import { PurchaseItemService } from '../shared/purchase-item.service';

@Component({
  selector: 'app-in-warehouse',
  templateUrl: './in-warehouse.component.html',
  styleUrls: ['./in-warehouse.component.scss']
})
export class InWarehouseComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Depodakiler';
  public purchaseItems: PurchaseItemListItem;
  public asyncPurchaseItems = new BehaviorSubject<PurchaseItemListItem[]>([]);
  public purchaseItemsSubscription: Subscription;
  public productGroups: ProductGroupSummaryListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupSummaryListItem[]>;
  public selectedProductGroup: ProductGroupSummaryListItem;
  public selectedProductGroupId = 0;
  public selectedProductId = 0;
  public selectedProductGroupName = '';
  public pageNumber = 1;
  public totalCount: number;
  public itemCountInPage: number = 0;
  public perPage: number = 10000;
  public measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  public searchValue = '';
  public searchQuery = new Subject<PagedSearchQuery>();
  public isSearchValueChanged = false;
  public isAllDataRowsLoaded = false;
  public user: User;
  public purchase: Purchase = new Purchase(
    0,
    0,
    null,
    0,
    null,
    null,
    null,
    0,
    0,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    []
  );
  public selectedCount = 0;
  public selectedCosts: string[] = [];
  public isHeaderSelected = false;
  public selectedStockCount = 0;
  public selectedSaleableCount = 0;

  @ViewChild('mainTable', { static: true }) public mainTableRef: ElementRef;

  private ngUnsubscribe = new Subject();
  private isPageLoaded = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly productGroupService: ProductGroupService,
    private readonly purchaseItemService: PurchaseItemService,
    private readonly router: Router,
    private readonly modalService: NgbModal
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckEnd) {
        this.purchaseItemsSubscription = null;
        this.isAllDataRowsLoaded = false;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.asyncPurchaseItems.next([]);
        this.ngUnsubscribe = new Subject();
      }
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;
      this.selectedProductId = parseInt(params['productId'], 10) || 0;

      this.getProductGroups();
    });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngAfterViewInit(): void {
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public onScrollDown(): void {
    if (this.isPageLoaded) {
      this.getPage(this.pageNumber + 1);
    }
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService.getSummary('InStock', 0, 10000).pipe(
      tap(() => {}),
      map((res) => {
        res.productGroups.sort((a, b) => a.order - b.order);

        return res.productGroups;
      }),
      tap((productGroups) => {
        this.productGroups = productGroups;
        if (productGroups.length > 0 && this.selectedProductGroupId === 0) {
          this.selectedProductGroupId = productGroups[0].id;
        }

        if (this.selectedProductGroupId > 0) {
          this.selectedProductGroup = productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          );
          this.selectedProductGroupName = this.selectedProductGroup.name;
        } else {
          this.selectedProductGroup = productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          );
        }

        this.getPage(this.pageNumber);
      }),
      share()
    );
  }

  public getPage(page: number) {
    if (this.isAllDataRowsLoaded) {
      return;
    }

    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (page - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged
      )
    );

    if (!this.purchaseItemsSubscription) {
      this.purchaseItemsSubscription = this.purchaseItemService
        .search(
          4,
          this.selectedProductGroupId < 0 ? 0 : this.selectedProductGroupId,
          0,
          this.searchQuery,
          null,
          this.selectedProductId
        )
        .pipe(
          tap((res) => {
            if (res.query !== this.searchValue) {
              return;
            }
            if (this.isSearchValueChanged) {
              this.asyncPurchaseItems.next([]);
              this.isSearchValueChanged = false;
              this.itemCountInPage = 0;
            }

            this.totalCount = res.totalCount;
            this.pageNumber = Math.floor(res.offset / res.limit) + 1;

            const currentData = this.asyncPurchaseItems.getValue();

            for (const purchaseItem of res.purchaseItems) {
              purchaseItem.identicalItems = [];
              const item = purchaseItem;
              const index = currentData.findIndex(this.isCorrespondingGroupItem(item));

              item.isGroupingItem = false;

              if (index > -1) {
                currentData[index].identicalItems.push(item);
              } else {
                const groupingItem = PurchaseItemListItem.clone(item);
                groupingItem.purchaseItemId = -1;
                groupingItem.isGroupingItem = true;
                groupingItem.isGroupOpen = false;
                groupingItem.identicalItems.push(item);
                currentData.push(groupingItem);
              }
            }

            this.asyncPurchaseItems.next(currentData);

            this.itemCountInPage += res.purchaseItems.length;
            this.isAllDataRowsLoaded = res.totalCount === this.itemCountInPage;

            if (!this.isPageLoaded) {
              this.isPageLoaded = true;
            }

            this.getPage(this.pageNumber + 1);
          }),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe();

      this.searchQuery.next(
        new PagedSearchQuery(
          this.searchValue,
          (page - 1) * this.perPage,
          this.perPage,
          !this.isSearchValueChanged
        )
      );
    }
  }

  public onClickGroupCaret(item: PurchaseItemListItem) {
    const currentData = this.asyncPurchaseItems.getValue();
    const selectedItemIndex = currentData.findIndex(this.isCorrespondingGroupItem(item));

    item.identicalItems.forEach((i) => {
      if (!item.isGroupOpen) {
        i.selected = item.selected;
        currentData.splice(selectedItemIndex + 1, 0, i);
      } else {
        const currentIndex = currentData.findIndex(
          (data) => data.purchaseItemId === i.purchaseItemId
        );
        currentData.splice(currentIndex, 1);
      }
    });

    currentData[selectedItemIndex].isGroupOpen = !currentData[selectedItemIndex].isGroupOpen;

    this.asyncPurchaseItems.next(currentData);

    this.calculateSelectedItem();
  }

  public onChangeHeaderCheckbox(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();

    purchaseItems.forEach((item) => {
      item.selected = this.isHeaderSelected;
    });

    this.calculateSelectedItem();
  }

  public calculateSelectedItem(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    const selectedItems = purchaseItems.filter(
      (x) => x.selected && (!x.isGroupingItem || !x.isGroupOpen)
    );
    this.selectedCount = 0;

    let totalStockCount = 0;
    let totalSaleableCount = 0;

    selectedItems.forEach((p) => {
      if (p.isGroupingItem && !p.isGroupOpen) {
        this.selectedCount += p.identicalItems.length;
        totalStockCount += p.stockCount * p.identicalItems.length;
        totalSaleableCount += p.saleableCount * p.identicalItems.length;
      } else if (p.isGroupingItem && p.isGroupOpen) {
        // Do nothing.
      } else {
        ++this.selectedCount;
        totalStockCount += p.stockCount;
        totalSaleableCount += p.saleableCount;
      }
    });

    this.selectedStockCount = totalStockCount;
    this.selectedSaleableCount = totalSaleableCount;
  }

  public onChangeItemCheckbox(item: PurchaseItemListItem): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    let isAllSelected = true;

    for (const purchaseItem of purchaseItems) {
      if (!purchaseItem.selected) {
        isAllSelected = false;
      }
    }

    this.isHeaderSelected = isAllSelected;

    if (item.isGroupingItem && item.isGroupOpen) {
      item.identicalItems.forEach((i) => {
        purchaseItems[
          purchaseItems.findIndex((k) => k.purchaseItemId === i.purchaseItemId)
        ].selected = item.selected;
      });
    }

    if (!item.isGroupingItem) {
      let isAllGroupItemsSelected = true;
      let itemGroupIndex = -1;

      for (let i = 0; i < purchaseItems.length; ++i) {
        if (!purchaseItems[i].isGroupingItem || !purchaseItems[i].isGroupOpen) {
          continue;
        }

        if (
          purchaseItems[i].identicalItems.findIndex(
            (k) => k.purchaseItemId === item.purchaseItemId
          ) > -1
        ) {
          itemGroupIndex = i;

          purchaseItems[i].identicalItems.forEach((k) => {
            if (!k.selected) {
              isAllGroupItemsSelected = false;
            }
          });
        }
      }

      purchaseItems[itemGroupIndex].selected = isAllGroupItemsSelected;
    }

    this.calculateSelectedItem();
  }

  public onKeyup(event: any): void {
    this.searchValue = event.target.value;
    this.pageNumber = 1;
    this.isSearchValueChanged = true;
    this.isAllDataRowsLoaded = false;
    this.itemCountInPage = 0;
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public onProductGroupChange() {
    this.pageNumber = 1;
    this.isAllDataRowsLoaded = false;
    this.itemCountInPage = 0;
    this.router.navigate(['/warehouse/in-warehouse'], {
      queryParams: { groupId: this.selectedProductGroupId }
    });
  }

  public saveExcel(): void {
    const itemData = [];

    itemData.push([
      {
        value: 'Ürün Adı',
        type: 'string'
      },
      {
        value: 'Tedarikçi',
        type: 'string'
      },
      {
        value: 'Miktar',
        type: 'string'
      },
      {
        value: 'Ölçü Birimi',
        type: 'string'
      }
    ]);

    let isAnyItemSelected = false;

    const purchaseItems = this.asyncPurchaseItems.getValue();

    for (const purchaseItem of purchaseItems) {
      if (purchaseItem.status === 1 && purchaseItem.selected) {
        isAnyItemSelected = true;
        break;
      }
    }

    for (const purchaseItem of purchaseItems) {
      if (isAnyItemSelected && !purchaseItem.selected) {
        continue;
      }

      if (purchaseItem.isGroupingItem && !purchaseItem.isGroupOpen) {
        purchaseItem.identicalItems.forEach((i) => {
          itemData.push(this.createExcelRow(i));
        });
      } else if (purchaseItem.isGroupingItem && purchaseItem.isGroupOpen) {
        // Do nothing.
      } else {
        itemData.push(this.createExcelRow(purchaseItem));
      }
    }

    zipcelx({
      filename: 'depodakiler',
      sheet: {
        data: itemData
      }
    });
  }

  private isCorrespondingGroupItem(
    item: PurchaseItemListItem
  ): (value: PurchaseItemListItem, index: number, obj: PurchaseItemListItem[]) => boolean {
    return (data) =>
      data.product.id === item.product.id &&
      data.supplierId === item.supplierId &&
      data.stockCount === item.stockCount &&
      data.saleableCount === item.saleableCount &&
      data.isGroupingItem;
  }

  private createExcelRow(item: PurchaseItemListItem): any {
    return [
      {
        value: item.product.name,
        type: 'string'
      },
      {
        value: item.supplierTitle,
        type: 'string'
      },
      {
        value: item.stockCount,
        type: 'number'
      },
      {
        value: this.measurementUnitSymbolPipe.transform(
          item.product.groupUnit,
          item.product.groupUnitGroup
        ),
        type: 'string'
      }
    ];
  }
}
