import { CustomerListItem } from './customer-list-item.model';

export class CustomerListResult {
  totalCount: number;
  offset: number;
  limit: number;
  query: string;
  customers: CustomerListItem[];

  constructor(totalCount: number, offset: number, limit: number, query: string, customers: CustomerListItem[]) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.query = query;
    this.customers = customers;
  }
}
