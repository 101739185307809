import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Globals } from '../globals';
import { AlertService } from './alert.service';
import { UpdateService } from './update.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
  @HostBinding('class') public cssClass = '';
  public title = 'app';

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly updateService: UpdateService
  ) {
    Sentry.setContext('client', {
      Id: Globals.client.id,
      Version: Globals.client.version
    });

    this.updateService.subscribe();

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        if (this.route.snapshot.firstChild) {
          this.cssClass = this.route.snapshot.firstChild.data[0].bodyCssClass;
        }

        this.alertService.shiftAlert();

        window.scrollTo(0, 0);
      });
  }

  public setPageTitle(pageName: string): void {
    if (pageName) {
      pageName += ' - ' + Globals.appName;
    } else {
      pageName = Globals.appName;
    }

    this.titleService.setTitle(pageName);
  }

  public ngOnDestroy() {
    this.updateService.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
