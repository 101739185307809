import { Supplier } from '../../suppliers/shared/supplier.model';
import { User } from '../../users/shared/user.model';
import { PurchaseItem } from './purchase-item.model';

export class Purchase {
  constructor(
    public purchaseId: number,
    public supplierId: number,
    public supplier: Supplier,
    public userId: number,
    public user: User,
    public recordTime: Date,
    public orderTime: Date,
    public status: number,
    public totalCost: number,
    public currency: string,
    public letterOfCreditTime: Date,
    public estimatedShippingTime: Date,
    public estimatedArrivalTime: Date,
    public arrivalTime: Date,
    public invoiceTime: Date,
    public invoiceNumber: string,
    public customsDeclarationTime: Date,
    public customsDeclarationNumber: string,
    public explanation: string,
    public purchaseItems: PurchaseItem[]
  ) {}
}
