import { ProductGroupSummaryListItem } from './product-group-summary-list-item.model';

export class ProductGroupSummaryListResult {
  totalCount: number;
  productGroups: ProductGroupSummaryListItem[];

  constructor(totalCount: number, productGroups: ProductGroupSummaryListItem[]) {
    this.totalCount = totalCount;
    this.productGroups = productGroups;
  }
}
