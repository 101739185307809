import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';

import { DipendoPaginationComponent } from './dipendo-pagination/dipendo-pagination.component';
import { DipendoFormComponent } from './forms/dipendo-form/dipendo-form.component';
import { DipendoInputComponent } from './forms/dipendo-input/dipendo-input.component';
import { DipendoModalFormComponent } from './forms/dipendo-modal-form/dipendo-modal-form.component';
import { IfAuthorizedToDirective } from './if-authorized-to.directive';
import { ActivePassivePipe } from './pipes/active-passive.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { IgnoreInvalidDatePipe } from './pipes/ignore-invalid-date.pipe';
import { MatchProductGroupPipe } from './pipes/match-product-group.pipe';
import { MatchPurchaseItemStatusPipe } from './pipes/match-purchase-item-status.pipe';
import { MatchSaleItemStatusPipe } from './pipes/match-sale-item-status.pipe';
import { MeasurementUnitNamePipe } from './pipes/measurement-unit-name.pipe';
import { MeasurementUnitSymbolPipe } from './pipes/measurement-unit-symbol.pipe';
import { NotDeletedFilePipe } from './pipes/not-deleted-file.pipe';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { TelPipe } from './pipes/tel.pipe';
import { UrlPipe } from './pipes/url.pipe';

@NgModule({
  imports: [
    ChartsModule,
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    MomentModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule
  ],
  declarations: [
    ActivePassivePipe,
    DipendoFormComponent,
    DipendoInputComponent,
    DipendoModalFormComponent,
    DipendoPaginationComponent,
    FormatDatePipe,
    IfAuthorizedToDirective,
    IgnoreInvalidDatePipe,
    MatchProductGroupPipe,
    MatchPurchaseItemStatusPipe,
    MatchSaleItemStatusPipe,
    MeasurementUnitNamePipe,
    MeasurementUnitSymbolPipe,
    ObjectToArrayPipe,
    TelPipe,
    UrlPipe,
    NotDeletedFilePipe
  ],
  exports: [
    ActivePassivePipe,
    ChartsModule,
    DipendoFormComponent,
    DipendoInputComponent,
    DipendoModalFormComponent,
    DipendoPaginationComponent,
    FormatDatePipe,
    IfAuthorizedToDirective,
    IgnoreInvalidDatePipe,
    MatchProductGroupPipe,
    MatchPurchaseItemStatusPipe,
    MatchSaleItemStatusPipe,
    MeasurementUnitNamePipe,
    MeasurementUnitSymbolPipe,
    ObjectToArrayPipe,
    TelPipe,
    UrlPipe,
    NotDeletedFilePipe,
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    MomentModule,
    NgxPaginationModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {}
