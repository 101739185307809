import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dipendo-upload-file-confirm',
  templateUrl: './upload-file-confirm.html',
  styleUrls: ['./upload-file-confirm.scss']
})
export class UploadFileConfirmComponent implements OnInit {
  public title = '';
  public message = '';
  public fileId: number;

  constructor(public activeModal: NgbActiveModal) {}

  public ngOnInit(): void {}

  public continue() {
    this.activeModal.close();
  }
}
