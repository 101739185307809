import { ProductGroupListItem } from './product-group-list-item.model';

export class ProductGroupListResult {
  totalCount: number;
  productGroups: ProductGroupListItem[];

  constructor(totalCount: number, productGroups: ProductGroupListItem[]) {
    this.totalCount = totalCount;
    this.productGroups = productGroups;
  }
}
