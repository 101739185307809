import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ReportListItem } from '../report/report-list-item.model';
import { ProfitabilityReportListResult } from './profitability-report-list-result.model';

@Injectable()
export class ProfitabilityReportService {
  private readonly endpoint = `${environment.baseApiUrl}reports/profitability-report`;

  constructor(private readonly http: HttpClient) {}

  public get(
    saleBeginDate = '',
    saleEndDate = '',
    supplierId = 0,
    customerId = 0,
    productIds: number[] = [],
    userId = 0
  ): Observable<ProfitabilityReportListResult> {
    const params = new HttpParams()
      .set('saleBeginDate', saleBeginDate.toString())
      .set('saleEndDate', saleEndDate.toString())
      .set('supplierId', supplierId.toString())
      .set('customerId', customerId.toString())
      .set('productIds', productIds.join(';').toString())
      .set('userId', userId.toString());

    return this.http
      .get<ReportListItem>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new ProfitabilityReportListResult(1, res.body);
        })
      );
  }
}
