import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatPipe, FromUtcPipe, LocalePipe, LocalTimePipe } from 'ngx-moment';

import { IgnoreInvalidDatePipe } from './ignore-invalid-date.pipe';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  private dateFormatPipe = new DateFormatPipe();
  private fromUtcPipe = new FromUtcPipe();
  private ignoreInvalidDatePipe = new IgnoreInvalidDatePipe();
  private localePipe = new LocalePipe();
  private localTimePipe = new LocalTimePipe();

  public transform(value: Date): string {
    return this.ignoreInvalidDatePipe.transform(
      this.dateFormatPipe.transform(
        this.localePipe.transform(
          this.localTimePipe.transform(this.fromUtcPipe.transform(value)).toISOString(),
          'tr'
        ),
        'L'
      )
    );
  }
}
