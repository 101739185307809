import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, Params, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { Globals } from '../../globals';
import { ProductListItemProperty } from '../../products/shared/product-list-item-property.model';
import { ProductListItem } from '../../products/shared/product-list-item.model';
import { ProductProperty } from '../../products/shared/product-property.model';
import { ProductService } from '../../products/shared/product.service';
import { ProductGroupListItem } from '../../settings/product-groups/shared/product-group-list-item.model';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { PurchaseItem } from '../shared/purchase-item.model';

@Component({
  selector: 'app-product-list',
  templateUrl: './purchase-product-list.component.html',
  styleUrls: ['./purchase-product-list.component.scss']
})
export class PurchaseProductListComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Ürünler';
  public productGroups: ProductGroupListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupListItem[]>;
  public selectedProductGroup: ProductGroup;
  public selectedProductGroupId = 0;
  public selectedProductGroupName = '';
  public products: ProductListItem[] = [];
  public asyncProducts = new BehaviorSubject<ProductListItem[]>([]);
  public productsSubscription: Subscription;
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public headerSelection = false;
  public selectedItemCount = 0;
  public searchValue = '';
  public searchQuery = new Subject<PagedSearchQuery>();
  public isSearchValueChanged = false;

  private ngUnsubscribe = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly productGroupService: ProductGroupService,
    private readonly productService: ProductService,
    private readonly router: Router,
    public readonly activeModal: NgbActiveModal
  ) {}

  public ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckEnd) {
        this.productsSubscription = null;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.asyncProducts.next([]);
        this.ngUnsubscribe = new Subject();
      }
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;

      this.getProductGroups();
    });
  }

  public ngAfterViewInit(): void {
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService.getAll(0, 10000).pipe(
      tap((res) => {
        this.productGroups = res.productGroups;

        if (res.totalCount > 0 && this.selectedProductGroupId === 0) {
          this.selectedProductGroupId = res.productGroups[0].id;
        }

        if (this.selectedProductGroupId > 0) {
          this.selectedProductGroupName = res.productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          ).name;
        }

        this.setProductGroup();
      }),
      map((res) => res.productGroups),
      share()
    );
  }

  public setProductGroup(): void {
    this.productGroupService
      .get(this.selectedProductGroupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.selectedProductGroup = res;
        this.selectedProductGroup.productGroupProperties.sort((a, b) => a.order - b.order);
        this.selectedProductGroupName = this.selectedProductGroup.name;
        this.getPage(this.pageNumber);
      });
  }

  public changePage(page: number) {
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (page - 1) * this.perPage, this.perPage)
    );
  }

  public getPage(page: number) {
    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (page - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged
      )
    );

    if (!this.productsSubscription) {
      this.productsSubscription = this.productService
        .search([this.selectedProductGroupId], this.searchQuery)
        .pipe(
          tap((res) => {
            if (res.query !== this.searchValue) {
              return;
            }

            if (this.isSearchValueChanged) {
              this.asyncProducts.next([]);
              this.isSearchValueChanged = false;
            }

            this.totalCount = res.totalCount;
            this.pageNumber = Math.floor(res.offset / res.limit) + 1;
            this.products = res.products;
            this.asyncProducts.next(this.products);
          }),
          map((res) => {
            this.headerSelection = false;
            this.products = res.products;

            return res.products;
          }),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe();

      this.searchQuery.next(
        new PagedSearchQuery(
          this.searchValue,
          (page - 1) * this.perPage,
          this.perPage,
          !this.isSearchValueChanged
        )
      );
    }
  }

  public onKeyup(event: any): void {
    this.searchValue = event.target.value;
    this.pageNumber = 1;
    this.isSearchValueChanged = true;
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public onChange() {
    this.pageNumber = 1;
    this.productsSubscription = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.asyncProducts.next([]);
    this.ngUnsubscribe = new Subject();
    this.setProductGroup();
  }

  public onChangeHeaderCheckbox() {
    this.products.forEach((item) => {
      item.selected = this.headerSelection;
    });

    this.selectedItemCount = this.products.filter((x) => x.selected).length;
  }

  public onChangeItemCheckbox() {
    let isAllSelected = true;

    for (const item of this.products) {
      if (!item.selected) {
        isAllSelected = false;
      }
    }

    this.headerSelection = isAllSelected;

    this.selectedItemCount = this.products.filter((x) => x.selected).length;
  }

  public add() {
    this.activeModal.close(this.prepareBindingObject(this.products));
  }

  public convertPropertyList(propertyValues: ProductListItemProperty[]): ProductProperty[] {
    const propertyList: ProductProperty[] = [];

    for (const item of propertyValues) {
      propertyList.push({
        productGroupPropertyId: item.propertyId,
        propertyValue: item.value
      });
    }

    return propertyList;
  }

  private prepareBindingObject(productList: ProductListItem[]): PurchaseItem[] {
    const bindingObject: PurchaseItem[] = [];

    for (const item of productList) {
      if (item.selected) {
        bindingObject.push({
          purchaseItemId: 0,
          purchaseId: 0,
          productId: item.id,
          stockCode: '',
          purchaseCount: 0,
          cost: 0,
          currencyCode: item.currencyCode,
          status: 1,
          letterOfCreditTime: new Date(),
          estimatedShippingTime: new Date(),
          estimatedArrivalTime: new Date(),
          arrivalTime: new Date(),
          invoiceTime: null,
          invoiceNumber: null,
          customsDeclarationTime: null,
          customsDeclarationNumber: null,
          product: {
            productId: item.id,
            name: item.name,
            currencyCode: item.currencyCode,
            listPrice: item.listPrice,
            unitMass: item.unitMass,
            unitOfMass: item.unitOfMass,
            productGroupId: item.groupId,
            productPropertyValues: this.convertPropertyList(item.propertyValues),
            isActive: true
          },
          selected: false,
          comments: []
        });
      }
    }

    return bindingObject;
  }
}
