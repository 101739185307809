import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as JsPdf from 'jspdf';
import { DateFormatPipe, FromUtcPipe, LocalePipe, LocalTimePipe } from 'ngx-moment';
import * as QrCode from 'qrcode';
import { Subject } from 'rxjs';
import { share, takeUntil, tap } from 'rxjs/operators';
import zipcelx from 'zipcelx-es5';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { Globals } from '../../globals';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { IgnoreInvalidDatePipe } from '../../shared/pipes/ignore-invalid-date.pipe';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { User } from '../../users/shared/user.model';
import { PurchaseActiveConfirmComponent } from '../purchase-active-confirm/purchase-active-confirm.component';
import { PurchaseCompleteFormComponent } from '../purchase-complete-form/purchase-complete-form.component';
import { PurchaseSummaryEditFormComponent } from '../purchase-summary-edit-form/purchase-summary-edit-form.component';
import { PurchaseItem } from '../shared/purchase-item.model';
import { Purchase } from '../shared/purchase.model';
import { PurchaseService } from '../shared/purchase.service';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss']
})
export class PurchaseDetailComponent implements OnDestroy, OnInit {
  public pageName = 'Satın Alma Detayı';
  public purchase: Purchase = new Purchase(
    0,
    0,
    null,
    0,
    null,
    null,
    null,
    0,
    0,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    []
  );
  public selectedCount = 0;
  public selectedMass = 0;
  public selectedCosts: string[] = [];
  public selectedTab = 1;
  public productGroupIds: Map<number, number[]> = new Map<number, number[]>();
  public productGroups: Map<number, ProductGroup> = new Map<number, ProductGroup>();
  public headerSelectionList: Map<number, any[]> = new Map<number, any[]>();
  public purchaseItemStatus = Globals.purchaseItemStatus;
  public dateFormatPipe = new DateFormatPipe();
  public fromUtcPipe = new FromUtcPipe();
  public ignoreInvalidDatePipe = new IgnoreInvalidDatePipe();
  public localePipe = new LocalePipe();
  public localTimePipe = new LocalTimePipe();
  public measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  public user: User;
  private selectedId: number;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly modalService: NgbModal,
    private readonly productGroupService: ProductGroupService,
    private readonly purchaseService: PurchaseService
  ) {}

  public ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      if (url[url.length - 1].path === 'detail') {
        this.activatedRoute.params.subscribe((params) => {
          this.selectedId = parseInt(params.id, 10) || -1;

          if (this.selectedId > 0) {
            this.getPurchase(this.selectedId);
          }
        });
      }
    });
  }

  public onChangeHeaderCheckbox(status: number, index: number): void {
    this.purchase.purchaseItems.forEach((item) => {
      if (
        item.status === status &&
        item.product.productGroupId === this.headerSelectionList.get(status)[index].groupId
      ) {
        item.selected = this.headerSelectionList.get(status)[index].selected;
      }
    });

    this.calculateSelectedItem();
  }

  public onChangeItemCheckbox(item: PurchaseItem, index: number): void {
    let isAllSelectedForGroup = true;

    for (const purchaseItem of this.purchase.purchaseItems) {
      if (
        purchaseItem.product.productGroupId === item.product.productGroupId &&
        purchaseItem.status === item.status &&
        !purchaseItem.selected
      ) {
        isAllSelectedForGroup = false;
      }
    }

    this.headerSelectionList.get(item.status)[index].selected = isAllSelectedForGroup;

    this.calculateSelectedItem();
  }

  public clearSelection(tab: number): void {
    if (tab !== this.selectedTab) {
      this.selectedTab = tab;

      this.purchase.purchaseItems.forEach((item) => {
        item.selected = false;
      });

      for (let s = 1; s <= 3; s++) {
        if (this.headerSelectionList.get(s)) {
          this.headerSelectionList.get(s).forEach((item) => {
            item.selected = false;
          });
        }
      }

      this.calculateSelectedItem();
    }
  }

  public calculateSelectedItem(): void {
    const purchaseItems = this.purchase.purchaseItems;
    const selectedItems = purchaseItems.filter((x) => x.selected);
    this.selectedCount = selectedItems.length;

    let totalMass = 0;

    selectedItems.forEach((p) => {
      let massModifier = 1;

      if (p.product.unitOfMass === 'mg') {
        massModifier = 1e-6;
      } else if (p.product.unitOfMass === 'g') {
        massModifier = 1e-3;
      } else {
        // Do nothing. Mass Modifier is already 1.
      }

      const itemMass = p.purchaseCount * p.product.unitMass * massModifier;

      totalMass += itemMass;
    });

    this.selectedMass = totalMass;

    this.calculateTotalPrice(selectedItems);
  }

  public changeItemStatus(step: number): void {
    const confirmModal = this.modalService.open(PurchaseActiveConfirmComponent);
    confirmModal.componentInstance.purchaseId = this.purchase.purchaseId;
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message = `Seçtiğiniz işlemlerin durum bilgisi '${
      this.purchaseItemStatus[this.selectedTab + step]
    }' olarak güncellenecektir. Onaylıyor musunuz?`;

    confirmModal.result.then(
      () => {
        const purchaseInsert: Purchase = { ...this.purchase };

        purchaseInsert.purchaseItems.forEach((item) => {
          if (item.selected) {
            item.status += step;
          }
        });

        this.purchaseService
          .update(purchaseInsert.purchaseId, purchaseInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              `Seçtiğiniz işlemlerin durum bilgisi '${
                this.purchaseItemStatus[this.selectedTab + step]
              }' olarak güncellendi.`
            );
            this.getPurchase(purchaseInsert.purchaseId);
            this.selectedCount = 0;
          });
      },
      () => {}
    );
  }

  public cancelItem(): void {
    const confirmModal = this.modalService.open(PurchaseActiveConfirmComponent);
    confirmModal.componentInstance.saleId = this.purchase.purchaseId;
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message =
      'Seçtiğiniz işlemler iptal edilecektir. Onaylıyor musunuz?';

    confirmModal.result.then(
      () => {
        const purchaseInsert: Purchase = { ...this.purchase };

        purchaseInsert.purchaseItems = purchaseInsert.purchaseItems.filter((n) => !n.selected);

        this.purchaseService
          .update(purchaseInsert.purchaseId, purchaseInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              'Seçtiğiniz işlemler başarıyla iptal edildi.'
            );
            this.getPurchase(purchaseInsert.purchaseId);
            this.selectedCount = 0;
          });
      },
      () => {}
    );
  }

  public changeSummaryDetail(): void {
    const updateModal = this.modalService.open(PurchaseSummaryEditFormComponent, {
      windowClass: 'large-modal'
    });
    updateModal.componentInstance.purchase = this.purchase;

    updateModal.result.then(
      (res) => {
        this.purchase.invoiceTime = res.invoiceTime;
        this.purchase.invoiceNumber = res.invoiceNumber;
        this.purchase.customsDeclarationTime = res.customsDeclarationTime;
        this.purchase.customsDeclarationNumber = res.customsDeclarationNumber;
        this.purchase.explanation = res.explanation;

        this.purchase.purchaseItems.forEach((item) => {
          item.invoiceTime = res.invoiceTime;
          item.invoiceNumber = res.invoiceNumber;
          item.customsDeclarationTime = res.customsDeclarationTime;
          item.customsDeclarationNumber = res.customsDeclarationNumber;
        });

        this.purchaseService
          .update(this.purchase.purchaseId, this.purchase)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(AlertType.success, 'Özet bilgiler başarıyla güncellendi.');
            this.getPurchase(this.purchase.purchaseId);
          });
      },
      () => {}
    );
  }

  public changeItemDetail(): void {
    let selectedItem: PurchaseItem;

    for (const item of this.purchase.purchaseItems) {
      if (item.selected) {
        selectedItem = item;
      }
    }

    const updateModal = this.modalService.open(PurchaseCompleteFormComponent, {
      windowClass: 'large-modal'
    });
    updateModal.componentInstance.purchaseItem = selectedItem;
    updateModal.componentInstance.itemCount = this.purchase.purchaseItems.filter(
      (x) => x.selected
    ).length;

    if (this.selectedTab === 1) {
      updateModal.componentInstance.inputLabels = new Map<string, string>([
        ['letterOfCreditTime', 'Hazırlanma Tarihi']
      ]);
    }

    updateModal.result.then(
      (res) => {
        selectedItem = res;

        this.purchase.purchaseItems.forEach((item) => {
          if (item.selected) {
            item.letterOfCreditTime = selectedItem.letterOfCreditTime;
            item.estimatedShippingTime = selectedItem.estimatedShippingTime;
            item.estimatedArrivalTime = selectedItem.estimatedArrivalTime;
            item.arrivalTime = selectedItem.arrivalTime;
          }
        });

        this.purchaseService
          .update(this.purchase.purchaseId, this.purchase)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              'Seçtiğiniz işlemlerin bilgileri başarılı olarak güncellendi.'
            );
            this.getPurchase(this.purchase.purchaseId);
          });
      },
      () => {}
    );
  }

  public saveExcel(): void {
    const itemData = [];

    itemData.push([
      {
        value: 'Ürün Adı',
        type: 'string'
      },
      {
        value: 'Ağırlık',
        type: 'string'
      },
      {
        value: this.selectedTab === 1 ? 'Hazırlanma Tarihi' : 'Akreditif Tarihi',
        type: 'string'
      },
      {
        value: 'Tahmini Yükleme Tarihi',
        type: 'string'
      },
      {
        value: 'Tahmini Varış Tarihi',
        type: 'string'
      },
      {
        value: 'Kesin Varış Tarihi',
        type: 'string'
      },
      {
        value: 'Fiyat',
        type: 'string'
      },
      {
        value: 'Miktar',
        type: 'string'
      }
    ]);

    let isAnyItemSelected = false;

    for (const purchaseItem of this.purchase.purchaseItems) {
      if (purchaseItem.status === this.selectedTab && purchaseItem.selected) {
        isAnyItemSelected = true;
        break;
      }
    }

    for (const purchaseItem of this.purchase.purchaseItems) {
      if (isAnyItemSelected) {
        if (purchaseItem.status === this.selectedTab && purchaseItem.selected) {
          itemData.push(this.createExcelRow(purchaseItem));
        }
      } else {
        if (purchaseItem.status === this.selectedTab) {
          itemData.push(this.createExcelRow(purchaseItem));
        }
      }
    }

    zipcelx({
      filename: `satin-alma-${this.purchase.purchaseId}-${
        this.purchaseItemStatus[this.selectedTab]
      }`,
      sheet: {
        data: itemData
      }
    });
  }

  public saveQrCodes() {
    let doesAnyErrorOccur = false;
    const purchaseItems: PurchaseItem[] = [];
    const purchaseItemsMap = new Map<number, PurchaseItem>();
    const qrCodes = new Map<number, string>();

    let isAnyItemSelected = false;

    for (const purchaseItem of this.purchase.purchaseItems) {
      if (purchaseItem.selected) {
        isAnyItemSelected = true;

        break;
      }
    }

    for (const purchaseItem of this.purchase.purchaseItems) {
      if (isAnyItemSelected) {
        if (purchaseItem.selected) {
          purchaseItems.push(purchaseItem);
        }
      } else {
        purchaseItems.push(purchaseItem);
      }
    }

    for (const purchaseItem of purchaseItems) {
      QrCode.toDataURL(`dipendo://info?id=${purchaseItem.purchaseItemId}`, {
        errorCorrectionLevel: 'Q'
      })
        .then((url) => {
          qrCodes.set(purchaseItem.purchaseItemId, url);
          purchaseItemsMap.set(purchaseItem.purchaseItemId, purchaseItem);

          let haveAllQrCodesBeenGenerated = true;

          purchaseItems.forEach((p) => {
            if (!qrCodes.get(p.purchaseItemId)) {
              haveAllQrCodesBeenGenerated = false;
            }
          });

          if (haveAllQrCodesBeenGenerated && !doesAnyErrorOccur) {
            const doc = new JsPdf();
            const fontHeight = 14;
            const leftSpacing = 5;
            const pageHeight = 297;
            let orderInPage = 0;
            let lineCount = 0;
            doc.setFont('Verdana', 'normal');
            doc.setFontSize(fontHeight);

            qrCodes.forEach((qrCode, purchaseItemId) => {
              const qrCodeSectionHeight = 37;
              const qrCodeSideLength = qrCodeSectionHeight * 0.8;
              const pageTopSpacing = 11;
              const qrCodeSectionSpacing = 3;
              let currentQrCodeSectionTop =
                pageTopSpacing +
                qrCodeSectionHeight * lineCount +
                qrCodeSectionSpacing * lineCount +
                orderInPage * 40;
              const nextHeight = currentQrCodeSectionTop + qrCodeSectionHeight;

              if (nextHeight > pageHeight) {
                doc.addPage();
                orderInPage = 0;
                lineCount = 0;
                currentQrCodeSectionTop =
                  pageTopSpacing +
                  qrCodeSectionHeight * lineCount +
                  qrCodeSectionSpacing * lineCount +
                  orderInPage * 40;
              }

              doc.addImage(
                qrCode,
                'PNG',
                leftSpacing + qrCodeSectionHeight * 0.1,
                currentQrCodeSectionTop + qrCodeSectionHeight * 0.1,
                qrCodeSideLength,
                qrCodeSideLength
              );

              const infoSectionLeft =
                leftSpacing + qrCodeSectionHeight * 0.1 * 2 + qrCodeSideLength;
              doc.text(
                purchaseItemId.toString(),
                infoSectionLeft,
                Math.floor(currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + fontHeight / 2)
              );
              doc.text(
                purchaseItemsMap.get(purchaseItemId).product.name,
                infoSectionLeft,
                Math.floor(currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + fontHeight)
              );
              const itemCount =
                this.productGroups.get(purchaseItemsMap.get(purchaseItemId).product.productGroupId)
                  .unitGroup === 'number'
                  ? Math.round(purchaseItemsMap.get(purchaseItemId).purchaseCount)
                  : purchaseItemsMap.get(purchaseItemId).purchaseCount;
              doc.text(
                `${itemCount} ${this.measurementUnitSymbolPipe.transform(
                  this.productGroups.get(
                    purchaseItemsMap.get(purchaseItemId).product.productGroupId
                  ).unit,
                  this.productGroups.get(
                    purchaseItemsMap.get(purchaseItemId).product.productGroupId
                  ).unitGroup
                )}`,
                infoSectionLeft,
                Math.floor(
                  currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + (fontHeight / 2) * 3
                )
              );
              doc.text(
                `${(
                  purchaseItemsMap.get(purchaseItemId).purchaseCount *
                  purchaseItemsMap.get(purchaseItemId).product.unitMass
                ).toFixed(3)} ${purchaseItemsMap.get(purchaseItemId).product.unitOfMass}`,
                infoSectionLeft,
                Math.floor(currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + fontHeight * 2)
              );
              ++orderInPage;
            });

            doc.save('karekod-listesi.pdf');
          }
        })
        .catch((err) => {
          doesAnyErrorOccur = true;
          this.alertService.setAlert(
            AlertType.danger,
            'Karekodların oluşturulması esnasında bir hata oluştu.'
          );

          throw err;
        });
    }
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getPurchase(id: number) {
    this.purchaseService
      .get(id)
      .pipe(
        tap(() => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.purchase = res;
        this.getProductGroups();
      });
  }

  private getProductGroup(id: number) {
    if (!this.productGroups.get(id)) {
      this.productGroupService
        .get(id)
        .pipe(
          tap(() => {}),
          share(),
          takeUntil(this.unsubscribe)
        )
        .subscribe((res) => {
          this.productGroups.set(id, res);
        });
    }
  }

  private getProductGroups() {
    this.productGroupIds = new Map<number, number[]>();
    this.productGroups = new Map<number, ProductGroup>();
    this.headerSelectionList = new Map<number, any[]>();

    if (this.purchase && this.purchase.purchaseItems && this.purchase.purchaseItems.length > 0) {
      for (const item of this.purchase.purchaseItems) {
        if (item.status > 0) {
          this.addProductGroupId(item.status, item.product.productGroupId);
        }
      }

      this.productGroupIds.forEach((groupIdList: number[]): void => {
        for (const id of groupIdList) {
          this.getProductGroup(id);
        }
      });
    }
  }

  private addProductGroupId(status: number, groupId: number): void {
    if (!this.productGroupIds.get(status)) {
      this.productGroupIds.set(status, [groupId]);
      this.headerSelectionList.set(status, [{ groupId, selected: false }]);
    } else if (this.productGroupIds.get(status).indexOf(groupId) < 0) {
      this.productGroupIds.get(status).push(groupId);
      this.headerSelectionList.get(status).push({ groupId, selected: false });
    }
  }

  private createExcelRow(item: PurchaseItem): any {
    return [
      {
        value: item.product.name,
        type: 'string'
      },
      {
        value: `${(item.product.unitMass * item.purchaseCount).toFixed(3).toString()} ${
          item.product.unitOfMass
        }`,
        type: 'string'
      },
      {
        value: this.formatDate(item.letterOfCreditTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.estimatedShippingTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.estimatedArrivalTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.arrivalTime),
        type: 'string'
      },
      {
        value: `${item.cost.toFixed(2).toString()} ${item.product.currencyCode}`,
        type: 'string'
      },
      {
        value: `${item.purchaseCount} ${this.measurementUnitSymbolPipe.transform(
          this.productGroups.get(item.product.productGroupId).unit,
          this.productGroups.get(item.product.productGroupId).unitGroup
        )}`,
        type: 'string'
      }
    ];
  }

  private formatDate(value: Date): string {
    return this.ignoreInvalidDatePipe.transform(
      this.dateFormatPipe.transform(
        this.localePipe.transform(
          this.localTimePipe.transform(this.fromUtcPipe.transform(value)).toString(),
          'tr'
        ),
        'L'
      )
    );
  }

  private calculateTotalPrice(itemList: PurchaseItem[]): void {
    const priceList = new Map<string, number>();

    for (const item of itemList) {
      if (priceList.get(item.product.currencyCode)) {
        const totalAmount =
          priceList.get(item.product.currencyCode) + item.cost * item.purchaseCount;
        priceList.set(item.product.currencyCode, totalAmount);
      } else {
        priceList.set(item.product.currencyCode, item.cost * item.purchaseCount);
      }
    }

    this.selectedCosts = [];

    priceList.forEach((value: number, key: string) => {
      this.selectedCosts.push(`${value.toFixed(2).toString()} ${key}`);
    });
  }
}
