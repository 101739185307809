<section class="content-header">
  <h1>
    {{ pageName }}
    <small>{{ selectedProductGroupName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>Depo</li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <!-- Default box -->
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">{{ selectedProductGroupName }}</h3>

      <div class="box-tools">
        <form class="form-inline" #productGroupForm="ngForm" autocomplete="off" novalidate>
          <button
            type="button"
            class="btn btn-sm btn-success"
            style="margin-right: 4pt;"
            (click)="saveQrCodes()"
          >
            Karekod Oluştur
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success"
            style="margin-right: 4pt;"
            (click)="saveExcel()"
          >
            Excel'e Aktar
          </button>
          <div *ngIf="this.selectedProductId === 0" class="form-group">
            <select
              class="form-control input-sm"
              [(ngModel)]="selectedProductGroupId"
              (change)="onProductGroupChange()"
              name="product-group"
            >
              <option
                *ngFor="let productGroup of asyncProductGroups | async"
                value="{{ productGroup.id }}"
                >{{ productGroup.name }} ({{ productGroup.itemCount }})</option
              >
            </select>
          </div>
        </form>
      </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th>Ürün Adı</th>
            <th>Tedarikçi</th>
            <th>Miktar</th>
            <th>Birim Ağırlık</th>
            <th>Toplam Ağırlık</th>
            <th>Kesin Varış Zamanı</th>
            <th *appIfAuthorizedTo="'EditPurchaseItems'"></th>
          </tr>
        </thead>
        <ng-container *ngIf="this.selectedProductId === 0 || (asyncProductGroups | async)">
          <tbody *ngIf="selectedProductGroup">
            <tr
              *ngFor="
                let purchaseItem of asyncPurchaseItems
                  | async
                  | paginate
                    : { itemsPerPage: perPage, currentPage: pageNumber, totalItems: totalCount }
              "
            >
              <td>
                <a [routerLink]="['/purchase-items', purchaseItem.purchaseItemId, 'detail']">{{
                  purchaseItem.product.name
                }}</a>
              </td>
              <td>
                <a [routerLink]="['/suppliers', purchaseItem.supplierId, 'detail']">{{
                  purchaseItem.supplierTitle
                }}</a>
              </td>
              <td>
                {{ purchaseItem.purchaseCount }}
                {{
                  selectedProductGroup.measurementUnit.split('-')[1]
                    | measurementUnitSymbol: selectedProductGroup.measurementUnit.split('-')[0]
                }}
              </td>
              <td>{{ purchaseItem.product.unitMass }} {{ purchaseItem.product.unitOfMass }}</td>
              <td>
                {{ purchaseItem.product.unitMass * purchaseItem.purchaseCount | number: '1.0-3' }}
                {{ purchaseItem.product.unitOfMass }}
              </td>
              <td>
                {{
                  purchaseItem.arrivalTime
                    | amFromUtc
                    | amLocal
                    | amLocale: 'tr'
                    | amDateFormat: 'L'
                    | ignoreInvalidDate
                }}
              </td>
              <td *appIfAuthorizedTo="'EditPurchaseItems'" class="no-break">
                <a (click)="doStorage(purchaseItem.purchaseItemId)">Depoya Al &raquo;</a>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">
        {{ selectedProductGroupName }} grubunda depo girişi bekleyen ürün bulunmamaktadır.
      </p>
    </div>
    <div class="box-footer clearfix">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>
</section>
