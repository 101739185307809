import { StockListItem } from './stock-list-item.model';

export class StockListResult {
  totalCount: number;
  stockItems: StockListItem[];

  constructor(totalCount: number, stockItems: StockListItem[]) {
    this.totalCount = totalCount;
    this.stockItems = stockItems;
  }
}
