import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatPipe, FromUtcPipe, LocalePipe, LocalTimePipe } from 'ngx-moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import zipcelx from 'zipcelx-es5';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { IgnoreInvalidDatePipe } from '../../shared/pipes/ignore-invalid-date.pipe';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { User } from '../../users/shared/user.model';
import { PurchaseItemListItem } from '../../warehouse/shared/purchase-item-list-item.model';
import { PurchaseItemService } from '../../warehouse/shared/purchase-item.service';
import { PurchaseItemEditFormComponent } from '../purchase-item-edit-form/purchase-item-edit-form.component';
import { Purchase } from '../shared/purchase.model';

@Component({
  selector: 'app-shipped',
  templateUrl: './shipped.component.html',
  styleUrls: ['./shipped.component.scss']
})
export class ShippedComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Transfer Edilenler';
  public asyncPurchaseItems = new BehaviorSubject<PurchaseItemListItem[]>([]);
  public purchaseItemsSubscription: Subscription;
  public selectedCount = 0;
  public selectedMass = 0;
  public selectedCosts: string[] = [];
  public isHeaderSelected = false;
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public searchValue = '';
  public selectedProductId = 0;
  public searchQuery = new Subject<PagedSearchQuery>();
  public isSearchValueChanged = false;
  public isAllDataRowsLoaded = false;
  public dateFormatPipe = new DateFormatPipe();
  public fromUtcPipe = new FromUtcPipe();
  public ignoreInvalidDatePipe = new IgnoreInvalidDatePipe();
  public localePipe = new LocalePipe();
  public localTimePipe = new LocalTimePipe();
  public measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  public user: User;
  public purchase: Purchase = new Purchase(
    0,
    0,
    null,
    0,
    null,
    null,
    null,
    0,
    0,
    '',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '',
    []
  );

  @ViewChild('mainTable', { static: true }) public mainTableRef: ElementRef;

  private unsubscribe: Subject<void> = new Subject();
  private isPageLoaded = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly appComponent: AppComponent,
    private readonly modalService: NgbModal,
    private readonly purchaseItemService: PurchaseItemService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductId = parseInt(params['productId'], 10) || 0;

      this.getPage(this.pageNumber);
    });
  }

  public ngAfterViewInit(): void {
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public onScrollDown(): void {
    if (this.isPageLoaded) {
      this.getPage(this.pageNumber + 1);
    }
  }

  public onKeyup(event: any): void {
    this.searchValue = event.target.value;
    this.pageNumber = 1;
    this.isSearchValueChanged = true;
    this.isAllDataRowsLoaded = false;
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public getPage(page: number) {
    if (this.isAllDataRowsLoaded) {
      return;
    }

    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (page - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged
      )
    );

    if (!this.purchaseItemsSubscription) {
      this.purchaseItemsSubscription = this.purchaseItemService
        .search(2, 0, 0, this.searchQuery, null, this.selectedProductId)
        .pipe(
          tap((res) => {
            if (res.query !== this.searchValue) {
              return;
            }

            if (this.isSearchValueChanged) {
              this.asyncPurchaseItems.next([]);
              this.isSearchValueChanged = false;
            }

            this.totalCount = res.totalCount;
            this.pageNumber = Math.floor(res.offset / res.limit) + 1;

            const currentData = this.asyncPurchaseItems.getValue();

            this.asyncPurchaseItems.next(currentData.concat(res.purchaseItems));

            this.isAllDataRowsLoaded = res.totalCount === this.asyncPurchaseItems.getValue().length;

            if (!this.isPageLoaded) {
              this.isPageLoaded = true;
            }

            if (window.innerHeight > this.mainTableRef.nativeElement.offsetHeight) {
              this.onScrollDown();
            }
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe();

      this.searchQuery.next(
        new PagedSearchQuery(
          this.searchValue,
          (page - 1) * this.perPage,
          this.perPage,
          !this.isSearchValueChanged
        )
      );
    }
  }

  public onChangeHeaderCheckbox(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();

    purchaseItems.forEach((item) => {
      item.selected = this.isHeaderSelected;
    });

    this.calculateSelectedItem();
  }

  public onChangeItemCheckbox(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    let isAllSelectedForGroup = true;

    for (const purchaseItem of purchaseItems) {
      if (!purchaseItem.selected) {
        isAllSelectedForGroup = false;
      }
    }

    this.isHeaderSelected = isAllSelectedForGroup;

    this.calculateSelectedItem();
  }

  public calculateSelectedItem(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    const selectedItems = purchaseItems.filter((x) => x.selected);
    this.selectedCount = selectedItems.length;

    let totalMass = 0;

    selectedItems.forEach((p) => {
      const itemMass = p.purchaseCount * p.product.unitMass;
      if (p.product.unitOfMass === 'mg') {
        totalMass += itemMass / 1000000;
      } else if (p.product.unitOfMass === 'g') {
        totalMass += itemMass / 1000;
      } else {
        totalMass += itemMass;
      }
    });

    this.selectedMass = totalMass;

    this.calculateTotalPrice(selectedItems);
  }

  public proceedItemToNextStep(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    const selectedItems = purchaseItems.filter((x) => x.selected);
    const confirmModal = this.modalService.open(PurchaseItemEditFormComponent, {
      windowClass: 'large-modal'
    });
    confirmModal.componentInstance.purchaseItem = selectedItems[0];
    confirmModal.componentInstance.itemCount = selectedItems.length;
    confirmModal.componentInstance.title = 'Ürünleri İlerlet';
    confirmModal.componentInstance.primaryButtonLabel = 'İlerlet';
    confirmModal.componentInstance.message = `<p>Seçtiğiniz ürünler '${Globals.purchaseItemStatus[3]}' adımına ilerletilecektir. Lütfen ilgili tarihleri girin.</p><p><strong>Hatırlatma:</strong> Burada girilen tarihler, ürünler üzerindeki mevcut tarihlerin üzerine yazılacaktır.</p>`;

    confirmModal.result.then(
      (res) => {
        const editedPurchaseItem = res as PurchaseItemListItem;
        editedPurchaseItem.status = 3;

        purchaseItems.forEach((item) => {
          if (item.selected) {
            item.letterOfCreditTime = editedPurchaseItem.letterOfCreditTime;
            item.estimatedShippingTime = editedPurchaseItem.estimatedShippingTime;
            item.estimatedArrivalTime = editedPurchaseItem.estimatedArrivalTime;
            item.arrivalTime = editedPurchaseItem.arrivalTime;
            item.status = 3;
          }
        });

        this.purchaseItemService
          .patch(
            'arrivalTime,estimatedArrivalTime,estimatedShippingTime,letterOfCreditTime,status',
            this.createPatchObject(selectedItems, editedPurchaseItem, 'update')
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              `Seçtiğiniz ürünler '${Globals.purchaseItemStatus[3]}' adımına başarıyla ilerletildi.`
            );

            for (let i = purchaseItems.length - 1; i >= 0; --i) {
              if (!purchaseItems[i].selected) {
                continue;
              }

              purchaseItems.splice(i, 1);
            }

            this.asyncPurchaseItems.next(purchaseItems);
            this.selectedCount = 0;
            this.isHeaderSelected = false;
            this.totalCount = purchaseItems.length;
          });
      },
      () => {}
    );
  }

  public editItem(): void {
    const purchaseItems = this.asyncPurchaseItems.getValue();
    const selectedItems = purchaseItems.filter((x) => x.selected);
    const updateModal = this.modalService.open(PurchaseItemEditFormComponent, {
      windowClass: 'large-modal'
    });
    updateModal.componentInstance.purchaseItem = selectedItems[0];
    updateModal.componentInstance.itemCount = selectedItems.length;
    updateModal.componentInstance.title = 'Ürün Detayları';

    updateModal.result.then(
      (res) => {
        const editedPurchaseItem = res as PurchaseItemListItem;
        purchaseItems.forEach((item) => {
          if (item.selected) {
            item.letterOfCreditTime = editedPurchaseItem.letterOfCreditTime;
            item.estimatedShippingTime = editedPurchaseItem.estimatedShippingTime;
            item.estimatedArrivalTime = editedPurchaseItem.estimatedArrivalTime;
            item.arrivalTime = editedPurchaseItem.arrivalTime;
          }
        });

        this.purchaseItemService
          .patch(
            'arrivalTime,estimatedArrivalTime,estimatedShippingTime,letterOfCreditTime',
            this.createPatchObject(selectedItems, editedPurchaseItem, 'update')
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.alertService.setAlert(
              AlertType.success,
              'Seçtiğiniz ürünlerin bilgileri başarıyla güncellendi.'
            );
            this.asyncPurchaseItems.next(purchaseItems);
          });
      },
      () => {}
    );
  }

  public saveExcel(): void {
    const itemData = [];

    itemData.push([
      {
        value: 'Konteyner No',
        type: 'string'
      },
      {
        value: 'Ürün Adı',
        type: 'string'
      },
      {
        value: 'Tedarikçi',
        type: 'string'
      },
      {
        value: 'Kütle',
        type: 'string'
      },
      {
        value: 'Kütle Birimi',
        type: 'string'
      },
      {
        value: 'Akreditif Tarihi',
        type: 'string'
      },
      {
        value: 'Tahmini Yükleme Tarihi',
        type: 'string'
      },
      {
        value: 'Tahmini Varış Tarihi',
        type: 'string'
      },
      {
        value: 'Kesin Varış Tarihi',
        type: 'string'
      },
      {
        value: 'Fiyat',
        type: 'string'
      },
      {
        value: 'Para Birimi',
        type: 'string'
      },
      {
        value: 'Miktar',
        type: 'string'
      },
      {
        value: 'Ölçü Birimi',
        type: 'string'
      }
    ]);

    let isAnyItemSelected = false;

    const purchaseItems = this.asyncPurchaseItems.getValue();

    for (const purchaseItem of purchaseItems) {
      if (purchaseItem.status === 2 && purchaseItem.selected) {
        isAnyItemSelected = true;
        break;
      }
    }

    for (const purchaseItem of purchaseItems) {
      if (isAnyItemSelected && !purchaseItem.selected) {
        continue;
      }

      itemData.push(this.createExcelRow(purchaseItem));
    }

    zipcelx({
      filename: 'transfer-edilenler',
      sheet: {
        data: itemData
      }
    });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private calculateTotalPrice(itemList: PurchaseItemListItem[]): void {
    const priceList = new Map<string, number>();

    for (const item of itemList) {
      if (priceList.get(item.product.currencyCode)) {
        const totalAmount =
          priceList.get(item.product.currencyCode) + item.cost * item.purchaseCount;
        priceList.set(item.product.currencyCode, totalAmount);
      } else {
        priceList.set(item.product.currencyCode, item.cost * item.purchaseCount);
      }
    }

    this.selectedCosts = [];

    priceList.forEach((value: number, key: string) => {
      this.selectedCosts.push(`${value.toFixed(4)} ${key}`);
    });
  }

  private createPatchObject(
    selectedItems: PurchaseItemListItem[],
    editedPurchaseItem: PurchaseItemListItem,
    operation: string
  ): any[] {
    const patchObject = [];

    selectedItems.forEach((i) => {
      if (i.isGroupingItem && !i.isGroupOpen) {
        i.identicalItems.forEach((k) => {
          if (operation === 'update') {
            patchObject.push({
              id: k.purchaseItemId,
              op: operation,
              arrivalTime: editedPurchaseItem.arrivalTime,
              estimatedArrivalTime: editedPurchaseItem.estimatedArrivalTime,
              estimatedShippingTime: editedPurchaseItem.estimatedShippingTime,
              letterOfCreditTime: editedPurchaseItem.letterOfCreditTime,
              status: editedPurchaseItem.status
            });
          } else if (operation === 'delete') {
            patchObject.push({
              id: k.purchaseItemId,
              op: operation
            });
          } else {
            throw new RangeError('Invalid operation.');
          }
        });
      } else if (i.isGroupingItem && i.isGroupOpen) {
        // Do nothing
      } else {
        if (operation === 'update') {
          patchObject.push({
            id: i.purchaseItemId,
            op: operation,
            arrivalTime: editedPurchaseItem.arrivalTime,
            estimatedArrivalTime: editedPurchaseItem.estimatedArrivalTime,
            estimatedShippingTime: editedPurchaseItem.estimatedShippingTime,
            letterOfCreditTime: editedPurchaseItem.letterOfCreditTime,
            status: editedPurchaseItem.status
          });
        } else if (operation === 'delete') {
          patchObject.push({
            id: i.purchaseItemId,
            op: operation
          });
        } else {
          throw new RangeError('Invalid operation.');
        }
      }
    });

    return patchObject;
  }

  private createExcelRow(item: PurchaseItemListItem): any {
    return [
      {
        value: item.containerName,
        type: 'string'
      },
      {
        value: item.product.name,
        type: 'string'
      },
      {
        value: item.supplierTitle,
        type: 'string'
      },
      {
        value: (item.product.unitMass * item.purchaseCount).toFixed(3),
        type: 'number'
      },
      {
        value: item.product.unitOfMass,
        type: 'string'
      },
      {
        value: this.formatDate(item.letterOfCreditTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.estimatedShippingTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.estimatedArrivalTime),
        type: 'string'
      },
      {
        value: this.formatDate(item.arrivalTime),
        type: 'string'
      },
      {
        value: item.cost.toFixed(4),
        type: 'number'
      },
      {
        value: item.product.currencyCode,
        type: 'string'
      },
      {
        value: item.purchaseCount,
        type: 'number'
      },
      {
        value: this.measurementUnitSymbolPipe.transform(
          item.product.groupUnit,
          item.product.groupUnitGroup
        ),
        type: 'string'
      }
    ];
  }

  private formatDate(value: Date): string {
    return this.ignoreInvalidDatePipe.transform(
      this.dateFormatPipe.transform(
        this.localePipe.transform(
          this.localTimePipe.transform(this.fromUtcPipe.transform(value)).toString(),
          'tr'
        ),
        'L'
      )
    );
  }
}
