import { CustomerSalesChartSummary } from './customer-sales-chart-summary.model';

export class CustomerSalesChartSummaryResult {
  public totalCount: number;
  public customerSalesChartSummaries: CustomerSalesChartSummary[];

  constructor(totalCount: number, customerSalesChartSummaries: CustomerSalesChartSummary[]) {
    this.totalCount = totalCount;
    this.customerSalesChartSummaries = customerSalesChartSummaries;
  }
}
