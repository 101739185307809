import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as JsPdf from 'jspdf';
import { Subject } from 'rxjs';
import { share, takeUntil, tap } from 'rxjs/operators';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { Globals } from '../../globals';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { FormatDatePipe } from '../../shared/pipes/format-date.pipe';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { SaleActiveConfirmComponent } from '../sale-active-confirm/sale-active-confirm.component';
import { SaleCompleteFormComponent } from '../sale-complete-form/sale-complete-form.component';
import { SaleItem } from '../shared/sale-item.model';
import { Sale } from '../shared/sale.model';
import { SaleService } from '../shared/sale.service';

@Component({
  selector: 'app-sale-detail',
  templateUrl: './sale-detail.component.html',
  styleUrls: ['./sale-detail.component.scss']
})
export class SaleDetailComponent implements OnDestroy, OnInit {
  public pageName = 'Satış Detayı';
  public sale: Sale;
  public selectedCount = 0;
  public selectedTab = 1;
  public productGroupIds: Map<number, number[]> = new Map<number, number[]>();
  public productGroups: Map<number, ProductGroup> = new Map<number, ProductGroup>();
  public headerSelectionList: Map<number, any[]> = new Map<number, any[]>();
  public saleItemStatus = Globals.saleItemStatus;

  private formatDatePipe = new FormatDatePipe();
  private measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  private groupedSaleItems: Map<string, SaleItem[]>;
  private pageItemLimit: number;
  private retrievedSale: Sale;
  private selectedId: number;
  private unsubscribe: Subject<void> = new Subject();

  private readonly fontHeight = 9;
  private readonly headerFontHeight = 12;
  private readonly lineHeight = this.fontHeight / 2;
  private readonly defaultPageItemLimit = 15;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly productGroupService: ProductGroupService,
    private readonly router: Router,
    private readonly saleService: SaleService
  ) {}

  public ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      if (url[url.length - 1].path === 'detail') {
        this.activatedRoute.params.subscribe((params) => {
          this.selectedId = parseInt(params.id, 10) || -1;

          if (this.selectedId > 0) {
            this.getSale(this.selectedId);
          }
        });
      }
    });
  }

  public onChangeHeaderCheckbox(status: number, index: number): void {
    this.sale.saleItems.forEach((item) => {
      if (
        item.status === status &&
        item.purchaseItem.product.productGroupId ===
          this.headerSelectionList.get(status)[index].groupId
      ) {
        item.selected = this.headerSelectionList.get(status)[index].selected;
      }
    });

    this.calculateSelectedItem();
  }

  public onChangeItemCheckbox(item: SaleItem, index: number): void {
    let isAllSelectedForGroup = true;

    for (const saleItem of this.sale.saleItems) {
      if (
        saleItem.purchaseItem.product.productGroupId === item.purchaseItem.product.productGroupId &&
        saleItem.status === item.status &&
        !saleItem.selected
      ) {
        isAllSelectedForGroup = false;
      }
    }

    this.headerSelectionList.get(item.status)[index].selected = isAllSelectedForGroup;

    this.calculateSelectedItem();
  }

  public clearSelection(tab: number): void {
    if (tab !== this.selectedTab && this.sale) {
      this.selectedTab = tab;

      this.sale.saleItems.forEach((item) => {
        item.selected = false;
      });

      for (let s = 1; s <= 3; s++) {
        if (this.headerSelectionList.get(s)) {
          this.headerSelectionList.get(s).forEach((item) => {
            item.selected = false;
          });
        }
      }

      this.calculateSelectedItem();
    }
  }

  public calculateSelectedItem(): void {
    this.selectedCount = this.sale.saleItems.filter((x) => x.selected).length;
  }

  public changeItemStatus(step: number): void {
    const confirmModal = this.modalService.open(SaleActiveConfirmComponent);
    confirmModal.componentInstance.saleId = this.sale.saleId;
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message = `Seçtiğiniz ürünlerin durum bilgisi '${
      this.saleItemStatus[this.selectedTab + step]
    }' olarak güncellenecektir. Onaylıyor musunuz?`;

    confirmModal.result.then(
      (res) => {
        const saleInsert: Sale = { ...this.sale };
        const saleItemsToAdd: SaleItem[] = [];

        for (let i = saleInsert.saleItems.length - 1; i >= 0; --i) {
          const item = saleInsert.saleItems[i];

          if (!this.productGroups.get(item.purchaseItem.product.productGroupId).areProductsUnique) {
            const itemsToAdd = [...this.groupedSaleItems.get(this.getGroupingKey(item))];

            if (item.selected) {
              itemsToAdd.forEach((itemToAdd) => {
                itemToAdd.status += step;
              });
            }

            saleItemsToAdd.push(...[...itemsToAdd]);
            saleInsert.saleItems.splice(i, 1);
          } else {
            if (item.selected) {
              item.status += step;
            }
          }
        }

        saleInsert.saleItems.push(...saleItemsToAdd);

        this.saleService
          .update(saleInsert.saleId, saleInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((saleResult) => {
            this.selectedCount = 0;
            this.alertService.setAlert(
              AlertType.success,
              `Seçtiğiniz ürünlerin durum bilgisi '${
                this.saleItemStatus[this.selectedTab + step]
              }' olarak güncellendi.`
            );
            this.getSale(saleInsert.saleId);
          });
      },
      (err) => {}
    );
  }

  public cancelItem(): void {
    const confirmModal = this.modalService.open(SaleActiveConfirmComponent);
    confirmModal.componentInstance.saleId = this.sale.saleId;
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message =
      'Seçtiğiniz ürünlerin satış işlemi iptal edilecektir. Onaylıyor musunuz?';

    confirmModal.result.then(
      (res) => {
        const saleInsert: Sale = { ...this.sale };
        const saleItemsToAdd: SaleItem[] = [];

        for (let i = saleInsert.saleItems.length - 1; i >= 0; --i) {
          const item = saleInsert.saleItems[i];

          if (!this.productGroups.get(item.purchaseItem.product.productGroupId).areProductsUnique) {
            const itemsToAdd = [...this.groupedSaleItems.get(this.getGroupingKey(item))];

            itemsToAdd.forEach((itemToAdd) => {
              itemToAdd.selected = item.selected;
            });

            saleItemsToAdd.push(...[...itemsToAdd]);
            saleInsert.saleItems.splice(i, 1);
          }
        }

        saleInsert.saleItems.push(...saleItemsToAdd);

        saleInsert.saleItems = saleInsert.saleItems.filter((n) => !n.selected);

        this.saleService
          .update(saleInsert.saleId, saleInsert)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((saleResult) => {
            this.selectedCount = 0;

            if (saleInsert.saleItems.length > 0) {
              this.alertService.setAlert(
                AlertType.success,
                'Seçtiğiniz ürünlerin satış işlemi iptal edildi.'
              );
              this.getSale(saleInsert.saleId);
            } else {
              this.alertService.setNextAlert(
                AlertType.success,
                'Seçtiğiniz satış işlemi iptal edildi.'
              );
              this.router.navigate(['/sales']);
            }
          });
      },
      (err) => {}
    );
  }

  public goToSaleEdit() {
    this.router.navigate(['/sales/' + this.sale.saleId + '/edit']);
  }

  public saveWarehouseCheckoutForm() {
    const doc = new JsPdf();

    doc.setFont('Verdana', 'normal');

    let currentItemCount = 0;

    while (currentItemCount < this.sale.saleItems.length) {
      if (currentItemCount >= this.pageItemLimit) {
        doc.addPage();
      }

      const nextLineY = this.lineHeight + 10;

      currentItemCount = this.prepareWarehouseCheckoutForm(doc, nextLineY, 2, currentItemCount);
    }

    doc.save(`depo-cikis-formu-${this.sale.saleId}.pdf`);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private prepareWarehouseCheckoutForm(
    doc: JsPdf,
    nextLineY: number,
    replicaIndex: number,
    initialCurrentItemCount: number
  ): number {
    doc.setFontSize(this.headerFontHeight);

    doc.text(
      'Depo Çıkış Formu',
      doc.internal.pageSize.getWidth() / 2,
      nextLineY,
      null,
      null,
      'center'
    );

    doc.setFontSize(this.fontHeight);
    nextLineY += this.headerFontHeight / 2;

    doc.text('Müşteri:', 10, nextLineY);
    doc.text(this.sale.customer.title, 60, nextLineY);

    nextLineY += this.lineHeight;

    doc.text('Satış Yapan Kullanıcı:', 10, nextLineY);
    doc.text(`${this.sale.user.firstName} ${this.sale.user.lastName}`, 60, nextLineY);

    nextLineY += this.lineHeight;

    doc.text('Satış Tarihi:', 10, nextLineY);
    doc.text(this.formatDatePipe.transform(this.sale.saleTime), 60, nextLineY);

    nextLineY += this.lineHeight;

    doc.text('Gönderim Tarihi:', 10, nextLineY);
    doc.text(this.formatDatePipe.transform(this.sale.deliveryTime), 60, nextLineY);

    nextLineY += this.lineHeight;

    doc.text('Müşterinin Sipariş Kodu:', 10, nextLineY);
    doc.text(this.sale.externalSaleCode || '', 60, nextLineY);

    nextLineY += this.lineHeight;

    doc.text('Açıklama:', 10, nextLineY);

    const explanationLines: string[] = doc.splitTextToSize(
      this.sale.explanation,
      doc.internal.pageSize.getWidth() - 60
    );

    explanationLines.forEach((line) => {
      doc.text(line, 60, nextLineY);
      nextLineY += this.lineHeight;
    });

    this.pageItemLimit = this.defaultPageItemLimit - explanationLines.length + 1;

    nextLineY += this.lineHeight;

    doc.text('Ürün Adı', 10, nextLineY);
    doc.text('Ürün Kimliği', 125, nextLineY);
    doc.text('Ağırlık', 160, nextLineY);
    doc.text('Miktar', 185, nextLineY);

    nextLineY += this.lineHeight;
    let currentItemCount = initialCurrentItemCount;

    for (let i = initialCurrentItemCount; i < this.sale.saleItems.length; ++i) {
      const saleItem = this.sale.saleItems[i];

      doc.text(saleItem.purchaseItem.product.name, 10, nextLineY);

      if (this.productGroups.get(saleItem.purchaseItem.product.productGroupId).areProductsUnique) {
        doc.text(saleItem.purchaseItem.purchaseItemId.toString(), 125, nextLineY);
      }

      doc.text(
        `${(saleItem.saleCount * saleItem.purchaseItem.product.unitMass).toFixed(3)} ${
          saleItem.purchaseItem.product.unitOfMass
        }`,
        160,
        nextLineY
      );
      doc.text(
        `${saleItem.saleCount} ${this.measurementUnitSymbolPipe.transform(
          this.productGroups.get(saleItem.purchaseItem.product.productGroupId).unit,
          this.productGroups.get(saleItem.purchaseItem.product.productGroupId).unitGroup
        )}`,
        185,
        nextLineY
      );

      nextLineY += this.lineHeight;
      ++currentItemCount;

      if (
        saleItem.purchaseItem.invoiceTime ||
        saleItem.purchaseItem.invoiceNumber ||
        saleItem.purchaseItem.customsDeclarationTime ||
        saleItem.purchaseItem.customsDeclarationNumber
      ) {
        doc.text(
          `(Fatura: ${this.formatDatePipe.transform(saleItem.purchaseItem.invoiceTime)} - ${
            saleItem.purchaseItem.invoiceNumber
          }, Gümrük Beyannamesi: ${this.formatDatePipe.transform(
            saleItem.purchaseItem.customsDeclarationTime
          )} - ${saleItem.purchaseItem.customsDeclarationNumber})`,
          10,
          nextLineY
        );

        nextLineY += this.lineHeight;
      }

      nextLineY += this.lineHeight;

      if (nextLineY > 254) {
        break;
      }
    }

    this.addFinalCheckSection(replicaIndex, doc);

    return currentItemCount;
  }

  private addFinalCheckSection(replicaIndex: number, doc: JsPdf) {
    const finalCheckSectionX = 10;
    const finalCheckSectionY =
      ((replicaIndex - 1) * doc.internal.pageSize.getHeight()) / 2 +
      doc.internal.pageSize.getHeight() / 2 -
      35;
    const finalCheckSectionWidth = doc.internal.pageSize.getWidth() - 20;
    const finalCheckSectionHeight = 25;
    doc.rect(
      finalCheckSectionX,
      finalCheckSectionY,
      finalCheckSectionWidth,
      finalCheckSectionHeight
    );

    const finalCheckSectionHeaderY = finalCheckSectionY + this.lineHeight - 1;
    doc.text(
      'Sevkiyat Son Kontrol',
      doc.internal.pageSize.getWidth() / 2,
      finalCheckSectionHeaderY,
      null,
      null,
      'center'
    );

    const finalCheckSectionFirstLineY = finalCheckSectionHeaderY + this.lineHeight - 3;
    doc.line(
      finalCheckSectionX,
      finalCheckSectionFirstLineY,
      doc.internal.pageSize.getWidth() - finalCheckSectionX,
      finalCheckSectionHeaderY + this.lineHeight - 3
    );

    const finalCheckSectionSecondRowTextY = finalCheckSectionFirstLineY + this.lineHeight - 1;
    const finalCheckSectionSecondRowText = [
      'Miktar',
      'Sağlamlık',
      'Ölçü',
      'Ürün Sertifikası',
      'İrsaliye Bilgileri',
      'Uygun',
      'Değil'
    ];
    const checkboxSizeLength = 5;
    const finalCheckSectionSecondRowCheckboxY = finalCheckSectionSecondRowTextY + 2;

    for (let i = 0; i < finalCheckSectionSecondRowText.length; ++i) {
      const textCenterX = finalCheckSectionX + (finalCheckSectionWidth / 14) * (i * 2 + 1);
      doc.text(
        finalCheckSectionSecondRowText[i],
        textCenterX,
        finalCheckSectionSecondRowTextY,
        null,
        null,
        'center'
      );
      doc.rect(
        textCenterX - checkboxSizeLength / 2,
        finalCheckSectionSecondRowCheckboxY,
        checkboxSizeLength,
        checkboxSizeLength
      );
    }

    const finalCheckSectionSecondLineY =
      finalCheckSectionSecondRowCheckboxY + checkboxSizeLength + 1;
    doc.line(
      finalCheckSectionX,
      finalCheckSectionSecondLineY,
      doc.internal.pageSize.getWidth() - finalCheckSectionX,
      finalCheckSectionSecondLineY
    );

    for (let i = 1; i < finalCheckSectionSecondRowText.length - 1; ++i) {
      doc.line(
        finalCheckSectionX + (finalCheckSectionWidth / 7) * i,
        finalCheckSectionFirstLineY,
        finalCheckSectionX + (finalCheckSectionWidth / 7) * i,
        finalCheckSectionSecondLineY
      );
    }

    doc.text(
      'Kontrol Tarihi: ...../...../..........',
      finalCheckSectionX + 1,
      finalCheckSectionSecondLineY + this.lineHeight + 1
    );
    doc.text(
      'Kontrol Eden:',
      finalCheckSectionX + (finalCheckSectionWidth / 7) * 2 + 1,
      finalCheckSectionSecondLineY + this.lineHeight + 1
    );

    doc.line(
      finalCheckSectionX + (finalCheckSectionWidth / 7) * 2,
      finalCheckSectionSecondLineY,
      finalCheckSectionX + (finalCheckSectionWidth / 7) * 2,
      finalCheckSectionY + finalCheckSectionHeight
    );
  }

  private getSale(id: number) {
    this.saleService
      .get(id)
      .pipe(
        tap((res) => {}),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.retrievedSale = res;
        this.getProductGroups();
      });
  }

  private getProductGroup(id: number) {
    if (!this.productGroups.get(id)) {
      this.productGroupService
        .get(id)
        .pipe(
          tap((res) => {}),
          share(),
          takeUntil(this.unsubscribe)
        )
        .subscribe((res) => {
          this.productGroups.set(id, res);

          let areAllProductGroupsLoaded = true;

          this.productGroupIds.forEach((p) => {
            p.forEach((i) => {
              if (!this.productGroups.get(i)) {
                areAllProductGroupsLoaded = false;
              }
            });
          });

          if (areAllProductGroupsLoaded) {
            this.groupSaleItems();
          }
        });
    }
  }

  private getProductGroups() {
    this.productGroupIds = new Map<number, number[]>();
    this.productGroups = new Map<number, ProductGroup>();
    this.headerSelectionList = new Map<number, any[]>();

    if (
      this.retrievedSale &&
      this.retrievedSale.saleItems &&
      this.retrievedSale.saleItems.length > 0
    ) {
      for (const item of this.retrievedSale.saleItems) {
        if (item.status > 0) {
          this.addProductGroupId(item.status, item.purchaseItem.product.productGroupId);
        }
      }

      this.productGroupIds.forEach(
        (groupIdList: number[], status: number, map: Map<number, number[]>): void => {
          for (const id of groupIdList) {
            this.getProductGroup(id);
          }
        }
      );
    }
  }

  private addProductGroupId(status: number, groupId: number): void {
    if (!this.productGroupIds.get(status)) {
      this.productGroupIds.set(status, [groupId]);
      this.headerSelectionList.set(status, [{ groupId, selected: false }]);
    } else if (this.productGroupIds.get(status).indexOf(groupId) < 0) {
      this.productGroupIds.get(status).push(groupId);
      this.headerSelectionList.get(status).push({ groupId, selected: false });
    }
  }

  private groupSaleItems() {
    this.groupedSaleItems = new Map<string, SaleItem[]>();

    this.retrievedSale.saleItems.forEach((i) => {
      const itemKey = this.getGroupingKey(i);

      if (!this.groupedSaleItems.get(itemKey)) {
        this.groupedSaleItems.set(itemKey, []);
      }

      this.groupedSaleItems.get(itemKey).push({ ...i });
    });

    this.retrievedSale.saleItems.splice(0, this.retrievedSale.saleItems.length);

    this.groupedSaleItems.forEach((saleItemGroup) => {
      const areProductsUnique = this.productGroups.get(
        saleItemGroup[0].purchaseItem.product.productGroupId
      ).areProductsUnique;
      let totalItemCount = 0;

      if (!areProductsUnique) {
        saleItemGroup.forEach((saleItem) => {
          totalItemCount += saleItem.saleCount;
        });

        this.retrievedSale.saleItems.push({ ...saleItemGroup[0] });
        this.retrievedSale.saleItems[
          this.retrievedSale.saleItems.length - 1
        ].saleCount = totalItemCount;
      } else {
        this.retrievedSale.saleItems.push(...[...saleItemGroup]);
      }
    });

    this.sale = this.retrievedSale;
    this.changeDetectorRef.detectChanges();
  }

  private getGroupingKey(item: SaleItem): string {
    return `${item.purchaseItem.productId}-${item.deliveryTime}-${item.price}-${item.currencyCode}-${item.status}`;
  }
}
