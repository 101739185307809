import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DipendoModalFormComponent } from '../../../shared/forms/dipendo-modal-form/dipendo-modal-form.component';
import { InputBase } from '../../../shared/forms/input-base';
import { InputCheckbox } from '../../../shared/forms/input-checkbox';
import { InputDropdown } from '../../../shared/forms/input-dropdown';
import { InputTextarea } from '../../../shared/forms/input-textarea';
import { InputTextbox } from '../../../shared/forms/input-textbox';
import { ProductGroupProperty } from '../shared/product-group-property.model';

@Component({
  selector: 'app-product-group-property-edit',
  templateUrl: './product-group-property-edit.component.html',
  styleUrls: ['./product-group-property-edit.component.scss']
})
export class ProductGroupPropertyEditComponent implements OnInit {
  public productGroupProperty: ProductGroupProperty;
  public isEdit = false;
  public inputs: any[];

  @ViewChild(DipendoModalFormComponent, { static: true })
  private readonly formComponent: DipendoModalFormComponent;

  constructor(public readonly activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.inputs = this.getInputs();
  }

  public onFormInit(): void {
    this.fillForm();
    this.formComponent.inputs
      .find((i) => i.key === 'propertyType')
      .onChange(this.productGroupProperty.propertyType);
  }

  public propertyTypeOnChange(value: string) {
    ((this['pageReference'].inputs as Array<InputBase<any>>).find(
      (i) => i.key === 'propertyValues'
    ) as InputTextarea).visible = value === '2' || parseInt(value, 10) === 2;
  }

  public fillForm() {
    if (this.productGroupProperty && this.formComponent.form) {
      this.formComponent.setValues(this.productGroupProperty);
    }
  }

  public onSubmit(formComponent: DipendoModalFormComponent) {
    this.activeModal.close(this.prepareBindingObject(formComponent));
  }

  private getInputs(): Array<InputBase<any>> {
    const inputs: Array<InputBase<any>> = [
      new InputTextbox({
        key: 'name',
        label: 'Ad',
        type: 'text',
        value: '',
        required: true,
        order: 1
      }),
      new InputCheckbox({
        key: 'isRequired',
        label: 'Zorunlu',
        checked: false,
        order: 2
      }),
      new InputCheckbox({
        key: 'isStockGroupingProperty',
        label: 'Stok Gruplama Özelliği',
        checked: false,
        order: 3
      }),
      new InputDropdown({
        key: 'propertyType',
        label: 'Giriş Tipi',
        onChange: this.propertyTypeOnChange,
        pageReference: this,
        options: [{ key: '1', value: 'Serbest Değer' }, { key: '2', value: 'Çoktan Seçmeli' }],
        required: true,
        showSelect: true,
        value: '',
        order: 4
      }),
      new InputTextarea({
        key: 'propertyValues',
        label: 'Değerler',
        rowCount: 3,
        value: '',
        visible: false,
        order: 5
      })
    ];

    return inputs.sort((a, b) => a.order - b.order);
  }

  private prepareBindingObject(formComponent: DipendoModalFormComponent): ProductGroupProperty {
    return {
      productGroupPropertyId: this.isEdit ? this.productGroupProperty.productGroupPropertyId : 0,
      name: formComponent.form.value.name,
      isRequired: formComponent.form.value.isRequired || false,
      isStockGroupingProperty: formComponent.form.value.isStockGroupingProperty || false,
      propertyType: formComponent.form.value.propertyType,
      propertyValues: formComponent.form.value.propertyValues,
      order: this.isEdit ? this.productGroupProperty.order : 0
    };
  }
}
