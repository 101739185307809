<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
    <small>{{ selectedSupplierTitle }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="box box-primary">
    <dipendo-form [boxTitle]="'Genel Bilgiler'" [inputs]="inputs" [isFooterVisible]="false">
      <div class="box-header with-border">
        <h3 class="box-title">
          Satın Alma Kalemleri
        </h3>

        <div class="box-tools">
          <button type="button" class="btn btn-sm btn-success" (click)="openProductsDialog()">
            Ürün Ekle
          </button>

          <button
            type="button"
            class="btn btn-sm alert-danger delete-button"
            *ngIf="purchase.purchaseId > 0"
            (click)="deletePurchase()"
          >
            Sepeti Sil
          </button>
        </div>
      </div>
      <!-- /.box-header -->
      <div class="box-body table-responsive">
        <ng-container *ngFor="let productGroupId of productGroupIds; let i = index">
          <table
            class="table table-bordered table-striped"
            *ngIf="productGroups.has(productGroupId)"
          >
            <thead>
              <tr>
                <th style="width: 10px;">
                  <input
                    type="checkbox"
                    autocomplete="off"
                    [(ngModel)]="headerSelectionList[i].selected"
                    (change)="onChangeHeaderCheckbox(i)"
                  />
                </th>
                <th>Ürün Adı</th>
                <th>Birim Kütle</th>
                <th>Adet</th>
                <th>Miktar</th>
                <th>T.Miktar</th>
                <th>T.Ağırlık</th>
                <th>Alış Fiyatı</th>
                <th>Toplam Fiyat</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let purchaseItem of purchase.purchaseItems | matchProductGroup: productGroupId
                "
              >
                <td>
                  <input
                    type="checkbox"
                    autocomplete="off"
                    [(ngModel)]="purchaseItem.selected"
                    (change)="onChangeItemCheckbox(purchaseItem, i)"
                  />
                </td>
                <td>{{ purchaseItem.product.name }}</td>
                <td>
                  {{ purchaseItem.product.unitMass }} {{ purchaseItem.product.unitOfMass }}/{{
                    productGroups.get(productGroupId).unit
                      | measurementUnitSymbol: productGroups.get(productGroupId).unitGroup
                  }}
                </td>
                <td>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    required
                    autocomplete="off"
                    class="form-control input-sm input-number-validatable"
                    [(ngModel)]="purchaseItem.count"
                    *ngIf="productGroups.get(productGroupId).unitGroup !== 'number'"
                    (blur)="onInputBlur(purchaseItem)"
                    style="min-width:100px"
                  />
                </td>
                <td>
                  <div class="input-group">
                    <input
                      type="number"
                      step="0.001"
                      min="0"
                      autocomplete="off"
                      class="form-control input-sm input-number-validatable"
                      [(ngModel)]="purchaseItem.purchaseCount"
                      (blur)="onInputBlur(purchaseItem)"
                      style="min-width:100px"
                    /><span
                      class="input-group-addon"
                      [title]="
                        productGroups.get(productGroupId).unit
                          | measurementUnitSymbol: productGroups.get(productGroupId).unitGroup
                      "
                      >{{
                        productGroups.get(productGroupId).unit
                          | measurementUnitSymbol: productGroups.get(productGroupId).unitGroup
                      }}</span
                    >
                  </div>
                </td>
                <td class="text-right">
                  {{ purchaseItem.totalCount }}
                  {{
                    productGroups.get(productGroupId).unit
                      | measurementUnitSymbol: productGroups.get(productGroupId).unitGroup
                  }}
                </td>
                <td class="text-right">
                  {{ purchaseItem.totalMass | number: '1.0-3' }}
                  {{ purchaseItem.product.unitOfMass }}
                </td>
                <td>
                  <div class="input-group">
                    <input
                      type="number"
                      step="0.0001"
                      min="0"
                      required
                      autocomplete="off"
                      class="form-control input-sm input-number-validatable"
                      [(ngModel)]="purchaseItem.cost"
                      (blur)="onInputBlur(purchaseItem)"
                      style="min-width:100px"
                    /><span class="input-group-addon">{{ purchaseItem.product.currencyCode }}</span>
                  </div>
                </td>
                <th class="text-right">
                  {{ purchaseItem.totalPrice | number: '1.2-4' }}
                  {{ purchaseItem.product.currencyCode }}
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="6" class="text-right">TOPLAM</th>
                <th class="text-right">{{ totalMass.get(productGroupId) | number: '1.0-3' }} kg</th>
                <th></th>
                <th class="text-right">
                  <span *ngFor="let price of totalPriceList.get(productGroupId)"
                    >{{ price }} <br
                  /></span>
                </th>
              </tr>
            </tfoot>
          </table>
          <br />
        </ng-container>
        <table *ngIf="productGroupIds.length > 1" class="table table-bordered table-striped">
          <tfoot>
            <tr>
              <th class="text-right">GENEL TOPLAM</th>
              <th class="text-right nostretch">{{ grandTotalMass | number: '1.0-3' }} kg</th>
              <th class="text-right nostretch">
                <span *ngFor="let price of grandTotalPriceList">{{ price }} <br /></span>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="box-body table-responsive" *ngIf="totalCount === 0">
        <p class="text-muted">
          Sepete henüz hiç ürün eklenmemiş. Devam etmek için lütfen ürün ekleyiniz.
        </p>
      </div>

      <div class="box-footer" *ngIf="purchase.purchaseItems?.length">
        <div class="col-md-3"></div>
        <div class="col-md-2">
          <button
            type="submit"
            name="submit"
            id="submit"
            class="btn btn-sm alert-danger"
            *ngIf="selectedCount > 0"
            (click)="deleteSelectedItems()"
          >
            Seçilenleri Sil
          </button>
        </div>
        <div class="col-md-2">
          <button
            type="submit"
            name="submit"
            id="submit"
            class="btn btn-primary"
            [disabled]="isSaving"
            (click)="save(2)"
          >
            Sepeti Kaydet
          </button>
        </div>
        <div class="col-md-2">
          <button
            type="submit"
            name="submit"
            id="submit"
            class="btn btn-success"
            [disabled]="isSaving"
            (click)="save(3)"
          >
            Tamamla
          </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </dipendo-form>
  </div>
</section>
