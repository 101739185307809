<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
    <small>{{ selectedProductName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<!-- Main content -->
<section class="content">
  <div class="row" *ngIf="asyncPurchaseItem | async as purchaseItem">
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Kimlik</h3>
        </div>
        <div class="box-body">
          <dl class="dl-horizontal">
            <dt>Nesne Kimliği</dt>
            <dd>{{ purchaseItem.purchaseItemId }}</dd>
          </dl>
        </div>
      </div>
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Özellikler</h3>
        </div>
        <div class="box-body">
          <dl class="dl-horizontal">
            <ng-container *ngFor="let property of purchaseItem.product.propertyValues">
              <dt>{{ property.propertyName }}</dt>
              <dd>{{ property.value }}</dd>
            </ng-container>
          </dl>
        </div>
      </div>
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Nicelik</h3>

          <div class="box-tools">
            <ng-container *ngIf="!isEditing">
              <button
                *appIfAuthorizedTo="'EditPurchaseItemsPurchaseCount'"
                type="button"
                class="btn btn-sm btn-success"
                (click)="editPurchaseAmount()"
              >
                Miktar Düzenle
              </button>
            </ng-container>
            <ng-container *ngIf="isEditing">
              <form #purchaseCountUpdateForm="ngForm" class="form-horizontal form-inline">
                <div class="input-group" style="max-width: 100px">
                  <input
                    id="purchaseCountInput"
                    name="purchaseCountInput"
                    type="number"
                    step="0.001"
                    min="0"
                    autocomplete="off"
                    class="form-control input-sm input-number-validatable"
                    required
                    [(ngModel)]="purchaseCount"
                    style="min-width:100px"
                    #purchaseCountInput="ngModel"
                  />
                  <div class="input-group-btn">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      [disabled]="!purchaseCountInput.valid"
                      (click)="updatePurchaseAmount()"
                    >
                      Miktarı Kaydet
                    </button>
                    <button type="button" class="btn btn-sm" (click)="isEditing = false">
                      İptal
                    </button>
                  </div>
                </div>
              </form>
            </ng-container>
          </div>
        </div>
        <div class="box-body">
          <dl class="dl-horizontal">
            <dt>Stok Miktarı</dt>
            <dd>
              {{
                purchaseItem.stockCount +
                  ' ' +
                  (purchaseItem.product.groupUnit
                    | measurementUnitSymbol: purchaseItem.product.groupUnitGroup)
              }}
            </dd>
            <dt>Satın Alınan Miktar</dt>
            <dd>
              {{
                purchaseItem.purchaseCount +
                  ' ' +
                  (purchaseItem.product.groupUnit
                    | measurementUnitSymbol: purchaseItem.product.groupUnitGroup)
              }}
            </dd>
            <dt>Birim Ağırlık</dt>
            <dd>
              {{
                purchaseItem.product.unitMass === 0
                  ? '-'
                  : purchaseItem.product.unitMass + ' ' + purchaseItem.product.unitOfMass
              }}
            </dd>
            <dt>Stok Toplam Ağırlığı</dt>
            <dd>
              {{
                purchaseItem.product.unitMass === 0
                  ? '-'
                  : purchaseItem.product.unitMass * purchaseItem.stockCount +
                    ' ' +
                    purchaseItem.product.unitOfMass
              }}
            </dd>
            <dt>Satın Alınan Toplam Ağırlık</dt>
            <dd>
              {{
                purchaseItem.product.unitMass === 0
                  ? '-'
                  : purchaseItem.product.unitMass * purchaseItem.purchaseCount +
                    ' ' +
                    purchaseItem.product.unitOfMass
              }}
            </dd>
          </dl>
        </div>
      </div>
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Fiyat</h3>
        </div>
        <div class="box-body">
          <dl class="dl-horizontal">
            <dt>Liste Fiyatı</dt>
            <dd>
              {{
                (purchaseItem.product.listPrice | number: '1.2-4') +
                  ' ' +
                  purchaseItem.product.currencyCode
              }}
            </dd>
          </dl>
        </div>
      </div>
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Yorumlar</h3>
        </div>
        <div class="box-body chat">
          <p class="text-muted" *ngIf="purchaseItem.comments.length == 0">
            Bu eşya için herhangi bir yorum yapılmamış.
          </p>
          <ng-container *ngIf="purchaseItem.comments.length > 0">
            <div class="item" *ngFor="let comment of purchaseItem.comments; let i = index">
              <img src="/assets/img/avatar.png" alt="" />
              <p
                [ngClass]="
                  'message' + (i == purchaseItem.comments.length - 1 ? ' last-message' : '')
                "
              >
                <a href="javascript:void(0);" class="name">
                  <small class="text-muted pull-right"
                    ><i class="fa fa-clock-o"></i>
                    {{
                      comment.recordTime
                        | amFromUtc
                        | amLocal
                        | amLocale: 'tr'
                        | amDateFormat: 'LLL'
                        | ignoreInvalidDate
                    }}</small
                  >
                  {{ comment.user.firstName + ' ' + comment.user.lastName }}
                </a>
                {{ comment.content }}
              </p>
            </div>
          </ng-container>
        </div>
        <div class="box-footer">
          <div class="input-group">
            <input
              name="commentContent"
              class="form-control"
              id="commentContent"
              [(ngModel)]="commentContent"
              autocomplete="off"
              placeholder="Yorumunuzu yazın..."
            />
            <div class="input-group-btn">
              <button type="button" class="btn btn-success" (click)="addComment()">
                Yorum Ekle
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Hareketler</h3>
        </div>
        <div class="box-body chat">
          <p class="text-muted" *ngIf="purchaseItem.activities.length == 0">
            Bu eşya için herhangi bir hareket kaydı bulunmuyor.
          </p>
          <ng-container *ngIf="purchaseItem.activities.length > 0">
            <div class="item" *ngFor="let activity of purchaseItem.activities; let i = index">
              <img src="/assets/img/avatar.png" alt="" />
              <p
                [ngClass]="
                  'message' + (i == purchaseItem.activities.length - 1 ? ' last-message' : '')
                "
              >
                <a href="javascript:void(0);" class="name">
                  <small class="text-muted pull-right"
                    ><i class="fa fa-clock-o"></i>
                    {{
                      activity.recordTime
                        | amFromUtc
                        | amLocal
                        | amLocale: 'tr'
                        | amDateFormat: 'LLL'
                        | ignoreInvalidDate
                    }}</small
                  >
                  {{ activity.user.firstName + ' ' + activity.user.lastName }}
                </a>
                {{ activity.content }}
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Karekod</h3>
        </div>
        <div class="box-body">
          <img
            class="centered-block"
            alt="{{ purchaseItem.product.name }}"
            src="{{ asyncQrCode }}"
          />
        </div>
        <div class="box-footer">
          <button type="button" class="btn btn-sm btn-success pull-right" (click)="saveQrCode()">
            Karekod Oluştur
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Dosyalar</h3>
        </div>
        <div class="box-body">
          <div class="col-md-12">
            <textarea
              class="form-control"
              rows="3"
              [(ngModel)]="fileDescription"
              name="fileDescription"
              maxlength="250"
              placeholder="{{ fileDescriptionPlaceholder }}"
            ></textarea>
          </div>

          <div *ngIf="isLoaded" class="col-md-12">
            <app-upload-file
              (change)="fileUpload($event)"
              (fileDownload)="showFile($event)"
              [fileList]="purchaseFiles"
              (fileSave)="saveFiles($event)"
            ></app-upload-file>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
