import { Pipe, PipeTransform } from '@angular/core';
import { PurchaseItem } from '../../purchases/shared/purchase-item.model';

@Pipe({
  name: 'matchPurchaseItemStatus'
})
export class MatchPurchaseItemStatusPipe implements PipeTransform {
  transform(items: Array<PurchaseItem>, purchaseItemStatus: number): Array<PurchaseItem> {
    return items.filter(item => item.status === purchaseItemStatus);
  }
}
