import { PurchaseItem } from './purchase-item.model';

export class PurchaseItemResult {
  totalCount: number;
  offset: number;
  limit: number;
  query: string;
  purchases: PurchaseItem[];

  constructor(totalCount: number, offset: number, limit: number, query: string, purchases: PurchaseItem[]) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.query = query;
    this.purchases = purchases;
  }
}
