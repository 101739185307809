import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import * as JsPdf from 'jspdf';
import * as moment from 'moment';
import * as QrCode from 'qrcode';
import { Observable, Subject } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { ProductListItem } from '../../products/shared/product-list-item.model';
import { Product } from '../../products/shared/product.model';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { UploadFileComponent } from '../../shared/upload-file/upload-file.component';
import { PurchaseItemService } from '../../warehouse/shared/purchase-item.service';
import { PurchaseItemFile } from '../shared/purchase-item-file.model';
import { PurchaseItem } from '../shared/purchase-item.model';
import { PurchaseItemDetailService } from '../shared/purchase-item.service';
import { PurchaseService } from '../shared/purchase.service';

@Component({
  selector: 'app-purchase-item-detail',
  templateUrl: './purchase-item-detail.component.html',
  styleUrls: ['./purchase-item-detail.component.scss']
})
export class PurchaseItemDetailComponent implements OnDestroy, OnInit {
  public errorType = '';
  public isEditing = false;
  public pageName = 'Eşya Detayları';
  public product: Product = null;
  public asyncProduct: Observable<Product>;
  public asyncPurchaseItem: Observable<PurchaseItem>;
  public asyncComments: Observable<any>;
  public asyncQrCode = '';
  public selectedProductGroupId = 0;
  public commentContent = '';
  public purchaseItemId = 0;
  public productGroup: ProductGroup;
  public asyncInputs: Observable<any>;
  public selectedProductName = '';
  public moment = moment;
  public products: ProductListItem[] = [];
  public selectedPurchaseItem: PurchaseItem;
  public measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  public productGroups: Map<number, ProductGroup> = new Map<number, ProductGroup>();
  public purchaseCount = 0;
  public productId = 0;
  public productName = '';
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public inputs: any[] = [];
  public fileDescription = '';
  public fileDescriptionPlaceholder = 'Buraya dosya açıklamasını girin.';
  public purchaseFiles: PurchaseItemFile[] = [];
  public isLoaded = false;
  public deletedFileIndex: number = -1;
  @ViewChild(UploadFileComponent)
  public uploadFileComponent: UploadFileComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly appComponent: AppComponent,
    private readonly purchaseItemService: PurchaseItemService,
    private readonly purchaseItemDetailService: PurchaseItemDetailService,
    private readonly productGroupService: ProductGroupService,
    private readonly purchaseService: PurchaseService,
    private readonly router: Router,
    private readonly modalService: NgbModal
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
    this.activatedRoute.queryParams.subscribe((activatedRouteQueryParams: Params) => {
      this.selectedProductGroupId = parseInt(activatedRouteQueryParams['groupId'], 10) || 0;
      this.productId = parseInt(activatedRouteQueryParams['productId'], 10) || 0;
      this.activatedRoute.url.subscribe((url) => {
        this.activatedRoute.params.subscribe((params) => {
          this.purchaseItemId = parseInt(params.id, 10) || -1;
          this.generateQrCode();
          this.getPurchaseItem();
        });
      });
    });
  }

  public getPurchaseItem() {
    this.asyncPurchaseItem = this.purchaseItemDetailService.get(this.purchaseItemId).pipe(
      tap(
        (res) => {
          this.selectedPurchaseItem = res;
          this.purchaseFiles = res.files != null ? res.files : [];
          this.isLoaded = true;
          this.product = res.product;
          this.getProductGroup(this.product['groupId']);
          this.selectedProductName = this.product.name;
          this.purchaseCount = res.purchaseCount;
        },
        (err) => {
          this.router.navigate(['/products']);
        }
      ),
      map((res) => {
        return res;
      })
    );
  }

  public changePage(page: number) {
    this.router.navigate(
      ['/products'],
      page > 1
        ? { queryParams: { groupId: this.selectedProductGroupId, page } }
        : { queryParams: { groupId: this.selectedProductGroupId } }
    );
  }

  public generateQrCode() {
    QrCode.toDataURL(`dipendo://info?id=${this.purchaseItemId}`, {
      errorCorrectionLevel: 'Q'
    })
      .then((url) => {
        this.asyncQrCode = url;
      })
      .catch((err) => {
        this.alertService.setAlert(
          AlertType.danger,
          'Karekodların oluşturulması esnasında bir hata oluştu.'
        );

        throw err;
      });
  }

  public saveQrCode() {
    const purchaseItemsMap = new Map<number, PurchaseItem>();
    const qrCodes = new Map<number, string>();

    const purchaseItem = this.selectedPurchaseItem;
    QrCode.toDataURL(`dipendo://info?id=${purchaseItem.purchaseItemId}`, {
      errorCorrectionLevel: 'Q'
    })
      .then((url) => {
        qrCodes.set(purchaseItem.purchaseItemId, url);
        purchaseItemsMap.set(purchaseItem.purchaseItemId, purchaseItem);
        const doc = new JsPdf();
        const fontHeight = 14;
        const fontSize = 20;
        const leftSpacing = 5;
        const pageHeight = 297;
        let orderInPage = 0;
        let lineCount = 0;
        doc.setFont('Verdana', 'normal');
        doc.setFontSize(fontHeight);

        qrCodes.forEach((qrCode, purchaseItemId) => {
          const qrCodeSectionHeight = 37;
          const qrCodeSideLength = qrCodeSectionHeight * 0.8;
          const pageTopSpacing = 11;
          const qrCodeSectionSpacing = 3;
          let currentQrCodeSectionTop =
            pageTopSpacing + qrCodeSectionHeight * lineCount + qrCodeSectionSpacing * lineCount;
          const nextHeight = currentQrCodeSectionTop + qrCodeSectionHeight;

          if (nextHeight > pageHeight) {
            doc.addPage();
            orderInPage = 0;
            lineCount = 0;
            currentQrCodeSectionTop =
              pageTopSpacing +
              qrCodeSectionHeight * orderInPage +
              qrCodeSectionSpacing * orderInPage;
          }

          doc.addImage(
            qrCode,
            'PNG',
            leftSpacing + qrCodeSectionHeight * 0.1,
            currentQrCodeSectionTop + qrCodeSectionHeight * 0.1,
            qrCodeSideLength,
            qrCodeSideLength
          );

          const infoSectionLeft = leftSpacing + qrCodeSectionHeight * 0.1 * 2 + qrCodeSideLength;

          doc.text(
            purchaseItemId.toString(),
            infoSectionLeft,
            Math.floor(currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + fontHeight / 2)
          );
          doc.text(
            purchaseItemsMap.get(purchaseItemId).product.name,
            infoSectionLeft,
            Math.floor(currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + fontHeight)
          );
          const itemCount =
            this.productGroups.get(purchaseItemsMap.get(purchaseItemId).product['groupId'])
              .unitGroup === 'number'
              ? Math.round(purchaseItemsMap.get(purchaseItemId).purchaseCount)
              : purchaseItemsMap.get(purchaseItemId).purchaseCount;
          doc.text(
            `${itemCount} ${this.measurementUnitSymbolPipe.transform(
              this.productGroups.get(purchaseItemsMap.get(purchaseItemId).product['groupId']).unit,
              this.productGroups.get(purchaseItemsMap.get(purchaseItemId).product['groupId'])
                .unitGroup
            )}`,
            infoSectionLeft,
            Math.floor(currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + (fontHeight / 2) * 3)
          );
          doc.text(
            `${(purchaseItem.purchaseCount * purchaseItem.product.unitMass).toFixed(3)} ${
              purchaseItem.product.unitOfMass
            }`,
            infoSectionLeft,
            Math.floor(currentQrCodeSectionTop + qrCodeSectionHeight * 0.1 + fontHeight * 2)
          );

          ++orderInPage;
        });

        doc.save('karekod-listesi.pdf');
      })
      .catch((err) => {
        this.alertService.setAlert(
          AlertType.danger,
          'Karekodların oluşturulması esnasında bir hata oluştu.'
        );

        throw err;
      });
  }

  public addComment() {
    if (this.commentContent.trim().length === 0) {
      return;
    }

    this.purchaseService
      .addItemComment(this.selectedPurchaseItem, {
        content: this.commentContent.trim()
      })
      .subscribe((res) => {
        this.selectedPurchaseItem.comments.push(res);
        this.commentContent = '';
      });
  }

  public editPurchaseAmount() {
    this.isEditing = true;
  }

  public updatePurchaseAmount() {
    this.purchaseItemService
      .patch('purchaseCount', [
        {
          id: this.selectedPurchaseItem.purchaseItemId,
          op: 'update',
          purchaseCount: this.purchaseCount
        }
      ])
      .subscribe((res) => {
        this.isEditing = false;
        this.getPurchaseItem();
      });
  }

  public fileUpload(files: any[]) {
    this.isLoaded = false;

    if (files.length) {
      this.purchaseFiles = files;
    }

    const fileList = this.purchaseFiles.filter((file) => file.isDeleted);
    let index = 0;
    fileList.forEach((file) => {
      index++;
      if (file.purchaseItemId > 0) {
        this.purchaseService
          .deleteFile(this.selectedPurchaseItem, file.purchaseItemFileId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              if (index === fileList.length) {
                this.purchaseFiles.splice(
                  this.purchaseFiles.findIndex(
                    (x) => x.purchaseItemFileId === file.purchaseItemFileId
                  ),
                  1
                );
                this.alertService.setAlert(AlertType.success, 'Değişiklikler kaydedildi.');
                window.scrollTo(0, 0);
                file.selected = false;
                this.uploadFileComponent.setResult(this.purchaseFiles);
              }
            },
            (e) => {
              this.alertService.setAlert(
                AlertType.danger,
                'Değişikliklerin kaydedilmesi sırasında hata oluştu.'
              );
              window.scrollTo(0, 0);
              return;
            }
          );
      } else {
        this.purchaseFiles.splice(this.purchaseFiles.findIndex((x) => x.index === file.index), 1);
        if (index === fileList.length) {
          this.alertService.setAlert(AlertType.success, 'Değişiklikler kaydedildi.');
          window.scrollTo(0, 0);
          file.selected = false;
          this.uploadFileComponent.setResult(this.purchaseFiles);
        }
      }
    });
    this.isLoaded = true;
  }

  public saveFiles(fileList: any[]) {
    if (!this.fileDescription) {
      this.alertService.setAlert(AlertType.danger, 'Lütfen dosya açıklaması giriniz!');
      window.scrollTo(0, 0);
      return;
    }
    let index = 0;
    const files = this.purchaseFiles.filter((file) => !file.purchaseItemId && !file.isDeleted);
    files.forEach((file) => {
      index++;
      if (!file.purchaseItemId && !file.isDeleted) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', this.fileDescription);

        this.purchaseService
          .insertFile(this.selectedPurchaseItem, formData)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              if (index === files.length) {
                this.alertService.setAlert(AlertType.success, 'Değişiklikler kaydedildi.');
                window.scrollTo(0, 0);
                this.getPurchaseItem();
                file.selected = false;
                this.fileDescription = '';
              }
            },
            () => {
              this.alertService.setAlert(
                AlertType.danger,
                'Değişikliklerin kaydedilmesi sırasında hata oluştu.'
              );
              window.scrollTo(0, 0);
              return;
            }
          );
      }
    });
  }

  public showFile(file: any) {
    if (file.purchaseItemFileId) {
      this.purchaseService
        .getFile(this.selectedPurchaseItem, file.purchaseItemFileId)
        .pipe(
          tap(
            (res) => {
              saveAs(res, file.name, {
                type: 'text/plain;charset=windows-1252'
              });
            },
            (err) => {
              this.alertService.setAlert(
                AlertType.danger,
                'Dosya gösterimi sırasında hata oluştu.'
              );
              window.scrollTo(0, 0);
            }
          )
        )
        .subscribe((res) => {});
    } else {
      this.downloadFile(file);
    }
  }

  public downloadFile(file) {
    const fr = new FileReader();
    fr.readAsDataURL(file);

    const blob = new Blob([file], { type: file.type });
    const objectURL = window.URL.createObjectURL(blob);

    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveOrOpenBlob(blob, file.name);
    } else {
      const link = document.createElement('a');
      link.href = objectURL;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getProductGroup(id: number) {
    if (!this.productGroups.get(id)) {
      this.productGroupService
        .get(id)
        .pipe(
          tap((res) => {}),
          share()
        )
        .subscribe((res) => {
          this.productGroups.set(id, res);
        });
    }
  }
}
