import { Pipe, PipeTransform } from '@angular/core';
import { Globals } from '../../globals';

@Pipe({
  name: 'measurementUnitSymbol'
})
export class MeasurementUnitSymbolPipe implements PipeTransform {
  transform(unit: string, unitGroup: string): string {
    if (unit && unitGroup) {
      return Globals.measurementUnits.find(m => m.id === unitGroup).units.find(m => m.id === unit).symbol;
    } else {
      return '';
    }
  }
}
