import { InputBase } from './input-base';

export class InputTextbox extends InputBase<string> {
  public controlType = 'textbox';
  public type: string;
  public maxLength: number;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.maxLength = options['maxLength'] || '';
  }
}
