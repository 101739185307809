<section class="content-header">
  <h1>
    {{ pageName }}
    <small>{{ selectedProductGroupName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>Depo</li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <!-- Default box -->
  <div class="box box-primary" #mainTable>
    <div class="box-header with-border">
      <h3 class="box-title">{{ selectedProductGroupName }}</h3>

      <div class="box-tools">
        <form class="form-inline" #productGroupForm="ngForm" autocomplete="off" novalidate>
          <button type="button" class="btn btn-sm btn-success" (click)="saveExcel()">
            Excel'e Aktar
          </button>
          <div *ngIf="this.selectedProductId === 0" class="form-group">
            <select
              class="form-control input-sm"
              [(ngModel)]="selectedProductGroupId"
              (change)="onProductGroupChange()"
              name="product-group"
            >
              <option
                *ngFor="let productGroup of asyncProductGroups | async"
                value="{{ productGroup.id }}"
                >{{ productGroup.name }} ({{ productGroup.itemCount }})</option
              >
            </select>
          </div>
          <input
            type="search"
            [(ngModel)]="searchValue"
            name="searchValue"
            class="form-control input-sm"
            placeholder="Depoda ara"
            (keyup)="onKeyup($event)"
          />
        </form>
      </div>
    </div>
    <!-- /.box-header -->
    <div
      class="box-body table-responsive"
      infinite-scroll
      (scrolled)="onScrollDown()"
      [immediateCheck]="true"
    >
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th class="grouping-cell"></th>
            <th class="selector-cell">
              <input
                type="checkbox"
                autocomplete="off"
                [(ngModel)]="isHeaderSelected"
                (change)="onChangeHeaderCheckbox()"
              />
            </th>
            <th>Ürün Adı</th>
            <th>Tedarikçi</th>
            <th>Miktar</th>
            <th>Liste Fiyatı</th>
          </tr>
        </thead>
        <ng-container *ngIf="this.selectedProductId === 0 || (asyncProductGroups | async)">
          <tbody *ngIf="selectedProductGroup">
            <tr *ngFor="let purchaseItem of asyncPurchaseItems | async">
              <td
                [class]="
                  'grouping-cell' +
                  (purchaseItem.identicalItems.length > 0 ? '' : ' grouping-sub-cell')
                "
              >
                <button
                  *ngIf="purchaseItem.identicalItems.length > 0"
                  class="btn btn-accordion"
                  (click)="onClickGroupCaret(purchaseItem)"
                >
                  <i
                    [class]="
                      'fa ' + (purchaseItem.isGroupOpen ? 'fa-caret-down' : 'fa-caret-right')
                    "
                  ></i>
                  ({{ purchaseItem.identicalItems.length }})
                </button>
              </td>
              <td
                [class]="
                  'selector-cell' +
                  (purchaseItem.identicalItems.length > 0 ? '' : ' selector-sub-cell')
                "
              >
                <input
                  type="checkbox"
                  autocomplete="off"
                  [(ngModel)]="purchaseItem.selected"
                  (change)="onChangeItemCheckbox(purchaseItem)"
                />
              </td>
              <td *ngIf="purchaseItem.purchaseItemId < 0">
                <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen">{{
                  purchaseItem.product.name
                }}</ng-container>
              </td>
              <td *ngIf="purchaseItem.purchaseItemId > 0">
                <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                  ><a [routerLink]="['/purchase-items', purchaseItem.purchaseItemId, 'detail']">{{
                    purchaseItem.product.name
                  }}</a></ng-container
                >
              </td>
              <td>
                <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                  ><a [routerLink]="['/suppliers', purchaseItem.supplierId, 'detail']">{{
                    purchaseItem.supplierTitle
                  }}</a></ng-container
                >
              </td>
              <td>
                <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                  >{{ purchaseItem.stockCount }}
                  {{
                    selectedProductGroup.measurementUnit.split('-')[1]
                      | measurementUnitSymbol: selectedProductGroup.measurementUnit.split('-')[0]
                  }}<small
                    *ngIf="purchaseItem.stockCount != purchaseItem.saleableCount"
                    class="text-muted"
                    >{{
                      ' (satılabilir ' +
                        purchaseItem.saleableCount +
                        ' ' +
                        (selectedProductGroup.measurementUnit.split('-')[1]
                          | measurementUnitSymbol
                            : selectedProductGroup.measurementUnit.split('-')[0]) +
                        ')'
                    }}</small
                  ></ng-container
                >
              </td>
              <td>
                <ng-container *ngIf="!purchaseItem.isGroupingItem || !purchaseItem.isGroupOpen"
                  >{{ purchaseItem.product.listPrice | number: '1.2-4' }}
                  {{ purchaseItem.product.currencyCode }}</ng-container
                >
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">{{ selectedProductGroupName }} grubunda depoda ürün bulunmamaktadır.</p>
    </div>
    <div class="box-footer clearfix"></div>
  </div>

  <div class="bottom-calculator" *ngIf="selectedCount > 0">
    {{ selectedCount }} öğe seçildi. Toplam Miktar: {{ selectedStockCount }}
    {{
      selectedProductGroup.measurementUnit.split('-')[1]
        | measurementUnitSymbol: selectedProductGroup.measurementUnit.split('-')[0]
    }}<small *ngIf="selectedStockCount != selectedSaleableCount">{{
      ' (satılabilir ' +
        selectedSaleableCount +
        ' ' +
        (selectedProductGroup.measurementUnit.split('-')[1]
          | measurementUnitSymbol: selectedProductGroup.measurementUnit.split('-')[0]) +
        ')'
    }}</small>
  </div>
</section>
