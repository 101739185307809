<app-header></app-header>

<app-sidebar></app-sidebar>

<div class="content-alert-wrapper">
  <section class="content-alert" *ngIf="alertService.getAlert()">
    <div class="alert alert-dismissible main-alert" [ngClass]="'alert-' + (alertService.getAlert().type || 'warning')">
      <button ng-show="closeable" type="button" class="close" (click)="closeAlert()">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Kapat</span>
      </button>
      {{ alertService.getAlert().message }}
    </div>
  </section>
</div>

<div id="content-container" class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
