<section class="content-header">
  <h1>
    {{ pageName }}
    <small></small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>Ayarlar</li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">Ürün Grubu Listesi</h3>

      <div class="box-tools">
        <form class="form-horizontal form-inline">
          <button
            type="button"
            class="btn btn-sm btn-success"
            routerLink="/settings/product-groups/add"
          >
            Yeni Ürün Grubu
          </button>
          <input
            type="search"
            [(ngModel)]="searchValue"
            name="searchValue"
            class="form-control input-sm"
            placeholder="Ürün gruplarında ara"
            (keyup)="searchQuery.next($event.target.value)"
          />
        </form>
      </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th>Ürün Grubu Adı</th>
            <th style="width: 10%;">Durum</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let productGroup of asyncProductGroups
                | async
                | paginate
                  : { itemsPerPage: perPage, currentPage: pageNumber, totalItems: totalCount }
            "
          >
            <td>{{ productGroup.name }}</td>
            <td [innerHTML]="productGroup.isActive | activePassive"></td>
            <td class="no-break">
              <a [routerLink]="['/settings/product-groups', productGroup.id, 'edit']">Düzenle</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">Henüz hiç ürün grubu eklenmemiş.</p>
    </div>
    <div class="box-footer clearfix">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>
</section>
