import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { PurchaseItemListItem } from './purchase-item-list-item.model';
import { PurchaseItemListResult } from './purchase-item-list-result.model';

@Injectable()
export class PurchaseItemService {
  private readonly endpoint = `${environment.baseApiUrl}purchase-items`;

  constructor(private readonly http: HttpClient) {}

  public getAll(
    status = 0,
    groupId = 0,
    purchaseId = 0,
    offset = 0,
    limit = 10,
    productId = 0
  ): Observable<PurchaseItemListResult> {
    const params = new HttpParams()
      .set('status', status.toString())
      .set('productId', productId.toString())
      .set('groupId', groupId.toString())
      .set('purchaseId', purchaseId.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.http
      .get<PurchaseItemListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new PurchaseItemListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            null,
            res.body
          );
        })
      );
  }

  public search(
    status = 0,
    groupId = 0,
    purchaseId = 0,
    pagedSearchQueries: Observable<PagedSearchQuery>,
    view: string = null,
    productId = 0
  ): Observable<PurchaseItemListResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) =>
        this.searchItems(status, groupId, purchaseId, pagedSearchQuery, view, productId)
      )
    );
  }

  public updateStatus(id: number, status: number): Observable<void> {
    return this.http.patch<void>(`${this.endpoint}/${id}`, {
      status
    });
  }

  public patch(fields: string, object: any): Observable<void> {
    return this.http.patch<void>(`${this.endpoint}?fields=${fields}`, object);
  }

  private searchItems(
    status = 0,
    groupId = 0,
    purchaseId = 0,
    pagedSearchQuery: PagedSearchQuery,
    view: string,
    productId = 0
  ): Observable<PurchaseItemListResult> {
    const params = new HttpParams()
      .set('status', status.toString())
      .set('productId', productId.toString())
      .set('groupId', groupId.toString())
      .set('purchaseId', purchaseId.toString())
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString())
      .set('view', view);

    return this.http
      .get<PurchaseItemListItem[]>(
        `${this.endpoint}?search=${pagedSearchQuery.query.replace(/\+/g, '%2B')}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new PurchaseItemListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            pagedSearchQuery.query,
            res.body
          );
        })
      );
  }
}
