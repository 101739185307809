import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { DipendoModalFormComponent } from '../../shared/forms/dipendo-modal-form/dipendo-modal-form.component';
import { InputBase } from '../../shared/forms/input-base';
import { InputDatepicker } from '../../shared/forms/input-datepicker';
import { InputTextarea } from '../../shared/forms/input-textarea';
import { PurchaseItem } from '../shared/purchase-item.model';
import { Purchase } from '../shared/purchase.model';

@Component({
  selector: 'app-purchase-complete-form',
  templateUrl: './purchase-complete-form.component.html',
  styleUrls: ['./purchase-complete-form.component.scss']
})
export class PurchaseCompleteFormComponent implements OnInit {
  public purchase: Purchase;
  public purchaseItem: PurchaseItem;
  public isEdit = false;
  public inputs: any[];
  public inputLabels = new Map<string, string>();
  public itemCount: number;

  @ViewChild(DipendoModalFormComponent, { static: true })
  private readonly formComponent: DipendoModalFormComponent;

  constructor(public readonly activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.inputs = this.getInputs();

    this.inputLabels.forEach((value, key) => {
      const inputIndex = this.inputs.findIndex((x) => x.key === key);
      if (inputIndex > -1) {
        this.inputs[inputIndex].label = value;
      }
    });
  }

  public onFormInit(): void {
    if (this.itemCount === 1) {
      this.fillForm();
    }
  }

  public fillForm() {
    if (this.purchase && this.formComponent.form) {
      this.formComponent.setValues(this.purchase);
    } else if (this.purchaseItem && this.formComponent.form) {
      this.formComponent.setValues(this.purchaseItem);
    }
  }

  public onSubmit(formComponent: DipendoModalFormComponent) {
    if (this.purchase) {
      this.activeModal.close(this.prepareBindingPurchase(formComponent));
    } else if (this.purchaseItem) {
      this.activeModal.close(this.prepareBindingPurchaseItem(formComponent));
    } else {
      this.activeModal.close();
    }
  }

  private getInputs(): InputBase<any>[] {
    const inputs: InputBase<any>[] = [
      new InputDatepicker({
        key: 'letterOfCreditTime',
        label: 'Akreditif Tarihi',
        type: 'text',
        value: '',
        maxDates: 'estimatedShippingTime,estimatedArrivalTime,arrivalTime',
        required: false,
        order: 1
      }),
      new InputDatepicker({
        key: 'estimatedShippingTime',
        label: 'Tahmini Gönderim Tarihi',
        type: 'text',
        value: '',
        minDates: 'letterOfCreditTime',
        maxDates: 'estimatedArrivalTime,arrivalTime',
        required: false,
        order: 2
      }),
      new InputDatepicker({
        key: 'estimatedArrivalTime',
        label: 'Tahmini Varış Tarihi',
        type: 'text',
        value: '',
        minDates: 'estimatedShippingTime,letterOfCreditTime',
        maxDates: 'arrivalTime',
        required: false,
        order: 3
      }),
      new InputDatepicker({
        key: 'arrivalTime',
        label: 'Kesin Varış Tarihi',
        type: 'text',
        value: '',
        minDates: 'estimatedArrivalTime,estimatedShippingTime,letterOfCreditTime',
        required: false,
        order: 4
      })
    ];

    if (this.purchase) {
      inputs.push(
        new InputTextarea({
          key: 'explanation',
          label: 'Açıklama',
          rowCount: 5,
          value: '',
          required: false,
          order: 5
        })
      );
    }

    return inputs.sort((a, b) => a.order - b.order);
  }

  private prepareBindingPurchase(formComponent: DipendoModalFormComponent): Purchase {
    this.purchase.letterOfCreditTime = moment(
      formComponent.form.value.letterOfCreditTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchase.estimatedShippingTime = moment(
      formComponent.form.value.estimatedShippingTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchase.estimatedArrivalTime = moment(
      formComponent.form.value.estimatedArrivalTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchase.arrivalTime = moment(formComponent.form.value.arrivalTime, 'DD.MM.YYYY').toDate();
    this.purchase.explanation = formComponent.form.value.explanation
      ? formComponent.form.value.explanation
      : 'Satın alma yapıldı.';

    return this.purchase;
  }

  private prepareBindingPurchaseItem(formComponent: DipendoModalFormComponent): PurchaseItem {
    this.purchaseItem.letterOfCreditTime = moment(
      formComponent.form.value.letterOfCreditTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchaseItem.estimatedShippingTime = moment(
      formComponent.form.value.estimatedShippingTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchaseItem.estimatedArrivalTime = moment(
      formComponent.form.value.estimatedArrivalTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchaseItem.arrivalTime = moment(
      formComponent.form.value.arrivalTime,
      'DD.MM.YYYY'
    ).toDate();

    return this.purchaseItem;
  }
}
