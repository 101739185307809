import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../core/auth/auth.service';

@Directive({
  selector: '[appIfAuthorizedTo]'
})
export class IfAuthorizedToDirective {
  private hasView = false;

  constructor(
    private readonly authService: AuthService,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) { }

  @Input() set appIfAuthorizedTo(requiredAuthorization: string) {
    if (this.authService.isAuthorized(requiredAuthorization) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.authService.isAuthorized(requiredAuthorization) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
