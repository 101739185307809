import { SupplierPurchaseItemsSummary } from './supplier-purchase-items-summary.model';

export class SupplierPurchaseItemsSummaryResult {
  public totalCount: number;
  public offset: number;
  public limit: number;
  public supplierPurchaseItemsSummaries: SupplierPurchaseItemsSummary[];

  constructor(
    totalCount: number,
    offset: number,
    limit: number,
    supplierPurchaseItemsSummaries: SupplierPurchaseItemsSummary[]
  ) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.supplierPurchaseItemsSummaries = supplierPurchaseItemsSummaries;
  }
}
