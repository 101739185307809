import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Globals } from '../../globals';
import { AccessToken } from './access-token.model';
import { ChangePasswordBinding } from './change-password-binding.model';
import { ForgotPasswordBinding } from './forgot-password-binding.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public claims: { [id: string]: string } = {};
  public token: string;
  public username: string;

  private readonly authUrl = `${environment.baseUrl}oauth/token`;
  private readonly changePasswordUrl = `${environment.baseApiUrl}accounts/change-password`;
  private readonly forgotPasswordUrl = `${environment.baseApiUrl}accounts/forgot-password`;

  constructor(private readonly http: HttpClient) {
    this.token = localStorage.getItem('accessToken');
    this.username = localStorage.getItem('currentUser');

    Sentry.setUser({
      email: this.username
    });

    this.setClaims();
  }

  public isAuthorized(claim: string): boolean {
    return this.claims && this.claims[claim] === '1';
  }

  public setClaims(): void {
    if (this.token) {
      JSON.parse(atob(this.token.split('.')[1]), (key, value) => {
        this.claims[key] = value;
      });
    }
  }

  public login(username: string, password: string): Observable<boolean> {
    return this.http
      .post(this.authUrl, {
        username,
        password,
        grant_type: 'password',
        client_id: Globals.client.id
      })
      .pipe(
        map((response: AccessToken) => {
          this.token = response.access_token;
          this.username = username;

          Sentry.setUser({
            email: this.username
          });

          localStorage.setItem('accessToken', response.access_token);
          localStorage.setItem('currentUser', username);

          return true;
        })
      );
  }

  public changePassword(passwordRequest: ChangePasswordBinding): Observable<void> {
    return this.http.post<void>(this.changePasswordUrl, passwordRequest);
  }

  public forgotPassword(passwordRequest: ForgotPasswordBinding): Observable<void> {
    return this.http.post<void>(this.forgotPasswordUrl, passwordRequest);
  }

  public logout(): void {
    this.token = null;
    this.username = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
  }

  public getAuthorizationHeader(): string {
    return `Bearer ${this.token}`;
  }
}
