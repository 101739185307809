<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    Ürün Grubu
    <small>{{ productGroup.name }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink="/"><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>Ayarlar</li>
    <li><a routerLink="/settings/product-groups">Ürün Grupları</a></li>
    <li class="active">Ürün Grubu Düzenle</li>
  </ol>
</section>

<section class="content">
  <div class="box box-primary">
    <dipendo-form [boxTitle]="'Ürün Grubu Bilgileri'" [inputs]="inputs" (submit)="onSubmit($event)">
      <div class="box-header with-border">
        <h3 class="box-title">Özellik Bilgileri</h3>
        <div class="box-tools">
          <button
            type="button"
            class="btn btn-sm btn-success"
            (click)="openProductGroupPropertyDialog(null)"
          >
            Özellik Ekle
          </button>
        </div>
      </div>
      <!-- /.box-header -->

      <div class="box-body">
        <label for="submit" class="col-md-4 control-label"></label>
        <div class="col-md-4">
          <p class="text-muted" *ngIf="productGroup.productGroupProperties.length === 0">
            Bu ürün grubu için henüz hiç özellik eklenmedi.
          </p>

          <ng-container *ngIf="productGroup.productGroupProperties.length > 0">
            <div
              *ngFor="
                let productGroupProperty of productGroup.productGroupProperties;
                let i = index
              "
            >
              <div class="btn-group pull-right">
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="changeOrder(productGroupProperty, -1)"
                  [disabled]="i === 0"
                >
                  <i class="fa fa-arrow-up"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="changeOrder(productGroupProperty, 1)"
                  [disabled]="i === productGroup.productGroupProperties.length - 1"
                >
                  <i class="fa fa-arrow-down"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="openProductGroupPropertyDialog(productGroupProperty)"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="removeProductGroupProperty(productGroupProperty)"
                >
                  <i class="fa fa-remove"></i>
                </button>
              </div>
              <strong>{{ productGroupProperty.name }}</strong>
              <p>
                {{ productGroupPropertyTypes[productGroupProperty.propertyType] }}
              </p>
              <hr
                *ngIf="
                  productGroup.productGroupProperties.length > 1 &&
                  i < productGroup.productGroupProperties.length - 1
                "
              />
            </div>
          </ng-container>
        </div>
        <span class="col-md-4 help-block"></span>
      </div>
    </dipendo-form>
  </div>
</section>
