import { ProductListItem } from '../../products/shared/product-list-item.model';
import { Comment } from '../../purchases/shared/comment.model';

export class PurchaseItemListItem {
  constructor(
    public purchaseItemId: number,
    public purchaseId: number,
    public productId: number,
    public stockCode: string,
    public purchaseCount: number,
    public cost: number,
    public currencyCode: string,
    public status: number,
    public letterOfCreditTime: Date,
    public estimatedShippingTime: Date,
    public estimatedArrivalTime: Date,
    public arrivalTime: Date,
    public product: ProductListItem,
    public saleableCount: number,
    public stockCount: number,
    public reservableCount: number,
    public selected: boolean,
    public supplierId: number,
    public supplierTitle: string,
    public containerId: string,
    public containerName: string,
    public identicalItems: PurchaseItemListItem[],
    public isGroupingItem: boolean,
    public isGroupOpen: boolean,
    public comments: Comment[]
  ) {}

  public static clone(item: PurchaseItemListItem): PurchaseItemListItem {
    return new PurchaseItemListItem(
      item.purchaseItemId,
      item.purchaseId,
      item.productId,
      item.stockCode,
      item.purchaseCount,
      item.cost,
      item.currencyCode,
      item.status,
      item.letterOfCreditTime,
      item.estimatedShippingTime,
      item.estimatedArrivalTime,
      item.arrivalTime,
      item.product,
      item.saleableCount,
      item.stockCount,
      item.reservableCount,
      item.selected,
      item.supplierId,
      item.supplierTitle,
      item.containerId,
      item.containerName,
      [],
      item.isGroupingItem,
      item.isGroupOpen,
      item.comments
    );
  }
}
