import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ReportListItem } from './report-list-item.model';
import { ReportListResult } from './report-list-result.model';

@Injectable()
export class ReportService {
  private readonly endpoint = `${environment.baseApiUrl}reports`;

  constructor(private readonly http: HttpClient) {}

  public get(
    purchaseBeginDate = '',
    purchaseEndDate = '',
    saleBeginDate = '',
    saleEndDate = '',
    supplierId = 0,
    customerId = 0,
    productIds: number[] = [],
    userId = 0
  ): Observable<ReportListResult> {
    const params = new HttpParams()
      .set('purchaseBeginDate', purchaseBeginDate.toString())
      .set('purchaseEndDate', purchaseEndDate.toString())
      .set('saleBeginDate', saleBeginDate.toString())
      .set('saleEndDate', saleEndDate.toString())
      .set('supplierId', supplierId.toString())
      .set('customerId', customerId.toString())
      .set('productIds', productIds.join(';').toString())
      .set('userId', userId.toString());

    return this.http
      .get<ReportListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new ReportListResult(1, res.body);
        })
      );
  }
}
