export class PagedSearchQuery {
  constructor(
    public query: string,
    public offset: number,
    public limit: number,
    public isAutoLoad = false,
    public startTime:Date = null,
    public endTime:Date = null
  ) { }
}
