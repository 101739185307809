import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ProductGroupListItem } from './product-group-list-item.model';
import { ProductGroupListResult } from './product-group-list-result.model';
import { ProductGroupSummaryListItem } from './product-group-summary-list-item.model';
import { ProductGroupSummaryListResult } from './product-group-summary-list-result.model';
import { ProductGroup } from './product-group.model';

@Injectable()
export class ProductGroupService {
  private readonly endpoint = `${environment.baseApiUrl}product-groups`;

  constructor(private readonly http: HttpClient) {}

  public getAll(offset = 0, limit = 10, isActive = true): Observable<ProductGroupListResult> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('isActive', isActive.toString());

    return this.http
      .get<ProductGroupListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new ProductGroupListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            res.body
          );
        })
      );
  }

  public getSummary(
    view: string,
    offset = 0,
    limit = 10
  ): Observable<ProductGroupSummaryListResult> {
    const params = new HttpParams()
      .set('view', view)
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.http
      .get<ProductGroupSummaryListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new ProductGroupSummaryListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            res.body
          );
        })
      );
  }

  public get(id: number): Observable<ProductGroup> {
    return this.http.get<ProductGroup>(`${this.endpoint}/${id}`);
  }

  public search(
    queries: Observable<string>,
    offset = 0,
    limit = 10
  ): Observable<ProductGroupListResult> {
    return queries.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((query) => this.searchItems(query, offset, limit))
    );
  }

  public insert(customer: ProductGroup): Observable<ProductGroup> {
    return this.http.post<ProductGroup>(this.endpoint, customer);
  }

  public update(id: number, customer: ProductGroup): Observable<ProductGroup> {
    return this.http.put<ProductGroup>(`${this.endpoint}/${id}`, customer);
  }

  private searchItems(query: string, offset = 0, limit = 10): Observable<ProductGroupListResult> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    return this.http
      .get<ProductGroupListItem[]>(`${this.endpoint}?search=${query.replace(/\+/g, '%2B')}`, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new ProductGroupListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            res.body
          );
        })
      );
  }
}
