import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { InputBase } from '../input-base';
import { InputControlService } from '../input-control.service';

@Component({
  selector: 'dipendo-modal-form',
  templateUrl: './dipendo-modal-form.component.html',
  styleUrls: ['./dipendo-modal-form.component.scss'],
  providers: [InputControlService]
})
export class DipendoModalFormComponent implements OnInit {
  @Input()
  public inputs: InputBase<any>[] = [];

  @Input()
  public title = '';

  @Input()
  public message;

  @Input()
  public primaryButtonLabel;

  @Input()
  public isEdit: boolean;

  /* This component is used everywhere. It is too risky to change onSubmit to submit. */
  /* tslint:disable-next-line */
  @Output()
  public onSubmit = new EventEmitter<DipendoModalFormComponent>();

  /* This component is used everywhere. It is too risky to change onFormInit to formInit. */
  /* tslint:disable-next-line */
  @Output()
  public onFormInit = new EventEmitter<void>();

  public form: FormGroup;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly ics: InputControlService
  ) {}

  public setValues(model: object): void {
    for (const property of Object.keys(model)) {
      const formControl = this.form.get(property);

      if (formControl) {
        formControl.setValue(model[property]);

        if (
          this.inputs.findIndex((i) => i.key === property && i.controlType === 'datepicker') > -1
        ) {
          const dateValue = moment
            .utc(model[property])
            .local()
            .format('DD.MM.YYYY');
          formControl.setValue(
            dateValue.localeCompare('Invalid date', 'en', { sensitivity: 'base' }) === 0
              ? ''
              : dateValue
          );
        }
      }
    }
  }

  public ngOnInit() {
    this.form = this.ics.toFromGroup(this.inputs);
    this.onFormInit.emit();
  }

  public close() {
    if (this.form.valid) {
      this.onSubmit.emit(this);
    } else {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsTouched();
      });
    }
  }
}
