import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { InputBase } from '../input-base';
import { InputDatepicker } from '../input-datepicker';
import { InputTwoSidedMultiSelect } from '../input-two-sided-multi-select';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'dipendo-input',
  templateUrl: './dipendo-input.component.html',
  styleUrls: ['./dipendo-input.component.scss']
})
export class DipendoInputComponent implements AfterViewInit {
  @Input()
  public input: InputBase<any>;
  @Input()
  public form: FormGroup;

  public get isValid(): boolean {
    return this.form.controls[this.input.key].valid;
  }

  public get shouldShowError(): boolean {
    return this.form.controls[this.input.key].touched && this.form.controls[this.input.key].invalid;
  }

  public get firstError(): string {
    return Object.keys(this.form.controls[this.input.key].errors)[0];
  }

  public get firstErrorValue(): string {
    return Object.values(this.form.controls[this.input.key].errors)[0];
  }

  public ngAfterViewInit() {
    if (this.input.controlType === 'datepicker') {
      const $this = this;
      const inputDatepicker = this.input as InputDatepicker;

      $('#' + this.input.key)
        .datepicker({
          autoclose: true,
          format: 'dd.mm.yyyy',
          language: 'tr',
          todayHighlight: true,
          startDate: inputDatepicker.startDate,
          orientation: inputDatepicker.orientation
        })
        .on('changeDate', (e: any) => {
          $this.form.controls[$this.input.key].patchValue(e.format('dd.mm.yyyy'));
        })
        .on('hide', (e: any) => {
          const value = $this.form.controls[$this.input.key].value;

          if (value && value.length < 10 && value !== '') {
            $this.form.controls[$this.input.key].patchValue('');
          }
        });
    }
  }

  public moveToRight(): void {
    const multiSelectControl = this.form.controls[this.input.key];
    const multiSelectControlRight = this.form.controls[
      (this.input as InputTwoSidedMultiSelect).keyRight
    ];
    const multiSelectInput = this.input as InputTwoSidedMultiSelect;
    const selectedValues = multiSelectControl.value;
    const selectedOptions = [];

    multiSelectInput.options
      .filter((option) => selectedValues.indexOf(option.key) > -1)
      .forEach((option) => {
        selectedOptions.push({ key: option.key, value: option.value });
      });

    selectedOptions.forEach((selectedOption) => {
      multiSelectInput.options.splice(
        multiSelectInput.options.findIndex((option) => option.key === selectedOption.key),
        1
      );
      multiSelectInput.optionsRight.push({ key: selectedOption.key, value: selectedOption.value });
    });

    multiSelectInput.options.sort((a, b) => {
      return a.value.localeCompare(b.value);
    });

    multiSelectInput.optionsRight.sort((a, b) => {
      return a.value.localeCompare(b.value);
    });

    multiSelectControl.patchValue([]);
    multiSelectControlRight.patchValue([]);
  }

  public moveToLeft(): void {
    const multiSelectControl = this.form.controls[this.input.key];
    const multiSelectControlRight = this.form.controls[
      (this.input as InputTwoSidedMultiSelect).keyRight
    ];
    const multiSelectInput = this.input as InputTwoSidedMultiSelect;
    const selectedValues = multiSelectControlRight.value;
    const selectedOptions = [];

    multiSelectInput.optionsRight
      .filter((option) => selectedValues.indexOf(option.key) > -1)
      .forEach((option) => {
        selectedOptions.push({ key: option.key, value: option.value });
      });

    selectedOptions.forEach((selectedOption) => {
      multiSelectInput.optionsRight.splice(
        multiSelectInput.optionsRight.findIndex((option) => option.key === selectedOption.key),
        1
      );
      multiSelectInput.options.push({ key: selectedOption.key, value: selectedOption.value });
    });

    multiSelectInput.options.sort((a, b) => {
      return a.value.localeCompare(b.value);
    });

    multiSelectInput.optionsRight.sort((a, b) => {
      return a.value.localeCompare(b.value);
    });

    multiSelectControl.patchValue([]);
    multiSelectControlRight.patchValue([]);
  }
}
