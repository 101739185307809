import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sale-active-confirm',
  templateUrl: './sale-active-confirm.component.html',
  styleUrls: ['./sale-active-confirm.component.scss']
})
export class SaleActiveConfirmComponent implements OnInit {

  public title = '';
  public message = '';
  public saleId: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  continue() {
    this.activeModal.close();
  }
}
