import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertType } from './alert-type.model';
import { AlertService } from './alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly alertService: AlertService, private readonly router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        let errorMessage = '';

        if (httpErrorResponse.status === 401) {
          errorMessage = httpErrorResponse.error.message;
          this.alertService.setNextAlert(AlertType.danger, errorMessage);
          this.router.navigate(['/login']);
          return of(null);
        } else if (httpErrorResponse.status === 403) {
          errorMessage = httpErrorResponse.error.message;
          this.alertService.setAlert(AlertType.danger, errorMessage);
          return of(null);
        } else if (httpErrorResponse.status === 504) {
          errorMessage =
            'Dipendo sunucularına erişmekte sorun yaşıyoruz. Lütfen internet bağlantınızı kontrol edin ve kısa bir süre sonra tekrar deneyin.';
          this.alertService.setAlert(AlertType.danger, errorMessage);
          Sentry.captureException(httpErrorResponse);
          return of(null);
        } else if (httpErrorResponse.status === 404) {
          errorMessage =
            'Erişmeye çalıştığınız nesne bulunamadı. Lütfen girdiğiniz adresi kontrol ederek tekrar deneyin.';
          this.alertService.setAlert(AlertType.danger, errorMessage);
          return of(null);
        }

        errorMessage = httpErrorResponse.error.message;
        this.alertService.setAlert(AlertType.danger, errorMessage);

        if (typeof httpErrorResponse.error.modelState !== 'undefined') {
          for (const field of Object.keys(httpErrorResponse.error.modelState)) {
            for (const validationError of Object.keys(httpErrorResponse.error.modelState[field])) {
              errorMessage = httpErrorResponse.error.modelState[field][validationError];
              this.alertService.setAlert(AlertType.danger, errorMessage);
            }
          }
        }

        if (typeof httpErrorResponse.error.error_description !== 'undefined') {
          errorMessage = httpErrorResponse.error.error_description;
          this.alertService.setAlert(AlertType.danger, errorMessage);
        }

        if (typeof httpErrorResponse.error.detail !== 'undefined') {
          errorMessage = httpErrorResponse.error.detail;
          this.alertService.setAlert(AlertType.danger, errorMessage);
        }

        window.scrollTo(0, 0);

        const errorToThrow = new Error(httpErrorResponse.message);
        errorToThrow.name = httpErrorResponse.name;
        errorToThrow.stack = errorMessage;

        return throwError(errorToThrow);
      })
    );
  }
}
