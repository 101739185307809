<section class="content-header">
  <h1>
    {{ pageName }}
    <small>{{ selectedProductGroupName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li>Depo</li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <!-- Default box -->
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">{{ selectedProductGroupName }}</h3>

      <div class="box-tools">
        <form class="form-inline" #productGroupForm="ngForm" autocomplete="off" novalidate>
          <div class="form-group">
            <button type="button" class="btn btn-sm btn-success" (click)="saveExcel()">
              Excel'e Aktar
            </button>
            <div class="input-group">
              <input
                name="timeRange"
                class="form-control input-sm"
                id="timeRange"
                type="text"
                autocomplete="off"
              />
            </div>
            <div *ngIf="this.selectedProductId === 0" class="form-group">
              <select
                class="form-control input-sm"
                [(ngModel)]="selectedProductGroupId"
                (change)="onProductGroupChange()"
                name="product-group"
              >
                <option
                  *ngFor="let productGroup of asyncProductGroups | async"
                  value="{{ productGroup.id }}"
                  >{{ productGroup.name }} ({{ productGroup.itemCount }})</option
                >
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th>Ürün Adı</th>
            <th>Miktar</th>
            <th>Birim Ağırlık</th>
            <th>Toplam Ağırlık</th>
            <th>Teslimat Zamanı</th>
            <th>Müşteri</th>
            <th *appIfAuthorizedTo="'EditSaleItems'"></th>
          </tr>
        </thead>
        <ng-container *ngIf="this.selectedProductId === 0 || (asyncProductGroups | async)">
          <tbody *ngIf="selectedProductGroup">
            <tr
              *ngFor="
                let saleItem of asyncSaleItems
                  | async
                  | paginate
                    : { itemsPerPage: perPage, currentPage: pageNumber, totalItems: totalCount }
              "
            >
              <td>
                <a
                  [routerLink]="['/purchase-items', saleItem.purchaseItem.purchaseItemId, 'detail']"
                  >{{ saleItem.purchaseItem.product.name }}</a
                >
              </td>
              <td>
                {{ saleItem.saleCount }}
                {{
                  selectedProductGroup.measurementUnit.split('-')[1]
                    | measurementUnitSymbol: selectedProductGroup.measurementUnit.split('-')[0]
                }}
              </td>
              <td>
                {{ saleItem.purchaseItem.product.unitMass }}
                {{ saleItem.purchaseItem.product.unitOfMass }}
              </td>
              <td>
                {{ saleItem.purchaseItem.product.unitMass * saleItem.saleCount | number: '1.0-3' }}
                {{ saleItem.purchaseItem.product.unitOfMass }}
              </td>
              <td>
                {{
                  saleItem.deliveryTime
                    | amFromUtc
                    | amLocal
                    | amLocale: 'tr'
                    | amDateFormat: 'L'
                    | ignoreInvalidDate
                }}
              </td>
              <td>
                <a [routerLink]="['/customers', saleItem.customer.id, 'detail']">{{
                  saleItem.customer.title
                }}</a>
              </td>
              <td *appIfAuthorizedTo="'EditSaleItems'" class="no-break">
                <a (click)="doCheckout(saleItem.saleItemId)">Sevke Hazırla &raquo;</a>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">
        Seçtiğiniz tarih aralığı için {{ selectedProductGroupName }} grubunda depo çıkışı bekleyen
        ürün bulunmamaktadır.
      </p>
    </div>
    <div class="box-footer clearfix">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>
</section>
