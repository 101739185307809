import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, Params, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { Globals } from '../../globals';
import { ProductListItemProperty } from '../../products/shared/product-list-item-property.model';
import { ProductProperty } from '../../products/shared/product-property.model';
import { PurchaseItem } from '../../purchases/shared/purchase-item.model';
import { ProductGroupListItem } from '../../settings/product-groups/shared/product-group-list-item.model';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { PurchaseItemListItem } from '../../warehouse/shared/purchase-item-list-item.model';
import { PurchaseItemService } from '../../warehouse/shared/purchase-item.service';
import { SaleItem } from '../shared/sale-item.model';

@Component({
  selector: 'app-sale-product-list',
  templateUrl: './sale-product-list.component.html',
  styleUrls: ['./sale-product-list.component.scss']
})
export class SaleProductListComponent implements AfterViewInit, OnDestroy, OnInit {
  public pageName = 'Stoktaki Ürünler';
  public purchaseItems: PurchaseItemListItem[] = [];
  public asyncPurchaseItems = new BehaviorSubject<PurchaseItemListItem[]>([]);
  public purchaseItemsSubscription: Subscription;
  public productGroups: ProductGroupListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupListItem[]>;
  public selectedProductGroup: ProductGroup;
  public selectedProductGroupId = 0;
  public selectedProductGroupName = '';
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public headerSelection = false;
  public selectedItemCount = 0;
  public searchValue = '';
  public searchQuery = new Subject<PagedSearchQuery>();
  public isSearchValueChanged = false;
  public selectedItems: SaleItem[] = [];
  public isCheckAddItem = false;

  private ngUnsubscribe = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly purchaseItemService: PurchaseItemService,
    private readonly productGroupService: ProductGroupService,
    private readonly router: Router,
    public readonly activeModal: NgbActiveModal
  ) {}

  public ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckEnd) {
        this.purchaseItemsSubscription = null;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.asyncPurchaseItems.next([]);
        this.ngUnsubscribe = new Subject();
      }
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;

      this.getProductGroups();
    });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngAfterViewInit(): void {
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  public onChange(): void {
    this.pageNumber = 1;
    this.purchaseItemsSubscription = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.asyncPurchaseItems.next([]);
    this.ngUnsubscribe = new Subject();
    this.setProductGroup();
  }

  public changePage(page: number): void {
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (page - 1) * this.perPage, this.perPage)
    );

    this.addItems();
  }

  public onChangeHeaderCheckbox(groupId: number): void {
    this.purchaseItems.forEach((x) => (x.selected = this.headerSelection));
    this.selectedItemCount = this.purchaseItems.filter((x) => x.selected).length;
    if (this.selectedItemCount === 0) {
      this.checkPurchaseItemStatus();
    }
  }

  public onChangeItemCheckbox(item: PurchaseItem): void {
    if (this.purchaseItems.filter((x) => !x.selected).length > 0) {
      this.headerSelection = false;
    } else {
      this.headerSelection = true;
    }

    this.selectedItemCount = this.purchaseItems.filter((x) => x.selected).length;
    this.checkPurchaseItemStatus();
  }

  public add() {
    this.addItems();

    this.activeModal.close(this.selectedItems);
  }

  public onKeyup(event: any): void {
    this.searchValue = event.target.value;
    this.pageNumber = 1;
    this.isSearchValueChanged = true;
    this.searchQuery.next(
      new PagedSearchQuery(this.searchValue, (this.pageNumber - 1) * this.perPage, this.perPage)
    );
  }

  private changeSelected() {
    for (const item of this.purchaseItems) {
      for (const selectedItem of this.selectedItems) {
        if (
          item.product.groupId === selectedItem.purchaseItem.product.productGroupId &&
          item.purchaseItemId === selectedItem.purchaseItemId &&
          !selectedItem.selected
        ) {
          item.selected = true;
        }
      }
    }
  }

  private checkPurchaseItemStatus() {
    for (const item of this.purchaseItems) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.selectedItemCount = this.selectedItems.filter((x) => !x.selected).length;
        if (
          item.selected === this.selectedItems[i].selected &&
          item.purchaseItemId === this.selectedItems[i].purchaseItemId
        ) {
          this.selectedItems.splice(i, 1);
        }
      }
    }
  }

  private addItems() {
    for (const item of this.purchaseItems.filter((x) => x.selected)) {
      this.isCheckAddItem = false;
      for (const selectedItems of this.selectedItems) {
        if (item.purchaseItemId === selectedItems.purchaseItemId) {
          this.isCheckAddItem = true;
        }
      }
      if (!this.isCheckAddItem) {
        this.selectedItems.push({
          saleItemId: 0,
          purchaseItemId: item.purchaseItemId,
          stockCode: item.stockCode,
          saleCount: 0,
          price: 0,
          currencyCode: item.product.currencyCode,
          status: 1,
          deliveryTime: new Date(),
          purchaseItem: this.convertPurchaseItem(item),
          selected: false
        });
      }
    }
  }

  private getProductGroups(): void {
    this.asyncProductGroups = this.productGroupService.getAll(0, 10000).pipe(
      tap((res) => {
        this.productGroups = res.productGroups;

        if (res.totalCount > 0 && this.selectedProductGroupId === 0) {
          this.selectedProductGroupId = res.productGroups[0].id;
        }

        if (this.selectedProductGroupId > 0) {
          this.selectedProductGroupName = res.productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          ).name;
        }

        this.setProductGroup();
      }),
      map((res) => res.productGroups),
      share()
    );
  }

  private setProductGroup(): void {
    this.addItems();

    this.productGroupService
      .get(this.selectedProductGroupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.selectedProductGroup = res;
        this.selectedProductGroup.productGroupProperties.sort((a, b) => a.order - b.order);
        this.selectedProductGroupName = this.selectedProductGroup.name;
        this.getPage(this.pageNumber);
      });
  }

  private getPage(page: number): void {
    this.searchQuery.next(
      new PagedSearchQuery(
        this.searchValue,
        (page - 1) * this.perPage,
        this.perPage,
        !this.isSearchValueChanged
      )
    );

    if (!this.purchaseItemsSubscription) {
      this.purchaseItemsSubscription = this.purchaseItemService
        .search(4, this.selectedProductGroupId, 0, this.searchQuery, 'SaleCart')
        .pipe(
          tap((res) => {
            if (res.query !== this.searchValue) {
              return;
            }

            if (this.isSearchValueChanged) {
              this.asyncPurchaseItems.next([]);
              this.isSearchValueChanged = false;
            }

            this.totalCount = res.totalCount;
            this.pageNumber = Math.floor(res.offset / res.limit) + 1;
            this.purchaseItems = res.purchaseItems.filter((x) => x.stockCount > 0);
            this.asyncPurchaseItems.next(this.purchaseItems);
            this.changeSelected();
          }),
          map((res) => {
            return res.purchaseItems.filter((x) => x.stockCount > 0);
          }),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe();

      this.searchQuery.next(
        new PagedSearchQuery(
          this.searchValue,
          (page - 1) * this.perPage,
          this.perPage,
          !this.isSearchValueChanged
        )
      );
    }
  }

  private convertPurchaseItem(item: PurchaseItemListItem): PurchaseItem {
    return {
      purchaseItemId: item.purchaseItemId,
      purchaseId: item.purchaseId,
      productId: item.productId,
      stockCode: item.stockCode,
      purchaseCount: item.purchaseCount,
      cost: item.cost,
      currencyCode: item.currencyCode,
      status: item.status,
      letterOfCreditTime: item.letterOfCreditTime,
      estimatedShippingTime: item.estimatedShippingTime,
      estimatedArrivalTime: item.estimatedArrivalTime,
      arrivalTime: item.arrivalTime,
      invoiceTime: null,
      invoiceNumber: null,
      customsDeclarationTime: null,
      customsDeclarationNumber: null,
      product: {
        productId: item.productId,
        name: item.product.name,
        currencyCode: item.product.currencyCode,
        listPrice: item.product.listPrice,
        unitMass: item.product.unitMass,
        unitOfMass: item.product.unitOfMass,
        productGroupId: item.product.groupId,
        productPropertyValues: this.convertPropertyList(item.product.propertyValues),
        isActive: true
      },
      selected: false,
      stockCount: item.stockCount,
      comments: item.comments
    };
  }

  private convertPropertyList(propertyValues: ProductListItemProperty[]): ProductProperty[] {
    const propertyList: ProductProperty[] = [];

    for (const item of propertyValues) {
      propertyList.push({
        productGroupPropertyId: item.propertyId,
        propertyValue: item.value
      });
    }

    return propertyList;
  }
}
