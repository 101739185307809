import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductProperty } from './product-property.model';

export class Product {
  constructor(
    public productId: number,
    public name: string,
    public productGroupId: number,
    public isActive: boolean,
    public listPrice: number,
    public currencyCode: string,
    public unitMass: number,
    public unitOfMass: string,
    public productPropertyValues: ProductProperty[],
    public productGroup?: ProductGroup
  ) {}
}
