<section class="content-header">
  <h1>
    {{ pageName }}
    <small>{{ selectedProductGroupName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">Ürün Listesi</h3>

      <div class="box-tools">
        <form class="form-inline" #productGroupForm="ngForm" autocomplete="off" novalidate>
          <div class="form-group">
            <select
              class="form-control input-sm"
              [(ngModel)]="selectedProductGroupId"
              (change)="onChange()"
              name="product-group"
            >
              <option
                *ngFor="let productGroup of asyncProductGroups | async"
                value="{{ productGroup.id }}"
                >{{ productGroup.name }}</option
              >
            </select>
          </div>
          <div class="form-group">
            <button
              *appIfAuthorizedTo="'EditProducts'"
              type="button"
              class="btn btn-sm btn-success"
              routerLink="/products/add"
              [queryParams]="{ groupId: selectedProductGroupId }"
            >
              Yeni Ürün
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body table-responsive">
      <table class="table table-bordered table-striped">
        <thead *ngIf="totalCount > 0">
          <tr>
            <th>Ürün Adı</th>
            <th *appIfAuthorizedTo="'EditProducts'"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let product of asyncProducts
                | async
                | paginate
                  : { itemsPerPage: perPage, currentPage: pageNumber, totalItems: totalCount }
            "
          >
            <td>
              <a
                [routerLink]="['/products', product.id, 'detail']"
                [queryParams]="{ groupId: selectedProductGroupId }"
                >{{ product.name }}</a
              >
            </td>
            <td *appIfAuthorizedTo="'EditProducts'" class="no-break">
              <a
                [routerLink]="['/products', product.id, 'edit']"
                [queryParams]="{ groupId: selectedProductGroupId }"
                >Düzenle</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body table-responsive" *ngIf="errorType === 'NoProductGroup'">
      <p class="alert alert-info">
        Henüz hiç ürün grubu eklenmemiş. Yeni ürün grubu eklemek için
        <a [routerLink]="['/settings/product-groups']">tıklayın</a>.
      </p>
    </div>
    <div class="box-body table-responsive" *ngIf="totalCount === 0">
      <p class="text-muted">{{ selectedProductGroupName }} grubuna henüz hiç ürün eklenmemiş.</p>
    </div>
    <div class="box-footer clearfix">
      <dipendo-pagination (pageChange)="changePage($event)"></dipendo-pagination>
    </div>
  </div>
</section>
