import { InputBase } from './input-base';

export class InputTextarea extends InputBase<string> {
  controlType = 'textarea';
  rowCount: number;

  constructor(options: {} = {}) {
    super(options);
    this.rowCount = options['rowCount'] || 5;
  }
}
