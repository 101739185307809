<form
  role="form"
  class="form-horizontal"
  [formGroup]="form"
  (ngSubmit)="submitForm($event)"
  autocomplete="off"
  novalidate
>
  <div class="box-header with-border">
    <h3 class="box-title">{{ boxTitle }}</h3>
  </div>

  <div class="box-body">
    <div class="form-group">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div
          class="alert alert-form alert-dismissible"
          [ngClass]="{ 'alert-success success': isSuccess, 'alert-danger': isError }"
          *ngIf="isSuccess || isError"
        >
          <button type="button" class="close" (click)="dismiss()" aria-hidden="true">
            &times;
          </button>
          <h4 *ngIf="isSuccess"><i class="icon fa fa-check"></i> İşlem Başarılı</h4>
          <h4 *ngIf="isError"><i class="icon fa fa-ban"></i> Hata!</h4>
          {{ errorMessage }}
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>

    <ng-container *ngFor="let input of inputs">
      <dipendo-input [input]="input" [form]="form"></dipendo-input>
    </ng-container>
  </div>

  <ng-content></ng-content>

  <div *ngIf="isFooterVisible" class="box-footer">
    <label for="submit" class="col-md-4 control-label"></label>
    <div class="col-md-4">
      <button type="submit" name="submit" id="submit" class="btn btn-primary">Kaydet</button>
    </div>
    <span class="col-md-4 help-block"></span>
  </div>
</form>
