import { Customer } from '../../customers/shared/customer.model';
import { User } from '../../users/shared/user.model';
import { SaleItem } from './sale-item.model';

export class Sale {
  constructor(
    public saleId: number,
    public customerId: number,
    public customer: Customer,
    public userId: number,
    public user: User,
    public recordTime: Date,
    public saleTime: Date,
    public totalCost: number,
    public currency: string,
    public status: number,
    public deliveryTime: Date,
    public explanation: string,
    public saleItems: SaleItem[],
    public externalSaleCode: string,
    public informationEmail: string
  ) {}
}
