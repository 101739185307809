import { SupplierListItem } from './supplier-list-item.model';

export class SupplierListResult {
  totalCount: number;
  offset: number;
  limit: number;
  query: string;
  suppliers: SupplierListItem[];

  constructor(totalCount: number, offset: number, limit: number, query: string, suppliers: SupplierListItem[]) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.query = query;
    this.suppliers = suppliers;
  }
}
