import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { InputBase } from '../input-base';
import { InputControlService } from '../input-control.service';

@Component({
  selector: 'dipendo-form',
  templateUrl: './dipendo-form.component.html',
  styleUrls: ['./dipendo-form.component.scss'],
  providers: [InputControlService]
})
export class DipendoFormComponent implements OnInit {
  @Input()
  public inputs: Array<InputBase<any>> = [];

  @Input()
  public boxTitle = '';

  @Input()
  public isFooterVisible = true;

  @Output()
  public submit = new EventEmitter<DipendoFormComponent>();

  @Output()
  public formInit = new EventEmitter<void>();

  public form: FormGroup;
  public isSuccess = false;
  public isError = false;
  public errorMessage = '';

  private formatDatePipe = new FormatDatePipe();

  constructor(private readonly ics: InputControlService) {}

  public setValues(model: object): void {
    for (const property of Object.keys(model)) {
      const formControl = this.form.get(property);

      if (formControl) {
        const date = moment.utc(model[property], [moment.ISO_8601, 'DD.MM.YYYY'], true);

        formControl.setValue(
          date.isValid() ? this.formatDatePipe.transform(date.toDate()) : model[property]
        );
      }
    }
  }

  public ngOnInit() {
    this.form = this.ics.toFromGroup(this.inputs);
    this.formInit.emit();
  }

  public submitForm(event: Event) {
    event.stopPropagation();

    if (this.form.valid) {
      this.submit.emit(this);
    } else {
      this.validate();
    }
  }

  public validate() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
  }

  public dismissAlert() {
    this.isError = false;
    this.isSuccess = false;
  }
}
