<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
    <small>{{ selectedCustomerTitle }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">Özet</h3>
    </div>
    <div class="box-body">
      <dl class="dl-horizontal">
        <dt>Müşteri</dt>
        <dd>
          <a [routerLink]="['/customers', sale?.customer?.customerId, 'detail']">{{
            sale?.customer?.title
          }}</a>
        </dd>
        <dt>Rezerve Yapan Kullanıcı</dt>
        <dd>{{ sale?.user?.firstName + ' ' + sale?.user?.lastName }}</dd>
        <dt>Rezerve Tarihi</dt>
        <dd>
          {{
            sale?.saleTime
              | amFromUtc
              | amLocal
              | amLocale: 'tr'
              | amDateFormat: 'L'
              | ignoreInvalidDate
          }}
        </dd>
        <dt>Gönderim Tarihi</dt>
        <dd>
          {{
            sale?.deliveryTime
              | amFromUtc
              | amLocal
              | amLocale: 'tr'
              | amDateFormat: 'L'
              | ignoreInvalidDate
          }}
        </dd>
        <dt>Müşterinin Sipariş Kodu</dt>
        <dd>{{ sale?.externalSaleCode }}</dd>
        <dt>Açıklama</dt>
        <dd>{{ sale?.explanation }}</dd>
      </dl>
    </div>
  </div>
  <div class="box box-primary">
    <dipendo-form [boxTitle]="'Genel Bilgiler'" [inputs]="inputs" [isFooterVisible]="false">
      <div class="box-header with-border">
        <h3 class="box-title">
          Rezervasyon Kalemleri
        </h3>

        <div class="box-tools">
          <button type="button" class="btn btn-sm btn-success" (click)="openProductsDialog()">
            Ürün Ekle
          </button>
          <button
            type="button"
            class="btn btn-sm alert-danger delete-button"
            *ngIf="sale.saleId > 0"
            (click)="deleteSale()"
          >
            Rezerve Sil
          </button>
        </div>
      </div>

      <div class="box-body table-responsive">
        <ng-container *ngFor="let productGroupId of productGroupIds; let i = index">
          <table
            class="table table-bordered table-striped"
            *ngIf="productGroups.has(productGroupId)"
          >
            <thead>
              <tr>
                <th style="width: 10px;">
                  <input
                    type="checkbox"
                    autocomplete="off"
                    [(ngModel)]="headerSelectionList[i].selected"
                    (change)="onChangeHeaderCheckbox(i)"
                  />
                </th>
                <th>Ürün Adı</th>
                <th>Stok Miktarı</th>
                <th>Liste Fiyatı</th>
                <th>Satış Fiyatı</th>
                <th>Para Birimi</th>
                <th>Adet</th>
                <th>Miktar</th>
                <th>Birim</th>
                <th>Toplam Fiyat</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let saleItem of sale.saleItems | matchProductGroup: productGroupId">
                <td>
                  <input
                    type="checkbox"
                    autocomplete="off"
                    [(ngModel)]="saleItem.selected"
                    (change)="onChangeItemCheckbox(saleItem, i)"
                  />
                </td>
                <td>{{ saleItem.purchaseItem.product.name }}</td>
                <td>
                  {{
                    saleItem.purchaseItem.status === 4 ? saleItem.purchaseItem.reservableCount : ''
                  }}
                </td>
                <td>
                  {{ saleItem.purchaseItem.product.listPrice | number: '1.2-4' }}
                  {{ saleItem.purchaseItem.product.currencyCode }}
                </td>
                <td>
                  <input
                    type="number"
                    step="0.0001"
                    min="0"
                    required
                    autocomplete="off"
                    class="form-control input-sm input-number-validatable"
                    [(ngModel)]="saleItem.price"
                    (blur)="onInputBlur(saleItem)"
                  />
                </td>
                <td>
                  {{ saleItem.currencyCode }}
                  <span
                    *ngIf="saleItem.currencyCode !== saleItem.purchaseItem.product.currencyCode"
                    class="icon fa fa-warning warning-icon"
                    matTooltip="Bu ürünün liste fiyatına ait para birimi siz sepetinizi kaydettikten sonra değiştirilmiş."
                    aria-label="Bu ürünün liste fiyatına ait para birimi siz sepetinizi kaydettikten sonra değiştirilmiş."
                  ></span>
                </td>
                <td>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    required
                    autocomplete="off"
                    class="form-control input-sm input-number-validatable"
                    [(ngModel)]="saleItem.count"
                    *ngIf="productGroups.get(productGroupId).unitGroup !== 'number'"
                    (blur)="onInputBlur(saleItem)"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    step="0.001"
                    min="0"
                    required
                    autocomplete="off"
                    class="form-control input-sm input-number-validatable"
                    [(ngModel)]="saleItem.saleCount"
                    (blur)="onInputBlur(saleItem)"
                  />
                </td>
                <td
                  [innerHTML]="
                    productGroups.get(productGroupId).unit
                      | measurementUnitSymbol: productGroups.get(productGroupId).unitGroup
                  "
                ></td>
                <th class="text-right">
                  <span *ngIf="saleItem.totalPrice > 0"
                    >{{ saleItem.totalPrice | number: '1.2-4' }} {{ saleItem.currencyCode }}</span
                  >
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th colspan="2" class="text-right">TOPLAM</th>
                <th class="text-right">
                  <span *ngFor="let price of totalPriceList.get(productGroupId)"
                    >{{ price }} <br
                  /></span>
                </th>
              </tr>
            </tfoot>
          </table>
          <br />
        </ng-container>
        <table *ngIf="productGroupIds.length > 1" class="table table-bordered table-striped">
          <tfoot>
            <tr>
              <th class="text-right">GENEL TOPLAM</th>
              <th class="text-right nostretch">
                <span *ngFor="let price of grandTotalPriceList">{{ price }} <br /></span>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="box-body table-responsive" *ngIf="totalCount === 0">
        <p class="text-muted">
          Sepete henüz hiç ürün eklenmemiş. Devam etmek için lütfen ürün ekleyiniz.
        </p>
      </div>

      <div class="box-footer" *ngIf="sale.saleItems?.length">
        <div class="col-md-3"></div>
        <div class="col-md-2">
          <button
            type="submit"
            name="delete-selected-items-button"
            id="delete-selected-items-button"
            class="btn btn-sm alert-danger"
            *ngIf="selectedCount > 0"
            (click)="deleteSelectedItems()"
          >
            Seçilenleri Sil
          </button>
        </div>
        <div class="col-md-2">
          <button
            type="submit"
            name="save-as-draft-button"
            id="save-as-draft-button"
            class="btn btn-primary"
            [disabled]="isSaving"
            (click)="save(4)"
          >
            Rezerve Güncelle
          </button>
        </div>
        <div class="col-md-2">
          <button
            type="submit"
            name="create-sale-button"
            id="create-sale-button"
            class="btn btn-success"
            [disabled]="isSaving"
            (click)="addSelectedItems()"
          >
            Tamamla
          </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </dipendo-form>
  </div>
</section>
