<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body container-fluid">
  <div *ngIf="message" [innerHTML]="message"></div>
  
  <form role="form" class="form-horizontal" [formGroup]="form" autocomplete="off" novalidate>

    <ng-container *ngFor="let input of inputs">
      <dipendo-input [input]="input" [form]="form"></dipendo-input>
    </ng-container>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="activeModal.dismiss()">İptal</button>
  <button type="button" class="btn btn-primary" (click)="close()">{{ primaryButtonLabel ? primaryButtonLabel : (isEdit ? 'Güncelle' : 'Ekle') }}</button>
</div>
