import { User } from './user.model';

export class UserListResult {
  totalCount: number;
  users: User[];

  constructor(totalCount: number, users: User[]) {
    this.totalCount = totalCount;
    this.users = users;
  }
}
