<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body container-fluid">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="activeModal.dismiss()">İptal</button>
  <button type="button" class="btn btn-primary" (click)="continue()">Devam</button>
</div>
