import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertType } from '../core/alert-type.model';
import { AlertService } from '../core/alert.service';
import { DipendoFormComponent } from '../shared/forms/dipendo-form/dipendo-form.component';
import { InputBase } from '../shared/forms/input-base';
import { InputDropdown } from '../shared/forms/input-dropdown';
import { InputTextbox } from '../shared/forms/input-textbox';
import { ProfileBinding } from './profile-binding.model';
import { Profile } from './profile.model';
import { ProfileService } from './profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements AfterViewInit, OnDestroy, OnInit {
  public inputs: any[];
  public profile: Profile;

  @ViewChild(DipendoFormComponent, { static: true })
  private readonly formComponent: DipendoFormComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly alertService: AlertService,
    private readonly profileService: ProfileService
  ) {}

  public ngOnInit(): void {
    this.inputs = this.getInputs();
  }

  public ngAfterViewInit(): void {
    this.profileService
      .get()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.profile = res;

        this.formComponent.setValues(this.profile);
      });
  }

  public onSubmit(formComponent: DipendoFormComponent) {
    this.profileService
      .update(this.prepareBindingObject(formComponent))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.alertService.setAlert(AlertType.success, 'Profiliniz başarıyla güncellendi.');
          this.ngAfterViewInit();
        },
        (err) => {
          formComponent.isError = true;
          formComponent.errorMessage = err.error.message;
        }
      );
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getInputs(): Array<InputBase<any>> {
    const inputs: Array<InputBase<any>> = [
      new InputTextbox({
        key: 'firstName',
        label: 'Ad',
        type: 'text',
        value: '',
        required: true,
        order: 1
      }),
      new InputTextbox({
        key: 'lastName',
        label: 'Soyad',
        type: 'text',
        value: '',
        required: true,
        order: 2
      }),
      new InputTextbox({
        key: 'email',
        label: 'E-posta',
        type: 'email',
        value: '',
        required: true,
        disabled: true,
        order: 3
      }),
      new InputDropdown({
        key: 'culture',
        label: 'Dil',
        options: [{ key: 'tr-TR', value: 'Türkçe' }],
        value: 'true',
        order: 4
      }),
      new InputTextbox({
        key: 'currentPassword',
        label: 'Mevcut Şifre',
        type: 'password',
        value: '',
        order: 5
      }),
      new InputTextbox({
        key: 'newPassword',
        label: 'Yeni Şifre',
        type: 'password',
        value: '',
        order: 6
      }),
      new InputTextbox({
        key: 'newPasswordConfirmation',
        label: 'Yeni Şifre (Tekrar)',
        type: 'password',
        value: '',
        order: 7
      })
    ];

    return inputs.sort((a, b) => a.order - b.order);
  }

  private prepareBindingObject(formComponent: DipendoFormComponent): ProfileBinding {
    return {
      firstName: formComponent.form.value.firstName,
      lastName: formComponent.form.value.lastName,
      currentPassword: formComponent.form.value.currentPassword,
      newPassword: formComponent.form.value.newPassword,
      newPasswordConfirmation: formComponent.form.value.newPasswordConfirmation
    };
  }
}
