import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertType } from '../../core/alert-type.model';
import { AlertService } from '../../core/alert.service';
import { UploadFileConfirmComponent } from './upload-file-confirm/upload-file-confirm';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnChanges {
  public files: any[] = [];
  public deletedFileIndex: number = -1;
  public checkedAll: boolean = false;

  @Output()
  public fileSave: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output()
  public change: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output()
  public fileDownload: EventEmitter<any> = new EventEmitter<any>();
  @Input() public fileList: any[];

  constructor(
    private readonly modalService: NgbModal,
    private readonly alertService: AlertService
  ) {}

  public ngOnInit() {
    this.setFileList();
  }

  public setResult(list) {
    this.fileList = list;

    this.files = this.fileList;
    let index = 0;
    this.checkedAll = true;
    this.files.forEach((file) => {
      file.index = index;
      if (!file.selected) {
        this.checkedAll = false;
      }
      index++;
    });

    if (this.files.length < 1) {
      this.checkedAll = false;
    }
  }

  public setFileList() {
    this.files = this.fileList;
    let index = 0;
    this.checkedAll = true;
    this.files.forEach((file) => {
      file.name = file.fileName ? file.fileName : file.name;
      file.type = file.mimeType ? file.mimeType : file.type;
      file.selected = file.selected ? file.selected : false;
      file.index = index;
      if (!file.selected) {
        this.checkedAll = false;
      }
      index++;
    });
    if (this.files.length < 1) {
      this.checkedAll = false;
    }
  }

  public ngOnChanges(change: SimpleChanges) {
    if (change.fileList) {
      this.setFileList();
    }
  }

  public onFileContainerClick(fileInput) {
    fileInput.value = null;
    fileInput.click();
  }

  public uploadFile(event) {
    for (const element of event) {
      if (element.type === '') {
        this.alertService.setAlert(
          AlertType.danger,
          'Dosya olarak klasör yükleyemezsiniz! Lütfen klasör içindeki dosyaları seçip yükleyiniz.'
        );
        window.scrollTo(0, 0);
      } else {
        this.files.push(element);
      }
    }
    let index = 0;
    this.files.forEach((file) => {
      file.index = index;
      index++;
    });
    this.change.emit(this.files);
  }

  public onChangeCheckbox() {
    this.change.emit(this.files);
  }

  public onChangeAllCheckbox() {
    this.files.forEach((file) => {
      file.selected = this.checkedAll;
    });
    this.change.emit(this.files);
  }

  public deleteAttachment(index) {
    const confirmModal = this.modalService.open(UploadFileConfirmComponent);
    if (index > -1) {
      this.files[index].isDeleted = true;
      confirmModal.componentInstance.fileId = index;
    } else {
      this.files.forEach((file) => {
        if (file.selected) {
          file.isDeleted = true;
        }
      });
    }
    confirmModal.componentInstance.title = 'ONAY';
    confirmModal.componentInstance.message = 'Dosya silinecektir. Onaylıyor musunuz?';

    confirmModal.result.then(
      (modalResult) => {
        this.change.emit(this.files);
      },
      (err) => {}
    );
  }

  public showFile(file) {
    this.fileDownload.emit(file);
  }

  public saveChanges() {
    this.fileSave.emit(this.files);
  }
}
