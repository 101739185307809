import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Globals } from '../globals';

@Injectable()
export class ClientInfoInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clientInfoReq = req;

    if (req.url.indexOf('/oauth/token') < 0) {
      clientInfoReq = req.clone({
        headers: req.headers
          .set('X-Client-Name', Globals.client.id)
          .set('X-Client-Version', Globals.client.version)
      });
    }

    return next.handle(clientInfoReq);
  }
}
