import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, share, takeUntil, tap } from 'rxjs/operators';

import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { ProductGroupListItem } from '../../settings/product-groups/shared/product-group-list-item.model';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { StockListItemProperty } from '../stock-list-item-property.model';
import { StockListItem } from '../stock-list-item.model';
import { StockService } from '../stock.service';

@Component({
  selector: 'app-stock-detail',
  templateUrl: './stock-detail.component.html',
  styleUrls: ['./stock-detail.component.scss']
})
export class StockDetailComponent implements OnDestroy, OnInit {
  public pageName = 'Stok Ayrıntıları';
  public productGroups: ProductGroupListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupListItem[]>;
  public selectedProductGroup: ProductGroup;
  public selectedProductGroupId = 0;
  public selectedProductGroupName = '';
  public selectedProperties: StockListItemProperty[] = [];
  public stockItems: StockListItem[] = [];
  public asyncStockItems: Observable<StockListItem[]>;
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly productGroupService: ProductGroupService,
    private readonly router: Router,
    private readonly stockService: StockService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;
      this.selectedProperties = JSON.parse(params['properties']) || [];

      this.getProductGroups();
    });
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService.getAll(0, 10000).pipe(
      tap((res) => {
        this.productGroups = res.productGroups;

        if (res.totalCount > 0 && this.selectedProductGroupId === 0) {
          this.selectedProductGroupId = res.productGroups[0].id;
        }

        if (this.selectedProductGroupId > 0) {
          this.selectedProductGroupName = res.productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          ).name;
        }

        this.productGroupService
          .get(this.selectedProductGroupId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((detailRes) => {
            this.selectedProductGroup = detailRes;
            this.selectedProductGroup.productGroupProperties.sort((a, b) => a.order - b.order);

            this.getPage(this.pageNumber);
          });
      }),
      map((res) => res.productGroups),
      share()
    );

    this.asyncProductGroups.pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public changePage(page: number) {
    this.router.navigate(['/stock/detail'], page > 1 ? { queryParams: { page } } : {});
  }

  public getPage(page: number) {
    this.asyncStockItems = this.stockService
      .getDetail(
        this.selectedProductGroupId,
        this.selectedProperties,
        (page - 1) * this.perPage,
        this.perPage
      )
      .pipe(
        tap((res) => {
          this.totalCount = res.totalCount;
          this.pageNumber = page;
          this.stockItems = res.stockItems;

          this.stockItems.forEach((s) => {
            s.product.propertyValues.sort((a, b) => a.propertyOrder - b.propertyOrder);
          });
        }),
        map((res) => {
          return res.stockItems;
        })
      );
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
