import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { SupplierListItem } from './supplier-list-item.model';
import { SupplierListResult } from './supplier-list-result.model';
import { SupplierPersonalNoteBinding } from './supplier-personal-note-binding.model';
import { SupplierPurchaseItemsSummaryResult } from './supplier-purchase-items-summary-result.model';
import { SupplierPurchaseItemsSummary } from './supplier-purchase-items-summary.model';
import { SupplierPurchasesChartSummaryResult } from './supplier-purchases-chart-summary-result.model';
import { SupplierPurchasesChartSummary } from './supplier-purchases-chart-summary.model';
import { Supplier } from './supplier.model';

@Injectable()
export class SupplierService {
  private readonly endpoint = `${environment.baseApiUrl}suppliers`;

  constructor(private readonly http: HttpClient) {}

  public getAll(offset = 0, limit = 10): Observable<SupplierListResult> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    return this.http
      .get<SupplierListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new SupplierListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            null,
            res.body
          );
        })
      );
  }

  public get(id: number): Observable<Supplier> {
    return this.http.get<Supplier>(`${this.endpoint}/${id}`);
  }

  public getPurchasesChartSummary(
    id: number,
    startTime: Date = null
  ): Observable<SupplierPurchasesChartSummaryResult> {
    const params = new HttpParams().set('startTime', startTime ? startTime.toISOString() : '');

    return this.http
      .get<SupplierPurchasesChartSummary[]>(`${this.endpoint}/${id}/purchases-chart-summary`, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new SupplierPurchasesChartSummaryResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            res.body
          );
        })
      );
  }

  public searchPurchaseItems(
    id: number,
    pagedSearchQueries: Observable<PagedSearchQuery>
  ): Observable<SupplierPurchaseItemsSummaryResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) => this.getPurchaseItemsSummary(id, pagedSearchQuery))
    );
  }

  public getPurchaseItemsSummary(
    id: number,
    pagedSearchQuery: PagedSearchQuery
  ): Observable<SupplierPurchaseItemsSummaryResult> {
    const params = new HttpParams()
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString())
      .set('startTime', pagedSearchQuery.startTime ? pagedSearchQuery.startTime.toISOString() : '')
      .set('endTime', pagedSearchQuery.endTime ? pagedSearchQuery.endTime.toISOString() : '');

    return this.http
      .get<SupplierPurchaseItemsSummary[]>(
        `${this.endpoint}/${id}/purchase-items-summary?search=${pagedSearchQuery.query.replace(
          /\+/g,
          '%2B'
        )}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new SupplierPurchaseItemsSummaryResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            res.body
          );
        })
      );
  }

  public insert(supplier: Supplier): Observable<Supplier> {
    return this.http.post<Supplier>(this.endpoint, supplier);
  }

  public update(id: number, supplier: Supplier): Observable<Supplier> {
    return this.http.put<Supplier>(`${this.endpoint}/${id}`, supplier);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/${id}`);
  }

  public search(pagedSearchQueries: Observable<PagedSearchQuery>): Observable<SupplierListResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) => this.searchItems(pagedSearchQuery))
    );
  }

  public savePersonalNote(id: number, personalNote: SupplierPersonalNoteBinding): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/${id}/personal-note`, personalNote);
  }

  private searchItems(pagedSearchQuery: PagedSearchQuery): Observable<SupplierListResult> {
    const params = new HttpParams()
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString());

    return this.http
      .get<SupplierListItem[]>(
        `${this.endpoint}?search=${pagedSearchQuery.query.replace(/\+/g, '%2B')}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new SupplierListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            pagedSearchQuery.query,
            res.body
          );
        })
      );
  }
}
