import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { DipendoModalFormComponent } from '../../shared/forms/dipendo-modal-form/dipendo-modal-form.component';
import { InputBase } from '../../shared/forms/input-base';
import { InputDatepicker } from '../../shared/forms/input-datepicker';
import { InputTextarea } from '../../shared/forms/input-textarea';
import { InputTextbox } from '../../shared/forms/input-textbox';
import { Purchase } from '../shared/purchase.model';

@Component({
  selector: 'app-purchase-summary-edit-form',
  templateUrl: './purchase-summary-edit-form.component.html'
})
export class PurchaseSummaryEditFormComponent implements OnInit {
  public purchase: Purchase;
  public isEdit = true;
  public inputs: any[];
  public inputLabels = new Map<string, string>();

  @ViewChild(DipendoModalFormComponent, { static: true })
  private readonly formComponent: DipendoModalFormComponent;

  constructor(public readonly activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.inputs = this.getInputs();

    this.inputLabels.forEach((value, key) => {
      const inputIndex = this.inputs.findIndex((x) => x.key === key);
      if (inputIndex > -1) {
        this.inputs[inputIndex].label = value;
      }
    });
  }

  public onFormInit(): void {
    this.fillForm();
  }

  public fillForm() {
    this.formComponent.setValues(this.purchase);
  }

  public onSubmit(formComponent: DipendoModalFormComponent) {
    if (this.purchase) {
      this.activeModal.close(this.prepareBindingPurchase(formComponent));
    } else {
      this.activeModal.close();
    }
  }

  private getInputs(): InputBase<any>[] {
    const inputs: InputBase<any>[] = [
      new InputDatepicker({
        key: 'invoiceTime',
        label: 'Fatura Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        minDates: '',
        required: false,
        order: 1
      }),
      new InputTextbox({
        key: 'invoiceNumber',
        label: 'Fatura No',
        type: 'text',
        value: '',
        maxLength: 50,
        required: false,
        order: 2
      }),
      new InputDatepicker({
        key: 'customsDeclarationTime',
        label: 'Gümrük Beyannamesi Tarihi',
        type: 'text',
        value: '',
        startDate: '01.01.2001',
        minDates: '',
        required: false,
        order: 3
      }),
      new InputTextbox({
        key: 'customsDeclarationNumber',
        label: 'Gümrük Beyannamesi No',
        type: 'text',
        value: '',
        maxLength: 50,
        required: false,
        order: 4
      }),
      new InputTextarea({
        key: 'explanation',
        label: 'Açıklama',
        rowCount: 5,
        value: '',
        required: false,
        order: 5
      })
    ];

    return inputs.sort((a, b) => a.order - b.order);
  }

  private prepareBindingPurchase(formComponent: DipendoModalFormComponent): Purchase {
    this.purchase.invoiceTime = moment(
      this.formComponent.form.value.invoiceTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchase.invoiceNumber = this.formComponent.form.value.invoiceNumber
      ? this.formComponent.form.value.invoiceNumber
      : null;
    this.purchase.customsDeclarationTime = moment(
      this.formComponent.form.value.customsDeclarationTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchase.customsDeclarationNumber = this.formComponent.form.value.customsDeclarationNumber
      ? this.formComponent.form.value.customsDeclarationNumber
      : null;
    this.purchase.explanation = formComponent.form.value.explanation
      ? formComponent.form.value.explanation
      : 'Satın alma yapıldı.';

    return this.purchase;
  }
}
