import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { AppComponent } from '../../core/app.component';
import { PurchaseItemResult } from '../../purchases/shared/purchase-item-result.model';
import { PurchaseItem } from '../../purchases/shared/purchase-item.model';
import { PurchaseItemDetailService } from '../../purchases/shared/purchase-item.service';
import { ProductGroup } from '../../settings/product-groups/shared/product-group.model';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { SaleItemListItem } from '../../warehouse/shared/sale-item-list-item.model';
import { SaleItemListResult } from '../../warehouse/shared/sale-item-list-result.model';
import { SaleItemService } from '../../warehouse/shared/sale-item.service';
import { ProductListItem } from '../shared/product-list-item.model';
import { Product } from '../shared/product.model';
import { ProductService } from '../shared/product.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnDestroy, OnInit {
  public errorType = '';
  public pageName = 'Ürün Detayı';
  public product: Product = null;
  public asyncProduct: Observable<Product>;
  public asyncOrdered: Observable<PurchaseItemResult>;
  public asyncShipped: Observable<PurchaseItemResult>;
  public asyncWaitingForStorage: Observable<PurchaseItemResult>;
  public asyncInWarehouse: Observable<PurchaseItemResult>;
  public ordered: PurchaseItem[];
  public shipped: PurchaseItem[];
  public waitingForStorage: PurchaseItem[];
  public inWarehouse: PurchaseItem[];
  public asyncWaitingForShipping: Observable<SaleItemListResult>;
  public asyncReadyForDispatch: Observable<SaleItemListResult>;
  public asyncShippingFromWarehouse: Observable<SaleItemListResult>;
  public waitingForShipping: SaleItemListItem[];
  public readyForDispatch: SaleItemListItem[];
  public shippingFromWarehouse: SaleItemListItem[];
  public purchaseItemId = 0;
  public selectedProductGroupId = 0;
  public selectedProductName = '';
  public products: ProductListItem[] = [];
  public measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  public productId = 0;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly purchaseItemService: PurchaseItemDetailService,
    private readonly productService: ProductService,
    private readonly saleItemService: SaleItemService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);
    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.selectedProductGroupId = parseInt(queryParams['groupId'], 10) || 0;
      this.activatedRoute.url.subscribe((url) => {
        this.activatedRoute.params.subscribe((params) => {
          this.productId = parseInt(params.id, 10) || -1;
          this.getProduct();
          this.getPurchaseItems();
          this.getSalesItems();
        });
      });
    });
  }

  public getProduct() {
    this.asyncProduct = this.productService.get(this.productId).pipe(
      tap((res) => {
        this.product = res;
        this.selectedProductName = this.product.name;
      }),
      map((res) => {
        return res;
      })
    );
  }

  public getPurchaseItems() {
    this.asyncOrdered = this.purchaseItemService.getAll(0, 5, this.productId, 1).pipe(
      tap((res) => {
        this.ordered = res.purchases;
      }),
      map((res) => {
        return res;
      })
    );
    this.asyncShipped = this.purchaseItemService.getAll(0, 5, this.productId, 2).pipe(
      tap((res) => {
        this.shipped = res.purchases;
      }),
      map((res) => {
        return res;
      })
    );
    this.asyncWaitingForStorage = this.purchaseItemService.getAll(0, 5, this.productId, 3).pipe(
      tap((res) => {
        this.waitingForStorage = res.purchases;
      }),
      map((res) => {
        return res;
      })
    );
    this.asyncInWarehouse = this.purchaseItemService.getAll(0, 5, this.productId, 4).pipe(
      tap((res) => {
        this.inWarehouse = res.purchases;
      }),
      map((res) => {
        return res;
      })
    );
  }

  public getSalesItems() {
    this.asyncWaitingForShipping = this.saleItemService
      .getAll(0, 1, 0, 0, 5, null, null, null, this.productId)
      .pipe(
        tap((res) => {
          this.waitingForShipping = res.saleItems;
        }),
        map((res) => {
          return res;
        })
      );
    this.asyncReadyForDispatch = this.saleItemService
      .getAll(0, 2, 0, 0, 5, null, null, null, this.productId)
      .pipe(
        tap((res) => {
          this.readyForDispatch = res.saleItems;
        }),
        map((res) => {
          return res;
        })
      );
    this.asyncShippingFromWarehouse = this.saleItemService
      .getAll(0, 3, 0, 0, 5, null, null, null, this.productId)
      .pipe(
        tap((res) => {
          this.shippingFromWarehouse = res.saleItems;
        }),
        map((res) => {
          return res;
        })
      );
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
