<div class="login-box">
  <div class="login-logo">
    <a ui-sref="app"><b>Dipendo</b></a>
  </div>
  <div class="login-box-body">
    <p class="login-box-msg">Başlamak için lütfen giriş yapın</p>

    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
      <ng-container *ngIf="alertService.getAlert()">
        <div class="form-group">
          <div class="alert alert-danger">
            {{ alertService.getAlert().message }}
          </div>
        </div>
      </ng-container>
      <div class="form-group has-feedback" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
        <input type="email" name="username" class="form-control" placeholder="E-posta" [(ngModel)]="model.username" #username="ngModel" required />
        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
      </div>
      <div class="form-group has-feedback" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
        <input type="password" name="password "class="form-control" placeholder="Şifre" [(ngModel)]="model.password" #password="ngModel" required />
        <span class="glyphicon glyphicon-lock form-control-feedback"></span>
      </div>
      <div class="row">
        <div class="col-xs-8">
          <a routerLink="/forgot-password">Şifremi Unuttum</a>
        </div>
        <div class="col-xs-4">
          <button [disabled]="loading" type="submit" class="btn btn-primary btn-block btn-flat" id="login-form-submit-button">Giriş</button>
        </div>
      </div>
    </form>

  </div>
</div>
