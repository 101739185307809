import { SupplierPurchasesChartSummary } from './supplier-purchases-chart-summary.model';

export class SupplierPurchasesChartSummaryResult {
  public totalCount: number;
  public supplierPurchasesChartSummaries: SupplierPurchasesChartSummary[];

  constructor(
    totalCount: number,
    supplierPurchasesChartSummaries: SupplierPurchasesChartSummary[]
  ) {
    this.totalCount = totalCount;
    this.supplierPurchasesChartSummaries = supplierPurchasesChartSummaries;
  }
}
