import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { DipendoModalFormComponent } from '../../shared/forms/dipendo-modal-form/dipendo-modal-form.component';
import { InputBase } from '../../shared/forms/input-base';
import { InputDatepicker } from '../../shared/forms/input-datepicker';
import { PurchaseItemListItem } from '../../warehouse/shared/purchase-item-list-item.model';

@Component({
  selector: 'app-purchase-item-edit-form',
  templateUrl: './purchase-item-edit-form.component.html',
  styleUrls: ['./purchase-item-edit-form.component.scss']
})
export class PurchaseItemEditFormComponent implements OnInit {
  public purchaseItem: PurchaseItemListItem;
  public isEdit = true;
  public inputs: any[];
  public inputLabels = new Map<string, string>();
  public itemCount: number;
  public title: string;
  public message: string;
  public primaryButtonLabel: string;

  @ViewChild(DipendoModalFormComponent, { static: true })
  private readonly formComponent: DipendoModalFormComponent;

  constructor(public readonly activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.inputs = this.getInputs();

    this.inputLabels.forEach((value, key) => {
      const inputIndex = this.inputs.findIndex((x) => x.key === key);
      if (inputIndex > -1) {
        this.inputs[inputIndex].label = value;
      }
    });
  }

  public onFormInit(): void {
    if (this.itemCount === 1) {
      this.fillForm();
    }
  }

  public fillForm() {
    this.formComponent.setValues(this.purchaseItem);
  }

  public onSubmit(formComponent: DipendoModalFormComponent) {
    if (this.purchaseItem) {
      this.activeModal.close(this.prepareBindingPurchaseItem(formComponent));
    } else {
      this.activeModal.close();
    }
  }

  private getInputs(): Array<InputBase<any>> {
    const inputs: Array<InputBase<any>> = [
      new InputDatepicker({
        key: 'letterOfCreditTime',
        label: 'Akreditif Tarihi',
        type: 'text',
        value: '',
        maxDates: 'estimatedShippingTime,estimatedArrivalTime,arrivalTime',
        required: false,
        order: 1
      }),
      new InputDatepicker({
        key: 'estimatedShippingTime',
        label: 'Tahmini Gönderim Tarihi',
        type: 'text',
        value: '',
        minDates: 'letterOfCreditTime',
        maxDates: 'estimatedArrivalTime,arrivalTime',
        required: false,
        order: 2
      }),
      new InputDatepicker({
        key: 'estimatedArrivalTime',
        label: 'Tahmini Varış Tarihi',
        type: 'text',
        value: '',
        minDates: 'estimatedShippingTime,letterOfCreditTime',
        maxDates: 'arrivalTime',
        required: false,
        order: 3
      }),
      new InputDatepicker({
        key: 'arrivalTime',
        label: 'Kesin Varış Tarihi',
        type: 'text',
        value: '',
        minDates: 'estimatedArrivalTime,estimatedShippingTime,letterOfCreditTime',
        required: false,
        order: 4
      })
    ];

    return inputs.sort((a, b) => a.order - b.order);
  }

  private prepareBindingPurchaseItem(
    formComponent: DipendoModalFormComponent
  ): PurchaseItemListItem {
    this.purchaseItem.letterOfCreditTime = moment(
      formComponent.form.value.letterOfCreditTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchaseItem.estimatedShippingTime = moment(
      formComponent.form.value.estimatedShippingTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchaseItem.estimatedArrivalTime = moment(
      formComponent.form.value.estimatedArrivalTime,
      'DD.MM.YYYY'
    ).toDate();
    this.purchaseItem.arrivalTime = moment(
      formComponent.form.value.arrivalTime,
      'DD.MM.YYYY'
    ).toDate();

    return this.purchaseItem;
  }
}
