<section class="content-header">
  <h1>
    {{pageName}}
  </h1>
  <ol class="breadcrumb">
    <li><a href="#"><i class="fa fa-dashboard"></i> Ana Sayfa</a></li>
    <li class="active">{{pageName}}</li>
  </ol>
</section>
<section class="content">
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">Dipendo</h3>
      <div class="box-tools pull-right">
      </div>
    </div>
    <div class="box-body">
      Telif Hakkı &copy; 2016 Dipendo. Her hakkı saklıdır.
    </div>
  </div>
</section>
