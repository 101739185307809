<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
    <small>{{ selectedProductName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<!-- Main content -->
<section class="content" *ngIf="asyncProduct | async">
  <!-- Default box -->
  <div class="box-body">
    <div class="col-md-4 table-responsive" *ngIf="(asyncOrdered | async) && ordered.length > 0">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Siparişler</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Tedarikçi</th>
                <th>Satın Alma Fiyatı</th>
                <th>Miktar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let purchaseItem of ordered">
                <td>
                  <a [routerLink]="['/suppliers', purchaseItem.supplierId, 'detail']">{{
                    purchaseItem.supplierTitle.length > 20
                      ? purchaseItem.supplierTitle.substr(0, 20) + '...'
                      : purchaseItem.supplierTitle
                  }}</a>
                </td>
                <td>{{ purchaseItem.cost | number: '1.2-4' }} {{ purchaseItem.currencyCode }}</td>
                <td>
                  {{ purchaseItem.purchaseCount }}
                  {{
                    purchaseItem.product.groupUnit
                      | measurementUnitSymbol: purchaseItem.product.groupUnitGroup
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-footer clearfix">
          <a
            class="pull-right"
            [routerLink]="['/purchase/ordered']"
            [queryParams]="{ productId: productId }"
            >Hepsini Göster</a
          >
        </div>
      </div>
    </div>

    <div class="col-md-4 table-responsive" *ngIf="(asyncShipped | async) && shipped.length > 0">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Transfer Edilenler</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Tedarikçi</th>
                <th>Satın Alma Fiyatı</th>
                <th>Miktar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let purchaseItem of shipped">
                <td>
                  <a [routerLink]="['/suppliers', purchaseItem.supplierId, 'detail']">{{
                    purchaseItem.supplierTitle.length > 20
                      ? purchaseItem.supplierTitle.substr(0, 20) + '...'
                      : purchaseItem.supplierTitle
                  }}</a>
                </td>
                <td>{{ purchaseItem.cost | number: '1.2-4' }} {{ purchaseItem.currencyCode }}</td>
                <td>
                  {{ purchaseItem.purchaseCount }}
                  {{
                    purchaseItem.product.groupUnit
                      | measurementUnitSymbol: purchaseItem.product.groupUnitGroup
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-footer clearfix">
          <a
            class="pull-right"
            [routerLink]="['/purchase/shipped']"
            [queryParams]="{ productId: productId }"
            >Hepsini Göster</a
          >
        </div>
      </div>
    </div>

    <div
      class="col-md-4 table-responsive"
      *ngIf="(asyncWaitingForStorage | async) && waitingForStorage.length > 0"
    >
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Depo Giriş Bekleyenler</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Tedarikçi</th>
                <th>Miktar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let purchaseItem of waitingForStorage">
                <td>
                  <a [routerLink]="['/suppliers', purchaseItem.supplierId, 'detail']">{{
                    purchaseItem.supplierTitle.length > 20
                      ? purchaseItem.supplierTitle.substr(0, 20) + '...'
                      : purchaseItem.supplierTitle
                  }}</a>
                </td>
                <td>
                  {{ purchaseItem.purchaseCount }}
                  {{
                    purchaseItem.product.groupUnit
                      | measurementUnitSymbol: purchaseItem.product.groupUnitGroup
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-footer clearfix">
          <a
            class="pull-right"
            [routerLink]="['/purchase/waiting-for-storage']"
            [queryParams]="{ productId: productId, groupId: selectedProductGroupId }"
            >Hepsini Göster</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-md-4 table-responsive"
      *ngIf="(asyncInWarehouse | async) && inWarehouse.length > 0"
    >
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Depodakiler</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Tedarikçi</th>
                <th>Miktar</th>
                <th>Liste Fiyatı</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let purchaseItem of inWarehouse">
                <td>
                  <a [routerLink]="['/suppliers', purchaseItem.supplierId, 'detail']">{{
                    purchaseItem.supplierTitle.length > 20
                      ? purchaseItem.supplierTitle.substr(0, 20) + '...'
                      : purchaseItem.supplierTitle
                  }}</a>
                </td>
                <td>
                  {{ purchaseItem.stockCount }}
                  {{
                    purchaseItem.product.groupUnit
                      | measurementUnitSymbol: purchaseItem.product.groupUnitGroup
                  }}
                </td>
                <td>
                  {{ purchaseItem.product.listPrice | number: '1.2-4' }}
                  {{ purchaseItem.product.currencyCode }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-footer clearfix">
          <a
            class="pull-right"
            [routerLink]="['/warehouse/in-warehouse']"
            [queryParams]="{ productId: productId, groupId: selectedProductGroupId }"
            >Hepsini Göster</a
          >
        </div>
      </div>
    </div>

    <div
      class="col-md-4 table-responsive"
      *ngIf="(asyncWaitingForShipping | async) && waitingForShipping.length > 0"
    >
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Çıkış Bekleyenler</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Miktar</th>
                <th>Müşteri</th>
                <th>Satış Fiyatı</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let saleItem of waitingForShipping">
                <td>
                  {{ saleItem.saleCount }}
                  {{
                    saleItem.purchaseItem.product.groupUnit
                      | measurementUnitSymbol: saleItem.purchaseItem.product.groupUnitGroup
                  }}
                </td>
                <td>
                  <a [routerLink]="['/customers', saleItem.customer.id, 'detail']">{{
                    saleItem.customer.title.length > 20
                      ? saleItem.customer.title.substr(0, 20) + '...'
                      : saleItem.customer.title
                  }}</a>
                </td>
                <td>{{ saleItem.price | number: '1.2-4' }} {{ saleItem.currencyCode }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-footer clearfix">
          <a
            class="pull-right"
            [routerLink]="['/sale/waiting-for-shipment']"
            [queryParams]="{ productId: productId, groupId: selectedProductGroupId }"
            >Hepsini Göster</a
          >
        </div>
      </div>
    </div>

    <div
      class="col-md-4 table-responsive"
      *ngIf="(asyncReadyForDispatch | async) && readyForDispatch.length > 0"
    >
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Sevke Hazır</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Miktar</th>
                <th>Müşteri</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let saleItem of readyForDispatch">
                <td>
                  {{ saleItem.saleCount }}
                  {{
                    saleItem.purchaseItem.product.groupUnit
                      | measurementUnitSymbol: saleItem.purchaseItem.product.groupUnitGroup
                  }}
                </td>
                <td>
                  <a [routerLink]="['/customers', saleItem.customer.id, 'detail']">{{
                    saleItem.customer.title.length > 20
                      ? saleItem.customer.title.substr(0, 20) + '...'
                      : saleItem.customer.title
                  }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-footer clearfix">
          <a
            class="pull-right"
            [routerLink]="['/sale/ready-for-dispatch']"
            [queryParams]="{ productId: productId, groupId: selectedProductGroupId }"
            >Hepsini Göster</a
          >
        </div>
      </div>
    </div>
    <div
      class="col-md-4 table-responsive"
      *ngIf="(asyncShippingFromWarehouse | async) && shippingFromWarehouse.length > 0"
    >
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Gönderilenler</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Miktar</th>
                <th>Müşteri</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let saleItem of shippingFromWarehouse">
                <td>
                  {{ saleItem.saleCount }}
                  {{
                    saleItem.purchaseItem.product.groupUnit
                      | measurementUnitSymbol: saleItem.purchaseItem.product.groupUnitGroup
                  }}
                </td>
                <td>
                  <a [routerLink]="['/customers', saleItem.customer.id, 'detail']">{{
                    saleItem.customer.title.length > 20
                      ? saleItem.customer.title.substr(0, 20) + '...'
                      : saleItem.customer.title
                  }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-footer clearfix">
          <a
            class="pull-right"
            [routerLink]="['/sale/shipped-from-warehouse']"
            [queryParams]="{ productId: productId, groupId: selectedProductGroupId }"
            >Hepsini Göster</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
