import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PagedSearchQuery } from '../../shared/paged-search-query.model';
import { ProductListItem } from './product-list-item.model';
import { ProductListResult } from './product-list-result.model';
import { Product } from './product.model';

@Injectable()
export class ProductService {
  private readonly endpoint = `${environment.baseApiUrl}products`;

  constructor(private readonly http: HttpClient) {}

  public getAll(
    groupId = 0,
    offset = 0,
    limit = 10,
    groupIdList: number[] = []
  ): Observable<ProductListResult> {
    const params = new HttpParams()
      .set('groupId', groupId.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('groupIds', groupIdList.join(';'));

    return this.http
      .get<ProductListItem[]>(this.endpoint, {
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return new ProductListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            offset,
            limit,
            null,
            res.body
          );
        })
      );
  }

  public search(
    groupIdList: number[] = [],
    pagedSearchQueries: Observable<PagedSearchQuery>
  ): Observable<ProductListResult> {
    return pagedSearchQueries.pipe(
      debounce((val) => (val.isAutoLoad ? timer(1) : timer(400))),
      distinctUntilChanged(),
      switchMap((pagedSearchQuery) => this.searchItems(groupIdList, pagedSearchQuery))
    );
  }

  public get(id: number): Observable<Product> {
    return this.http.get<Product>(`${this.endpoint}/${id}`);
  }

  public insert(product: Product): Observable<Product> {
    return this.http.post<Product>(this.endpoint, product);
  }

  public update(id: number, product: Product): Observable<Product> {
    return this.http.put<Product>(`${this.endpoint}/${id}`, product);
  }

  private searchItems(
    groupIdList: number[] = [],
    pagedSearchQuery: PagedSearchQuery
  ): Observable<ProductListResult> {
    const params = new HttpParams()
      .set('groupId', groupIdList.length === 1 ? groupIdList[0].toString() : '0')
      .set('groupIds', groupIdList.length > 1 ? groupIdList.join(';') : '')
      .set('offset', pagedSearchQuery.offset.toString())
      .set('limit', pagedSearchQuery.limit.toString());

    return this.http
      .get<ProductListItem[]>(
        `${this.endpoint}?search=${pagedSearchQuery.query.replace(/\+/g, '%2B')}`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(
        map((res) => {
          return new ProductListResult(
            parseInt(res.headers.get('X-Total-Count'), 10),
            pagedSearchQuery.offset,
            pagedSearchQuery.limit,
            pagedSearchQuery.query,
            res.body
          );
        })
      );
  }
}
