import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DateFormatPipe, FromUtcPipe, LocalePipe, LocalTimePipe } from 'ngx-moment';
import { Observable, Subject } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import zipcelx from 'zipcelx-es5';

import { AppComponent } from '../../core/app.component';
import { Globals } from '../../globals';
import { Sale } from '../../sales/shared/sale.model';
import { ProductGroupSummaryListItem } from '../../settings/product-groups/shared/product-group-summary-list-item.model';
import { ProductGroupService } from '../../settings/product-groups/shared/product-group.service';
import { IgnoreInvalidDatePipe } from '../../shared/pipes/ignore-invalid-date.pipe';
import { MeasurementUnitSymbolPipe } from '../../shared/pipes/measurement-unit-symbol.pipe';
import { User } from '../../users/shared/user.model';
import { SaleItemListItem } from '../shared/sale-item-list-item.model';
import { SaleItemService } from '../shared/sale-item.service';

@Component({
  selector: 'app-shipped-from-warehouse',
  templateUrl: './shipped-from-warehouse.component.html',
  styleUrls: ['./shipped-from-warehouse.component.scss']
})
export class ShippedFromWarehouseComponent implements OnDestroy, OnInit {
  public pageName = 'Depodan Gönderilenler';
  public saleItems: SaleItemListItem[] = [];
  public asyncSaleItems: Observable<SaleItemListItem[]>;
  public productGroups: ProductGroupSummaryListItem[] = [];
  public asyncProductGroups: Observable<ProductGroupSummaryListItem[]>;
  public dateFormatPipe = new DateFormatPipe();
  public fromUtcPipe = new FromUtcPipe();
  public ignoreInvalidDatePipe = new IgnoreInvalidDatePipe();
  public localePipe = new LocalePipe();
  public localTimePipe = new LocalTimePipe();
  public measurementUnitSymbolPipe = new MeasurementUnitSymbolPipe();
  public selectedProductGroup: ProductGroupSummaryListItem;
  public selectedProductGroupId = 0;
  public selectedProductId = 0;
  public selectedProductGroupName = '';
  public pageNumber = 1;
  public totalCount: number;
  public perPage: number = Globals.pagination.itemsPerPage;
  public sale: Sale;
  public user: User;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly modalService: NgbModal,
    private readonly productGroupService: ProductGroupService,
    private readonly router: Router,
    private readonly saleItemService: SaleItemService
  ) {}

  public ngOnInit() {
    this.appComponent.setPageTitle(this.pageName);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.pageNumber = parseInt(params['page'], 10) || 1;
      this.selectedProductGroupId = parseInt(params['groupId'], 10) || 0;
      this.selectedProductId = parseInt(params['productId'], 10) || 0;

      this.getProductGroups();
    });
  }

  public getProductGroups() {
    this.asyncProductGroups = this.productGroupService.getSummary('Shipped', 0, 10000).pipe(
      tap(() => {}),
      map((res) => {
        res.productGroups.sort((a, b) => a.order - b.order);

        return res.productGroups;
      }),
      tap((productGroups) => {
        this.productGroups = productGroups;

        if (productGroups.length > 0 && this.selectedProductGroupId === 0) {
          this.selectedProductGroupId = productGroups[0].id;
        }

        if (this.selectedProductGroupId > 0) {
          this.selectedProductGroup = productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          );
          this.selectedProductGroupName = this.selectedProductGroup.name;
        } else {
          this.selectedProductGroup = productGroups.find(
            (g) => g.id === this.selectedProductGroupId
          );
        }

        this.getPage(this.pageNumber);
      }),
      share()
    );
  }

  public changePage(page: number) {
    this.router.navigate(
      ['/warehouse/shipped-from-warehouse'],
      page > 1
        ? {
            queryParams: {
              productId: this.selectedProductId,
              groupId: this.selectedProductGroupId,
              page
            }
          }
        : {
            queryParams: { productId: this.selectedProductId, groupId: this.selectedProductGroupId }
          }
    );
  }

  public getPage(page: number) {
    this.asyncSaleItems = this.saleItemService
      .getAll(
        this.selectedProductGroupId < 0 ? 0 : this.selectedProductGroupId,
        3,
        0,
        (page - 1) * this.perPage,
        this.perPage,
        null,
        moment.utc().toDate(),
        'Shipped',
        this.selectedProductId < 0 ? 0 : this.selectedProductId
      )
      .pipe(
        tap((res) => {
          this.totalCount = res.totalCount;
          this.pageNumber = page;
          this.saleItems = res.saleItems;
        }),
        map((res) => {
          res.saleItems.forEach((p) => {
            p.purchaseItem.product.propertyValues.sort((a, b) => a.propertyOrder - b.propertyOrder);
          });

          return res.saleItems;
        })
      );
  }

  public onProductGroupChange() {
    this.router.navigate(['/warehouse/shipped-from-warehouse'], {
      queryParams: { productId: this.selectedProductId, groupId: this.selectedProductGroupId }
    });
  }

  public saveExcel(): void {
    const itemData = [];

    itemData.push([
      {
        value: 'Ürün Adı',
        type: 'string'
      },
      {
        value: 'Miktar',
        type: 'string'
      },
      {
        value: 'Teslimat Zamanı',
        type: 'string'
      },
      {
        value: 'Müşteri',
        type: 'string'
      }
    ]);

    this.saleItems.forEach((saleItem) => {
      itemData.push(this.createExcelRow(saleItem));
    });

    zipcelx({
      filename: 'depodan-gonderilenler',
      sheet: {
        data: itemData
      }
    });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private createExcelRow(item: SaleItemListItem): any {
    return [
      {
        value: item.purchaseItem.product.name,
        type: 'string'
      },
      {
        value: `${item.saleCount} ${this.measurementUnitSymbolPipe.transform(
          item.purchaseItem.product.groupUnit,
          item.purchaseItem.product.groupUnitGroup
        )}`,
        type: 'string'
      },
      {
        value: this.formatDate(item.deliveryTime),
        type: 'string'
      },
      {
        value: item.customer.title,
        type: 'string'
      }
    ];
  }

  private formatDate(value: Date): string {
    return this.ignoreInvalidDatePipe.transform(
      this.dateFormatPipe.transform(
        this.localePipe.transform(
          this.localTimePipe.transform(this.fromUtcPipe.transform(value)).toString(),
          'tr'
        ),
        'L'
      )
    );
  }
}
