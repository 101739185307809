import { PurchaseListItem } from './purchase-list-item.model';

export class PurchaseListResult {
  totalCount: number;
  offset: number;
  limit: number;
  query: string;
  purchases: PurchaseListItem[];

  constructor(totalCount: number, offset: number, limit: number, query: string, purchases: PurchaseListItem[]) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.query = query;
    this.purchases = purchases;
  }
}
