<div
  *ngIf="input.controlType != 'twoSidedMultiSelect' && input.visible"
  class="form-group"
  [ngClass]="{ 'has-error': shouldShowError }"
  [formGroup]="form"
>
  <label [attr.for]="input.key" class="col-md-4 control-label">{{ input.label }}</label>

  <ng-container class="col-md-4" [ngSwitch]="input.controlType">
    <div *ngSwitchCase="'textbox'" class="col-md-4">
      <input
        [formControlName]="input.key"
        class="form-control"
        [id]="input.key"
        [type]="input.type"
        autocomplete="off"
        [maxlength]="input.maxLength"
      />
    </div>

    <div *ngSwitchCase="'checkbox'" class="col-md-4 dipendo-checkbox">
      <input
        [formControlName]="input.key"
        [id]="input.key"
        type="checkbox"
        autocomplete="off"
        [value]="input.value"
      />
    </div>

    <div *ngSwitchCase="'textarea'" class="col-md-4">
      <textarea
        [formControlName]="input.key"
        class="form-control"
        [id]="input.key"
        [rows]="input.rowCount"
        autocomplete="off"
        >{{ input.value }}</textarea
      >
    </div>

    <div *ngSwitchCase="'dropdown'" class="col-md-4">
      <select
        [formControlName]="input.key"
        class="form-control"
        [id]="input.key"
        (change)="input.onChange($event.target.value)"
      >
        <option value="" *ngIf="input.showSelect">-- Seçiniz --</option>
        <option *ngFor="let option of input.options" [value]="option.key">{{
          option.value
        }}</option>
      </select>
    </div>

    <div *ngSwitchCase="'datepicker'" class="col-md-4">
      <input
        [formControlName]="input.key"
        class="form-control pull-right"
        [id]="input.key"
        type="text"
        autocomplete="off"
      />
    </div>
  </ng-container>
  <span class="col-md-4 help-block">
    <ng-container *ngIf="shouldShowError" [ngSwitch]="firstError">
      <ng-container *ngSwitchCase="'required'"> {{ input.label }} alanı zorunludur. </ng-container>
      <ng-container *ngSwitchCase="'email'">
        Lütfen geçerli bir e-posta adresi girin.
      </ng-container>
      <ng-container *ngSwitchCase="'minDate'">
        {{ input.label }}, {{ firstErrorValue.label }} için girilenden önceki bir tarih olamaz.
      </ng-container>
      <ng-container *ngSwitchCase="'maxDate'">
        {{ input.label }}, {{ firstErrorValue.label }} için girilenden sonraki bir tarih olamaz.
      </ng-container>
    </ng-container>
    &nbsp;
  </span>
</div>
<div
  *ngIf="input.controlType == 'twoSidedMultiSelect' && input.visible"
  class="form-group"
  [ngClass]="{ 'has-error': shouldShowError }"
  [formGroup]="form"
>
  <label [attr.for]="input.key" class="col-md-4 control-label">{{ input.label }}</label>

  <div class="col-md-4">
    <select
      multiple
      [size]="input.size"
      class="form-control select-multiple-with-button"
      [id]="input.key"
      [formControlName]="input.key"
    >
      <option *ngFor="let option of input.options" [value]="option.key">{{ option.value }}</option>
    </select>
    <button
      type="button"
      name="add"
      id="add"
      class="btn btn-success btn-block"
      (click)="moveToRight()"
      [innerHtml]="input.buttonLabelLeft"
    ></button>
  </div>

  <span class="col-md-4 help-block">
    &nbsp;
  </span>
</div>
<div
  *ngIf="input.controlType == 'twoSidedMultiSelect' && input.visible"
  class="form-group"
  [ngClass]="{ 'has-error': shouldShowError }"
  [formGroup]="form"
>
  <label [attr.for]="input.key" class="col-md-4 control-label">{{ input.labelRight }}</label>

  <div class="col-md-4">
    <select
      multiple
      [size]="input.sizeRight"
      class="form-control select-multiple-with-button"
      [id]="input.keyRight"
      [formControlName]="input.keyRight"
    >
      <option *ngFor="let option of input.optionsRight" [value]="option.key">{{
        option.value
      }}</option>
    </select>
    <button
      type="button"
      name="remove"
      id="remove"
      class="btn btn-danger btn-block"
      (click)="moveToLeft()"
      [innerHtml]="input.buttonLabelRight"
    ></button>
  </div>

  <span class="col-md-4 help-block">
    &nbsp;
  </span>
</div>
