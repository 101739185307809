import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'dipendo-pagination',
  templateUrl: './dipendo-pagination.component.html',
  styleUrls: ['./dipendo-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DipendoPaginationComponent {

  @Input() id: string;
  @Input() maxSize = 7;
  @Input()
  get directionLinks(): boolean {
    return this._directionLinks;
  }
  set directionLinks(value: boolean) {
    this._directionLinks = !!value && value as any !== 'false';
  }
  @Input()
  get autoHide(): boolean {
    return this._autoHide;
  }
  set autoHide(value: boolean) {
    this._autoHide = !!value && value as any !== 'false';
  }
  @Input() previousLabel = `&laquo;`;
  @Input() nextLabel = '&raquo;';
  @Input() screenReaderPaginationLabel = 'Sayfalar';
  @Input() screenReaderPageLabel = 'sayfa';
  @Input() screenReaderCurrentLabel = `Şu sayfadasınız:`;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  private _directionLinks = true;
  private _autoHide = false;

}
