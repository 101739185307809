import { ProductListItem } from './product-list-item.model';

export class ProductListResult {
  totalCount: number;
  offset: number;
  limit: number;
  query: string;
  products: ProductListItem[];

  constructor(totalCount: number, offset: number, limit: number, query: string, products: ProductListItem[]) {
    this.totalCount = totalCount;
    this.offset = offset;
    this.limit = limit;
    this.query = query;
    this.products = products;
  }
}
