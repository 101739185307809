<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<section class="content">
  <div class="row">
    <div class="col-md-12">
      <!-- Default box -->
      <div class="box box-primary" *ngIf="purchase.purchaseId > 0">
        <div class="box-header with-border">
          <h3 class="box-title">Özet</h3>
          <div class="box-tools">
            <button type="button" class="btn btn-sm btn-success" (click)="changeSummaryDetail()">
              Özet Bilgileri Güncelle
            </button>
          </div>
        </div>
        <!-- /.box-header -->
        <div class="box-body">
          <dl class="dl-horizontal">
            <dt>Tedarikçi</dt>
            <dd>
              <a [routerLink]="['/suppliers', purchase.supplier.supplierId, 'detail']">{{
                purchase.supplier.title
              }}</a>
            </dd>
            <dt>Satın Alan Kullanıcı</dt>
            <dd>{{ purchase.user.firstName + ' ' + purchase.user.lastName }}</dd>
            <dt>Sipariş Tarihi</dt>
            <dd>
              {{
                purchase.orderTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </dd>
            <dt *ngIf="purchase.letterOfCreditTime">Akreditif Tarihi</dt>
            <dd *ngIf="purchase.letterOfCreditTime">
              {{
                purchase.letterOfCreditTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </dd>
            <dt *ngIf="purchase.estimatedShippingTime">Tahmini Yükleme Tarihi</dt>
            <dd *ngIf="purchase.estimatedShippingTime">
              {{
                purchase.estimatedShippingTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </dd>
            <dt *ngIf="purchase.estimatedArrivalTime">Tahmini Varış Tarihi</dt>
            <dd *ngIf="purchase.estimatedArrivalTime">
              {{
                purchase.estimatedArrivalTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </dd>
            <dt *ngIf="purchase.arrivalTime">Kesin Varış Tarihi</dt>
            <dd *ngIf="purchase.arrivalTime">
              {{
                purchase.arrivalTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
            </dd>
            <dt *ngIf="purchase.invoiceTime || purchase.invoiceNumber">Fatura</dt>
            <dd *ngIf="purchase.invoiceTime || purchase.invoiceNumber">
              {{
                purchase.invoiceTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
              -
              {{ purchase.invoiceNumber }}
            </dd>
            <dt *ngIf="purchase.customsDeclarationTime || purchase.customsDeclarationNumber">
              Gümrük Beyannamesi
            </dt>
            <dd *ngIf="purchase.customsDeclarationTime || purchase.customsDeclarationNumber">
              {{
                purchase.customsDeclarationTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'L'
                  | ignoreInvalidDate
              }}
              -
              {{ purchase.customsDeclarationNumber }}
            </dd>
            <dt>Açıklama</dt>
            <dd>{{ purchase.explanation }}</dd>
          </dl>
        </div>
        <!-- /.box-body -->
      </div>
      <!-- /.box -->

      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li class="active" (click)="clearSelection(1)">
            <a href="#tab_1" data-toggle="tab"><strong>Sipariş</strong></a>
          </li>
          <li (click)="clearSelection(2)">
            <a href="#tab_2" data-toggle="tab"><strong>Transfer</strong></a>
          </li>
          <li (click)="clearSelection(3)">
            <a href="#tab_3" data-toggle="tab"><strong>Gümrük</strong></a>
          </li>
          <li (click)="clearSelection(4)">
            <a href="#tab_4" data-toggle="tab"><strong>Depo/Satılan</strong></a>
          </li>
          <li class="box-tools">
            <button type="button" class="btn btn-sm btn-success" (click)="saveQrCodes()">
              Karekod Oluştur
            </button>
            <button
              type="button"
              class="btn btn-sm btn-success"
              (click)="saveExcel()"
              style="margin-left: 4pt;"
            >
              Excel'e Aktar
            </button>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane active" id="tab_1">
            <ng-container *ngIf="productGroupIds.get(1)">
              <ng-container *ngFor="let productGroupId of productGroupIds.get(1); let i = index">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style="width: 10px;">
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="headerSelectionList.get(1)[i].selected"
                          (change)="onChangeHeaderCheckbox(1, i)"
                        />
                      </th>
                      <th>Ürün Adı</th>
                      <th style="width: 100px;">Ağırlık</th>
                      <th style="width: 100px;">Hazırlanma Tarihi</th>
                      <th style="width: 100px;">Tahmini Yükleme Tarihi</th>
                      <th style="width: 100px;">Tahmini Varış Tarihi</th>
                      <th style="width: 100px;">Kesin Varış Tarihi</th>
                      <th style="width: 100px;">Satın Alma Fiyatı</th>
                      <th style="width: 80px;">Alınan Miktar</th>
                      <th style="width: 120px;">Toplam Satın Alma Fiyatı</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let purchaseItem of purchase.purchaseItems
                          | matchProductGroup: productGroupId
                          | matchPurchaseItemStatus: 1
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="purchaseItem.selected"
                          (change)="onChangeItemCheckbox(purchaseItem, i)"
                        />
                      </td>
                      <td>
                        <a
                          [routerLink]="['/purchase-items', purchaseItem.purchaseItemId, 'detail']"
                          >{{ purchaseItem.product.name }}</a
                        >
                      </td>
                      <td>
                        {{
                          purchaseItem.product.unitMass * purchaseItem.purchaseCount
                            | number: '1.0-3'
                        }}
                        {{ purchaseItem.product.unitOfMass }}
                      </td>
                      <td>
                        {{
                          purchaseItem.letterOfCreditTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedShippingTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedArrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.arrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                      <td>
                        {{ purchaseItem.purchaseCount }}
                        {{
                          productGroups.get(productGroupId)?.unit
                            | measurementUnitSymbol: productGroups.get(productGroupId)?.unitGroup
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost * purchaseItem.purchaseCount | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!productGroupIds.get(1)">
              <p><strong>"Sipariş"</strong> durumunda bir sipariş bulunmuyor.</p>
            </ng-container>
          </div>

          <div class="tab-pane" id="tab_2">
            <ng-container *ngIf="productGroupIds.get(2)">
              <ng-container *ngFor="let productGroupId of productGroupIds.get(2); let i = index">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style="width: 10px;">
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="headerSelectionList.get(2)[i].selected"
                          (change)="onChangeHeaderCheckbox(2, i)"
                        />
                      </th>
                      <th>Ürün Adı</th>
                      <th style="width: 100px;">Ağırlık</th>
                      <th style="width: 100px;">Akreditif Tarihi</th>
                      <th style="width: 100px;">Tahmini Yükleme Tarihi</th>
                      <th style="width: 100px;">Tahmini Varış Tarihi</th>
                      <th style="width: 100px;">Kesin Varış Tarihi</th>
                      <th style="width: 100px;">Satın Alma Fiyatı</th>
                      <th style="width: 80px;">Alınan Miktar</th>
                      <th style="width: 120px;">Toplam Satın Alma Fiyatı</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let purchaseItem of purchase.purchaseItems
                          | matchProductGroup: productGroupId
                          | matchPurchaseItemStatus: 2
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="purchaseItem.selected"
                          (change)="onChangeItemCheckbox(purchaseItem, i)"
                        />
                      </td>
                      <td>
                        <a
                          [routerLink]="['/purchase-items', purchaseItem.purchaseItemId, 'detail']"
                          >{{ purchaseItem.product.name }}</a
                        >
                      </td>
                      <td>
                        {{
                          purchaseItem.product.unitMass * purchaseItem.purchaseCount
                            | number: '1.0-3'
                        }}
                        {{ purchaseItem.product.unitOfMass }}
                      </td>
                      <td>
                        {{
                          purchaseItem.letterOfCreditTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedShippingTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedArrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.arrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                      <td>
                        {{ purchaseItem.purchaseCount }}
                        {{
                          productGroups.get(productGroupId)?.unit
                            | measurementUnitSymbol: productGroups.get(productGroupId)?.unitGroup
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost * purchaseItem.purchaseCount | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!productGroupIds.get(2)">
              <p><strong>"Transfer"</strong> durumunda bir sipariş bulunmuyor.</p>
            </ng-container>
          </div>

          <div class="tab-pane" id="tab_3">
            <ng-container *ngIf="productGroupIds.get(3)">
              <ng-container *ngFor="let productGroupId of productGroupIds.get(3); let i = index">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style="width: 10px;">
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="headerSelectionList.get(3)[i].selected"
                          (change)="onChangeHeaderCheckbox(3, i)"
                        />
                      </th>
                      <th>Ürün Adı</th>
                      <th style="width: 100px;">Ağırlık</th>
                      <th style="width: 100px;">Akreditif Tarihi</th>
                      <th style="width: 100px;">Tahmini Yükleme Tarihi</th>
                      <th style="width: 100px;">Tahmini Varış Tarihi</th>
                      <th style="width: 100px;">Kesin Varış Tarihi</th>
                      <th style="width: 100px;">Satın Alma Fiyatı</th>
                      <th style="width: 80px;">Alınan Miktar</th>
                      <th style="width: 120px;">Toplam Satın Alma Fiyatı</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let purchaseItem of purchase.purchaseItems
                          | matchProductGroup: productGroupId
                          | matchPurchaseItemStatus: 3
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="purchaseItem.selected"
                          (change)="onChangeItemCheckbox(purchaseItem, i)"
                        />
                      </td>
                      <td>
                        <a
                          [routerLink]="['/purchase-items', purchaseItem.purchaseItemId, 'detail']"
                          >{{ purchaseItem.product.name }}</a
                        >
                      </td>
                      <td>
                        {{
                          purchaseItem.product.unitMass * purchaseItem.purchaseCount
                            | number: '1.0-3'
                        }}
                        {{ purchaseItem.product.unitOfMass }}
                      </td>
                      <td>
                        {{
                          purchaseItem.letterOfCreditTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedShippingTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedArrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.arrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                      <td>
                        {{ purchaseItem.purchaseCount }}
                        {{
                          productGroups.get(productGroupId)?.unit
                            | measurementUnitSymbol: productGroups.get(productGroupId)?.unitGroup
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost * purchaseItem.purchaseCount | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!productGroupIds.get(3)">
              <p><strong>"Gümrük"</strong> durumunda bir sipariş bulunmuyor.</p>
            </ng-container>
          </div>

          <div class="tab-pane" id="tab_4">
            <ng-container *ngIf="productGroupIds.get(4)">
              <ng-container *ngFor="let productGroupId of productGroupIds.get(4); let i = index">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style="width: 10px;">
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="headerSelectionList.get(4)[i].selected"
                          (change)="onChangeHeaderCheckbox(4, i)"
                        />
                      </th>
                      <th>Ürün Adı</th>
                      <th style="width: 100px;">Ağırlık</th>
                      <th style="width: 100px;">Akreditif Tarihi</th>
                      <th style="width: 100px;">Tahmini Yükleme Tarihi</th>
                      <th style="width: 100px;">Tahmini Varış Tarihi</th>
                      <th style="width: 100px;">Kesin Varış Tarihi</th>
                      <th style="width: 100px;">Satın Alma Fiyatı</th>
                      <th style="width: 80px;">Alınan Miktar</th>
                      <th style="width: 120px;">Toplam Satın Alma Fiyatı</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let purchaseItem of purchase.purchaseItems
                          | matchProductGroup: productGroupId
                          | matchPurchaseItemStatus: 4
                      "
                    >
                      <td>
                        <input
                          type="checkbox"
                          autocomplete="off"
                          [(ngModel)]="purchaseItem.selected"
                          (change)="onChangeItemCheckbox(purchaseItem, i)"
                        />
                      </td>
                      <td>
                        <a
                          [routerLink]="['/purchase-items', purchaseItem.purchaseItemId, 'detail']"
                          >{{ purchaseItem.product.name }}</a
                        >
                      </td>
                      <td>
                        {{
                          purchaseItem.product.unitMass * purchaseItem.purchaseCount
                            | number: '1.0-3'
                        }}
                        {{ purchaseItem.product.unitOfMass }}
                      </td>
                      <td>
                        {{
                          purchaseItem.letterOfCreditTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedShippingTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.estimatedArrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{
                          purchaseItem.arrivalTime
                            | amFromUtc
                            | amLocal
                            | amLocale: 'tr'
                            | amDateFormat: 'L'
                            | ignoreInvalidDate
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                      <td>
                        {{ purchaseItem.purchaseCount }}
                        {{
                          productGroups.get(productGroupId)?.unit
                            | measurementUnitSymbol: productGroups.get(productGroupId)?.unitGroup
                        }}
                      </td>
                      <td>
                        {{ purchaseItem.cost * purchaseItem.purchaseCount | number: '1.2-4' }}
                        {{ purchaseItem.product.currencyCode }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!productGroupIds.get(4)">
              <p><strong>"Depo/Satılan"</strong> durumunda bir sipariş bulunmuyor.</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-calculator" *ngIf="selectedCount > 0">
    {{ selectedCount }} öğe seçildi. Toplam Kütle: {{ selectedMass | number: '1.0-3' }} kg, Toplam
    Tutar: {{ selectedCosts.join(' + ') }}
    <a
      href="javascript:void(0);"
      *ngIf="selectedTab === 1"
      (click)="cancelItem()"
      class="button"
      title="İptal Et"
      ><i class="fa fa-trash"></i
    ></a>
    <a
      href="javascript:void(0);"
      *ngIf="selectedTab > 1 && selectedTab < 4"
      (click)="changeItemStatus(-1)"
      class="button"
      title="Geri Al"
      ><i class="fa fa-step-backward"></i
    ></a>
    <a
      href="javascript:void(0);"
      *ngIf="selectedTab < 4"
      (click)="changeItemStatus(1)"
      class="button"
      title="İlerlet"
      ><i class="fa fa-step-forward"></i
    ></a>
    <a
      href="javascript:void(0);"
      *ngIf="selectedTab < 4"
      (click)="changeItemDetail()"
      class="button"
      title="Düzenle"
      ><i class="fa fa-edit"></i
    ></a>
  </div>
</section>
