import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;

    if (req.url.indexOf('/oauth/token') < 0 && this.authService.token) {
      authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${this.authService.token}`) });
    }

    return next.handle(authReq);
  }
}
