import { Pipe, PipeTransform } from '@angular/core';

import { PurchaseItem } from '../../purchases/shared/purchase-item.model';
import { SaleItem } from '../../sales/shared/sale-item.model';

@Pipe({
    name: 'matchProductGroup',
    pure: false
})
export class MatchProductGroupPipe implements PipeTransform {
  public transform(items: any[], productGroupId: number): any[] {
    if (items[0].product) {
      return items.filter(item => item.product.productGroupId === productGroupId);
    } else if (items[0].purchaseItem.product) {
      return items.filter(item => item.purchaseItem.product.productGroupId === productGroupId);
    } else {
      return items;
    }
  }
}
