import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { share, takeUntil, tap } from 'rxjs/operators';

import { AlertType } from '../../../core/alert-type.model';
import { AlertService } from '../../../core/alert.service';
import { Globals } from '../../../globals';
import { DipendoFormComponent } from '../../../shared/forms/dipendo-form/dipendo-form.component';
import { InputBase } from '../../../shared/forms/input-base';
import { InputCheckbox } from '../../../shared/forms/input-checkbox';
import { InputDropdown } from '../../../shared/forms/input-dropdown';
import { InputTextbox } from '../../../shared/forms/input-textbox';
import { ProductGroupPropertyEditComponent } from '../product-group-property-edit/product-group-property-edit.component';
import { ProductGroupProperty } from '../shared/product-group-property.model';
import { ProductGroup } from '../shared/product-group.model';
import { ProductGroupService } from '../shared/product-group.service';

@Component({
  selector: 'app-product-group-edit',
  templateUrl: './product-group-edit.component.html',
  styleUrls: ['./product-group-edit.component.scss']
})
export class ProductGroupEditComponent implements AfterViewInit, OnDestroy, OnInit {
  public inputs: any[];
  public productGroup: ProductGroup = new ProductGroup(0, false, '', 0, '', '', false, []);
  public error = false;
  public productGroupPropertyTypes = Globals.productGroupPropertyTypes;
  public measurementUnits: any;

  private selectedId: number;
  private selectedProductGroup: ProductGroup;

  @ViewChild(DipendoFormComponent, { static: true })
  private readonly formComponent: DipendoFormComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly modalService: NgbModal,
    private readonly productGroupService: ProductGroupService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.inputs = this.getInputs();
  }

  public ngAfterViewInit(): void {
    this.activatedRoute.url.subscribe((url) => {
      if (url[url.length - 1].path === 'edit') {
        this.activatedRoute.params.subscribe((params) => {
          this.selectedId = parseInt(params.id, 10) || -1;

          if (this.selectedId > 0) {
            this.getProductGroup(this.selectedId);
          } else {
            this.router.navigate(['/settings/product-groups']);
          }
        });
      }
    });
  }

  public unitGroupOnChange(value: string, shouldReset = true) {
    const options: any[] = [];

    if (value !== '') {
      for (const unit of Globals.measurementUnits.find((m) => m.id === value).units) {
        options.push({
          key: unit.id,
          value: unit.name
        });
      }
    }

    ((this['pageReference'].inputs as Array<InputBase<any>>).find(
      (i) => i.key === 'unit'
    ) as InputDropdown).options = options;

    if (shouldReset) {
      (this['pageReference'].formComponent as DipendoFormComponent).form.get('unit').setValue('');
    }
  }

  public openProductGroupPropertyDialog(productGroupProperty: ProductGroupProperty): void {
    const index = productGroupProperty
      ? this.productGroup.productGroupProperties.indexOf(productGroupProperty)
      : -1;
    const isEdit = productGroupProperty ? true : false;
    const modalRef = this.modalService.open(ProductGroupPropertyEditComponent, {
      windowClass: 'large-modal'
    });
    modalRef.componentInstance.isEdit = isEdit;
    modalRef.componentInstance.productGroupProperty = { ...productGroupProperty };

    modalRef.result.then(
      (res) => {
        if (isEdit) {
          this.productGroup.productGroupProperties[index] = { ...res };
        } else {
          res.order =
            Math.max(
              ...this.productGroup.productGroupProperties.map((o) => {
                return o.order;
              }),
              0
            ) + 1;

          this.productGroup.productGroupProperties.push(res);
          this.productGroup.productGroupProperties.sort((a, b) => a.order - b.order);
        }
      },
      (err) => {}
    );
  }

  public changeOrder(productGroupProperty: ProductGroupProperty, change: number): void {
    const newOrder = productGroupProperty.order + change;

    for (const property of this.productGroup.productGroupProperties) {
      if (property.order === newOrder) {
        property.order = productGroupProperty.order;
        productGroupProperty.order = newOrder;
        break;
      }
    }

    this.productGroup.productGroupProperties.sort((a, b) => a.order - b.order);
  }

  public removeProductGroupProperty(productGroupProperty: ProductGroupProperty): void {
    const oldOrder = productGroupProperty.order;

    this.productGroup.productGroupProperties.splice(
      this.productGroup.productGroupProperties.indexOf(productGroupProperty),
      1
    );

    for (const property of this.productGroup.productGroupProperties) {
      if (property.order > oldOrder) {
        property.order--;
      }
    }
  }

  public onSubmit(formComponent: DipendoFormComponent) {
    if (this.selectedId > 0) {
      this.productGroupService
        .update(this.selectedId, this.prepareBindingObject(formComponent))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res) => {
            this.alertService.setNextAlert(AlertType.success, 'Ürün grubu başarıyla güncellendi.');
            this.router.navigate(['/settings/product-groups']);
          },
          (err) => {
            formComponent.isError = true;
            formComponent.errorMessage = err.error.message;
          }
        );
    } else {
      this.productGroupService
        .insert(this.prepareBindingObject(formComponent))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res) => {
            this.alertService.setNextAlert(AlertType.success, 'Ürün Grubu başarıyla eklendi.');
            this.router.navigate(['/settings/product-groups']);
          },
          (err) => {
            formComponent.isError = true;
            formComponent.errorMessage = err.error.message;
          }
        );
    }
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getProductGroup(id: number) {
    this.productGroupService
      .get(id)
      .pipe(
        tap(
          (res) => {},
          (err) => {
            this.error = true;
          }
        ),
        share(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((res) => {
        this.selectedProductGroup = res;
        this.productGroup = res;

        this.formComponent.setValues(this.selectedProductGroup);

        this.formComponent.inputs
          .find((i) => i.key === 'unitGroup')
          .onChange(this.selectedProductGroup.unitGroup, false);
        this.productGroup.productGroupProperties.sort((a, b) => a.order - b.order);
      });
  }

  private getInputs(): Array<InputBase<any>> {
    const inputs: Array<InputBase<any>> = [
      new InputTextbox({
        key: 'name',
        label: 'Ad',
        type: 'text',
        value: '',
        required: true,
        order: 1
      }),
      new InputDropdown({
        key: 'unitGroup',
        label: 'Ölçü',
        onChange: this.unitGroupOnChange,
        pageReference: this,
        options: [
          { key: 'length', value: 'Uzunluk' },
          { key: 'number', value: 'Sayı' },
          { key: 'weight', value: 'Ağırlık' },
          { key: 'volume', value: 'Hacim' }
        ],
        required: true,
        showSelect: true,
        value: '',
        order: 2
      }),
      new InputDropdown({
        key: 'unit',
        label: 'Birim',
        options: [],
        required: true,
        showSelect: true,
        value: '',
        order: 3
      }),
      new InputTextbox({
        key: 'order',
        label: 'Sıralama',
        type: 'number',
        value: '',
        required: true,
        order: 4
      }),
      new InputCheckbox({
        key: 'areProductsUnique',
        label: 'Benzersiz Ürünler',
        value: false,
        checked: false,
        order: 5
      }),
      new InputCheckbox({
        key: 'isActive',
        label: 'Aktif',
        value: true,
        checked: false,
        order: 6
      })
    ];

    return inputs.sort((a, b) => a.order - b.order);
  }

  private prepareBindingObject(formComponent: DipendoFormComponent): ProductGroup {
    return {
      productGroupId: this.selectedId > 0 ? this.selectedId : 0,
      name: formComponent.form.value.name,
      order: formComponent.form.value.order,
      productGroupProperties: this.productGroup.productGroupProperties.map((x) => ({ ...x })),
      areProductsUnique:
        formComponent.form.value.areProductsUnique === true ||
        formComponent.form.value.areProductsUnique === 'true',
      isActive:
        formComponent.form.value.isActive === true || formComponent.form.value.isActive === 'true',
      unit: formComponent.form.value.unit,
      unitGroup: formComponent.form.value.unitGroup
    };
  }
}
