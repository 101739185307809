export class InputBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  disabled: boolean;
  order: number;
  controlType: string;
  onChange: Function;
  pageReference: this;
  visible: boolean;

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    disabled?: boolean,
    order?: number,
    controlType?: string,
    onChange?: Function,
    pageReference?: any,
    visible?: boolean
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.disabled = !!options.disabled;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.onChange = options.onChange || new Function();
    this.pageReference = options.pageReference;
    this.visible = options.visible || true;
  }
}
