import { InputBase } from './input-base';

export class InputDatepicker extends InputBase<string> {
  public controlType = 'datepicker';
  public minDates: string[];
  public maxDates: string[];
  public startDate: string;
  public orientation: string;

  constructor(options: {} = {}) {
    super(options);
    this.minDates = ((options['minDates'] || '') as string).split(',');
    this.maxDates = ((options['maxDates'] || '') as string).split(',');
    this.startDate = options['startDate'] || '';
    this.orientation = options['orientation'] || '';
  }
}
