import { ReportListItem } from '../report/report-list-item.model';

export class ProfitabilityReportListResult {
  public totalCount: number;
  public reports: ReportListItem;

  constructor(totalCount: number, reports: ReportListItem) {
    this.totalCount = totalCount;
    this.reports = reports;
  }
}
