<section class="content-header">
  <h1>
    <a href="javascript:history.back();"><i class="fa fa-fw fa-arrow-circle-o-left"></i></a>
    {{ pageName }}
    <small>{{ selectedPurchaseItemFile?.fileName }}</small>
  </h1>
  <ol class="breadcrumb">
    <li>
      <a routerLink=""><i class="fa fa-home"></i> Ana Sayfa</a>
    </li>
    <li class="active">{{ pageName }}</li>
  </ol>
</section>

<!-- Main content -->
<section class="content">
  <div class="row" *ngIf="asyncPurchaseItemFile | async as purchaseItemFile">
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Özet</h3>
          <div class="box-tools">
            <button type="button" class="btn btn-sm btn-success" (click)="downloadFile()">
              Dosyayı İndir
            </button>
          </div>
        </div>
        <div class="box-body">
          <dl class="dl-horizontal">
            <dt>Açıklama</dt>
            <dd>{{ purchaseItemFile.description }}</dd>
          </dl>
          <dl class="dl-horizontal">
            <dt>Büyüklük</dt>
            <dd>{{ purchaseItemFile.size | filesize }}</dd>
          </dl>
          <dl class="dl-horizontal">
            <dt>Yükleme Zamanı</dt>
            <dd>
              {{
                purchaseItemFile.recordTime
                  | amFromUtc
                  | amLocal
                  | amLocale: 'tr'
                  | amDateFormat: 'LLL'
                  | ignoreInvalidDate
              }}
            </dd>
          </dl>
          <dl class="dl-horizontal">
            <dt>Yükleyen</dt>
            <dd>{{ purchaseItemFile.user.firstName + ' ' + purchaseItemFile.user.lastName }}</dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">Paylaşım</h3>
          <div class="box-tools">
            <ng-template
              [ngIf]="purchaseItemFile.isPublicSharingActive"
              [ngIfElse]="boxHeaderElseBlock"
            >
              <button type="button" class="btn btn-sm btn-danger" (click)="unshareFile()">
                Paylaşımı Durdur
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                (click)="saveQrCode()"
                style="margin-left: 4pt;"
              >
                Karekodu İndir
              </button>
            </ng-template>
            <ng-template #boxHeaderElseBlock>
              <button type="button" class="btn btn-sm btn-success" (click)="shareFile()">
                Paylaş
              </button>
            </ng-template>
          </div>
        </div>
        <div class="box-body">
          <ng-template
            [ngIf]="purchaseItemFile.isPublicSharingActive"
            [ngIfElse]="boxBodyElseBlock"
          >
            <p>Bu dosya dış dünya ile aşağıdaki karekod ve bağlantı üzerinden paylaşılıyor.</p>
            <img
              class="centered-block"
              alt="{{ purchaseItemFile.fileName }} karekodu"
              src="{{ asyncQrCode }}"
            />
            <a
              *ngIf="publicFileUrl"
              href="{{ publicFileUrl }}"
              target="_blank"
              class="centered-block"
              style="word-break: break-all;"
              >{{ publicFileUrl }}</a
            >
          </ng-template>
          <ng-template #boxBodyElseBlock>
            <p class="text-muted">
              Bu dosya dış dünya ile paylaşılmıyor. Paylaş düğmesine tıklayarak herkese açık bir
              bağlantı ve karekod oluşturabilirsiniz.
            </p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
